<!-- DEPRECATED -->

<unh-side-menu position="right" [isOpen]="isOpen">
  <unh-side-menu-header>
    <a class="close-btn" (click)="close()">&#x2573;</a>

    <h3 class="title">{{ menuTitle }}</h3>

    <unh-button-old-container design="icony" *ngIf="menuType === 'user_menu'">
      <a (click)="onItemClicked($event)" [title]="username" [ngStyle]="{ width: '34px', height: '34px'}" [routerLink]="['/perfil/' + uid + '/' + username]">
          <unh-user-avatar
              [avatarUrl]="useravatarurl"
              [username]="username"
              [uid]="uid"
          ></unh-user-avatar>
      </a>
    </unh-button-old-container>
  </unh-side-menu-header>

  <div class="nano">
    <unh-side-menu-content class="nano-content" [class.centered]="!itensList || itensList.length === 0">
      <ng-content *ngIf="itensList && itensList.length > 0"></ng-content>

      <!-- <div class="spinner-loader-container" *ngIf="!itensList || itensList.length === 0">
          <unh-spinner-loader></unh-spinner-loader>
      </div> -->

      <div *ngIf="!itensList || itensList.length === 0" style="max-width: 100%; padding: 32px;">
        <img
          style="max-width: inherit"
          src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/empty-notifications.png"
          alt="Nenhuma notificação encontrada."
        >
      </div>

      <unh-generic-list-container *ngIf="menuType === 'notifications_menu'" [itensList]="itensList">
          <ng-template let-item>
              <unh-notifications-menu-item [notificationItem]="item" (itemClicked)="onItemClicked($event)">
              </unh-notifications-menu-item>
          </ng-template>
      </unh-generic-list-container>

      <unh-generic-list-container *ngIf="menuType === 'user_menu'" [itensList]="itensList">
        <ng-template let-item>
          <ng-container [ngSwitch]="item.itemtype">
            <unh-user-menu-item-button
              *ngSwitchCase="'action'"
              [itemAction]="item.link"
              [itemTitle]="item.title"
              [itemSubtitle]="item.subtitle"
              [itemIconType]="item.iconType"
              [itemIcon]="item.icon"
              (clickAction)="onItemClicked($event)"
            ></unh-user-menu-item-button>

            <unh-user-menu-item-link
              *ngSwitchDefault
              [itemLink]="item.link"
              [itemTitle]="item.title"
              [itemSubtitle]="item.subtitle"
              [itemIconType]="item.iconType"
              [itemIcon]="item.icon"
              (itemClicked)="onItemClicked($event)"
            ></unh-user-menu-item-link>
          </ng-container>
        </ng-template>
      </unh-generic-list-container>
    </unh-side-menu-content>
  </div>
</unh-side-menu>

