export interface UserGendersQueryData {
  genderid: number;
  name: string;
}

export const userGendersQuery = `
  query UserGendersQuery {
    UserGender {
      all {
        items {
          genderid
          name
        }
      }
    }
  }
`;
