<div class="generic-galery-admin-toolbar" >
    <div class="edit-mode-switch">
        <span>{{translations?.editModeSwitchLabel}}</span>        
        <unh-switch 
            class="unh-side-toggle" 
            (unhChange)="onEditModeChanged($event)" 
            [checked]="editMode">
        </unh-switch>
    </div>
    <div class="flex-space"></div>

    <mat-form-field *ngIf="!isProject && displayOptions" class="list-display-options" [class.unh-disabled]="!editMode">
        <mat-label>{{translations?.listDisplayOptions?.matLabel}} </mat-label>

        <mat-select
            class="unh-select"
            panelClass="unh-select-panel"
            [compareWith]="compareObjectsByValueKey"
            [formControl]="selectedDisplayOption"
            [disabled]="!editMode"
            (selectionChange)="onListFilterChange()"

        >
            <mat-option *ngFor="let option of displayOptions" [value]="option" >{{option.viewValue}}</mat-option>
        </mat-select>
    </mat-form-field>
    <div class="flex-space"></div>
</div>
