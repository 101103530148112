<unh-step-card
  [ngClass]="inDialog ? 'unh-change-password-container in-dialog' : 'unh-change-password-container'"
  [step]="0"
  [maxWidth]="512"
>
  <div slot="content">
    <form
      #changePasswordForm="ngForm"
      [formGroup]="changePasswordFormGroup"
      class="step"
    >
      <unh-button *ngIf="!inDialog" class="back" routerLink="/recover-password">
        <unh-text-icon>
          <unh-icon
            style="padding-right: 14px"
            url="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/course-admin/back-course-admin-icon.svg"
          ></unh-icon>
        </unh-text-icon>

        {{pageTranslations?.backButtonText}}
      </unh-button>

      <h1 class="title">{{pageTranslations?.h1ChangePasswordTitle}}</h1>

      <span>{{pageTranslations?.insertNewPasswordspanText}}</span>

      <unh-input
        class="first"
        type="password"
        placeholder={{pageTranslations?.newPasswordinputPlaceholder}}
        unhInpuAcessor
        formControlName="password"
      ></unh-input>

      <unh-input
        unhInpuAcessor
        formControlName="confirmPassword"
        type="password"
        placeholder={{pageTranslations?.confirmPasswordInputPlaceholder}}
      ></unh-input>
      <div class="validations">
        <unh-validation-text [variant]="validationMaxLength">
          {{pageTranslations?.validationsTexts?.validationMaxLengthText}}
        </unh-validation-text>

        <unh-validation-text [variant]="validationMinLengthPassword">
          {{pageTranslations?.validationsTexts?.validationMinLengthPasswordText}}
        </unh-validation-text>

        <unh-validation-text [variant]="validationContainNumberPassword">
          {{pageTranslations?.validationsTexts?.validationContainNumberPasswordText}}
        </unh-validation-text>

        <unh-validation-text
          [variant]="state.matchPassword ? 'completed' : 'wrong'"
        >
          {{pageTranslations?.validationsTexts?.validationMatchPasswordText}}
        </unh-validation-text>

        <unh-validation-text [variant]="validationContainUppercaseLetter">
          {{pageTranslations?.validationsTexts?.validationContainUppercaseLetterText}}
        </unh-validation-text>

        <unh-validation-text [variant]="validationSpecialCharacter">
          {{pageTranslations?.validationsTexts?.validationSpecialCharacterText}}
        </unh-validation-text>
      </div>
      <unh-button
        [disabled]="!(state.matchPassword && changePasswordFormGroup.valid)"
        (click)="onSubmit()"
        class="final"
        block
        color="gradient"
        >{{pageTranslations?.confirmButtonText}}</unh-button
      >
    </form>
  </div>
</unh-step-card>
