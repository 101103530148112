import { Injectable } from '@angular/core';
import { Action, State, StateContext } from '@ngxs/store';
import { catchError, filter, map, switchMap, take, tap } from 'rxjs/operators';
import { FetchLessonUpsetData, UpdateLessonUpset, UpdateGroupnameList, PublishLesson, UpdateCommonData, UpdateCurrentLanguage, UpdateUpsetTranslatableData, UpdateLessonParams, UploadVideo, UpdateTranslatedGroupnameListIntoTranslatableData, UpdateTranslatedCurrentGroupname, AddLessonAssets, FetchVideoFiles, UpdateFiles, RemoveLessonFileLocal, changeFreeLessonPosition } from './course-admin-lesson.actions';
import { defaults } from './course-admin-lesson.model';
import { GetVdoCipherCredentialsMutation } from '../../graphql/mutation/get-vdo-cipher-credentials.mutation'
import { UpdateCourseAdminLessonMutation } from '../../graphql/mutation/update-course-admin-lesson.mutation'
import { CreateLessonMutation } from '../../graphql/mutation/create-course-admin-lesson.mutation'
import { FetchLessonUpsetDataQuery } from '../../graphql/query/get-lesson-upset-data.admin.query'
import { forkJoin, fromEvent, Observable, of, Subject, concat, throwError } from 'rxjs';
import { CourseAdminService } from '@frontend/unhideschool/modules/course-admin/services/course-admin.service';
import { CourseAdminUpsetLesson } from '../../models/lesson/lesson-upset-model.model';
import * as LessonUpsetModels from './course-admin-lesson.model'

import { AddFileToVideoMutation, UploadSubtitlesMutation } from '../../graphql/mutation/index'
import { GetVideoFilesQuery, getvideoidquery } from '@frontend/unhideschool/modules/course-admin/graphql/query';
import { formatFile } from '../../helpers/course-admin.helpers';
import { asNumber } from '@frontend/unhideschool/app/helpers/utility-functions';
import { ResetState } from '../project-upsert/course-admin-project-upsert.actions';
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';
import { environment } from '@frontend/unhideschool/env';
import { RestApiService } from '@frontend/unhideschool/app/api-gateway/services/rest-api.service';
import { createCredentialsFormData, filterThumbnailUploadCompleted } from './utils';
@State<LessonUpsetModels.StateModel>({
  name: LessonUpsetModels.STATE_NAME,
  defaults
})

@Injectable()
export class CourseAdminLessonUpsetState {
  videoChanged$ = new Subject();
  constructor(
    private graph: GraphqlApiService,
    private rest: RestApiService,
    private cas: CourseAdminService

  ) { }

  @Action(UpdateUpsetTranslatableData)
  updateCourseAdminLessonUpsetTranslatableData(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateUpsetTranslatableData
  ) {
    const { payload, lang } = action;
    const state = ctx.getState().translatableData;

    state[lang] = payload;

    ctx.patchState({
      translatableData: state,
    })
    this.updateLastState(ctx);
  }


  @Action(UpdateCommonData)
  updateCourseAdminLessonUpsetCommonData(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateCommonData
  ) {
    const { payload } = action;
    ctx.patchState({
      commonData: payload
    })
    this.updateLastState(ctx);

  }

  @Action(UpdateCurrentLanguage)
  updateCourseAdminLessonUpsetCurrentLanguage(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateCurrentLanguage
  ) {
    const { lang } = action;
    return ctx.patchState({
      currentLanguage: lang
    })

  }

  @Action(UpdateLessonParams)
  updatePostid(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateLessonParams
  ) {
    const { postid, orderedpostpartid } = action;
    ctx.patchState({
      postid: postid,
      orderedpostpartid: orderedpostpartid,
      mode: orderedpostpartid != undefined ? 'edit' : 'create'
    })

  }

  @Action(UploadVideo)
  uploadVideoUploadCourseAdminLessonUpset(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UploadVideo
  ) {
    const file = action.file;
    const objectURL = URL.createObjectURL(file)
    const videoElement = document.createElement('video');
    videoElement.src = objectURL;
    videoElement.preload = "metadata";
    const obs = fromEvent(videoElement, "loadedmetadata").pipe(
      take(1),
      switchMap(() => {
        const contenttime = Math.round(videoElement.duration);
        const query = GetVdoCipherCredentialsMutation;
        const variables = {
          title: file.name,
          contenttime
        }
        return this.graph.graphqlUpdate<'UploadCredentialsVdocipher', any>(query, variables);
      }),
      map(res => res?.data.UploadCredentialsVdocipher),
      switchMap(({ credentials, mediaitem }) => {
        const credentialsData = createCredentialsFormData(credentials, action.file);
        this.uploadSubtitles(ctx, credentials);

        return this.rest.upload(null, credentialsData, {
          fullUrl: credentials.clientPayload.uploadLink,
          responseType: 'text'
        }, null, false).pipe(
          tap(res => {
            if (res.status == 'progress') {
              const commonData = ctx.getState().commonData;
              commonData.media.video.uploadprogress = res.message;
              ctx.patchState({ commonData });
            }
          }),
          filter(res => res.status == 'complete'),
          map(() => {
            const commonData = ctx.getState().commonData;
            commonData.media.video.uploadprogress = 0;
            ctx.patchState({ commonData });
            return mediaitem;
          }),
        )
      }),
    )

    return obs;
  }

  @Action(PublishLesson)
  publishLessonCourseAdminLessonUpset(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: PublishLesson
  ) {
    const lessonState = ctx.getState();
    let mediaitemid = null;

    return this.cas.uploadimage(lessonState.commonData.media.postpartthumbnail.file, 'postpartthumbnail')
      .pipe(
        filter((res: any) => filterThumbnailUploadCompleted(res)),
        map((res: any) => res.message.data),
        tap((res: any) => {
          mediaitemid = res.UploadMedia.mediaitem.mediaitemid;
          this.updateThumbnailState(res, ctx);
        }),
        switchMap((res: any) => {
          const thumbnailUploaded = res;
          const hasVideo = (lessonState.commonData.media.video != undefined) && lessonState.commonData.media.video.file;
          if (!(thumbnailUploaded && hasVideo)) {
            return of(null);
          }
          return this.uploadVideoUploadCourseAdminLessonUpset(ctx, new UploadVideo(lessonState.commonData.media.video.file));
        }),
        switchMap((video: any) => {
          const creatlessonmutation = CreateLessonMutation;
          const videoNotUploaded = video == undefined || video == null;
          if (videoNotUploaded) {
            const lesson = new CourseAdminUpsetLesson(lessonState, mediaitemid);
            const variables = {
              ...lesson
            }
            return this.graph.graphqlRequest(creatlessonmutation, variables);
          }

          const media = ctx.getState().commonData.media
          const data = ctx.getState().commonData
          ctx.patchState({
            commonData: {
              ...data,
              media: {
                ...media, postpartthumbnail: {
                  src: video.content,
                  id: video.mediaitemid
                }
              }
            }
          })
          const lesson2 = new CourseAdminUpsetLesson(lessonState, mediaitemid, video.mediaitemid);
          const variables2 = {
            ...lesson2
          }
          return this.graph.graphqlRequest(creatlessonmutation, variables2);
        }),
        tap(() => this.resetState(ctx),
        ),
        catchError(err => {
          return of(err);
        }),
      )
  }


  @Action(FetchLessonUpsetData)
  fetchLessonUpsetData(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: FetchLessonUpsetData
  ) {
    const { orderedpostpartid } = action;
    const query = FetchLessonUpsetDataQuery;
    const variables = {
      orderedpostpartid
    }
    return this.graph.graphqlRequest<'Video', any>(query, variables).pipe(
      map(res => res.data.Video),
      catchError((e, cautgh) => {
        return of(cautgh);
      }),
      tap(lesson => {
        const currentState = ctx.getState();
        const convertedLesson = this.convertLessonDataToFormFormat(lesson, currentState);
        ctx.patchState({ ...convertedLesson });
      }),

    );
  }

  @Action(UpdateLessonUpset)
  updateLessonUpset(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: FetchLessonUpsetData
  ) {
    return this.handleUpdateLesson(ctx).pipe(switchMap(() => this.editSubtitles(ctx)));
  }

  @Action(UpdateGroupnameList)
  courseAdminLessonUpsetUpdateGroupname(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateGroupnameList
  ) {
    const { currentLanguage, translatableData } = ctx.getState();
    translatableData[currentLanguage].groupnamelist = action.groupnames;

    ctx.patchState({
      translatableData: { ...ctx.getState().translatableData, ...translatableData }
    });
  }

  @Action(AddLessonAssets)
  updateLessonAssets(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: AddLessonAssets
  ) {
    const { mediaitemid, postpartid } = action;
    const mutation = AddFileToVideoMutation;
    const variables = {
      mediaitemids: [mediaitemid], postpartid
    };
    return this.graph.graphqlUpdate(mutation, variables);
  }

  @Action(FetchVideoFiles)
  fetchVideoFiles(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: FetchVideoFiles
  ) {
    const { orderedpostpartid } = action;
    const mutation = GetVideoFilesQuery;
    const variables = {
      orderedpostpartid
    };
    return this.graph.graphqlUpdate<'Video', any>(mutation, variables).pipe(
      map(res => res.data.Video.files),
      tap(files => {
        ctx.patchState({
          files: [...files.map(file => formatFile(file))],
        })
      })
    );
  }

  @Action(UpdateFiles)
  updateFiles(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateFiles
  ) {
    ctx.patchState({
      files: [...action.files],
    })
  }


  @Action(RemoveLessonFileLocal)
  RemoveLessonFileLocal(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: RemoveLessonFileLocal
  ) {
    const files = ctx.getState().files.filter(file => file.id == action.id)
    ctx.patchState({
      files: files,
    })
  }

  @Action(UpdateTranslatedCurrentGroupname)
  updateTranslatedCurrentGroupname(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateTranslatedCurrentGroupname
  ) {
    const { groupnameLanguages } = action;
    const { currentLanguage, translatableData } = ctx.getState();
    translatableData['pt_BR'].groupname = { title: groupnameLanguages['ptBR'], internalname: groupnameLanguages['ptBR'], navtive: true };
    translatableData['en_US'].groupname = { title: groupnameLanguages['enUS'], internalname: groupnameLanguages['enUS'], native: true };

    ctx.patchState({
      translatableData: { ...ctx.getState().translatableData, ...translatableData }
    });
  }

  @Action(UpdateTranslatedGroupnameListIntoTranslatableData)
  updateTranslatedGroupnameListIntoTranslatableData(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: UpdateTranslatedGroupnameListIntoTranslatableData
  ) {
    const { currentLanguage, translatableData } = ctx.getState();
    translatableData['pt_BR'].groupnamelist = action.groupnames.map(groupnameLanguages => ({ title: groupnameLanguages['ptBR'], internalname: groupnameLanguages['ptBR'], native: true }));
    translatableData['en_US'].groupnamelist = action.groupnames.map(groupnameLanguages => ({ title: groupnameLanguages['enUS'], internalname: groupnameLanguages['enUS'], native: true }));
    ctx.patchState({
      translatableData: { ...ctx.getState().translatableData, ...translatableData }
    });
  }

  editSubtitles(
    ctx: StateContext<LessonUpsetModels.StateModel>,
  ) {
    const ptBRSubs = ctx.getState().translatableData.pt_BR.videosubtitle ? ctx.getState().translatableData.pt_BR.videosubtitle.file : undefined;
    const enUSSubs = ctx.getState().translatableData.en_US.videosubtitle ? ctx.getState().translatableData.en_US.videosubtitle.file : undefined;

    const hasPtBRSubs = ptBRSubs != undefined;
    const hasEnUSSubs = enUSSubs != undefined;
    const subtitlesquery = UploadSubtitlesMutation;

    const videoidquery = getvideoidquery;
    const variable = {
      orderedpostpartid: ctx.getState().orderedpostpartid
    }
    return this.graph.graphqlRequest<'Video', any>(videoidquery, variable).pipe(
      map(res => res.data.Video.content ? JSON.parse(res.data.Video.content)[0] : null),
      switchMap(id => {
        if (id) {
          const subtitlesvariables = {
            file: null,
            vdocipherVideoid: id,
          }
          const requests = [];
          if (hasPtBRSubs) {
            subtitlesvariables['language'] = 'pt'
            requests.push(this.graph.graphqlUpload(subtitlesquery, subtitlesvariables, ptBRSubs, null));
          }
          if (hasEnUSSubs) {
            subtitlesvariables['language'] = 'en'
            requests.push(this.graph.graphqlUpload(subtitlesquery, subtitlesvariables, enUSSubs, null));
          }
          if (requests.length === 0) {
            return of();
          }
          return forkJoin(requests);
        }
        return of();
      })
    )
  }


  uploadSubtitles(ctx: StateContext<LessonUpsetModels.StateModel>, credentials) {
    let ptBRSubs, enUSSubs;
    const translatableDataPTBR = ctx.getState().translatableData.pt_BR;
    const translatableDataENUS = ctx.getState().translatableData.en_US;

    if (translatableDataPTBR.videosubtitle && translatableDataPTBR.videosubtitle != undefined && translatableDataPTBR.videosubtitle.file != undefined) {
      ptBRSubs = translatableDataPTBR.videosubtitle.file;
    }
    if (translatableDataENUS.videosubtitle && translatableDataENUS.videosubtitle != undefined && translatableDataENUS.videosubtitle.file != undefined) {
      enUSSubs = translatableDataENUS.videosubtitle.file;
    }
    const hasPtBRSubs = ptBRSubs != undefined;
    const hasEnUSSubs = enUSSubs != undefined;

    const subtitlesquery = UploadSubtitlesMutation;

    const subtitlesvariables = {
      file: null,
      vdocipherVideoid: credentials.videoId,
    }
    if (hasPtBRSubs) {
      subtitlesvariables['language'] = 'pt'
      this.graph.graphqlUpload(subtitlesquery, subtitlesvariables, ptBRSubs, null).subscribe();
    }
    if (hasEnUSSubs) {
      subtitlesvariables['language'] = 'en'
      this.graph.graphqlUpload(subtitlesquery, subtitlesvariables, enUSSubs, null).subscribe();
    }
  }

  convertLessonDataToFormFormat(lesson, currentState: LessonUpsetModels.StateModel): LessonUpsetModels.StateModel {
    const converted: LessonUpsetModels.StateModel = {
      mode: 'edit',
      currentLanguage: 'pt_BR',
      postpartid: lesson.postpartid,
      commonData: {
        instructor: {
          title: '',
          uid: lesson.creatoruid
        },
        media: {
          postpartthumbnail: {
            read: LessonUpsetModels.MediaTypes.read,
            src: lesson.thumbnail,
          },
          video: {
            read: LessonUpsetModels.MediaTypes.read,
            src: lesson.content != undefined ? lesson.thumbnail : '',
          }
        },
        tags: lesson.tags.map(tag => {
          return { content: tag.name, value: true, color: "outline" }
        }),
        visibility: {
          icon: environment.cdnUrl + '/course-admin-icons/Lock.svg',
          title: "Assinatura",
          value: lesson.public
        },
      },
      translatableData: {
        pt_BR: {
          title: lesson.titleLanguages['ptBR'],
          description: lesson.descriptionLanguages['ptBR'],
          groupname: { title: lesson.groupnameLanguages['ptBR'] },
          groupnamelist: [...currentState.translatableData['pt_BR'].groupnamelist],
        },
        en_US: {
          title: lesson.titleLanguages['enUS'],
          description: lesson.descriptionLanguages['enUS'],
          groupname: { title: lesson.groupnameLanguages['enUS'] },
          groupnamelist: [...currentState.translatableData['en_US'].groupnamelist],
        }
      }
    }
    return converted;
  }

  handleUpdateLesson(ctx: StateContext<LessonUpsetModels.StateModel>) {
    const lessonState = ctx.getState();
    const { orderedpostpartid } = lessonState;
    const query = UpdateCourseAdminLessonMutation;

    const title = {
      ptBr: lessonState.translatableData.pt_BR.title,
      enUs: lessonState.translatableData.en_US.title
    }

    const description = {
      ptBr: lessonState.translatableData.pt_BR.description,
      enUs: lessonState.translatableData.en_US.description
    }

    const variables: any = {
      orderedpostpartid: orderedpostpartid,
      creatoruid: lessonState.commonData.instructor.uid,
      description: description,
      title: title,
      groupname: { ptBr: lessonState.translatableData.pt_BR.groupname.title, enUs: lessonState.translatableData.en_US.groupname.title },
      tags: lessonState.commonData.tags.map(tag => tag.content),
      active: true,
      public: lessonState.commonData.visibility.value
    }
    const hasPostpartthumbnailUpdate = (!lessonState.commonData.media.postpartthumbnail.read);
    const hasVideoUpdate = (lessonState.commonData.media.video != undefined && !lessonState.commonData.media.video.read);

    if (hasPostpartthumbnailUpdate && hasVideoUpdate) {
      return this.updateVideoWithVideoAndThumbnail(ctx, query, variables);

    } else if (hasPostpartthumbnailUpdate && !hasVideoUpdate) {

      return this.updateLessonWithThumbnail(ctx, query, variables);

    } else if (hasVideoUpdate && !hasPostpartthumbnailUpdate) {
      return this.updateLessonWithVideo(ctx, query, variables);

    }

    return this.graph.graphqlRequest(query, variables);

  }


  private updateVideoWithVideoAndThumbnail(ctx: StateContext<LessonUpsetModels.StateModel>, query, variables) {
    const media = ctx.getState().commonData.media
    return this.handleMediaUpload(ctx, media).pipe(
      switchMap(({ video, mediaitem }) => {
        variables['mediaitemid'] = video.mediaitemid
        variables['thumbnailid'] = mediaitem.mediaitemid
        variables['public'] = video.mediaadapter.internalname == 'vdocipher';
        return this.graph.graphqlUpdate<'UpdateVideo', any>(query, variables)
      }),
      map(res => res.data.UpdateVideo)
    )
  }

  private updateLessonWithThumbnail(ctx: StateContext<LessonUpsetModels.StateModel>, query, variables) {
    const file = ctx.getState().commonData.media;
    return this.handleThumbnailUpload(file)
      .pipe(
        tap((uploadMedia: any) => {
          variables['thumbnailid'] = uploadMedia.mediaitem.mediaitemid
        }),
        switchMap(() => this.graph.graphqlRequest(query, variables))
      )
  }

  private updateLessonWithVideo(ctx: StateContext<LessonUpsetModels.StateModel>, query, variables) {
    const file = ctx.getState().commonData.media.video.file;
    return this.uploadVideoUploadCourseAdminLessonUpset(ctx, new UploadVideo(file))
      .pipe(
        tap((mediaitem: any) => {
          variables['mediaitemid'] = asNumber(mediaitem.mediaitemid);
        }),
        switchMap(() => this.graph.graphqlRequest(query, variables))
      )
  }
  private handleThumbnailUpload(medias) {
    return this.cas.uploadimage(medias.postpartthumbnail.file, 'postpartthumbnail').pipe(
      filter((res: any) => res.status === 'complete'),
      map((res: any) => res.message.data.UploadMedia)
    );
  }

  private handleMediaUpload(ctx: StateContext<LessonUpsetModels.StateModel>, medias): Observable<any> {
    return this.cas.uploadimage(medias.postpartthumbnail.file, 'postpartthumbnail').pipe(
      filter((res) => res.status === 'complete'),
      map(res => res.message.data.UploadMedia.mediaitem),
      switchMap(mediaitem => {
        return this.uploadVideoUploadCourseAdminLessonUpset(ctx, new UploadVideo(medias.video.file)).pipe(
          map(video => ({ video, mediaitem }))
        );
      })
    )
  }

  private updateLastState(ctx: StateContext<LessonUpsetModels.StateModel>) {
    const fullstate = ctx.getState();
    ctx.patchState({
      // lastdata: fullstate
    })
  }

  @Action(ResetState)
  resetState(
    ctx: StateContext<LessonUpsetModels.StateModel>,
  ) {
    return ctx.patchState({ ...defaults });
  }


  @Action(changeFreeLessonPosition)
  changeLessonPosition(
    ctx: StateContext<LessonUpsetModels.StateModel>,
    action: changeFreeLessonPosition
  ) {
    const { lessons } = action;
    const less = lessons.map((lesson, index) => {
      const mutation = UpdateCourseAdminLessonMutation;
      const variables = {
        orderedpostpartid: asNumber(lesson.orderedpostpartid),
        position: asNumber(index + 1)
      };
      return this.graph.graphqlUpdate(mutation, variables).pipe(catchError(e => of()));
    })
    return concat(...less);
  }

  updateThumbnailState(res, ctx: StateContext<LessonUpsetModels.StateModel>) {
    const mediaitemid = res.UploadMedia.mediaitem.mediaitemid;
    const media = ctx.getState().commonData.media
    const data = ctx.getState().commonData
    ctx.patchState({
      commonData: {
        ...data,
        media: {
          ...media, postpartthumbnail: {
            src: res.UploadMedia.mediaitem.content,
            id: mediaitemid
          }
        }
      }
    })
  }
}

