export const createImageSettings = `
    mutation(
        $width: Int,
        $height: Int,
        $waspectratio: Int,
        $haspectratio: Int,
        $xcenter: Int,
        $ycenter: Int
    ) {
        createImagesetting(imagesettingData: {
            width: $width,
            height: $height,
            waspectratio: $waspectratio,
            haspectratio: $haspectratio,
            xcenter: $xcenter,
            ycenter: $ycenter,
        }) {
            ImageSetting {
                imagesettingid
            }
        }
    }
`;