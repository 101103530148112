<div
  *ngIf="project && project.title !== 'loading'"
  class="mozaic-projects-item template-thumb"
  (click)="!project.iscommingsoon && itemClicked.emit()"
>

  <div class="ribbon" *ngIf="project.isnew && !project.iscommingsoon">
    <img src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/ico-ribbon-novo.png" alt="Projeto novo">
  </div>

  <div class="ribbon" *ngIf="project.iscommingsoon">
    <img src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/ico-ribbon-is-coming-soon.png" alt="Projeto em breve">
  </div>

  <div
    [ngClass]="{'bg-container' : !project.iscommingsoon, 'bg-commingsoon' : project.iscommingsoon }"
    [ngStyle]="{ 'background-image': 'url(' + project.postthumbnail + '?tr=w-480)' }"
  >
  </div>

  <div class="wrapper" *ngIf="!project.iscommingsoon">
    <div class="content">
      <div class="title">{{project.title}}</div>
      <div class="instrutor" *ngIf="project.creator.alias">        
        <img [src]="creatorAvatar" alt="{{ project.creator.alias }}">        
        <div class="name">
          <span *ngIf="project.creator.fullname !== 'UNHIDE School'">Instrutor:</span>
          {{ project.creator.alias }}
        </div>
      </div>
    </div>

    <div class="watch-now">
        <img src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/icon-watch-now-2020.png" [alt]="project.title">
        <div class="text">Assistir Aula</div>
    </div>
  </div>
</div>
