import { notificationItemContentMapping } from './mocks/notification-item-content.mock';

import { environment } from '@frontend/unhideschool/env';

import { getHTMLStringAsText, interpolate, slugify, truncate } from '../../helpers/utility-functions';

import { formatDistanceToNow } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';

import { NotificationDataModel, NotificationItemImageType } from './notifications.model';
import { SubscriptionMemberStates } from '../../shared/models/subscription/subscription-members.model';

export enum SubscriptionNotificationTypes {
    INVITED = 'subscriptionmemberinvite',
    ACCEPTED = 'subscriptionmemberaccepted',
    DECLINED = 'subscriptionmemberdeclined',
    REMOVED = 'subscriptionmemberremoved',
    EXPIRING = 'subscriptionmessage',
    EXPIRED = 'subscriptionexpiredmessage',
    BACKSUB = 'subscriptionbackmessage'
}

export const notificationLinkModelMapping = {
    'root.post': '/post/{postid}/{orderedpostpartid}/{posttitleslug}/{postparttitleslug}',
    'root.view-all': '/view-all/{postid}/{posttitleslug}',
    'root.community.topic': '/forum/topico/{communityid}/{communitypath}/{postid}/{postpartid}//{postparttitleslug}'
};

export interface NotificationAction {
    type: string;
    label: string;
}

export interface NotificationId {
    notificationid?: number;
}

export interface NotificationItemModel extends NotificationId {
    data?: NotificationDataModel;
    read?: boolean;
    title?: string;
    notifcategid?: number;
    notifcateginternalname?: string;
    datecreated?: string;
    originPath?: string;
    postpartcommentid?: number | string;
    imageurl?: string;
    imagetype?: NotificationItemImageType;
    text?: string;
    fullname?: string;
    subscriptionid?: number;
    subscriptionmemberid?: number;
    actions?: NotificationAction[];
    disableNavigation?: boolean;
    days?: string

}

export class NotificationItem implements NotificationItemModel {
    notificationid: number;
    data: NotificationDataModel;
    read: boolean;
    title: string;
    notifcategid: number;
    notifcateginternalname: string;
    datecreated: string;
    originPath: string;
    postpartcommentid: number | string;
    imageurl: string;
    imagetype: NotificationItemImageType;
    text: string;
    fullname: string;
    uid?: any;
    subscriptionid: number;
    subscriptionmemberid: number;
    actions: NotificationAction[];
    disableNavigation: boolean;

    constructor(
        data: NotificationDataModel,
        notificationid: number,
        notifcategid: number,
        notifcateg_internalname: string,
        read: boolean,
        title: string,
        datecreated: string,
    ) {
        try {
            this.data = data;
            this.notificationid = notificationid;
            this.notifcategid = notifcategid;
            this.notifcateginternalname = notifcateg_internalname;
            this.read = read;
            this.title = title;

            const withLocale = { locale: pt };
            this.datecreated = formatDistanceToNow(new Date(datecreated), withLocale);  

            const imageType = this.getImageTypeByNotificationCategory(notifcateg_internalname);
            this.imagetype = imageType;
            const payload = {
                accepted: data.accepted,
                day: this.data.days
            }
            this.imageurl = imageType === 'icony'
                ? this.getNotifIcon(notifcateg_internalname,payload ) : this.getNotificationImage(this.data);

            if (this.data.posttype != null) {
                const notificationUrl = this.buildNotificationUrl(this.data, this.notifcategid, this.notifcateginternalname);
                this.originPath = notificationUrl.url;
                this.postpartcommentid = notificationUrl.postpartcommentid;
            } else {
                // Default to disabled
                this.disableNavigation = true;

                try {
                    const allowedNavigationMapping = {
                        [SubscriptionNotificationTypes.ACCEPTED]: true,
                        [SubscriptionNotificationTypes.DECLINED]: true,
                        [SubscriptionNotificationTypes.INVITED + 'accepted']: true,
                        [SubscriptionNotificationTypes.INVITED + 'declined']: false,
                        [SubscriptionNotificationTypes.INVITED]: false,
                        [SubscriptionNotificationTypes.REMOVED]: false,
                        [SubscriptionNotificationTypes.EXPIRING]: true,
                        [SubscriptionNotificationTypes.EXPIRED]: true,
                        [SubscriptionNotificationTypes.BACKSUB]: true,


                    };

                    const subscriptionNotifcateg = typeof this.data.accepted !== 'undefined'
                        ? notifcateg_internalname + (this.data.accepted ? 'accepted' : 'declined') : notifcateg_internalname;

                    this.disableNavigation = !allowedNavigationMapping[subscriptionNotifcateg];
                } catch {
                    // empty
                }

                this.originPath = '/membership/plan';
            }

            const notificationContent = this.getNotificationContent(notifcateg_internalname, this.data);
            this.title = notificationContent.title;
            this.text = notificationContent.text;

            if (Object.values(SubscriptionNotificationTypes).some(nci => nci === this.notifcateginternalname)) {
                this.actions = [];

                if (
                    this.notifcateginternalname === 'subscriptionmemberinvite' &&
                    typeof this.data.accepted === 'undefined'
                ) {
                    this.actions = [
                        { type: SubscriptionMemberStates.ACCEPTED, label: 'aceitar' },
                        { type: SubscriptionMemberStates.DECLINED, label: 'recusar' }
                    ];
                }
            }

        } catch (e: any) {
            console.log('[NotificationItem]', e.message);
        }
    }

    clone(data: NotificationItemModel): NotificationItem {
        return Object.assign(Object.create(this), this, data);
    }

    private getNotifIcon(notifcateg: string, payload: any) {
        const internalname = notifcateg;
        if (internalname === 'subscriptionmessage') {
            let subtype;
            switch(payload.day ){
                case 15:{
                    subtype = 15
                    break;
                }
                case 5:{
                    subtype = 5
                    break;
                }
                case 1:{
                    subtype = 1
                    break;
                }
            }
            const urlPattern = environment.cdnUrl + '/notif-icons/svg/{internalname}{subtype}.svg';
            return interpolate(urlPattern, { internalname, subtype });

        }
        if (payload.accepted != undefined) {
            const urlPattern = environment.cdnUrl + '/notif-icons/svg/{internalname}{subtype}.png';
            const subtype = payload.accepted ? 'accepted' : 'declined';
            return interpolate(urlPattern, { internalname, subtype });
        }

        // tslint:disable-next-line: no-shadowed-variable
        const urlPattern = environment.cdnUrl + '/notif-icons/svg/{internalname}.svg';
        return interpolate(urlPattern, { internalname });

    }

    private getImageTypeByNotificationCategory(notifcateg: string) {
        const imageTypeByNotificationCategory: { [k: string]: string[] } = {
            avatar: ['commentdownvote', 'commentreplies', 'commentupvote', 'postcomment', 'postlike'],
            landscape: ['broadcast', 'neworderedpostpart', 'newposts'],
            icony: Object.values(SubscriptionNotificationTypes)
        };

        for (const key in imageTypeByNotificationCategory) {
            if (key in imageTypeByNotificationCategory) {
                const arr = imageTypeByNotificationCategory[key];

                if (arr.some(cat => cat === notifcateg)) {
                    return key as NotificationItemImageType;
                }
            }
        }

        console.error(
            `[${notifcateg}] Can't match this notification category. This error may lead to an unstable experience.`
        );

        return 'notImplemented' as NotificationItemImageType;
    }

    private getNotificationContent(notifcateg: string, data: NotificationDataModel) {
        let postfix = '';
        if(typeof data.accepted !== 'undefined'){
             postfix = data.accepted ? 'accepted' : 'declined';

        }
         if(data.days){
            postfix = data.days
        }

        const content = notificationItemContentMapping[notifcateg + postfix];

        const interpolationData = {
            commenteralias: data.commenteralias || data.useralias,
            text: data.text,
            posttitle: data.posttitle,
            postparttitle: data.postparttitle,
            creator: data.creator,
            title: data.title,
            fullname: data.fullname
        };

        const isMembershipNotification = Object.values(SubscriptionNotificationTypes).some(snt => snt === notifcateg);

        const notificationContent = {
            title: truncate(interpolate(content.titlemodel, interpolationData), 40),
            text: isMembershipNotification
                ? interpolate(content.textmodel, interpolationData)
                : getHTMLStringAsText(interpolate(content.textmodel, interpolationData), 65)
        };

        return notificationContent;
    }

    private getNotificationImage(data: NotificationDataModel) {
        if (data.profilepicture) {
            return this.fixImageUrl(data.profilepicture);
        }

        if (data.relatedmedia) {
            return this.fixImageUrl(data.relatedmedia.postthumbnail);
        }

        if (data.postpartthumbnail) {
            return this.fixImageUrl(data.postpartthumbnail);
        }

        if (data.commenterpicture) {
            return this.fixImageUrl(data.commenterpicture);
        }

        // TODO: Opitionally return default image path...
        return null;
    }

    // TODO: Mover para UtilityService
    private fixImageUrl(url) {
        const isImagekit = url.indexOf('.imagekit.io') > -1;
        const isAmazonS3 = url.indexOf('.amazonaws.com') > -1;
        return isImagekit || isAmazonS3 ? url : `${environment.apipath}/${url}`;
    }

    private buildNotificationUrl(data: NotificationDataModel, notifcategid?: number, notifcateg_internalname?: string) {
        let paramsData: any = {};
        let originRoute: any = {};

        switch (data.posttype) {
            case 'broadcast':
                paramsData = { notifcategid };
                break;

            case 'officialpost':
                originRoute = notifcateg_internalname !== 'newposts' ? 'root.post' : 'root.view-all';

                // eslint-disable-next-line no-case-declarations
                const posttitle = data.posttitle || data.title;

                paramsData = {
                    postid: data.postid ? data.postid : '',
                    orderedpostpartid: data.orderedpostpartid ? data.orderedpostpartid : '',
                    posttitleslug: posttitle ? slugify(posttitle) : '',
                    postparttitleslug: data.postparttitle ? slugify(data.postparttitle) : '',
                    postpartcommentid: data.postpartcommentid ? data.postpartcommentid : '',
                };
                break;

            case 'contestpost':
            case 'communitypost':
                originRoute = 'root.community.topic';

                paramsData = {
                    postid: data.postid,
                    communityid: data.postid,
                    postpartid: data.postpartid ? data.postpartid : '',
                    orderedpostpartid: data.orderedpostpartid,
                    communitypath: slugify(data.posttitle),
                    postparttitleslug: slugify(data.postparttitle),
                    postpartcommentid: data.postpartcommentid,
                };
                break;
        }

        const urlModel = notificationLinkModelMapping[originRoute];
        const url = interpolate(urlModel, paramsData);
        const postpartcommentid = data.postpartcommentid;
        return { url, postpartcommentid };
    }
}
