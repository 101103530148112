import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { MenuContentFooterComponent } from './layouts/menu-content-footer/menu-content-footer.layout';
import { MenuContainerComponent } from './components/menu-container/menu-container.component';
import { FooterContainerComponent } from './components/footer-container/footer-container.component';
import { PreCheckoutContainerComponent } from './components/pre-checkout-container/pre-checkout-container.component';
import { EmptyContentComponent } from './layouts/empty-content/empty-content.component';
import { MenuContentMobileComponent } from './layouts/menu-content-mobile/menu-content-mobile.layout';
import { ApiGatewayModule } from '../api-gateway/api-gateway.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SvgIconInterceptor } from './interceptors/svg-icon.interceptor';
import { CommonModule } from '@angular/common';
import { NotificationsMenuItemComponent } from './components/notifications-menu-item/notifications-menu-item.component';
import { SideMenuComponent } from './components/side-menu/side-menu.component';
import { SideMenuContainerComponent } from './components/side-menu-container/side-menu-container.component';
import { SideMenuHeaderComponent } from './components/side-menu-header/side-menu-header.component';
import { SideMenuContentComponent } from './components/side-menu-content/side-menu-content.component';
import { SideMenuFooterComponent } from './components/side-menu-footer/side-menu-footer.component';
import { SideMenuContainerMobileComponent } from './components/side-menu-container-mobile/side-menu-container-mobile.component';
import { MaterialModule } from '../material/material.module';
import { AcceptLanguageInterceptor } from './interceptors/accept-language.interceptor';
import { MenuToggleComponent } from '../shared/components/menu-toggle/menu-toggle.component';
import { UserMenuItemButtonComponent } from '../shared/components/user-menu-item-button/user-menu-item-button.component';
import { GenericListContainerComponent } from '../shared/components/generic-list-container/generic-list-container.component';
import { UserMenuItemLinkComponent } from '../shared/components/user-menu-item-link/user-menu-item-link.component';
import { IconContainerComponent } from '../shared/components/icon-container/icon-container.component';
import { MenuLogoComponent } from '../shared/components/menu-logo/menu-logo.component';
import { UserAvatarModule } from '../shared/components/user-avatar/user-avatar.module';

@NgModule({
  declarations: [
    MenuContentFooterComponent,
    MenuContainerComponent,
    FooterContainerComponent,
    PreCheckoutContainerComponent,
    MenuToggleComponent,
    MenuContentMobileComponent,
    EmptyContentComponent,
    NotificationsMenuItemComponent,
    SideMenuComponent,
    SideMenuContainerComponent,
    SideMenuHeaderComponent,
    SideMenuContentComponent,
    SideMenuFooterComponent,
    SideMenuContainerMobileComponent,
    GenericListContainerComponent,
    UserMenuItemLinkComponent,
    UserMenuItemButtonComponent,
    IconContainerComponent,
    MenuLogoComponent
  ],
  imports: [
    CommonModule,
    ApiGatewayModule,
    RouterModule,
    MaterialModule,
    UserAvatarModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AcceptLanguageInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: SvgIconInterceptor, multi: true },
  ],
  exports: [
    PreCheckoutContainerComponent,
    MenuContentFooterComponent,
    MenuContentMobileComponent,
    FooterContainerComponent,
    MaterialModule
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],
})
export class CoreModule { }
