export const updateGalleryItemMutation = `
mutation(
  $galleryitemid: ID!,
  $active: Boolean,
) {
  updateGalleryitem(galleryitemData: {
      galleryitemid: $galleryitemid,
      active:$active

  }) {
      GalleryItem {
          galleryitemid,
          active,
          galleryid
      }
  }
}
`;
