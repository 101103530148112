import { throwError, of } from 'rxjs';

export interface ApiResponse<T> {
  dict: T;
  reason: string;
  success: boolean;
  errordata?: string;
}

export interface GenericDict<T> {
  [propname: string]: T;
}

export type ExternalResponse<T> = GenericDict<T>;
export type Response<T> = ApiResponse<T>;
export type Dict<T> = GenericDict<T>;

export function handleApiErrors(res) {
  // Unhide API handling
  if ('success' in res) {
    return !res.success ? throwError(() => res.reason) : of(res);
  }

  // Graphql handling
  return res.errors ? throwError(() => res?.errors) : of(res);
}
