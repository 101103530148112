<div class="dialog-banner" >
  <div class="banner-image" [style.background-image]="bgImageStyle"></div>
  <pzl-skeleton width="100%" height="100%" ></pzl-skeleton>
  <ng-container  *ngIf="(hasAnualPromo$ | async) || project?.hasPromo">
    <div class="promo_flag" *ngIf="!isSubscribed()">
      <unh-custom-promo-tag slot="promo_tag" [type]="'flag'" [plus]="true" [width]="119" [fontSize]="'38px'" [activePromo]="(activePromo$ | async)"></unh-custom-promo-tag>
    </div>
  </ng-container>
  <div [class.centered]="!isSmallScreen" class="dialog-banner-content">

    <pzl-skeleton *ngIf="!project?.title" width="70%" height="30px" ></pzl-skeleton>
    <pzl-skeleton *ngIf="!project?.title" width="70%" height="10px" ></pzl-skeleton>

    <h1>{{ project?.title }}</h1>
    <h2>{{ project?.headline }}</h2>

    <!-- <div class="rating"></div> -->

    <div flex-spacer></div>

    <div>
      <ng-content></ng-content>

      <div class="features">
        <ng-container *ngFor="let feat of features">
          <div *ngIf="feat.text !== ''" class="feature">
            <span class="feature-icon">
              <img [src]="feat?.iconUrl" alt="">
            </span>

            {{ feat?.text }}
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
