export const getPerfilIdQuery = `
query UserProfileMutation($uid: Int!) {
    UserProfile(uid: $uid) {
      username
      fullname
      profilepicture
      coverpicture
      occupation {
        name
      }
      isPro
      role{
        internalname
        rolepermissions {
          name
        }
      }
      shortbio
      galleries {
        gallery {
          galleryid
          name
          cover{
          mediaitem{
              content
              render
            }
          }
        }
      }
      interests {
        interestid
        uid
        haveExperience
        interestIn
        studying
        uid
        interest {
            name
            interestid
            active

        }
      }
      websites {
        url
        userwebsiteid
        wscategid
        websitecategory {
          internalname
          name
        }
      }
    }
  }
  `;
export const getWebsitesByUserQuery = `
query UserProfileMutation($uid: Int!) {
  UserProfile(uid: $uid) {
    websites {
      url
      userwebsiteid
      wscategid
      websitecategory {
        internalname
        name
      }
    }
  }
}
`;

export const getInterestCategoriesPerfil = `
query {
  drUserInterestCategories(page: 1, perpage: 1000) {
		name
    interestid
    active
  }
}
`;

export const getWebSiteCategoryQuery = `
query drUserWebsiteCategoriesQuery{
  drUserWebsiteCategories {
    wscategid
    internalname
    name

  }
}
`;

export const getMyCoursesQuery = `
query MyCourses {
  me {
    courses {
      postid
      creatoruid
      posttypeid
      title
      description
      fulldescription
      headline
      productid
      published
      postthumbnail
      postthumbnailcover
      postmobilecover
      postviewallhead

      creator {
        username
        occupationtitle
        alias
        profilepicture

      relatedmedia{
        render

        imagesettings {
          imagesettingid
          width
          height
          waspectratio
          haspectratio
          xcenter
          ycenter
        }

        mediacontenttype {
          internalname
        }
      }
      }
      badges {
        level
        badgeid
        badge {
          name
          internalname
          description
          badgeid
        }
        post {
          title
          fulldescription
          description
          label {
            name
            internalname
            postlabelid
          }
        }
      }
      tags {
        tagid
        name
        hometag
        tagtype{
          name
          internalname

        }
        tagtypeid
      }
    }
  }
}
`;

export const myCoursesLastWatchedQuery = `
query GetMyCourses($page: Int, $perpage: Int) {
  MyCourses(page: $page, perpage: $perpage) {
    courses {
      postid
      progress
      title
      postthumbnail
      postthumbnailcover
      postmobilecover
      contenttime
      active
      published
      creator {
        username
        occupationtitle
        uid
        alias
        profilepicture
        relatedmedia{
          render

          imagesettings {
            imagesettingid
            width
            height
            waspectratio
            haspectratio
            xcenter
            ycenter
          }

          mediacontenttype {
            internalname
          }
        }
      }
      tags {
        tagid
        name
        tagtype{
          name
          internalname

        }
        tagtypeid
      }
      traits {
        name
        internalname
      }
      lastwatched {
        title
      	orderedpostpartid
      }
    }
    totalCount
  }
}
`;

export const updateUserPerfilQuery = `
mutation updateUserPerfilQuery(
  $userid: Int!,
  $email: String,
  $shortbio: String,
  $username: String,
  $fullname: String,
  $password: InputPassword,
  $avatarid: Int,
  $coverid: Int,
  $alias: String,
  $websites: [InputWebsite],
  $interests: [InputInterest],
  $genderid: Int,
  $countryid: Int,
  $occupationtitle: String,
  $datebirth: Date,
  $autoplay: Boolean,
  $availableForWork: Boolean
) {
  UpdateUserProfile(userData:
    {
      userid: $userid,
      shortbio: $shortbio,
      username: $username,
      password: $password,
      avatarid: $avatarid,
      coverid: $coverid,
      alias: $alias,
      websites: $websites,
      interests: $interests,
      fullname: $fullname,
      genderid: $genderid,
      countryid: $countryid,
      occupationtitle: $occupationtitle,
      datebirth: $datebirth,
      email: $email,
      autoplay: $autoplay,
      availableForWork: $availableForWork
    }) {
    user {
      shortbio
      fullname
      alias
      occupationtitle
      availableForWork
    }
  }
}

`;
