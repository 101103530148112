import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from '@frontend/unhideschool/env';

@Injectable({
  providedIn: 'root'
})
export class CustomIconsRegistryService {
  customIcons: { label: string; url: string; }[] = [
    { label: 'unh_box_in', url: environment.cdnUrl + '/admin-icons/box-in.svg' },
    { label: 'unh_box_in_active', url: environment.cdnUrl + '/admin-icons/box-in_active.svg' },
    { label: 'unh_box_in_trash', url: environment.cdnUrl + '/admin-icons/box-in_trash.svg' },
    { label: 'unh_block_3', url: environment.cdnUrl + '/admin-icons/block-3.svg' },
    { label: 'unh_block_3_active', url: environment.cdnUrl + '/admin-icons/block-3_active.svg' },
    { label: 'unh_check_circle', url: environment.cdnUrl + '/admin-icons/check-circle.svg' },
    { label: 'unh_check_circle_active', url: environment.cdnUrl + '/admin-icons/check-circle_active.svg' },
    { label: 'unh_check', url: environment.cdnUrl + '/svg-icons/check-icon.svg' },
    { label: 'unh_check_blue', url: environment.cdnUrl + '/icons/svg/icon-check-blue.svg' },
    { label: 'unh_cover', url: environment.cdnUrl + '/svg-icons/cover-icon.svg' },
    { label: 'unh_download', url: environment.cdnUrl + '/common-icons/unh-download.svg' },
    { label: 'unh_logo', url: environment.cdnUrl + '/logo-unhide.svg' },
    { label: 'unh_login', url: environment.cdnUrl + '/svg-icons/main-menu/ico-login.svg' },
    { label: 'unh_mouse_scroll', url: environment.cdnUrl + '/custom-icons/unh-mouse-scroll.svg' },
    { label: 'unh_original_size', url: environment.cdnUrl + '/common-icons/unh-original-size.svg' },
    { label: 'unh_round_tipped_left_arrow', url: environment.cdnUrl + '/common-icons/unh-round-tipped-left-arrow.svg' },
    { label: 'unh_round_tipped_right_arrow', url: environment.cdnUrl + '/common-icons/unh-round-tipped-right-arrow.svg' },
    { label: 'unh_plus', url: environment.cdnUrl + '/svg-icons/plus-icon.svg' },
    { label: 'unh_text_edit', url: environment.cdnUrl + '/admin-icons/text-edit.svg' },
    { label: 'unh_text_edit_active', url: environment.cdnUrl + '/admin-icons/text-edit_active.svg' },
    { label: 'unh_times', url: environment.cdnUrl + '/common-icons/unh-times.svg' },
    { label: 'unh_free_rounded', url: environment.cdnUrl + '/icons/svg/icon-free-rounded.svg' },
    { label: 'unh_project_check_blue', url: environment.cdnUrl + '/svg-icons/check-blue-icon.svg' },
    { label: 'unh_project_cart', url: environment.cdnUrl + '/svg-icons/cart-icon.svg' },
    { label: 'unh_pro_outlined_pink', url: environment.cdnUrl + '/svg-icons/pro-outlined-pink.svg' },

    { label: 'unh_menu-home', url: environment.cdnUrl + '/svg-icons/main-menu/ico-home.svg' },
    { label: 'unh_menu-gallery', url: environment.cdnUrl + '/svg-icons/main-menu/ico-gallery.svg' },
    { label: 'unh_menu-plans', url: environment.cdnUrl + '/svg-icons/main-menu/ico-plans.svg' },
    { label: 'unh_menu-unhide', url: environment.cdnUrl + '/svg-icons/main-menu/ico-unhide.svg' },
    { label: 'unh_menu-community', url: environment.cdnUrl + '/svg-icons/main-menu/ico-community.svg' },
    { label: 'unh_menu-plus', url: environment.cdnUrl + '/svg-icons/main-menu/icon-plus.svg' },
    { label: 'unh_menu-group', url: environment.cdnUrl + '/svg-icons/main-menu/ico-group.svg' },
    { label: 'unh_menu-forum', url: environment.cdnUrl + '/svg-icons/main-menu/ico-forum.svg' },
    { label: 'unh_menu-cast', url: environment.cdnUrl + '/svg-icons/main-menu/ico-cast.svg' },
    { label: 'unh_menu-twitch', url: environment.cdnUrl + '/svg-icons/main-menu/ico-twitch.svg' },
    { label: 'unh_menu-mentory-gallery', url: environment.cdnUrl + '/svg-icons/main-menu/ico-mentory-gallery.svg' },
    { label: 'unh_menu-mentory-month-contest', url: environment.cdnUrl + '/svg-icons/main-menu/ico-month-contest.svg' },
    { label: 'unh_menu-mentory-past-contest', url: environment.cdnUrl + '/svg-icons/main-menu/ico-past-contest.svg' },
    { label: 'unh_menu-mentory-how-contest', url: environment.cdnUrl + '/svg-icons/main-menu/ico-how-contest.svg' },
    { label: 'unh_menu-community-gallery', url: environment.cdnUrl + '/svg-icons/main-menu/ico-community-gallery.svg' },
    { label: 'unh_menu-contests-highlight', url: environment.cdnUrl + '/svg-icons/main-menu/ico-contests-highlight.svg' },
    { label: 'unh_menu-lives', url: environment.cdnUrl + '/svg-icons/main-menu/ico-lives.svg' },
    { label: 'unh_menu-facebook', url: environment.cdnUrl + '/svg-icons/main-menu/ico-facebook.svg' },
    { label: 'unh_menu-contests', url: environment.cdnUrl + '/svg-icons/main-menu/ico-contests.svg' },


    { label: 'unh_footer-mail', url: environment.cdnUrl + '/icons/footer/ico-mail.svg' },
    { label: 'unh_footer-insta', url: environment.cdnUrl + '/icons/footer/ico-insta.svg' },
    { label: 'unh_footer-twitch', url: environment.cdnUrl + '/icons/footer/ico-twitch.svg' },
    { label: 'unh_footer-discord', url: environment.cdnUrl + '/icons/footer/ico-discord.svg' },
    { label: 'unh_footer-twitter', url: environment.cdnUrl + '/icons/footer/ico-twitter.svg' },
    { label: 'unh_footer-face', url: environment.cdnUrl + '/icons/footer/ico-face.svg' },
    { label: 'unh_footer-tube', url: environment.cdnUrl + '/icons/footer/ico-tube.svg' },
    { label: 'unh_footer-linkedin', url: environment.cdnUrl + '/icons/footer/ico-linkedin.svg' },

    { label: 'unh_project_level', url: environment.cdnUrl + '/icons/view-all-banner/ico-level.png' },
    { label: 'unh_student_hat', url: environment.cdnUrl + '/icons/view-all-banner/ico-students.png' },
    { label: 'unh_clock', url: environment.cdnUrl + '/icons/view-all-banner/ico-time.png' },
    { label: 'unh_speaker', url: environment.cdnUrl + '/icons/view-all-banner/ico-language.png' },
    { label: 'unh_subtitle', url: environment.cdnUrl + '/icons/view-all-banner/ico-legends.png' },
    { label: 'unh_cloud_down', url: environment.cdnUrl + '/svg-icons/cloud-down-icon.svg' },
    { label: 'unh_rounded_heart_gray', url: environment.cdnUrl + '/svg-icons/rounded-like.svg' },

    { label: 'unh_close', url: environment.cdnUrl + '/svg-icons/close-gray.svg' },
    { label: 'unh_config', url: environment.cdnUrl + '/svg-icons/config-icon.svg' },



    { label: 'unh_clock_custom', url: environment.cdnUrl + '/svg-icons/ico_relogio.svg' },
    { label: 'unh_breadcrumb_arrow', url: environment.cdnUrl + '/svg-icons/ico-breadcrumb-arrow.svg' },

    // Membership page
    { label: 'unh_media-pool', url: environment.cdnUrl + '/icons/membership/svg/icon-media-pool.svg' },
    { label: 'unh_certificate', url: environment.cdnUrl + '/icons/membership/svg/icon-certificate.svg' },
    { label: 'unh_lessons', url: environment.cdnUrl + '/icons/membership/svg/icon-lesson-hours.svg' },
    { label: 'unh_community', url: environment.cdnUrl + '/icons/membership/svg/icon-community.svg' },
    { label: 'unh_contests', url: environment.cdnUrl + '/icons/membership/svg/icon-contests.svg' },
    { label: 'unh_custom', url: environment.cdnUrl + '/icons/membership/svg/icon-custom.svg' },
    { label: 'unh_discount', url: environment.cdnUrl + '/icons/membership/svg/icon-discount.svg' },
    { label: 'unh_free', url: environment.cdnUrl + '/icons/membership/svg/icon-free.svg' },
    { label: 'unh_management', url: environment.cdnUrl + '/icons/membership/svg/icon-management.svg' },
    { label: 'unh_pro', url: environment.cdnUrl + '/icons/membership/svg/icon-pro.svg' },
    { label: 'unh_checked', url: environment.cdnUrl + '/icons/membership/svg/icon-checked.svg' },
    { label: 'unh_creditcard_pink', url: environment.cdnUrl + '/svg-icons/creditcard_pink.svg' },
    { label: 'unh_bankslip_pink', url: environment.cdnUrl + '/svg-icons/bankslip_pink.svg' },

    // Itens de menu
    { label: 'unh_pro_menu', url: environment.cdnUrl + '/svg-icons/pro-menu-gray.svg' },
    { label: 'unh_pro_menu_pink', url: environment.cdnUrl + '/svg-icons/pro-menu-pink.svg' },
    { label: 'unh_arrow_comments', url: environment.cdnUrl + '/svg-icons/arrow-comments.svg' },

    //Box mosaico
    { label: 'unh_mosaico_level', url: environment.cdnUrl + '/mosaico/icon-level.svg' },
    { label: 'unh_mosaico_clock', url: environment.cdnUrl + '/mosaico/icon-clock.svg' },
    { label: 'unh_mosaico_sound', url: environment.cdnUrl + '/mosaico/icon-sound.svg' },
    { label: 'unh_mosaico_legends', url: environment.cdnUrl + '/mosaico/icon-legends.svg' },
    { label: 'unh_mosaico_software', url: environment.cdnUrl + '/mosaico/icon-software.svg' },
    { label: 'unh_mosaico_teacher', url: environment.cdnUrl + '/mosaico/icon-teacher.svg' },

    //EMoji
    { label: 'unh_emoji_sad', url: environment.cdnUrl + '/svg-icons/emoji/emoji-sad.svg' },

    { label: 'unh_meus_cursos', url: environment.cdnUrl + '/svg-icons/meus-cursos/meus-cursos-icon.svg' },
    // { label: 'unh_meus_cursos_mobile', url: environment.cdnUrl + '/icons/svg/meus-cursos/icon-meus_cursos-mobile.svg' },

    { label: 'unh_forum_insert_image', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_insert_single_image.svg' },
    { label: 'unh_forum_insert_gallery', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_insert_gallery.svg' },
    { label: 'unh_forum_insert_thumbnail_cover', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_insert_thumbnail_cover.svg' },
    { label: 'unh_forum_edit_gallery', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_edit_gallery.svg' },

    { label: 'unh_forum_insert_link', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_insert_link.svg' },
    { label: 'unh_forum_insert_text', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_insert_text.svg' },
    { label: 'unh_forum_insert_video', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_insert_video.svg' },
    { label: 'unh_forum_delete_component', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_delete_component.svg' },
    { label: 'unh_forum_move_component', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_move_component.svg' },
    { label: 'unh_forum_alert', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_alert.svg' },
    { label: 'u_fnhorum_plus', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_plus.svg' },
    { label: 'unh_forum_hamburguer', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_hamburguer.svg' },
    { label: 'unh_forum_reorder', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_reorder.svg' },
    { label: 'unh_forum_plus', url: environment.cdnUrl + '/svg-icons/forum/ico_forum_plus.svg' },

    // FORUM SINGLE IMAGE TOOLBAR
    { label: 'unh_forum_toolbar_edit', url: environment.cdnUrl + '/svg-icons/forum/single-image-toolbar/ico_forum_toolbar_edit.svg' },
    { label: 'unh_forum_toolbar_set_cover', url: environment.cdnUrl + '/svg-icons/forum/single-image-toolbar/ico_forum_toolbar_set_cover.svg' },
    { label: 'unh_forum_toolbar_update_link', url: environment.cdnUrl + '/svg-icons/forum/single-image-toolbar/ico_forum_toolbar_update_link.svg' },
    { label: 'unh_forum_toolbar_update_alt_text', url: environment.cdnUrl + '/svg-icons/forum/single-image-toolbar/ico_forum_toolbar_update_alt_text.svg' },
    { label: 'unh_forum_toolbar_trash', url: environment.cdnUrl + '/svg-icons/forum/single-image-toolbar/ico_forum_toolbar_trash.svg' },

    //FORUM COMMUNITY
    { label: 'unh_community_contests', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_contests.svg' },
    { label: 'unh_community_contestsanteriores', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_contestsanteriores.svg' },
    { label: 'unh_community_mentoria', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_mentoria.svg' },
    { label: 'unh_community_unhidecast', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_unhidecast.svg' },

    { label: 'unh_community_tutoriais', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_tutoriais.svg' },
    { label: 'unh_community_unhideblog', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_blog.svg' },
    { label: 'unh_community_embreve', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_embreve.svg' },
    { label: 'unh_community_sériesespeciais', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_seriesespeciais.svg' },



    { label: 'unh_community_bugreport', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_bugreport.svg' },
    { label: 'unh_community_featurerequests', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_featurerequest.svg' },
    { label: 'unh_community_entrevistas', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_unhidelives.svg' },
    { label: 'unh_community_feedback', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_feedback.svg' },
    { label: 'unh_community_galeria', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_galeria.svg' },
    { label: 'unh_community_vagasdeemprego', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_vagasdeemprego.svg' },
    { label: 'unh_breadcrumbs_separator', url: environment.cdnUrl + '/svg-icons/forum/breadcrumbs/ico_breadcrumbs_separator.svg' },

    { label: 'unh_community_releasenotes', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_releasenotes.svg' },
    { label: 'unh_community_wipfeedback', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_wipfeedback.svg' },
    { label: 'unh_community_contests', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_contests.svg' },


    { label: 'unh_community_novasfeatures', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_releasenotes.svg' },
    { label: 'unh_community_unhideusergroup', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_wipfeedback.svg' },
    { label: 'unh_community_unhidecontests', url: environment.cdnUrl + '/svg-icons/forum/community/ico_community_contests.svg' },

    { label: 'unh_forum_manager_flag', url: environment.cdnUrl + '/svg-icons/forum/dropdown-manager/ico_flag.svg' },
    { label: 'unh_forum_manager_pen', url: environment.cdnUrl + '/svg-icons/forum/dropdown-manager/ico_pen.svg' },
    { label: 'unh_forum_manager_pin', url: environment.cdnUrl + '/svg-icons/forum/dropdown-manager/ico_pin.svg' },
    { label: 'unh_forum_manager_delete', url: environment.cdnUrl + '/svg-icons/forum/dropdown-manager/ico_deletar.svg' },

    //bottomsheet
    { label: 'unh_forum_manager_close', url: environment.cdnUrl + '/svg-icons/forum/bottomsheet-manager/bottomsheet-close.svg' },

    //UNHIDEWEEK
    { label: 'promo-old-price-line', url: environment.cdnUrl + '/promos/unhideweek/svg-icon/old-price-line-white.svg' },



    //PLAYER
    { label: 'unh_player_arrow', url: environment.cdnUrl + '/player/svg-icons/player-mobile-arrow-icon.svg' },
    { label: 'unh_player_header_menu', url: environment.cdnUrl + '/player/svg-icons/player-menu-mobile-icon.svg' },



  ];

  constructor(private mir: MatIconRegistry, private sanitizer: DomSanitizer) { }

  registerCustomIcons(customIcons: { label: string; url: string; }[] = []) {
    const icons = [...this.customIcons, ...customIcons];
    icons.forEach(ci => this.mir.addSvgIcon(ci.label, this.sanitizer.bypassSecurityTrustResourceUrl(ci.url)));
  }
}
