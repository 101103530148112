import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { StorageService } from './services/storage.service';
import { RestApiService } from './services/rest-api.service';
import { GraphqlApiService } from './services/graphql-api.service';
import { UHQLApiService } from './services/uhql-api.service';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    StorageService,
    RestApiService,
    GraphqlApiService,
    UHQLApiService
  ]
})
export class ApiGatewayModule { }
