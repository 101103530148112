import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngxs/store';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Class } from 'utility-types';
interface DialogData {
  text?: {
    title?: string;
    message?: string;
  };
  size?: 'small' | 'default';
  buttons?: {
    text: string;
    type: 'default' | 'outline';
    action?: Class<any>;
    actionArgs: any[];
    response?: any;
  }[];

}
@Component({
  selector: 'unh-confirm-default',
  templateUrl: './confirm-default.component.html',
  styleUrls: ['./confirm-default.component.scss']
})
export class ConfirmDefaultComponent implements OnInit {
  spinnerControl: boolean;

  constructor(
    private dialogRef: MatDialogRef<ConfirmDefaultComponent>,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data?: DialogData

  ) { }

  ngOnInit(): void {
  }

  response(response: boolean) {
    if (response && this.data.buttons[1].action != undefined) {
      this.spinnerControl = true;

      this.store
        .dispatch(new this.data.buttons[1].action(...this.data.buttons[1].actionArgs))
        .pipe(
          catchError((err) =>
            of(this.dialogRef.close('Houve um Erro no dispatch'))
          )
        )
        .subscribe(() => {
          this.spinnerControl = false;
          this.dialogRef.close(true);
        });
    }
    this.dialogRef.close(response);
  }
}
