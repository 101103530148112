import { Pipe, PipeTransform } from '@angular/core';
import { getHTMLStringAsText } from '@frontend/unhideschool/app/helpers/utility-functions';

@Pipe({
    name: 'getHTMLStringAsText'
})

export class getHTMLStringAsTextPipe implements PipeTransform {
    transform(str: string): any {
        const text = getHTMLStringAsText(str)
        
        try {
            return JSON.parse(text);
        } catch (error) {
            return text;
        }
    }
}