import { groupCollectionByKey } from '@frontend/unhideschool/app/helpers/group-collection-by-key.func';

import {
  asNumber,
  checkPostIsNew,
  getActivePromoPrice,
  safeJsonParse,
  slugify
} from '@frontend/unhideschool/app/helpers/utility-functions';

import { ActivePromotion } from '@frontend/unhideschool/app/shared-nxgs/application/application.model';
import { Course, CreatorType } from '@frontend/unhideschool/graphql-schema';
import { Gallery } from '../dtos/gallery.model';
import { Tagtype } from '../dtos/posts.model';
import { MeInfo } from '@frontend/unhideschool/app/core/models';


///////////////////// AVAILABLE LANGUAGE OPTIONS /////////////////////
enum Languages {
  PT = 'Português',
  EN = 'Inglês',
  ES = 'Espanhol',
}

enum Subtitles {
  portuguesesubtitles = 'PT',
  englishsubtitles = 'EN',
  spanishsubtitles = 'ES',
}

enum AudiosLanguages {
  portugueseaudio = 'PT',
  englishaudio = 'EN',
  spanishaudio = 'ES',
}

enum LessonLevels {
  nivelbasico = 'Básico',
  nivelintermediario = 'Intermediário',
  nivelavancado = 'Avançado',
}

function extractTraists(traits: { internalname: string }[]) {
  const languageOptions = {
    audio: {
      shortlangs: [],
      langs: [],
    },
    subtitle: {
      shortlangs: [],
      langs: [],
    },
    levels: [],
  };

  traits.forEach((t) => {
    if (t.internalname.indexOf('audio') > -1) {
      languageOptions.audio = {
        shortlangs: [
          ...languageOptions.audio.shortlangs,
          AudiosLanguages[t.internalname],
        ],
        langs: [
          ...languageOptions.audio.langs,
          Languages[AudiosLanguages[t.internalname]],
        ],
      };
    }

    if (t.internalname.indexOf('subtitles') > -1) {
      languageOptions.subtitle = {
        shortlangs: [
          ...languageOptions.subtitle.shortlangs,
          Subtitles[t.internalname],
        ],
        langs: [
          ...languageOptions.subtitle.langs,
          Languages[Subtitles[t.internalname]],
        ],
      };
    }

    if (t.internalname.indexOf('nivel') > -1) {
      languageOptions.levels = [
        ...languageOptions.levels,
        LessonLevels[t.internalname],
      ];
    }
  });

  return languageOptions;
}

export class ProjectPost {
  // todo: remove any types
  progress?: any;
  tags?: any;

  isAdminUser: boolean;
  creatoruid?: number;
  postid?: number;
  title?: string;
  headline?: string;
  duration?: string;
  fulldescription?: string;
  isnew?: boolean;
  iscommingsoon?: boolean;
  showInMyCourses?: boolean;
  showInPublicListings?: boolean;

  audio?: any;
  subtitle?: any;
  levels?: string[];

  postviewallhead?: string;
  postthumbnail?: string;
  postmobilecover?: string;
  postthumbnailcover?: string;

  orderedpostparts?: {
    orderedpostpartid?: number | string;
    postpartid?: number;
    postpart: {
      subtitle: string;
      postparttype?: {
        internalname?: any;
      };
    };
  }[];

  creator?: CreatorType;
  galleries?: Gallery;

  basictags?: Tagtype[];
  softwaretags?: Tagtype[];
  difficultytags?: Tagtype[];

  routerlink?: any;

  product?: {
    active: boolean;
    productid: number;
    productpriceid: number;
    price: string;
    pricewithdiscount?: string;
  };

  position?: number;
  traits?: any;
  active? = false;
  published = false;

  hasPromo?= false;

  get titleslug() {
    return slugify(this.title);
  }

  constructor(
    course: Course,
    activePromotion: ActivePromotion = {
      initialized: false,
      active: false,
      coupon: null,
    },
    routerLinkPrefix = '/home/view-all',
    isAdminUser: boolean = false
  ) {
    this.creatoruid = asNumber(course.creator.uid);
    this.postid = asNumber(course.postid);
    this.title = course.title;

    if (course.active !== undefined) {
      this.active = course.active;
    }
    if (course.published !== undefined) {
      this.published = course.published;
    }

    this.headline = safeJsonParse(course.headline);
    this.fulldescription = safeJsonParse(course?.fulldescription)?.replace(/rel=\"noopener noreferrer\"/g,'rel=\"noopener noreferrer nofollow\"');
    // Dates and Times
    this.isnew = checkPostIsNew(course.datepublished, 30);

    if (course.contenttime != undefined) {
      this.duration = calculateCourseDuration(course.contenttime);
    }

    // Cover and Thumbnail's
    this.postviewallhead = course.postviewallhead;
    this.postthumbnail = course.postthumbnail + '?tr=w-350,h-550';
    this.postmobilecover = course.postmobilecover;
    this.postthumbnailcover = course.postthumbnailcover;

    //Is comming soon admin click availability
    this.isAdminUser = isAdminUser

    // this.orderedpostparts = course.orderedpostparts;

    // Creator
    this.creator = course.creator;

    // Available Language Options
    if (course.traits) {
      const traits = extractTraists(course.traits);
      this.audio = traits.audio;
      this.subtitle = traits.subtitle;
      this.levels = this.sortLevels(traits.levels);
    }

    //get traits
    if (course.traits != undefined) {
      // Available Language Options
      const traits = extractTraists(course.traits);
      this.audio = traits.audio;
      this.subtitle = traits.subtitle;
      this.levels = this.sortLevels(traits.levels);
      this.traits = course.traits;
    }

    // Post Tags by Type
    if (course.tags != undefined) {
      const { basic, software, difficulty } = groupCollectionByKey(
        course.tags,
        'tagtype.internalname'
      ).reduce(
        (acc, v) => {
          acc[v.name] = v.items;
          return acc;
        },
        { basic: [], software: [], difficulty: [] }
      );

      this.difficultytags = difficulty;
      this.softwaretags = (software as Tagtype[]).map((st) => ({
        ...st,
        name: slugify(st.name),
      }));
      this.basictags = basic;
    }

    // Comming Soon & Link
    this.iscommingsoon =
      course.traits.some((t) => t.internalname === 'comingsoon') &&
      !course.published &&
      course.active;

    this.showInMyCourses = !course.traits.some(
      (trait) => trait.internalname === 'comingsoon'
    );

    this.showInPublicListings =
      (this.active && this.published) || this.iscommingsoon;

    const slug = slugify(this.title);
    if (isAdminUser) {
      this.routerlink = [routerLinkPrefix, this.postid, slug];

    } else if  (!this.iscommingsoon) {
        this.routerlink = [routerLinkPrefix, this.postid, slug];
      }
    

    // Product
    if (course.product && course.product != undefined) {
      const productprice = course.product.prices[0];
      if (
        course.product.discountcoupons &&
        activePromotion.coupon &&
        activePromotion.coupon.couponcode
      ) {
        this.hasPromo = course.product.discountcoupons.some(
          (coupon) => coupon.couponcode === activePromotion.coupon.couponcode
        );
      }
      this.product = {
        active: course.product.active,
        price: productprice.price.toString(),
        productpriceid: asNumber(productprice.productpriceid),
        productid: asNumber(course.product.productid),
      };

      // Aplicar desconto da promoção ativa
      if (activePromotion.active) {
        this.product.pricewithdiscount = getActivePromoPrice(activePromotion, {
          price: productprice.price,
          coupons: course.product.discountcoupons.map((dc) => dc.couponcode),
        });
      }
    }

    this.position = course.position;
    if (course.galleries) {
      this.galleries = course.galleries;
    }
  }

  update(item) {
    const newversion = { state: this, ...item };
    const arr = Object.entries(newversion);
    arr.forEach(([key, value]) => {
      if (value != undefined) {
        this[key] = value;
      }
    });
    return this;
  }

  checkLevelWeight(level): number {
    switch (level) {
      case 'Básico': {
        return 1;
      }
      case 'Intermediário': {
        return 2;
      }
      case 'Avançado': {
        return 3;
      }
      default:
        return undefined;
    }
  }

  sortLevels(levels: any[]) {
    return levels.sort((a, b) => {
      const aNumber = this.checkLevelWeight(a);
      const bNumber = this.checkLevelWeight(b);
      return aNumber - bNumber;
    });
  }
}

const calculateCourseDuration = (duration) => {
  const _minutes = Math.floor(duration / 60);
  const hours = Math.floor(duration / 3600);
  const minutes = _minutes > 60 ? _minutes - hours * 60 : _minutes;
  // const seconds = duration - (minutes * 60 + hours * 3600);
  return formatToCourseTime(hours, minutes);
};

const formatToCourseTime = (hours, minutes) => {
  return (hours ? hours + 'h ' : '') + minutes + 'm';
}
