import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  setItem(key: string, value: any, isRaw = false): void {
    try {
      localStorage.setItem(key, !isRaw ? JSON.stringify(value) : value);
    } catch (error) {
      console.error(error);
    }
  }

  getItem(key: string, isRaw = false): any {
    try {
      const value = localStorage.getItem(key);
      return !isRaw ? JSON.parse(value) : value;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  removeItem(key: string): void {
    localStorage.removeItem(key);
  }
  
}
