export const FetchCareersQuery = `
query FetchCareers{
    CourseTrail{
      all(orderBy: POSITION_ASC) {
        items {
          id
          name
          isSelected
          relatedMedia {
            render
          }
        }
      }
    }
  }
`;

export const FetchMyCareersQuery = `
query FetchUserCareers($userId: Int!){
    CourseTrail{
      all(page:1, perPage: 200, userId: $userId, orderBy: POSITION_ASC) {
        items {
          id
          name
          progress(uid: $userId)
          lastWatchedCourse(uid: $userId){
            position
          }
          relatedMedia {
            render
          }
        }
      }
    }
  }
`;