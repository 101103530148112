import { Component, Input } from '@angular/core';

@Component({
  selector: 'unh-spinner-loader',
  styleUrls: ['./spinner-loader.component.scss'],
  template: `
    <div [style.--spinner-color]="color" class="loadingio-spinner-eclipse-73jl37ndeuj" [ngClass]="size + '-spinner'">
      <div class="ldio-q46azzrny2a">
        <div></div>
      </div>
    </div>
  `
})
export class SpinnerLoaderComponent {
  @Input() size = 'medium';
  @Input() color = '#ff0048';
}
