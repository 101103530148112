
/**
 * Graphql Query | ProjectPosts
 * ***
 * @filters posttypeid, active, published
 * ***
 * Essa query aplica 3 filtros padrões para trazer filtrar os posts
 * de projeto e não aceita argumentos.
 */
export const getNextPrevPostsQuery = `
    query getNextPrevPosts($prevPos: Int, $nextPos: Int) {
        prevPost:Posts(
            filters: [
                {position: {op: EQ, v: $prevPos}},
                {posttypeid:{op:EQ,v:1}},
                {active:{op:EQ,v:true}},
                {published:{op:EQ,v:true}}
            ],
            orderBy: {f:position,o:ASC}
        ) {
            postid
            position
            title
            traits {
                internalname
            }
        }

        nextPost:Posts(
            filters: [
                {position: {op: EQ, v: $nextPos}},
                {posttypeid:{op:EQ,v:1}},
                {active:{op:EQ,v:true}},
                {published:{op:EQ,v:true}}
            ],
            orderBy: {f:position,o:ASC}
        ) {
            postid
            position
            title
            traits {
                internalname
            }
        }
    }
`;
