import { createSelector } from '@ngxs/store';
import * as LessonUpsetModels from './course-admin-lesson.model'
// import { StateModel } from './course-admin-lesson.model'
import { CourseAdminLessonUpsetState } from './course-admin-lesson.state'

export class CourseAdminLessonUpsetSelectors {
  static getParams() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => ({ postid: state.postid, postpartid: state.postpartid }));
  }

  static getLastData() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => state.lastdata);
  }
  static getCurrentTranslatableData(lang?) {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => {
        return state.translatableData[lang ? lang : state.currentLanguage];
      });
  }

  static getCurrentCommonData() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => state.commonData);
  }

  static getCurrentLanguage() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => state.currentLanguage);
  }
  static getMode() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => state.mode);
  }

  static isEditMode() {
    return createSelector(
      [CourseAdminLessonUpsetSelectors.getMode()],
      (mode: string) => mode === 'edit');
  }

  static getCurrentMode() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => state.mode);
  }

  static getGroupnameList() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) =>{
        return state.translatableData[state.currentLanguage].groupnamelist});
  }

  static getCurrentGroupname() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => state.translatableData[state.currentLanguage].groupname);
  }

 static getVideoProgress() {
    return createSelector(
      [CourseAdminLessonUpsetState] ,
      (state: LessonUpsetModels.StateModel) => state.commonData && state.commonData.media && state.commonData.media.video ? state.commonData.media.video.uploadprogress : null);
  }

  static getFiles() {
    return createSelector(
      [CourseAdminLessonUpsetState],
      (state: LessonUpsetModels.StateModel) => {
        if (state.files != undefined && state.files.length > 0) {
          return state.files;
        }
        return [];
      })
  }

}

