<div class="dialog-wrapper">
  <div class="dialog-container">
    <div class="text-container" *ngIf="data">
      <h2 class="title">{{ data.text.title }}</h2>
      <p class="text" [innerHtml]="data.text.message"></p>
    </div>
    <div class="loading" *ngIf="spinnerControl">
      <unh-spinner-loader> </unh-spinner-loader>
    </div>
    <div class="actions" *ngIf="data.buttons">
      <ng-container *ngFor="let btn of data.buttons">
        <unh-button
          [color]="btn.type"
          [block]="true"
          (click)="response(btn.response !== undefined ? btn.response : true)"
          >{{ btn.text }}</unh-button>
      </ng-container>
    </div>
  </div>
</div>
