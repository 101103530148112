export const getAllCoursesAdminQuery = `
    query getAllCoursesAdminQuery(
      $orderBy: PostOrderByParams
      $page: Int,
      $perpage: Int,
      $filters: [PostQueryParams]
    ){
      Posts(
        filters:$filters
        orderBy: $orderBy
        page: $page
        perpage: $perpage
      ) {
        postid
        postviewallhead
        title
        active
        published
        datecreated
        traits {
          internalname
        }
        creator{
          uid
        }
      }
    }
`;
