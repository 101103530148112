import { Injectable, Inject } from '@angular/core';
import { HttpEventType, HttpClient } from '@angular/common/http';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MediaUploaderService {
  constructor(
    @Inject('Env') private Env: any,
    private http: HttpClient
  ) { }

  upload(
    endpoint: string,
    auth?: boolean,
    data?: any,
    contentType?: string
) {
  const localstorageData = localStorage.getItem(this.Env.storagekeys.unhtoken) as string;
  const token: any = JSON.parse(localstorageData);
  const httpOptions: any = {
    headers: {
      Authorization:`Unhide tok = ${ token }`,
    },
    reportProgress: true,
    observe: 'events'
  };

  const url = `${this.Env.apipath}/media/uploadfile`;

  return this.http.post(url, data, httpOptions).pipe(
    switchMap((event:any) => {
      let progress: any;

      switch (event.type) {
        case HttpEventType.UploadProgress:
          progress = Math.round(100 * event.loaded / event.total);
          return of({ status: 'progress', progress });

        case HttpEventType.Response:
          return of(event.body);

        default:
          return of(event);
      }
    })
  );
}


}
