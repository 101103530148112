import { Component, ViewChild } from '@angular/core';

@Component({
  selector: 'unh-empty-content',
  templateUrl: './empty-content.component.html',
  styleUrls: ['./empty-content.component.scss'],
})
export class EmptyContentComponent {
  @ViewChild('hybridizerContainer') hydc: any;

  // constructor(private upgrade: UpgradeModule) {}

  // ngAfterViewInit() {
  //   // const containerEl = this.hydc.nativeElement;
  //   // this.upgrade.bootstrap(containerEl, ['unhide']);
  // }

}
