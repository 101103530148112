
/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateUpsetTranslatableData {
  static readonly type = '[Update Course Admin Lesson Upset Translatable Data] Update1';
  constructor(public payload: any, public lang) { }
}



/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateCommonData {
  static readonly type = '[Update Course Admin Lesson Upset Common Data] Update';
  constructor(public payload: any, public lang) { }
}

/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateCurrentLanguage {
  static readonly type = '[Update Course Admin Lesson Upset Current Language 213123] Update';
  constructor(public lang) { }
}


/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UploadVideo {
  static readonly type = '[uploadVideoUploadCourseAdminLessonUpset] Update';
  constructor(public file: any) { }
}


/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class PublishLesson {
  static readonly type = '[PublishLessonCourseAdminLessonUpset] Post';
  constructor() { }
}



/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchLessonUpsetData {
  static readonly type = '[CourseAdmin_FetchLessonUpsetData] Post';
  constructor(public orderedpostpartid: number) { }
}



/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateLessonUpset {
  static readonly type = '[CourseAdmin_UpdateLessonUpset] Update';
  constructor() { }
}



/**
 * Class that represents a NGXS Action
 * @param groupnames Define an array of Groupnames.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateGroupnameList {
  static readonly type = '[CourseAdmin LessonUpset] UpdateGroupnameList';
  constructor(public groupnames: any[]) { }
}



/**
 * Class that represents a NGXS Action
 * @param groupnames Define an array of Groupnames.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateTranslatedGroupnameListIntoTranslatableData {
  static readonly type = '[CourseAdmin LessonUpset] UpdateTranslatedGroupnameListIntoTranslatableData';
  constructor(public groupnames: any[]) { }
}




/**
 * Class that represents a NGXS Action
 * @param groupnames Define an array of Groupnames.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateTranslatedCurrentGroupname {
  static readonly type = '[CourseAdmin LessonUpset] UpdateTranslatedCurrentGroupname';
  constructor(public groupnameLanguages: any) { }
}


/**
 * Class that represents a NGXS Action
 * @param groupnames Define an array of Groupnames.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateCurrentGroupname {
  static readonly type = '[CourseAdmin LessonUpset] UpdateCurrentGroupname';
  constructor(public groupname: string) { }
}

/**
 * Class that represents a NGXS Action
 * @param groupnames Define an array of Groupnames.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class updateLesson {
  static readonly type = '[CourseAdmin LessonUpset] UpdateLesson';
  constructor() { }
}


export class UpdateLessonParams {
  static readonly type = '[CourseAdmin LessonUpset] UpdatePostid';
  constructor(public postid: number, public orderedpostpartid?: number) { }
}

export class AddLessonAssets {
  static readonly type = '[CourseAdmin LessonUpset] AddLessonAssets';
  constructor(public mediaitemid: number, public postpartid: number) { }
}


export class FetchVideoFiles {
  static readonly type = '[CourseAdmin LessonUpset] getVideoFiles';
  constructor(public orderedpostpartid: number) { }
}

export class RemoveLesson {
  static readonly type = '[CourseAdmin LessonUpset] RemoveLesson';
  constructor(public orderedpostpartid: number) { }
}

export class UpdateFiles {
  static readonly type = '[CourseAdmin LessonUpset] UpdateFiles';
  constructor(public files: any[]) { }
}

export class RemoveLessonFileLocal {
  static readonly type = '[CourseAdmin LessonUpset] RemoveLessonFileLocal';
  constructor(public id: any[]) { }
}

export class ResetState {
  static readonly type = '[CourseAdmin LessonUpset] ResetState';
  constructor() { }
}
export class UploadSubtitles {
  static readonly type = '[CourseAdmin LessonUpset] UploadSubtitles';
  constructor() { }
}

export class editSubtitles {
  static readonly type = '[CourseAdmin LessonUpset] editSubtitles';
  constructor() { }
}
export class changeFreeLessonPosition {
  static readonly type = '[ Course Admin] changeFreeLessonPosition';
  constructor(public orderedpostpartid: number, public lessons: any[]) { }
}
