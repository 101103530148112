import { NormalizedPlanProduct, NormalizedSubscriptionPlan } from "../../modules/membership/models/membership-normalized-plan.model";

interface DiscountCouponType {
  internalname: string;
}

export interface DiscountCoupon {
  active: boolean;
  name: string;
  couponcode: string;
  value: number;
  cycles: number;
  validuntil: string;
  datecreated: string;
  discountcoupontype: DiscountCouponType;
}

export interface ActivePromotion {
  initialized: boolean;
  active: boolean;
  coupon: DiscountCoupon;
  global?: boolean;
}

export interface SidebarAppStateModel {
  open: boolean;
}


export interface UserAppStateModel {
  coursesIdList: number[];
}

export interface ApplicationStateModel {
  subscriptionWarning: boolean;
  activepromotion: ActivePromotion;
  subscriptionPlans: NormalizedSubscriptionPlan[]
  sidebar: SidebarAppStateModel;
  user: UserAppStateModel;
  anualPlan: NormalizedSubscriptionPlan,
  anualPlanRaw: any;
}

export const defaultApplicationStateModel: ApplicationStateModel = {
  subscriptionPlans: [],
  subscriptionWarning: false,
  activepromotion: {
    initialized: false,
    active: false,
    global: false,
    coupon: null
  },
  sidebar: {
    open: true,
  },
  user: {
    coursesIdList: null
  },
  anualPlan: null,
  anualPlanRaw: null
};
