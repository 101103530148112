import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';

declare const $;

@Component({
  selector: 'unh-side-menu-container',
  templateUrl: './side-menu-container.component.html',
  styleUrls: ['./side-menu-container.component.scss']
})
export class SideMenuContainerComponent implements OnInit, OnDestroy {
  @Input() ispro: boolean;
  @Input() username?: string;
  @Input() uid?: string;
  @Input() useravatarurl?: string;

  @Input() isOpen: boolean;
  @Input() menuType: string;
  @Input() menuTitle: string;
  @Input() itensList: any[];

  @Output() closeClicked = new EventEmitter();
  @Output() itemClicked = new EventEmitter();

  scrolled: boolean;

  close() {
    this.closeClicked.emit(true);
  }

  ngOnInit() {
    $('.nano').nanoScroller();
  }

  ngOnDestroy() {
    $('.nano').nanoScroller({ destroy: true });
  }

  onItemClicked(payload: any) {
    this.itemClicked.emit(payload);
  }
}
