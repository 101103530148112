export const getSoftwareTagsList = `
query SoftwareTargsCourseList{
  SoftwareTags{
    tagid
    name
    tagtype{
      tagtypeid
      name
    }
  }
}`


export const getCareersTags = `
  {
    drTags(filters: [{tagtypeid: {op: EQ, v: 4}}]) {
      name
      tagid
    }
  }
`;
