<div class="crop-dialog-container"
  
>
  <h2 *ngIf="!data?.header">
    DEFINIR
    <strong>RECORTE {{ aspectRatio?.x + 'x' + aspectRatio?.y }}</strong>
  </h2>
  <div *ngIf="data?.header" class="header">
    <h2 class="title">{{data.header.title}}</h2>
    <p class="subtitle">
      {{data.header.subtitle}}
      <strong class="dimensions">{{data.header.dimensions}}</strong>
    </p>
  </div>
  <mat-radio-group
    *ngIf="aspectRatios?.length > 1"
    [(ngModel)]="aspectRatio"
    aria-label="Selecionar uma opção"
  >
    <mat-radio-button [value]="ar" *ngFor="let ar of aspectRatios">
      {{ ar?.x + 'x' + ar?.y }}
    </mat-radio-button>
  </mat-radio-group>

  <br />

  <div class="cropper-container-box">
    <unh-image-cropper-container
      [imageFile]="imageFile | async"
      [aspectRatio]="aspectRatio.x / aspectRatio.y"
      (cropInfo)="setImageUrlWithCropOptions($event)"
      (cropImageLoaded)="onCropImageLoaded($event)"
    ></unh-image-cropper-container>
  </div>

  <br />
  <br />

  <div>
    <div *ngIf="imageLoaded" class="actions">
      <a
        mat-button
        theme="pink-pink-outline"
        id="cancel-button"
        class="unh-button-old mat-button mat-button-base"
        (click)="closeDialog()"
        >Cancelar</a
      >

      <a
        mat-button
        theme="pink-white"
        class="unh-button-old mat-button mat-button-base"
        (click)="closeDialog({ cropInfo: cropInfo, aspectRatio: aspectRatio })"
      >
        Confirmar
      </a>
    </div>
  </div>
</div>
