
export interface MeInfoQueryData {
    user: {
        uid: number;
        alias: string;
        profilepicture: string;
        relatedmedia: any;
        role: {
            internalname: string;
        };
    };

    courses: { postid: number }[];

    subscribed: boolean;
}

export const meInfoQuery = `
    query MeBasicInfoQuery {
        me {
            user {
                uid
                alias
                profilepicture
                relatedmedia{
                  imagesettings {
                    imagesettingid
                    width
                    height
                    waspectratio
                    haspectratio
                    xcenter
                    ycenter
                  }
                  mediacontenttype{
                    internalname
                  }
                }
                role {
                    internalname
                }
            }
            courses {
                postid
              },
            subscribed
        }
    }
`;
