export const lastWatchedLessonQuery = `
query fetchLastWatchedLesson($postId: Int){
    MyCourses(postId: $postId) {
      courses {
        lastwatched{
          orderedpostpartid
          title
        }
      }
    }
  }
`