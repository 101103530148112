export interface CollectionGroupItem<T> {
  name: string;
  items: T[];
}

export function groupCollectionByKey<T>(
  collection: T[],
  groupkey: string
): CollectionGroupItem<T>[] {
  return collection.reduce<CollectionGroupItem<T>[]>((acc, item) => {
    const name = deepExtractPropertyByKey(item, groupkey);
    const exist = acc.some(g => g.name === name);

    // Cria um novo grupo se ainda não existir
    const groups = !exist ? [...acc, { name, items: [] }] : acc;

    const index = groups.findIndex(g => g.name === name);
    groups[index].items = [ ...groups[index].items, item];

    return groups;
  }, []);
}


export function returnGroupTranslatedNames<T>(
  collection: T[],
  groupkey: string
): any[] {
  return collection.reduce<any[]>((acc, item) => {
    const name = deepExtractPropertyByKey(item, groupkey);
    const groupnameLanguages = deepExtractPropertyByKey(item, 'groupnameLanguages');
    const exist = acc.some(g => {
      return (g['ptBR'] === groupnameLanguages['ptBR']) && (g['enUS'] === groupnameLanguages['enUS'])
      });

    // Cria um novo grupo se ainda não existir
    const groups = !exist ? [...acc, { ...groupnameLanguages }] : acc;
    return groups;
  }, []);
}

function deepExtractPropertyByKey(obj, key) {
  const keyparts = key.split('.');

  if (keyparts.length === 1) {
    return obj[key];
  }
  return keyparts.reduce((acc, k) => acc[k], obj);
}
