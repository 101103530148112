export const GetProjectByIDQuery = `
query getProjectById($postid: Int!){
  VideoPlayer(postid: $postid){
    course{
      postid
      creator{
        uid
      }
      playlist{
        items {
          orderedpostpartid
          thumbnail
          title
          groupname
          position
          groupnameLanguages
        }
      }
    }
  }
}`
