export const getActiveCoupons = `
  query getAllActiveDicountCoupons {
    DiscountCoupon {
      all( active: true) {
        items {
          couponcode
        }
      }
    }
  }
`
