export const updateGalleryMutation = `
    mutation(
        $galleryid: ID!,
        $published: Boolean,
        $active: Boolean,
        $coverid: Int
    ) {
        updateGallery(galleryData: {
            galleryid: $galleryid,
            published: $published,
            active: $active,
            coverid: $coverid
        }) {
            Gallery {
                name,
                galleryid,
                published,
                active,
                coverid
                items {
                    active,
                    galleryitemid,
                    imagesettings {
                        imagesettingid,
                        width,
                        height,
                        waspectratio,
                        haspectratio,
                        xcenter,
                        ycenter
                    },
                    mediaitem {
                        render,
                        creator
                        {
                uid,
                username,
                            profilepicture,
                            fullname,
                            occupationtitle,
                            occupation {
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;
