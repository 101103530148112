
<unh-side-menu position="right" [isOpen]="isOpen" class="fixed-top">
    <unh-side-menu-header>
        <div class="avatar-container">
          <unh-user-avatar
            [avatarUrl]="useravatarurl"
            [username]="username"
            [uid]="uid"
            [userSubscribed]="isSubscribed"
            [hasUnreadNotifs]="unreadNotifs"
            [redirectToProfile]="true"
            [defaultAvatarUrl]="defaultAvatarUrl"
          ></unh-user-avatar>
        </div>

        <div class="title">{{ menuTitle?.length > 25 ? menuTitle?.substring(0, 25).concat('...') : menuTitle }}</div>

        <a class="close-btn" (click)="close()">
          <mat-icon svgIcon="unh_close"></mat-icon>
        </a>
    </unh-side-menu-header>

    <div class="simple-tabs">
        <a
            [class.selected]="selectedTab === 'userMenuTab'"
            (click)="selectedTab = 'userMenuTab'"
        >
            Configurações
        </a>

        <a
            [class.selected]="selectedTab === 'notificationsMenuTab'"
            [class.unreads]="unreadNotifs"
            (click)="selectedTab = 'notificationsMenuTab'"
        >
            Notificações
        </a>
    </div>

    <div class="user-menu-tab" [class.opened]="selectedTab === 'userMenuTab'">
        <div class="nano">
            <unh-side-menu-content
                class="nano-content"
                [class.centered]="!userMenuItens || userMenuItens.length === 0"
            >
                <div class="spinner-loader-container" *ngIf="!userMenuItens || userMenuItens.length === 0">
                    <unh-spinner-loader></unh-spinner-loader>
                </div>

                <unh-generic-list-container [itensList]="userMenuItens">
                    <ng-template let-item>
                        <ng-container [ngSwitch]="item.itemtype">
                            <unh-user-menu-item-button
                                *ngSwitchCase="'action'"
                                [itemAction]="item.link"
                                [itemTitle]="item.title"
                                [itemSubtitle]="item.subtitle"
                                [itemIconType]="item.iconType"
                                [itemIcon]="item.icon"
                                (clickAction)="closeAtButton($event)"
                            ></unh-user-menu-item-button>

                            <unh-user-menu-item-link
                                *ngSwitchDefault
                                [itemLink]="item.link"
                                [itemTitle]="item.title"
                                [itemSubtitle]="item.subtitle"
                                [itemIconType]="item.iconType"
                                [itemIcon]="item.icon"
                                (itemClicked)="close()"
                            ></unh-user-menu-item-link>
                        </ng-container>
                    </ng-template>
                </unh-generic-list-container>
            </unh-side-menu-content>
        </div>
    </div>

    <div class="notifications-menu-tab" [class.opened]="selectedTab === 'notificationsMenuTab'">
        <div>
            <ng-content select=".mark-all-read"></ng-content>
        </div>

        <div class="nano">
            <unh-side-menu-content
                class="nano-content"
                [class.centered]="!notificationsMenuItens || notificationsMenuItens.length === 0"
            >
                <div class="spinner-loader-container" *ngIf="!notificationsMenuItens || notificationsMenuItens.length === 0">
                    <unh-spinner-loader></unh-spinner-loader>
                </div>

                <unh-generic-list-container [itensList]="notificationsMenuItens">
                    <ng-template let-item>
                        <unh-notifications-menu-item
                            [notificationItem]="item"
                            (itemClicked)="notificationsMenuItemClicked.emit($event)"
                        ></unh-notifications-menu-item>
                    </ng-template>
                </unh-generic-list-container>
            </unh-side-menu-content>
        </div>
    </div>
</unh-side-menu>

