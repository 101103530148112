import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'isProductOwned'})
export class IsProductOwnedPipe implements PipeTransform {
    transform(meProductidList: number[], productid: number): boolean {
        if (!meProductidList) {
            return false;
        }

        return meProductidList.some(pid => Number(pid) === Number(productid));
    }
}
