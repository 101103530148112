import { Component, ChangeDetectionStrategy, Input } from '@angular/core';

import { UseTranslations } from '@frontend/unhideschool/app/utils/i18n/use-translation.decorator';
import { UNHHomeModel } from '@frontend/unhideschool/app/utils/i18n/locale/unh-home/models/unh-home.i18n.model';
import { environment } from '@frontend/unhideschool/env';
import { AuthService } from '../../services/auth.service';


interface Translations {
  "unh-home": UNHHomeModel;
}

@Component({
  selector: 'unh-footer-container',
  templateUrl: './footer-container.component.html',
  styleUrls: ['./footer-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

@UseTranslations({
  translatableComponents: ["unh-home"]
})

export class FooterContainerComponent {

  pageTranslations: UNHHomeModel;
  translations: Promise<Translations>;

  year = new Date().getFullYear();
  @Input() navItemList: any[];

  policyPrivacyFileUrl = environment.policyPrivacyFileUrl;
  policyUsageFileUrl = environment.policyUsageFileUrl;
  currentLang = localStorage.getItem('unh-accept-language');
  constructor(
    private auths: AuthService
  ) {
    this.translations.then((translations) => {
      this.pageTranslations = translations['unh-home'];
    })
  }

  changeLanguage(event) {
    this.auths.setAcceptedLanguage(event.target.value);
    setTimeout(() => document.location.reload(), 300);
  }
}
