<nav class="user-actions">
    <ng-container *ngIf="!isLogged">
        <unh-button-old-container [design]="isSmallScreen ? 'icony' : ''">
            <a href="/login" title="Entrar">
                <unh-svg-icon
                    svgurl="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/svg/login.svg"
                    [customStyles]="isSmallScreen ? { width: '28px', height: '28px'} : { width: '18px', height: '18px'}"
                ></unh-svg-icon>

                <span *ngIf="!isSmallScreen">Entrar</span>
            </a>
        </unh-button-old-container>

        <unh-button-old-container [design]="'primary raised ' + (isSmallScreen ? 'icony' : '')">
            <a href="/signup" title="Cadastre-se">
                <unh-svg-icon
                    *ngIf="isSmallScreen"
                    svgurl="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/svg/signup.svg"
                    [customStyles]="{ width: '30px', height: '30px' }"
                ></unh-svg-icon>

                <span *ngIf="!isSmallScreen">Cadastre-se</span>
            </a>
        </unh-button-old-container>
    </ng-container>

    <div class="logged-user-actions" *ngIf="isLogged">
        <!-- <unh-button-old-container design="darkgray icony" *ngIf="!isSmallScreen">
            <ng-container *ngIf="unreadNotifs.length">
                <div *ngIf="unreadNotifs.length > 0" class="count-button-badge">{{ unreadNotifs.length }}</div>
            </ng-container>
        </unh-button-old-container> -->

        <!-- <a *ngIf="!isSmallScreen" mat-icon-button (click)="onToggleNotificationsMenu()" title="Notificações">
            <unh-svg-icon
                svgurl="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/icon-notif_area.png"
                [customStyles]="{ width: '34px', height: '34px'}"
            ></unh-svg-icon>
        </a> -->

        <div class="avatar-container" (click)="onToggleUserMenu()">
            <unh-user-avatar
              [avatarUrl]="user?.profilepicture"
              [username]="user?.username"
              [uid]="user?.uid+''"
              [userSubscribed]="luss.subscribed"
              [hasUnreadNotifs]="unreadNotifs.length > 0"
              [redirectToProfile]="false"
              [defaultAvatarUrl]="defaultAvatarUrl"
            ></unh-user-avatar>

        </div>
        <!-- TODO: pegar userSubscribed real -->
    </div>
</nav>
