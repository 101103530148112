import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UHQLApiService } from '@frontend/unhideschool/app/api-gateway/services/uhql-api.service';

import {
    SubscriptionFormDataStoreModel,
    defaultSubscriptionMemberFormData,
} from '../../../shared/models/subscription/subscription-form-data.model';

import { BaseStore } from '@frontend/unhideschool/app/helpers/base-store.class';
import { UHQLApiResponse } from '@frontend/unhideschool/core/models/base-uh-crude.model';

@Injectable({
    providedIn: 'root'
})
export class SubscriptionFormDataStore extends BaseStore<SubscriptionFormDataStoreModel> {
    constructor(private uhql: UHQLApiService) {
        super(defaultSubscriptionMemberFormData);
    }

    fetchStoreStateData<T>(selector: keyof SubscriptionFormDataStoreModel): Observable<T> {
        let request;

        switch (selector) {
            case 'subscriptionstates':
                request = this.uhql.uhqlRequest('LIST', 'SUBSCRIPTION_STATES');
                break;

            case 'memberstates':
                request = this.uhql.uhqlRequest('LIST', 'SUBSCRIPTION_MEMBER_STATES');
                break;

            case 'membertypes':
                request = this.uhql.uhqlRequest('LIST', 'SUBSCRIPTION_MEMBER_TYPES');
                break;
        }

        return request.pipe(
            map((res: UHQLApiResponse<T>) => res.dict.resource)
        );
    }
}
