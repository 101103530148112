import { createSelector } from '@ngxs/store';
import { ActivePromotion, ApplicationStateModel } from './application.model';
import { ApplicationState } from './application.state';

const ANUALPRICE = 528;

export class ApplicationSelectors {
  static hasSubscriptionWarning() {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => {
        // mudar para logica de booleanos quando subscription warning passar a ser um array
        return state.subscriptionWarning;
      }
    );
  }

  static getSubscriptions() {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => state.subscriptionPlans
      
    )
  }

  static getSubscriptionsByName(name) {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => {
        return state.subscriptionPlans.find(plan => plan.name.toLocaleLowerCase() === name.toLowerCase())
      }
    )
  }

  static getActivePromotion() {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => state.activepromotion
    );
  }

  static getAnualPriceWithDiscount() {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => state.anualPlan.price
    );
  }

  static hasAnualPromo() {
    return createSelector(
      [ApplicationSelectors.getActivePromotion()],
      (activepromotion: ActivePromotion) => activepromotion.global
    );
  }

  static hasActivePromotion() {
    return createSelector(
      [ApplicationSelectors.getActivePromotion()],
      (activepromotion: ActivePromotion) => activepromotion.active
    );
  }

  static getActivePromotionCoupon() {
    return createSelector(
      [ApplicationSelectors.getActivePromotion()],
      (activepromotion: ActivePromotion) => activepromotion.coupon
    );
  }
  static observeSidebarToggleState() {
    return createSelector(
      [ApplicationState], (state: ApplicationStateModel) => state.sidebar.open
    );
  }

  static getUserCoursesIdList() {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => state.user.coursesIdList
    )
  }

  static getAnualPlan() {
    return createSelector(
      [ApplicationState], (state: ApplicationStateModel) => state.anualPlan
    );
  }

  static getTeamSubscriptionPlan() {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => {
        return state.subscriptionPlans.find(plan => plan.name === 'Team');
      }
    );
  }
    
  static getSubscriptionByName(name) {
    return createSelector(
      [ApplicationState],
      (state: ApplicationStateModel) => {
        return state.subscriptionPlans.find(plan => plan.name.toLocaleLowerCase() === name.toLowerCase());
      }
    );
  }
}
