// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apipath: 'https://api.dev.unhideschool.com/api',
  checkoutpath: 'https://wil-checkout.embryo.octopusdate.com',
  cdnUrl: "https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com",
  enabledMenus: ['main_nav_menu', 'notifications_menu', 'user_menu', 'mobile_user_menu_notifications'],
  storagekeys: {
    unhcookieaccept: 'unhide.ca',
    unhacceptlang: 'unhide.al',
    unhtoken: 'unhide.token',
    unhuser: 'unhide.me'
  },
  applicationTitle: 'UNHIDE School',
  defaultAvatarUrl: 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/avatar.png',
  clientIdFacebook: '838749452958097',
  clientIdGoogle: '677186294365-akt50en2hk1i5a6ok6n3l2jgift51bk8.apps.googleusercontent.com',
  intercomAppid: 'p5vn00iy',
  RDStationToken: '58aad83bd07df2e81c0fa1fc5a3f7226',
  apppath: "http://localhost:4200",
  clickupApiPath: 'https://private-anon-f5bd8c69af-clickup20.apiary-proxy.com/api/v2',
  clickupJobApplyAuth: 'pk_3167463_J10QJD2KZE2DOMR7V2J09HL0OCJIAIRG',
  clickupJobApplyTemplateTaskId: 't-22habe5',
  clickupJobApplyList: '168865173',
  policyPrivacyFileUrl: 'https://drive.google.com/file/d/19y6D16DwaxSDe6G1xnGuSCiEFd-xq3lq/view?usp=sharing',
  policyUsageFileUrl: 'https://drive.google.com/file/d/1r4XeccYljhs9Wg0qhhpLjde7ec-WaL7A/view?usp=sharing'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
