import { createSelector } from '@ngxs/store';

import { UserStateModel } from './user.model';
import { UserState } from './user.state';

export class UserSelectors {
  static getUser(){
    return createSelector(
      [UserState],
      (state: UserStateModel) => state
    );
  }
  static getUserCourseList(){
    return createSelector(
      [UserState],
      (state: UserStateModel) => state.courses
    );
  }

}
