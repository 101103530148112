import { NgModule } from '@angular/core';

// Material
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatRadioModule } from '@angular/material/radio';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTooltipModule } from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatRippleModule } from '@angular/material/core';

@NgModule({
    imports: [
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatButtonModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatDialogModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTooltipModule,
        MatBottomSheetModule,
        MatSnackBarModule,
        MatRippleModule
    ],
    exports: [
        MatInputModule,
        MatFormFieldModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatButtonModule,
        MatListModule,
        MatMenuModule,
        MatIconModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatDialogModule,
        MatExpansionModule,
        MatProgressBarModule,
        MatCheckboxModule,
        MatChipsModule,
        MatTooltipModule,
        MatBottomSheetModule,
        MatSnackBarModule,
        MatRippleModule

    ],
})
export class MaterialModule { }
