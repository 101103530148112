import { GalleryItemId, Gallery, PreviewData } from '@frontend/unhideschool/shared/models/dtos/gallery.model';
import { SimpleGalleryItem } from './simple-gallery-item.model';

export interface SimpleGalleryModel {
    galleryid: GalleryItemId;
    name: string;
    cover: SimpleGalleryItem;
    items: SimpleGalleryItem[];
    published: boolean;
    active: boolean;
    preview?:boolean;
    previewdata?: any;
    gallerytopicdata?: {
        urldata?: any;
        badges?: any;
        info?: {
            title?: string
        }
    }
}

export class SimpleGallery implements SimpleGalleryModel {
    galleryid: GalleryItemId;
    cover: SimpleGalleryItem;
    items: SimpleGalleryItem[];
    name: string;
    published: boolean;
    active: boolean;
    preview?:boolean;
    previewdata?: PreviewData;
    gallerytopicdata?: {
        urldata?: any;
        badges?: any;
        info?: {
            title?: string
        }
    }

    constructor(gallery: Gallery) {
        this.name = gallery.name;
        this.active = gallery.active;
        this.published = gallery.published;
        this.galleryid = gallery.galleryid;
        this.preview = gallery.preview
        this.previewdata = gallery.previewdata
    if (gallery.orderedpostpart) {
            this.gallerytopicdata = {
                urldata: {
                    communitycategid: gallery.orderedpostpart.post.community.communitycategid,
                    subtitle: JSON.parse(gallery.orderedpostpart.postpart.subtitle),
                    postid: gallery.orderedpostpart.postid,
                    postpartid: gallery.orderedpostpart.postpartid
                },
                badges: gallery.orderedpostpart.post.badges,
                info: {
                    title: gallery.orderedpostpart.post.title
                }

            }
        }
        // tslint:disable-next-line: triple-equals
        const cover = gallery.items.find(gi => gi.galleryitemid == gallery.coverid);
        this.cover = new SimpleGalleryItem(this, cover != null ? cover : gallery.items[0]);

        this.items = gallery.items.map((gi) => new SimpleGalleryItem(this, gi));
    }

}



