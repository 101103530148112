export const notificationItemContentMapping = {
    subscriptionmemberinviteaccepted: {
        titlemodel: 'Convite aceito!',
        textmodel: '<span>Parabéns</span>, agora você faz parte da comunidade UNHIDE School, já deu aquele abraço no {fullname}?'
    },
    subscriptionmemberinvitedeclined: {
        titlemodel: 'Convite recusado',
        textmodel: 'Poxa, que pena que o convite não foi aceito, mas ainda podemos conversar sobre a sua <span>assinatura</span>. vem comigo!'
    },
    subscriptionmemberaccepted: {
        titlemodel: 'A.D.I.V.I.N.H.A',
        textmodel: '{fullname} aceitou fazer parte do seu <strong>Plano Empresa</strong>. Já podem combinar um collab!'
    },
    subscriptionmemberdeclined: {
        titlemodel: 'Epa...',
        textmodel: '{fullname} recusou o convite para participar do seu <strong>Plano Empresa</strong>.'
    },
    subscriptionmemberinvite: {
        titlemodel: 'Convite Inesperado!',
        textmodel: '{fullname} está te chamando para entrar no <strong>Plano Empresa</strong>. Vem que é seu momento!'
    },
    subscriptionmemberremoved: {
        titlemodel: 'Poxa vida...',
        textmodel: '{fullname} te removeu do <strong>Plano Empresa</strong>.<br> Mas não fica triste, você pode continuar com a gente!'
    },
    commentdownvote: {
        titlemodel: '{commenteralias} despositivou seu comentário',
        textmodel: ''
    },
    commentupvote: {
        titlemodel: '{commenteralias} positivou seu comentário',
        textmodel: ''
    },
    postlike: {
        titlemodel: '{commenteralias} curtiu {postparttitle}',
        textmodel: ''
    },
    postcomment: {
        titlemodel: '{commenteralias} comentou em {postparttitle}',
        textmodel: '{text}'
    },
    commentreplies: {
        titlemodel: '{commenteralias} repondeu seu comentário em {postparttitle}',
        textmodel: '{text}'
    },
    neworderedpostpart: {
        titlemodel: 'Novo vídeo disponível em {posttitle}',
        textmodel: '{postparttitle}'
    },
    newposts: {
        titlemodel: 'Novo curso',
        textmodel: '{title}'
    },
    subscriptionwarning:{
        titlemodel:'without titlemodel',
        textmodel:'whithout',
    },
   
    subscriptionexpiredwarning:{
        titlemodel:'without titlemodel',
        textmodel:'whithout',
    },
    subscriptionbackwarning:{
        titlemodel:'without titlemodel',
        textmodel:'whithout',
    },
    subscriptionmessage15:{
        titlemodel:'Hora de renovar a assinatura!',
        textmodel:'Seu plano PRO vai expirar em 15 dias... Posso te pedir pra ficar?',
    },
    subscriptionmessage5:{
        titlemodel:'Não se vá...',
        textmodel:'Seu plano PRO vai expirar em 5 dias. Vamos manter sua assinatura ativa? Clica aqui!',
    },
    subscriptionmessage1:{
        titlemodel:'Tem alguém ai?',
        textmodel:'Seu plano PRO vai expirar em 1 dia. Não dá mole e clica aqui para renovar agora a sua assinatura!',
    },
    subscriptionexpiredmessage:{
        titlemodel:'Sua Assinatura PRO expirou...',
        textmodel:'Mas podemos ressuscitá-la! Clica aqui para renovar agora e volte a ter acesso a todos os cursos disponíveis!',
    },
    subscriptionbackmessage:{
        titlemodel:'Todo mês tem novidade por aqui.',
        textmodel:'Volte a ser PRO e não perca nenhum curso novo na UNHIDE School!',
    }
};
