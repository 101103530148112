import { Component, Input } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'unh-icon-container',
  styleUrls: ['./icon-container.component.scss'],
  template: `
    <ng-container *ngIf="icon && iconType === 'url'">
      <div class="icon-wall" [style.background-image]="'url(' + icon + ')'"></div>
    </ng-container>

    <ng-container *ngIf="icon && iconType === 'class'">
      <div
        class="icon-wall"
        [ngClass]="icon + '-icon'"
        [@simpleFadeOutAnimation]="'in'"
      ></div>

      <div
        class="icon-wall"
        *ngIf="active"
        [ngClass]="icon + '-icon-active'"
        [@simpleFadeOutAnimation]="'in'"
      ></div>
    </ng-container>
  `,
  animations: [
    trigger('simpleFadeOutAnimation', [
      state('in', style({ opacity: 1 })),

      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),

      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ]
})
export class IconContainerComponent {
  @Input() active = false;
  @Input() iconType;
  @Input() icon;
}
