export class ViewAllFeatureBaseItem {
    iconUrl: string;
    text: string;

    constructor(post: any) {
        this.populate(post);
    }

    protected populate(post: any) {
    }
}

export class ViewAllFeatureLevel extends ViewAllFeatureBaseItem {
    constructor(post) {
        super(post);
    }

    populate() {
        this.iconUrl = 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/view-all-banner/ico-level.png';
        this.text = 'Nível: Intermediário';
    }
}

export class ViewAllFeatureViews extends ViewAllFeatureBaseItem {
    constructor(post) {
        super(post);
    }

    populate() {
        this.iconUrl = 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/view-all-banner/ico-students.png';
        this.text = '129 Estudantes';
    }
}

export class ViewAllFeatureDuration extends ViewAllFeatureBaseItem {
    constructor(post) {
        super(post);
    }

    populate() {
        this.iconUrl = 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/view-all-banner/ico-time.png';
        this.text = '5h 51m';
    }
}

export class ViewAllFeatureAudio extends ViewAllFeatureBaseItem {
    constructor(post) {
        super(post);
    }

    populate() {
        this.iconUrl = 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/view-all-banner/ico-language.png';
        this.text = 'Português';
    }
}

export class ViewAllFeatureSubtitles extends ViewAllFeatureBaseItem {
    constructor(post) {
        super(post);
    }

    populate() {
        this.iconUrl = 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/view-all-banner/ico-legends.png';
        this.text = 'Inglês / Espanhol';
    }
}

enum ViewAllFeatureTypes {
    Level,
    Views,
    Duration,
    Audio,
    Subtitles
}

export function viewAllFeaturesBuilder(post: any) {
    const types = Object.keys(ViewAllFeatureTypes).filter(k => isNaN(k as any));
    return types.map(type => {
        switch (type) {
            case 'Level': return new ViewAllFeatureLevel(post);
            case 'Views': return new ViewAllFeatureViews(post);
            case 'Duration': return new ViewAllFeatureDuration(post);
            case 'Audio': return new ViewAllFeatureAudio(post);
            case 'Subtitles': return new ViewAllFeatureSubtitles(post);
            default: throw new Error(
                `View all feature not implemented. (reading ${type})`);
        }
    });
}
