
export const loginFacebookMutation = `
  mutation LoginFacebookMutation($authtoken: String!) {
    FacebookLogin(accesstoken: $authtoken) {
      success
      authtoken {
        token
      }
    }
  }
`;
