import { ApplicationRef, ComponentFactory, ComponentFactoryResolver, ComponentRef, EmbeddedViewRef, Injectable, Injector, Type } from '@angular/core';
import { ConfirmationComponent } from '@frontend/unhideschool/shared/dialogs/confirmation/confirmation.component';
import { DialogPosition, MatDialog } from '@angular/material/dialog';
import { LoadingComponent } from '@frontend/unhideschool/shared/dialogs/loading/loading.component';

import { CookieDialogComponent } from '@frontend/unhideschool/shared/dialogs/cookie-dialog/cookie-dialog.component';
import { BreakpointObserver } from '@angular/cdk/layout';
import { ModalxContainerComponent } from '@frontend/unhideschool/shared/dialogs/modalx/modalx.component';
import { UnhDialogConfrimDefaultDataModel, UnhDialogData } from '../models/unh-dialog-service.model'
import { ConfirmDefaultComponent } from '@frontend/unhideschool/shared/dialogs/confirm-default/confirm-default.component';
@Injectable({
  providedIn: 'root',
})
export class UnhDialog {
  constructor(
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,

    private cfr: ComponentFactoryResolver,
    private applicationRef: ApplicationRef,
    private injector: Injector,
  ) { }
  /**
   * Abre um UNHIDE Confirm Dialog
   * @param options -Objeto Opcional com tipo definido abaixo:

   *
   * @type text: {
    *  title: string,
    *  message: string
   * } - data: {any}
   *
   *
   * @returns Retorna um DialogRef
   *
   * @beta <0.1>
   */
  confirm(options?: UnhDialogData) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      panelClass: 'dialog-style-reset',
      data: options ? options : null,
      width: '384px',
    });
    return dialogRef.afterClosed();
  }



  defaultConfirm(options?: UnhDialogConfrimDefaultDataModel) {
    const dialogRef = this.dialog.open(ConfirmDefaultComponent, {
      panelClass: 'dialog-dark',
      data: options ? options : null,
      width: '384px',
    });
    return dialogRef.afterClosed();
  }


  /**
 * Abre um UNHIDE Loading Dialog
 * @param options -Objeto Opcional com tipo definido abaixo:

 *
 * @type text: {
  *  title: string,
  *  message: string
 * } - data: {any}
 *
 *
 * @returns Retorna um DialogRef
 *
 * @beta <0.1>
 */
  loading(options?: UnhDialogData) {
    const dialogRef = this.dialog.open(LoadingComponent, {
      panelClass: 'dialog-style-reset',
      data: options ? options : null,
      width: '384px',
    });
    return dialogRef.afterClosed();
  }


  /**
 * Abre um UNHIDE Cookie Confirm Dialog
 * @returns Retorna um DialogRef
 *
 * @beta <0.2>
 */
  cookieConfirm(options?: UnhDialogData) {
    const componentRef: ComponentRef<CookieDialogComponent> = this.getComponentRef(CookieDialogComponent);
    this.applicationRef.attachView(componentRef.hostView);
    const hostView = (componentRef.hostView as EmbeddedViewRef<any>);
    const domElem = hostView.rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    componentRef.instance.afterClosed.subscribe(
      () => {
        this.applicationRef.detachView(componentRef.hostView)
      }
    )
    return componentRef;
  }



  /**
* Abre um ModalX
* @returns Retorna um DialogRef
*
* @beta <0.1>
*/
  open(component: Type<any>, options: any = null): ComponentRef<ModalxContainerComponent> {
    const componentRef: ComponentRef<ModalxContainerComponent> = this.getComponentRef(ModalxContainerComponent);
    const customComponentFactoryRef = this.cfr.resolveComponentFactory(component);
    componentRef.instance.customComponentFactoryRef = customComponentFactoryRef;

    if (options) {
      componentRef.instance.width = options.width;
      componentRef.instance.maxWidth = options.maxWidth;
      componentRef.instance.maxHeight = options.maxHeight;
      componentRef.instance.height = options.height;
      componentRef.instance.isMobile = options.isMobile;
    }

    this.applicationRef.attachView(componentRef.hostView);
    const hostView = (componentRef.hostView as EmbeddedViewRef<any>);
    const domElem = hostView.rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);


    return componentRef;
  }


  public beforeClosed(componentRef: ComponentRef<ModalxContainerComponent>) {
    componentRef.instance.beforeClosed.subscribe(() => {
      this.applicationRef.detachView(componentRef.hostView);
    })
    return componentRef.instance.beforeClosed;
  }

  private getComponentFactory(component: Type<any>): ComponentFactory<any> {
    return this.cfr.resolveComponentFactory(component);

  }

  private getComponentRef(component: Type<any>): ComponentRef<any> {
    return this.getComponentFactory(component).create(this.injector);
  }
}
