import { LocaleServiceController } from './locale-service-controller.service';

// const isFunction = (fn) => typeof fn === 'function';
type Settings = { translatableComponents: string[] };

async function getAllLocaleComponentStrings(
  localeService: LocaleServiceController, 
  translatableComponents: string[]
) {
  const translationsObject = {};
  
  for(const tc of translatableComponents){
    translationsObject[tc] = await localeService.getLocaleComponentStrings(tc);
  }

  return translationsObject;
}

export function UseTranslations({ translatableComponents }: Settings) {
  // eslint-disable-next-line @typescript-eslint/ban-types
  return function (constructor: Function) {
    const localeService = new LocaleServiceController();

    constructor.prototype['translations'] = new Promise((resolve) => {
      getAllLocaleComponentStrings(localeService, 
        translatableComponents).then(translations => resolve(translations));
    });
  };
}
