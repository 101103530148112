import { ApiResponse } from './base-api.model';

export type UHQLRequestSchema = UHQLRequestSchemaObject[] | UHQLRequestSchemaObject | number | string | boolean;
export type UHQLRequestMethod = 'getlist' | 'getone' | 'create' | 'update';
export type UHQLRequestFilterOperator = '==' | '!=' | '<' | '>' | 'like';
export type UHQLRequestFilterValue = string | number | boolean;

export enum UHQLRequestMethods {
    ONE = 'getone',
    LIST = 'getlist',
    CREATE = 'create',
    UPDATE = 'update',
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UHQLApiResponse<T> extends ApiResponse<{ resource: T }> {}

export interface UHQLRequestSchemaObject {
    [key: string]: UHQLRequestSchema;
}

export class UHCrudSchemaObjectBaseClass implements UHQLRequestSchemaObject {
    [k: string]: UHQLRequestSchema;
}

export interface UHQLRequestFilter {
    field: string;
    op: UHQLRequestFilterOperator;
    value: UHQLRequestFilterValue;
}

export interface UHQLRequestOptions {
    order_by?: string;
    page?: number;
    perpage?: number;
    total?: string;
}

export interface UHQLRequestData extends UHQLRequestOptions {
    resource: string;
    schema?: UHQLRequestSchema;
    filters?: UHQLRequestFilter[];
}
