import { Injectable } from "@angular/core";
import { State, Action, StateContext } from "@ngxs/store";

import {
  GraphqlFilters,
  GraphqlOrderByOption,
} from "@frontend/unhideschool/shared/models/graphql/base-graphql";

import { tap, map } from "rxjs/operators";
import { Observable } from "rxjs";

import {
  FetchProjectPosts,
  FetchProjectPostOrderedPostParts,
  FetchProjectPostRelatedUrlFiles,
} from "./project-posts.actions";

import { ProjectPost } from "@frontend/unhideschool/shared/models/project/project-post.model";
import { OrderedPostPart } from "@frontend/unhideschool/shared/models/dtos/ordered-post-part.model";

import {
  ProjectPostsStateModel,
  defaultHomePostsState,
  defaultEmptyCourse,
} from "./project-posts.model";

import { orderedPostPartsQuery } from "@frontend/unhideschool/shared/models/graphql/ordered-post-parts.query";
import { postRelatedUrlFilesQuery } from "@frontend/unhideschool/shared/models/graphql/post-related-url-files.query";

import { customProjectPostQuery } from "./../../shared/models/graphql/project-posts.query";
import { ActivePromotion } from "../application/application.model";
import { GraphqlApiService } from "../../api-gateway/services/graphql-api.service";
import { LoggedUserStateService } from "../../core/services/legacy/logged-user.state";

function mapFetchedHomepostsToProjectPosts(activePromotion: ActivePromotion,isAdminUser: boolean) {
  return (obs: Observable<any>) => obs.pipe(
    map((res) => {
      const posts: ProjectPost[] = res.data.homeposts.posts.map(
        (post) => {
          const projectprost = new ProjectPost(post, activePromotion,'view-all', isAdminUser );
          projectprost.galleries = post.galleries; // Passando a referência para as galerias
          return projectprost;
        }
      );
      const totalCount: number = res.data.homeposts.totalCount;
      return { posts, totalCount };
    })
  );
}

@State<ProjectPostsStateModel>({
  name: "projectPosts",
  defaults: defaultHomePostsState,
})
@Injectable()
export class ProjectPostsState {
  constructor(private graph: GraphqlApiService, private luss: LoggedUserStateService) {}

  @Action(FetchProjectPosts)
  fetchProjectPosts(
    ctx: StateContext<ProjectPostsStateModel>,
    action: FetchProjectPosts
  ) {
    if (action.reset) {
      ctx.patchState({
        postsLimit: defaultHomePostsState.postsLimit,
        projectposts: defaultHomePostsState.projectposts,
      });
    }

    return this.updateProjectPostsState(ctx, action, this.luss.isAdmin);
  }

  @Action(FetchProjectPostOrderedPostParts)
  getOrderedPostParts(
    ctx: StateContext<ProjectPostsStateModel>,
    action: FetchProjectPostOrderedPostParts
  ): Observable<OrderedPostPart[]> {
    const filters: GraphqlFilters = [
      { postid: { op: "EQ", v: action.postid } },
    ];

    const orderby: GraphqlOrderByOption<any> = { f: "position", o: "ASC" };

    return this.graph.graphqlRequest<'OrderedPostParts', any[]>(
      orderedPostPartsQuery, 
      { filters, orderby }
    ).pipe(
      map((res) => res.data.OrderedPostParts),
      tap((orderedPostParts: any) =>
        ctx.patchState({ 
          orderedPostParts: { [action.postid]: orderedPostParts } 
        })
      )
    );
  }

  @Action(FetchProjectPostRelatedUrlFiles)
  getPostRelatedUrlFiles(
    ctx: StateContext<ProjectPostsStateModel>,
    action: FetchProjectPostOrderedPostParts
  ): Observable<ProjectPostsStateModel[]> {
    const filters: GraphqlFilters = [
      { postid: { op: "EQ", v: action.postid } },
    ];

    return this.graph.graphqlRequest<'VideoPlayer', { course: any }>(
      postRelatedUrlFilesQuery, 
      { filters }
    ).pipe(
      map(res => res.data.VideoPlayer),
      tap((postRelatedUrlFiles: any) =>
        ctx.patchState({
          postRelatedUrlFiles: { [action.postid]: postRelatedUrlFiles },
        })
      )
    );
  }

  private updateProjectPostsState(
    ctx: StateContext<ProjectPostsStateModel>,
    action: FetchProjectPosts,
    isAdminUser: boolean
  ) {
    const variables = this.getRequestVariables(action);
    const stateProjectPosts: ProjectPost[] = ctx.getState().projectposts;

    if(stateProjectPosts.length == 0){
      this.prepareLoadingItems(ctx, action, stateProjectPosts, action.initItemsQuantity);
    }else{
      this.prepareLoadingItems(ctx, action, stateProjectPosts, action.initItemsQuantity);
    }

    // Add the loading items to state
    return this.graph.graphqlRequest<'homeposts', any>(customProjectPostQuery, variables).pipe(
      mapFetchedHomepostsToProjectPosts(action.activePromotion, isAdminUser),
      tap(({ posts, totalCount }) => {
        const projectposts = [...stateProjectPosts, ...posts];

        const postsLimit =
          action.perpage * (action.page - 1) + posts.length >= totalCount;

        ctx.patchState({
          postsLimit,
          projectposts,
          actualFilterState: action.filter.slug,
        });
      })
    );
  }

  private prepareLoadingItems(
    ctx: StateContext<ProjectPostsStateModel>,
    action: FetchProjectPosts,
    projectPosts: ProjectPost[],
    length: number
  ) {
    const loadingItems = Array.from({ length }, (_, i) => {
      const emptyCourse = defaultEmptyCourse;
      emptyCourse.postid = -(i + 1);
      return emptyCourse;
    });

    ctx.patchState({
      projectposts: [...projectPosts, ...loadingItems],
      postsLimit: false,
      actualFilterState: action.filter ? action.filter.slug : "",
    });
  }

  private getRequestVariables(action: FetchProjectPosts) {
    const variables = {
      page: action.page,
      perpage: action.perpage,
    };

    if (action.filter.tagid > 0) {
      variables["tags"] = [action.filter.tagid];
    }

    return variables;
  }
}
