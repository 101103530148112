<div class="generic-galery">
    <div class="gallery-layout-container">
        <div [class.small]="isProject" class="masonry-grid ">
            <div class="grid-area">
              <!-- TODO: Refatorar essa parte para nao conter informacao específica!!! -->
                <div
                    *ngFor="let galleryItem of galleryItems; trackBy: trackByFn;"
                    [style.grid-row-end]="'span ' + galleryItem.imagesettings?.haspectratio"
                    [style.grid-column-end]="'span ' + galleryItem.imagesettings?.waspectratio"

                >
                    <div
                    class="generic-item__container"
                    >
                        <ng-container
                            [ngTemplateOutlet]="genericGalleryItemTemplateRef"
                            [ngTemplateOutletContext]="{ $implicit: galleryItem, editMode: editMode }"
                        ></ng-container>
                      </div>

                </div>
              </div>
        </div>
    </div>
</div>
