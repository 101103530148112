import { ProjectPost } from './project-post.model';
import { slugify, interpolate } from '@frontend/unhideschool/app/helpers/utility-functions';
import { defaultEmptyCourse } from '@frontend/unhideschool/app/shared-nxgs/project-posts/project-posts.model';
import { Course, Video } from '@frontend/unhideschool/graphql-schema';
import { DeepPartial } from 'utility-types';

export interface MyCoursesModel {
  projectpost?: Partial<ProjectPost>;
  progress?: any | null;
  lastpostpartview?: Video;
  lastViewLink?: string;
}

export interface MyCoursesMethods {
  setProgress: (course: DeepPartial<Course>) => void;
  setLastWatched: (course: DeepPartial<Course>) => void;
}

export class MyCourses implements MyCoursesModel, MyCoursesMethods {
  progress: string | null = null;
  lastpostpartview: Video | null = null;
  projectpost: ProjectPost;
  lastViewLink: string;
  active?: boolean;
  published?: boolean;
  constructor(course: DeepPartial<Course>) {
    this.progress = course?.progress?.toString();
    this.projectpost = new ProjectPost(course as Course);
    if (course.lastwatched) {
      this.setLastWatched(course);
    }

  }

  setProgress(course: DeepPartial<Course>) {
    this.progress = course?.progress?.toString();
  }

  setLastWatched(course: DeepPartial<Course>) {
    this.lastpostpartview = course.lastwatched as Video;
    
    if (!this.lastpostpartview) {
      return this.lastViewLink = this.projectpost.routerlink
    }

    const dataLink = {
      postid: this.projectpost.postid,
      orderedpostpartid: this.lastpostpartview.orderedpostpartid,
      posttitleslug: slugify(this.projectpost.title),
      postparttitleslug: slugify(this.lastpostpartview.title)
    };

    const lastViewUrl =
      '/post/{postid}/{orderedpostpartid}/{posttitleslug}/{postparttitleslug}';

    this.lastViewLink = interpolate(lastViewUrl, dataLink);

  }
}


