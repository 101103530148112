
export const signupGoogleMutation = `
  mutation SignupGoogleMutation(
    $clientid: String!, 
    $alias: String!, 
    $termaccept: Boolean!, 
    $idtoken: String!, 
    $datebirth: Date!, 
    $genderid: Int!
  ) {
    GoogleSignup(signupData: {
      clientid: $clientid,
      idtoken: $idtoken,
      datebirth: $datebirth,
      alias: $alias,
      genderid: $genderid,
      termaccept: $termaccept
    }) {
      success
      authtoken {
        token
      }
    }
  }
`;
