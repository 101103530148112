<div class="sidebar-wrapper">
  <container-element [ngSwitch]="isSmallScreen">
    <div *ngIf="!isSmallScreen"[class.menuClosed]="compactMenu" class="sidebar-fill-space" >
    </div>

    <div *ngSwitchDefault class="sidebar-ng-container">
      <unh-sidebar
        [islogged]="islogged"
        [currentMenu]="currentMenu"
        [userMenu]="args.userMenu"
        [user]="userMenu"
        [compact]="compactMenu"
        [menus]="args.menus"
        [signupMenuItem]="loginMenuItem"
        [loginMenuItem]="registerMenuItem"
        [appendSidebarOpen]="notificationsOpen"
        (menuChanged)="menuChanged($event)"
        (languageChanged)="languageChanged($event)"
        (toggleChanged)="toggleHandler($event)"
        (appendSidebarChanged)="changeNotificationsState($event)"
        [currLanguage]="currLanguage"
        [languages]="args.userMenu.user.languages"
        [currentPath]="args.currentPath"
        >
        <div class="append-sidebar-content" slot="append-sidebar-content">
          <pzl-button type="default"></pzl-button>
          <ng-container *ngIf="(notifcationsMenuItens$ | async); let notifications">
            <ng-container *ngIf="unreadNotifs$ | async; let unreadNotifs;">
              <div class="mark-all-read" *ngIf=" unreadNotifs.length > 0">
                <unh-button-old-container (click)="markNotificationsAsRead(unreadNotifs)" design="lightgrey">
                  Marcar tudo como visto ({{ unreadNotifs.length }})
                </unh-button-old-container>
              </div>
            </ng-container>
            <unh-notifications-menu-item *ngFor="let item of notifications" [notificationItem]="item"
              (itemClicked)="handleNotificationActions($event)">
            </unh-notifications-menu-item>
          </ng-container>
        </div>

      </unh-sidebar>
    </div>

    <ng-container *ngSwitchCase="true">
      <unh-menu-content-mobile></unh-menu-content-mobile>
      <ng-container *ngIf="(unreadNotifs$ | async); let unreadNotifs;">
        <unh-side-menu-container *ngIf="!isSmallScreen" [isOpen]="notificationsMenuIsOpen$ | async"
          [menuType]="notificationsMenuName" [menuTitle]="notificationsMenuTitle"
          [itensList]="notifcationsMenuItens$ | async" (closeClicked)="closeMenu()"
          (itemClicked)="handleNotificationActions($event)">
          <div class="mark-all-read" *ngIf="unreadNotifs.length > 0">
            <unh-button-old-container (click)="markNotificationsAsRead(unreadNotifs)" design="lightgrey">
              Marcar tudo como visto ({{ unreadNotifs.length }})
            </unh-button-old-container>
          </div>
        </unh-side-menu-container>

        <unh-side-menu-container-mobile [username]="luss?.user?.alias" [uid]="luss?.user?.uid" [useravatarurl]="luss?.user?.profilepicture"
          [menuTitle]="userMenuTitle" [userMenuItens]="userMenuItens"
          [notificationsMenuItens]="notifcationsMenuItens$ | async" [unreadNotifs]="unreadNotifs.length > 0"
          [isOpen]="userMenuIsOpen$ | async" (closeClicked)="closeMenu()"
          (userMenuItemClicked)="handleUserMenuItemClick($event)"
          (notificationsMenuItemClicked)="handleNotificationActions($event)">
          <div class="mark-all-read" *ngIf="unreadNotifs.length > 0">
            <unh-button-old-container (click)="markNotificationsAsRead(unreadNotifs)" design="lightgrey">
              Marcar tudo como visto ({{ unreadNotifs.length }})
            </unh-button-old-container>
          </div>
        </unh-side-menu-container-mobile>
      </ng-container>

    </ng-container>
  </container-element>

  <div id="unh-wrapper" [class.logged]="!isSmallScreen" [class.menuClosed]="compactMenu">
    <div class="wrapper-content"
      [class.active-promo]="hasActivePromotion$ | async"
      [class.unhideweek-promo]="hasActivePromotion$ | async"
    >
      <div class="layout-container" [class.logged-layout]="islogged"></div>
      <router-outlet></router-outlet>
    </div>

    <unh-footer-container></unh-footer-container>
  </div>
</div>
