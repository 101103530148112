import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'unh-post-badges-list',
  templateUrl: './post-badges-list.component.html',
  styleUrls: ['./post-badges-list.component.scss']
})
export class PostBadgesListComponent implements OnInit {
  @Input() badges;

  constructor() { }

  ngOnInit(): void {

  }
}
