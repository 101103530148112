import { Injectable } from '@angular/core';
import { GTMEventPayloadModel, GTMUserPropertiesPayloadModel, GTMVisitorsTypes } from '../models/google-tag-manager-service.model';
declare const dataLayer;

@Injectable({ providedIn: 'root' })
export class GoogleTagManagerService {
    setVisitor<T>(userType: GTMVisitorsTypes) {
        const payload: GTMEventPayloadModel<GTMUserPropertiesPayloadModel> = {
            event: 'set_visitor', data: {
                user: {
                    userType
                }
            }
        }
        dataLayer.push(payload);
    }
}
