export const UpdateCourseAdminLessonMutation = `
mutation (
  $orderedpostpartid: Int!,
  $thumbnailid: Int,
  $creatoruid: Int,
  $description: InputMVStringBag,
  $mediaitemid: Int,
  $title: InputMVStringBag,
  $public: Boolean,
  $groupname: InputMVStringBag,
  $tags: [String],
  $active: Boolean,
	$position: Int
) {
  UpdateVideo(videoData:
    {orderedpostpartid: $orderedpostpartid,
      thumbnailid: $thumbnailid,
      creatoruid: $creatoruid,
      description: $description,
      public:$public,
      mediaitemid: $mediaitemid,
      title: $title,
      groupname: $groupname,
      tags: $tags,
      active:$active,
    	position: $position
    }) {
      video{
        orderedpostpartid
        title
        position
        content
        orderedpostpartid
        postpartid
        postid
        public
        traits{
          tagtypeid
        }
        tags{
          name
        }
        mediaadapter{
          internalname
        }
      }
}}
`
