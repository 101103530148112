import { Component, Input } from '@angular/core';

@Component({
  selector: 'unh-button-old-container',
  styleUrls: ['./button-container.component.scss'],
  template: `
    <span class="button-container" [ngClass]="design">
      <ng-content></ng-content>
    </span>
  `
})
export class ButtonContainerComponent {
  @Input() design = '';
  @Input() radio;
}
