export const MediaTypes = {
  read: true,
  write: false
}

export interface MediaInfoModel {
  src?: string;
  id?: any;
  file?: any;
  read?: boolean;
  uploadprogress?: number;
}

export interface CourseAdmin_LessonUpsetMediaModel {
  postpartthumbnail: MediaInfoModel;
  video: MediaInfoModel;
}

export interface TranslatableDataGroupnameModel {
  title: string;
}

export interface TranslatableDataInfoModel {
  title?: string;
  description?: string;
  groupname?: any;
  groupnamelist?: TranslatableDataGroupnameModel[];
  videosubtitle?: MediaInfoModel
}

export interface TranslatableDataModel {
  pt_BR: TranslatableDataInfoModel,
  en_US: TranslatableDataInfoModel
}

export interface CommonDataModel {
  tags?: any[],
  media?: CourseAdmin_LessonUpsetMediaModel,
  instructor?: any;
  visibility?: any;
}

export interface LessonFilesModel {
  id?: string;
  name?: string;
  mediaitemid?: string;
  content?: string;
}
export interface StateModel {
  mode?: string;
  postid?: number,
  postpartid?: number,
  orderedpostpartid?: number,
  currentLanguage: string;
  translatableData: TranslatableDataModel;
  commonData: CommonDataModel;
  lastdata?: StateModel;
  files?: any[];
}
export const STATE_NAME = 'LessonUpsert'

export const defaults: StateModel = {
  mode: 'create',
  currentLanguage: 'pt_BR',
  translatableData: {
    pt_BR: {
      title: '',
      description: '',
      groupname: null,
      groupnamelist: [],
      videosubtitle: {}
    },
    en_US: {
      title: '',
      description: '',
      groupname: null,
      groupnamelist: [],
      videosubtitle: {}

    }
  },
  commonData: {

  },

}
