import { ActivatedRoute } from "@angular/router";

export interface OriginDataModel {
  token: string;
  origin: 'unhideschool:membership' | string;
  subscriptionid?: number;
  orderid?: number;
  couponcode?: string;
}

export class OriginData implements OriginDataModel {
  private hash: string;

  token: string;
  origin: string;
  subscriptionid?: number;
  orderid?: number;
  couponcode?: string;

  constructor(origindata: OriginDataModel) {
    this.token = origindata.token;
    this.origin = origindata.origin;

    if ('couponcode' in origindata) {
      this.couponcode = origindata.couponcode;
    }

    if ('subscriptionid' in origindata) {
      this.subscriptionid = origindata.subscriptionid;
    }

    if ('orderid' in origindata) {
      this.orderid = origindata.orderid;
    }

    this.makeHash();
  }

  checkout(checkoutUrl: string) {
    window.location.href = this.getCheckoutUrl(checkoutUrl);
  }

  getCheckoutUrl(checkoutUrl: string) {
    return `${checkoutUrl}?_c=${this.hash}`;
  }

  private makeHash() {
    const origindata: OriginDataModel = {
      origin: this.origin,
      token: this.token,
      couponcode: this.couponcode
    };

    if (this.subscriptionid != null) {
      origindata.subscriptionid = this.subscriptionid;
    }

    if (this.orderid != null) {
      origindata.orderid = this.orderid;
    }
    this.hash = btoa(JSON.stringify(origindata));
  }

  private getQueryCouponCode(couponcode) {
    const hasCouponcode = couponcode != undefined && couponcode != '';
    return hasCouponcode ? `&_ac=${couponcode}` : '';
  }
}
