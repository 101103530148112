export interface UserData {
  fullname: string;
  email: string;
  password: string;
  alias: string;
  genderid: number;
  datebirth: string;
  termaccept: boolean;
}

export const signupApplicationMutation = `
  mutation SignupApplicationMutation(
    $fullname: String!
    $alias: String!, 
    $email: String!, 
    $password: String!, 
    $genderid: Int!,
    $datebirth: Date!,
    $termaccept: Boolean!,
  ) {
    Signup(signupData: {
      alias: $alias, 
      email: $email, 
      password: $password, 
      genderid: $genderid, 
      datebirth: $datebirth, 
      termaccept: $termaccept,
      fullname: $fullname
    }) {
      authtoken {
        user {
          uid
          alias
          username
          fullname
          email
          country {
            countryid
            name
          }
          genderid
          datebirth
          interests {
            interestid
          }
          websites {
            uid
            url
          }
        }
        token
      }
      success
    }
  }
`;
