import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { CouponcodeGuardService } from '../services/couponcode-guard.service'

const whitelist = [
  'aqualua', 'randall', 'pixel', 'monjinho', 'rafatuma', 'polerito'
];

@Injectable({
  providedIn: 'root'
})
export class CouponCodeGuard implements CanActivate {

  constructor(
    private router: Router,
    private csg: CouponcodeGuardService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const couponcode = route.routeConfig.path;
    if (whitelist.includes(couponcode)) {
      const plansandpriceurl = 'assinatura/planos-e-precos/';
      this.router.navigate([plansandpriceurl, couponcode]);
      return false;
    }
    return true;

  }
}
