import { Directive, HostListener, Output, EventEmitter } from '@angular/core';

@Directive({
    selector: '[unhHoverObserver]',
    exportAs: 'hoverObs'
})
export class HoverObserverDirective {
    hovered = false;

    @Output() hoverStateChanged = new EventEmitter<boolean>();

    @HostListener('mouseover')
    onMouseOver() {
        this.hovered = true;
        this.hoverStateChanged.emit(true);
    }

    @HostListener('mouseout')
    onMouseOut() {
      this.hovered = false;
        this.hoverStateChanged.emit(false);
    }
}
