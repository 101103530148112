
export const loginGoogleMutation = `
  mutation LoginGoogleMutation($idtoken: String!, $clientid: String!){
    GoogleLogin(idtoken: $idtoken, clientid: $clientid){
      authtoken {
        token
      }
    }
  }
`;
