import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'unh-warning-bar',
  templateUrl: './warning-bar.component.html',
  styleUrls: ['./warning-bar.component.scss']
})
export class WarningBarComponent implements OnInit {
  @Output() closeTrigger:any = new EventEmitter()
  constructor() { }

  ngOnInit(): void {
  }
  closeBar(){
    this.closeTrigger.emit(true)
  }
}
