import { MeInfoQueryData } from "../../core/models/graphql/me-info.query";

export interface UserRelatedmedia {
    profilepicture?: string;
    userheader?: string;
}

export interface Addresstype {
    addresstypeid: number;
    name: string;
    internalname: string;
    datecreated: string;
    active: boolean;
}

export interface Addresse {
    useraddressid: number;
    uid: number;
    addresstypeid: number;
    line1: string;
    line2: string;
    number: string;
    neighborhood: string;
    city: string;
    country: string;
    zipcode: string;
    datecreated: string;
    active: boolean;
    addresstype: Addresstype;
}

export interface Role {
    name: string;
    internalname: string;
  }

  export interface Country {
    code2: string;
    countryid: string;
  }

  export interface Mediacontenttype {
    internalname: string;
  }

  export interface Imagesettings {
    width: number;
    height: number;
    xcenter: number;
    ycenter: number;
    haspectratio: number;
    waspectratio: number;
  }

  export interface Relatedmedia {
    mediacontenttype: Mediacontenttype;
    imagesettings: Imagesettings;
  }

  export interface Websitecategory {
    internalname: string;
    name: string;
  }

  export interface Website {
    url: string;
    userwebsiteid: string;
    wscategid: number;
    websitecategory: Websitecategory;
    internalname?: any;
  }

  export interface Interest2 {
    interestid: string;
    name: string;
    icon?: string;
  }

  export interface Interest {
    uid?: string;
    interestid?: string;
    interestIn?: boolean;
    studying?: boolean;
    interest?: Interest2;
    haveExperience?: boolean;
    interestcategoryid?: any,
    name?: string;
    icon?: string;
  }

export interface UserData {
    uid: number;
    email: string;
    username: string;
    fullname: string;
    profilepicture: string;
    datejoined: string;
    activated: boolean;
    autoplay: boolean;
    shortbio: string;
    active: boolean;
    alias: string;
    role: Role;
    country: Country;
    occupationtitle: string;
    relatedmedia: Relatedmedia[];
    alpha: boolean;
    subscribed: boolean;
    rolename: string;
    websites: Website[];
    interests: Interest[];
    coverpicture: string;
    availableForWork: boolean;
    isPro?: boolean;
  }

export interface LoggedUser {
    username?: string;
    alias?: string;
    activated?: boolean;
    countryid?: number;
    fullname?: string;
    email?: string;
    active?: boolean;
    autoplay?: boolean;
    company?: any;
    shortbio?: any;
    uid?: number;
    sociallogindata?: any;
    datejoined?: string;
    countrycode?: string;
    rolename?: string;
    profilepicture?: string;
    addresses?: Addresse[];
    documents?: Document[];
    alpha?: boolean;
    occupationtitle?: any;
}

export interface MeInfoUser {
    uid: number;
    alias: string;
    rolename: string;
    profilepicture: string;
    relatedmedia: any;
}

export class MeInfo {
    public user: MeInfoUser;
    public courseListIds: number[];
    public subscribed: boolean;

    constructor(me: MeInfoQueryData) {
        this.user = {
            uid: Number(me.user.uid),
            alias: me.user.alias,
            profilepicture: me.user.profilepicture,
            rolename: me.user.role.internalname,
            relatedmedia: me.user.relatedmedia
        };

        this.courseListIds = me.courses.map(p => Number(p.postid));

        this.subscribed = me.subscribed;
    }
}
