import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';

import { tap, map } from 'rxjs/operators';

import {
    FetchRecommendedLessons
} from './lesson-postparts.actions';

import { defaultLessonPostpartsState, LessonPostpartsStateModel, RecommendedLesson } from './lesson-postparts.model';

import { RestApiService } from '@frontend/unhideschool/app/api-gateway/services/rest-api.service';

@State<LessonPostpartsStateModel>({
    name: 'lessonPostparts',
    defaults: defaultLessonPostpartsState
})
@Injectable()
export class LessonPostpartsState {
    constructor(
        private rest: RestApiService
    ) { }

    @Action(FetchRecommendedLessons)
    fetchRecommendedLessons(
        ctx: StateContext<LessonPostpartsStateModel>
    ) {
        const endpoint = '/post/recommended';

        return this.rest.get<{ basiccontent: RecommendedLesson[] }>(endpoint).pipe(
            map(res => res.dict.basiccontent),
            tap(suggestions => ctx.patchState({ suggestions}))
        );
    }
}
