<div class="menu-wrapper">
  <ng-container *ngIf="allNotifications$ | async ; let notifications">
    <unh-subscription-warning [notifications]="notifications"></unh-subscription-warning>
  </ng-container>

  <unh-top-bar-container *ngIf="activationEmailSent || luss.isLogged && !luss.userProfileInfo.activated">
    <unh-top-bar-item>
      <ng-container [ngSwitch]="true">
        <ng-container *ngSwitchCase="activationEmailSent">
          <span>
            Pronto!
            Enviamos novamente os dados de ativação de conta para o email <b>{{ luss.userProfileInfo.email }}</b>,
            acesse sua caixa de entrada e ative sua conta.
          </span>
        </ng-container>

        <!-- <ng-container *ngSwitchCase="activationEmailLoading">
          Por favor, aguarda enquanto enviamos os dados de&nbsp;<b>ativação da conta</b>&nbsp;para o seu e-mail...

          <div class="nano-spinner">
            <unh-spinner-loader size="nano" color="#fff"></unh-spinner-loader>
          </div>
        </ng-container> -->

        <ng-container *ngSwitchDefault>
          <span>
            Confirme seu e-mail para desbloquear os downloads do Media Pool. Não encontrou o e-mail?
            <a (click)="resendActivationEmail()"><b>Clique aqui</b></a> para receber novamente!
          </span>
        </ng-container>
      </ng-container>
    </unh-top-bar-item>
  </unh-top-bar-container>

  <div class="menu-container" [class.open]="mainNavMenuIsOpen">
    <unh-menu-toggle
      [layout]="mainNavMenuIsOpen ? 'times' : 'bars'"
      (clickHandler)="toggleMobileMenu()"
    ></unh-menu-toggle>

    <div class="logo-container">
      <unh-menu-logo></unh-menu-logo>
    </div>

    <unh-main-nav-container
      [navItemList]="navItemList"
      [isOpen]="mainNavMenuIsOpen"
      position="left"
      [hasWarning]='hasWarning$ | async'
    ></unh-main-nav-container>

    <div class="flex-separator"></div>

    <nav class="user-actions">
      <ng-container *ngIf="!luss?.isLogged">
        <a
          mat-icon-button
          class="unh-button-old btn-login-mobile btn-login"
          href="/login"
          title="Entrar"
        >
          <mat-icon svgIcon="unh_login"></mat-icon>
        </a>

        <a mat-button class="unh-button-old btn-login btn-login-desktop" href="/login" title="Entrar">
          <mat-icon svgIcon="unh_login"
            style="margin: 0 8px 4px 0;"></mat-icon>
          <span>Acesse sua conta</span>
        </a>
      </ng-container>

      <ng-container *ngIf="luss?.isLogged">
        <div class="btn-meus_cursos" *ngIf="hasCourse">
          <unh-nav-item-link
            title="Meus Cursos"
            target="_self"
            linkpath="/meus-cursos"
            icon="unh_meus_cursos"
            [scrollable]="false"
            iconSize="24px">
          </unh-nav-item-link>
        </div>

        <div class="avatar-container" (click)="openUserMenu()">
          <unh-user-avatar
            [avatarUrl]="userAvatarUrl"
            [username]="luss.user?.alias"
            [uid]="luss.user?.uid+''"
            [userSubscribed]="luss.subscribed"
            [hasUnreadNotifs]="(unreadNotifs$ | async).length > 0"
            [redirectToProfile]="false"
            [defaultAvatarUrl]="defaultAvatarUrl"
          ></unh-user-avatar>
        </div>
      </ng-container>
    </nav>
  </div>
</div>
