export const FetchUserArtworkDataQuery = `
query fetchUserArtworkData($username: String) {
  Community {
    all(path: $username, active: false) {
      items {
        path
        communitycategid
        datecreated
        active
        communityid
        category {
          name
        }
      }
    }
  }
}

`;
