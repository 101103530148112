import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Directive({
  
  selector: '[unhInpuAcessor]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAcessorDirective),
      multi: true
    }
  ]
})
export class InputAcessorDirective implements ControlValueAccessor {

  @HostListener('unhChange', ['$event.detail'])
  listenForValueChange(value) {
    this.value = value;
  }

  constructor(
    private el: ElementRef
  ) {

  }

  onChange: any = () => { };
  onTouched: any = () => { };

  private _value: number;

  get value() {
    return this._value;
  }

  set value(val) {

    if(typeof val == 'object'){
      this._value = val;
      this.onChange(this._value);
      this.onTouched();
    }
    if (val !== this._value) {
      this._value = val;
      this.onChange(this._value);
      this.onTouched();
    }
  }

  writeValue(value) {
    if(this.el.nativeElement.type != 'tag'){
      this.el.nativeElement.defaultValue = value;

    }
    if(this.el.nativeElement.type == 'price'){
      this.el.nativeElement.priceValue = value;

    }
    if(this.el.nativeElement.type == 'tag'){
      this.el.nativeElement.tags = value;

    }
    if(this.el.nativeElement.tagName == 'UNH-SWITCH'){
      if(this.el.nativeElement.active != undefined){
        this.el.nativeElement.active = value;
      }
    }
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

}

