<div class="view-all-dialog">
  <div class="dialog-page">
    <header *ngIf="isSmallScreen" class="mobile-only">
      <div class="close-container">
        <button mat-icon-button [mat-dialog-close]="true">
          <mat-icon svgIcon="unh_times"></mat-icon>
        </button>
      </div>

      <div class="logo-container">
        <a mat-button routerLink="/home" class="unh-button-old">
          <mat-icon svgIcon="unh_logo"></mat-icon>
        </a>
      </div>

      <unh-user-actions
        [user]="luss.user"
        [unreadNotifs]="unreadNotifs$ | async"
        [isSmallScreen]="isSmallScreen"
        (notificationsMenuToggleClicked)="openNotificationsMenu()"
        (userMenuToggleClicked)="openUserMenu()"
      >
      </unh-user-actions>
    </header>

    <unh-view-all-header
      [project]="project"
      [totalViews]="totalViews"
      [isSmallScreen]="isSmallScreen"
      [viewsLabel]="
        viewAllDialogMock?.viewAllLessonItemComponentTranslation.viewsLabel
      "
    >
      <ng-container
        *ngIf="
          (!luss.subscribed &&
            project?.showInPublicListings &&
            !(
              mePurchasedProducts$
              | async
              | isProductOwned : project?.product?.productid
            )) ||
          ischeckingout
        "
      >
        <div
          class="cta-container"
          *ngIf="project?.product?.active; else ctasubscribe"
        >
          <div class="active-promo-wrapper">
            <button
              mat-button
              class="unh-button-old view-all-button"
              theme="pink-white"
              [mat-dialog-close]="true"
              routerLink="assinatura/planos-e-precos"
            >
              <span
                >{{ viewAllDialogMock?.viewAllBuyCourseButton }}

                <ng-container #defaultPrice>
                  R${{
                    (hasAnualPromo$ | async)
                      ? anualPlan?.price
                      : anualPlan?.price
                  }}{{ viewAllDialogMock?.viewAllH4UnlockProPlanMonth }}
                </ng-container>
              </span>
            </button>
          </div>

          <h4 class="cta-subtitle" [ngSwitch]="project?.hasPromo">
            <ng-container *ngSwitchCase="true">
              {{ viewAllDialogMock?.viewAllH4UnlockProPlan }}
              R${{ project?.product?.pricewithdiscount }}
            </ng-container>
            <ng-container *ngSwitchDefault>
              {{ viewAllDialogMock?.viewAllH4UnlockProPlan }}
              R${{ project?.product?.price }}
            </ng-container>
            <a
              (click)="
                checkoutProduct(
                  project?.product?.productid,
                  project?.product?.productpriceid
                )
              "
            >
              {{ viewAllDialogMock?.viewAllH4ClickHere }}
            </a>
          </h4>

          <div class="header-buttons" *ngIf="isSmallScreen">
            <a
              [unhBounceOnClick]="true"
              [class.pro-only]="!luss.subscribed"
              [href]="
                luss.subscribed
                  ? mentoringTopicUrl
                  : 'assinatura/planos-e-precos'
              "
            >
              <img
                src="assets/web-components/images/icons/side-buttons/ico-circle-crown-mobile.svg"
                alt="Fórum de Mentoria"
              />
              <p>
                {{ viewAllDialogMock?.mentoringForumLinkText }}
              </p>
            </a>

            <a
              [unhBounceOnClick]="true"
              *ngIf="!luss.subscribed"
              href="/assinatura/planos-e-precos"
            >
              <img
                src="assets/web-components/images/icons/side-buttons/ico-circle-point-mobile.svg"
                alt="Assinar UNHIDE School'"
              />
              <p>
                {{ viewAllDialogMock?.plansAndPricesLinkText }}
              </p>
            </a>
          </div>
        </div>

        <ng-template #ctasubscribe>
          <div
            *ngIf="hasAnualPromo$ | async; let hasActivePromo"
            class="cta-container"
          >
            <a
              mat-button
              class="unh-button-old view-all-button"
              theme="pink-white"
              href="/assinatura/planos-e-precos"
            >
            {{ viewAllDialogMock?.subscribeForButtonText }} R$
              {{ hasActivePromo ? 44 : 44 * 3
              }}{{ viewAllDialogMock?.subscribeForButtonMonthText }}
            </a>

            <h4 class="cta-subtitle">
              {{ viewAllDialogMock?.subscribeForButtonH4Text }}
              <a href="/assinatura/planos-e-precos">{{
                viewAllDialogMock?.viewAllH4ClickHere
              }}</a>
            </h4>
            <div class="buttons" *ngIf="isSmallScreen">
              <a
                [unhBounceOnClick]="true"
                [class.pro-only]="!luss.subscribed"
                [href]="
                  luss.subscribed
                    ? mentoringTopicUrl
                    : 'assinatura/planos-e-precos'
                "
              >
                <img
                  [src]="cdnUrl + '/side-buttons/ico-circle-crown-mobile.svg'"
                  alt="Fórum de Mentoria"
                />
                {{ viewAllDialogMock?.mentoringForumLinkText }}
              </a>

              <a
                [unhBounceOnClick]="true"
                *ngIf="!luss.subscribed"
                href="/assinatura/planos-e-precos"
              >
                <img
                  [src]="cdnUrl + '/side-buttons/ico-circle-point-mobile.png'"
                  alt="Assinar UNHIDE School"
                />
                {{ viewAllDialogMock?.plansAndPricesLinkText }}
              </a>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </unh-view-all-header>

    <div class="dialog-content">
      <div class="centered">
        <div class="skeleton">
          <ng-container *ngIf="!(project?.fulldescription.length > 0)">
            <pzl-skeleton width="100%" height="30px"></pzl-skeleton>
            <br />
            <pzl-skeleton width="100%" height="750px"></pzl-skeleton>
            <br />
            <pzl-skeleton width="100%" height="30px"></pzl-skeleton>
            <br />
            <pzl-skeleton width="100%" height="30px"></pzl-skeleton>
          </ng-container>

          <unh-quill-content-renderer
            *ngIf="project?.fulldescription.length > 0"
            [content]="project?.fulldescription"
          ></unh-quill-content-renderer>
        </div>
      </div>

      <div
        *ngIf="project?.softwaretags?.length > 0"
        class="text-container centered"
        style="margin-top: 60px"
      >
        <h3>{{ viewAllDialogMock?.usedSoftwareTechniquesH3Text }}</h3>

        <div class="technique-box">
          <div
            class="technique"
            *ngFor="let softwaretag of project?.softwaretags"
          >
            <unh-software-tag
              class="ico ico-time"
              [software]="softwaretag.name"
            ></unh-software-tag>

            <span>{{ softwaretag.name }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="parentGallery?.items?.length > 0 || isAdmin()"
      class="dialog-content"
      style="padding: 0"
    >
      <div class="centered">
        <div class="view-all-gallery-container">
          <div
            [class.isadmin]="isAdmin()"
            class="view-all-gallery-header text-container"
          >
            <h3>{{ viewAllDialogMock?.projectGalleryH3text }}</h3>
          </div>

          <unh-basic-gallery
            *ngIf="parentGallery"
            [isProject]="true"
            [creatorId]="project.creatoruid"
            [galleries]="[parentGallery]"
            [galleryViewPath]="routerData?.currentRoute + '/gallery/view'"
            [galleryType]="'ProjectGalleries'"
            (loadMore)="loadMoreGalleries()"
            (filterEmmiter)="listenFilter($event)"
          >
          </unh-basic-gallery>
        </div>
      </div>
    </div>

    <!-- Hack-ish margin-top implementation -->
    <div
      [ngStyle]="parentGallery?.items?.length > 0 && { marginTop: '60px' }"
      class="dialog-content quero-assinar"
      *ngIf="!luss.subscribed"
    >
      <div class="view-all-container-discount">
        <div class="text-container left centered view-all-text-wrapper">
          <div class="assine-por" *ngIf="hasAnualPromo$ | async">
            {{ viewAllDialogMock?.subscribeForBeforePriceText }} 
            {{
              (hasAnualPromo$ | async)
                ? anualPlan?.price
                : anualPlan?.price
            }}{{ viewAllDialogMock?.subscribeForAfterPriceMonthText }}
            <span>{{ viewAllDialogMock?.subscribeForSpanText }}</span>
          </div>

          <div class="assine-por" *ngIf="(hasAnualPromo$ | async) === false">
            {{ viewAllDialogMock?.subscribeForBeforePriceText }} {{anualPlan?.price}}{{
              viewAllDialogMock?.subscribeForAfterPriceMonthText
            }}
            <span>{{ viewAllDialogMock?.subscribeForSpanText }}</span>
          </div>
          <div class="plan-type">
            {{ viewAllDialogMock?.planTypeInstallmentsText }}
          </div>
          <div class="action-button">
            <a
              mat-button
              (click)="
                navigateToRoute.emit({ url: 'assinatura/planos-e-precos' })
              "
              class="unh-button-old"
              theme="pink-white"
              >{{ viewAllDialogMock?.planTypeInstallmentsButtonText }}</a
            >
          </div>
        </div>
        <div class="flag-discount">
          <ng-container *ngIf="!isSmallScreen && hasAnualPromo$ | async">
            <unh-custom-promo-tag
              [type]="'tag'"
              [plus]="false"
              [width]="113"
              [activePromo]="activePromo"
            ></unh-custom-promo-tag>
          </ng-container>
          <ng-container *ngIf="isSmallScreen && hasAnualPromo$ | async">
            <unh-custom-promo-tag
              [type]="'tag'"
              [plus]="false"
              [width]="73"
              [fontSize]="'28px'"
              [activePromo]="activePromo"
            ></unh-custom-promo-tag>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="dialog-content">
      <div class="text-container centered pb-0 lessons-title-container">
        <ng-container
          *ngIf="
            groupedLessons === null ||
            groupedLessons === undefined ||
            groupedLessons.length === 0
          "
        >
          <div class="lessons-skeleton" *ngFor="let item of [1, 2, 3, 4, 5]">
            <br />
            <pzl-skeleton
              width="100%"
              height="65px"
              radius="8px"
            ></pzl-skeleton>
            <br />
            <pzl-skeleton
              width="100%"
              height="65px"
              radius="8px"
            ></pzl-skeleton>
          </div>
        </ng-container>
        <a
          (click)="togglePanelExpanded('lessonsExpanded', videosAccordion)"
          class="link-expandir"
        >
          <span>{{
            lessonsExpanded
              ? viewAllDialogMock?.viewAllMatExpanderTranslationTitle
                  .closeAllText
              : viewAllDialogMock?.viewAllMatExpanderTranslationTitle
                  .expandAllText
          }}</span>
        </a>
      </div>

      <div class="text-container pt-0">
        <div class="lista-container">
          <mat-accordion #videosAccordion="matAccordion" multi="true">
            <mat-expansion-panel
              [expanded]="index === 0"
              *ngFor="let group of groupedLessons; let index = index"
            >
              <mat-expansion-panel-header
                [collapsedHeight]="'auto'"
                [expandedHeight]="'auto'"
              >
                <mat-panel-title>
                  <div class="ls-title">
                    {{ group.name }}
                    <!-- <span class="ls-subtitle">0 de 3 vídeos concluídos</span> -->
                  </div>
                </mat-panel-title>

                <mat-panel-description>
                  <div class="total-time">
                    <img
                      class="ico ico-time"
                      [src]="cdnUrl + '/clock.svg'"
                      alt=""
                    />
                    {{
                      convertMsIntoHoursAndMinutes(
                        (group.items | sumProperty : 'duration') * 1000
                      )
                    }}
                  </div>
                </mat-panel-description>
              </mat-expansion-panel-header>

              <unh-view-all-lesson-item
                *ngFor="let lesson of group.items; let index = index"
                [class.striped]="index % 2 !== 0"
                [lessonItem]="lesson"
                [project]="project"
                [viewsLabel]="
                  viewAllDialogMock?.viewAllLessonItemComponentTranslation
                    .viewsLabel
                "
                [likesLabel]="
                  viewAllDialogMock?.viewAllLessonItemComponentTranslation
                    .likesLabel
                "
                [commentsLabel]="
                  viewAllDialogMock?.viewAllLessonItemComponentTranslation
                    .commentsLabel
                "
                (navigatedToLesson)="handleNavigateToLesson()"
              >
              </unh-view-all-lesson-item>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>
    </div>

    <div
      class="dialog-content"
      *ngIf="luss.subscribed && mediafiles.length > 0"
    >
      <div class="text-container centered pb-0">
        <h3>Media Pool</h3>
      </div>

      <div class="text-container pt-0">
        <div class="media-pool-box">
          <div
            class="media-pool-item"
            *ngFor="let file of mediafiles"
            [style.opacity]="luss.subscribed ? 1 : 0.5"
          >
            <div class="media-pool-item__details">
              <img [src]="mediapoolIconUrl" alt="Download Media" />
              <span>&lrm;{{ file.name }}</span>
            </div>
            <a
              *ngIf="
                (file.inprogress === undefined &&
                  file.completed === undefined) ||
                (file.inprogress === false && file.completed === true) ||
                file.cancelled === true
              "
              class="unh-button-old"
              theme="pink-white"
              mat-button
              (click)="downloadAsset(file)"
              [disabled]="!luss.subscribed"
            >
              <mat-icon
                *ngIf="isSmallScreen"
                svgIcon="unh_cloud_down"
              ></mat-icon>
              <span *ngIf="!isSmallScreen">{{
                file.completed ? 'Baixar novamente' : 'Download'
              }}</span>
            </a>
            <pzl-progress-circle
              *ngIf="
                file.inprogress &&
                file.completed !== true &&
                file.cancelled === false
              "
              style="height: 100%; max-width: 50px; margin-right: 60px"
              [value]="file.completed ? 0 : file.progress"
              [allowShowProgress]="false"
              [borderRadius]="'50%'"
              [barStrokeWidth]="5"
              [backLineStrokeColor]="'rgba(255,255,255, 0.3)'"
              [backlineStrokeWidth]="5"
              [allowCompleted]="false"
            >
              <pzl-icon
                style="cursor: pointer; position: relative; z-index: 99999"
                (click)="cancelDownload(file)"
                slot="content-slot"
                [url]="cdnUrl + '/stop-icon.svg'"
              ></pzl-icon>
            </pzl-progress-circle>
          </div>
        </div>
      </div>
    </div>

    <div class="dialog-content">
      <div class="text-container artist-container">
        <h3>{{ viewAllDialogMock?.aboutInstructorH3Text }}</h3>
        <div class="img-text-box">
          <div class="artist-img">
            <pzl-skeleton
              *ngIf="
                project === null ||
                project === undefined ||
                project?.creator?.profilepicture === undefined
              "
              width="100%"
              radius="20px"
            ></pzl-skeleton>
            <img
              *ngIf="project?.creator?.profilepicture !== undefined"
              class="artist"
              [src]="
                getCroppedImage(
                  project?.creator?.profilepicture,
                  project?.creator?.relatedmedia
                )
              "
              alt="{{ project?.creator?.alias }}"
            />
          </div>

          <div class="artist-text">
            <h4>
              {{ project?.creator?.alias }}
              <span style="text-transform: capitalize">{{
                project?.creator?.occupationtitle
              }}</span>
            </h4>

            <unh-quill-content-renderer
              [content]="project?.creator?.shortbio"
              theme="short-bio"
            >
            </unh-quill-content-renderer>
          </div>
        </div>
      </div>
    </div>

    <!-- SOBRE A ESCOLA - Static Content -->
    <!-- <div id="about-school" class="dialog-content bg-light">
      <div class="text-container centered">
        <h3 class="text-upper">Sobre a Escola</h3>

        <p>
          UNHIDE School é uma plataforma de ensino de arte digital e economia
          criativa, prezamos pela relevância dos assuntos abordados e todos os
          nossos cursos são ministrados por pessoas atuantes no mercado de
          trabalho. Esperamos que você possa aprender o máximo possível e que
          essa informação faça diferença no seu dia-a-dia.
        </p>

        <br />

        <p>
          Seus comentários e opiniões são fundamentais para termos um canal
          sólido e honesto de comunicação. Avaliando e detalhando a sua
          avaliação é o melhor jeito de garantir que a plataforma atenda
          necessidades, cada vez mais complexas de um mundo em constante
          transformações.
        </p>

        <br />

        <p>Bons estudos.</p>
      </div>
    </div> -->

    <div class="dialog-content" style="padding-bottom: 0">
      <div class="text-container centered pb-0">
        <h3>{{ viewAllDialogMock?.exploreOtherCourses }}</h3>
      </div>

      <div class="text-container pt-0 pb-0 project-highlight-container">
        <unh-generic-swiper-slider
          class="swiper-slide-square"
          *ngIf="projects.length > 0"
          [isMobile]="isSmallScreen"
          [items]="projects"
          (onSlideChange)="loadmoreOtherProjects($event)"
        >
          <ng-template #genericSwiperItemTemplate let-item>
            <unh-project-item
              [project]="item"
              (itemClicked)="
                navigateTo({
                  position: item.position,
                  postid: item.postid,
                  title: item.title,
                  traits: item.traits
                })
              "
            >
            </unh-project-item>
          </ng-template>
        </unh-generic-swiper-slider>
      </div>
    </div>
  </div>

  <div id="side-buttons" class="side-buttons">
    <div class="fixed-wrapper">
      <button [mat-dialog-close]="true" class="">
        <img
          class="times-ico"
          [src]="cdnUrl + '/icons/ico-close.png'"
          alt="Close modal"
        />
        <span>{{ viewAllDialogMock?.closeSpanButtonText }}</span>
      </button>

      <!--
        <unh-animated-like-button
          [unhBounceOnClick]="!userLiked"
          [animated]="userLiked"
          (animationCompleted)="userLiked = true"
        >
          <span slot="bottom">Gostei</span>
        </unh-animated-like-button>
      -->
      <a
        [unhBounceOnClick]="true"
        [class.pro-only]="!luss.subscribed"
        [href]="
          luss.subscribed ? mentoringTopicUrl : 'assinatura/planos-e-precos'
        "
      >
        <img
          src="assets/web-components/images/icons/side-buttons/ico-circle-crown.png"
          alt="Fórum de Mentoria"
        />
        {{ viewAllDialogMock?.mentoringForumLinkText }}
      </a>

      <!--
        <button unhBounceOnClick="true">
          <img [src]="cdnUrl + '/side-buttons/ico-circle-share.png'" alt="Compartilhar">
          Compartilhar
        </button>
      -->

      <a
        [unhBounceOnClick]="true"
        *ngIf="!luss.subscribed"
        href="/assinatura/planos-e-precos"
      >
        <img
          src="assets/web-components/images/icons/side-buttons/ico-circle-point.png"
          alt="Assinar UNHIDE School"
        />
        {{ viewAllDialogMock?.plansAndPricesLinkText }}
      </a>

      <button
        [unhBounceOnClick]="true"
        *ngIf="luss.subscribed"
        (click)="playLesson()"
      >
        <img
          src="assets/web-components/images/icons/side-buttons/ico-circle-play.png"
          alt=""
        />
        {{ viewAllDialogMock?.playButtonText }}
      </button>
    </div>
  </div>

  <ng-container *ngIf="!isSmallScreen">
    <!-- TODO: COMPONETIZE! -->
    <div class="unh-dialog-nav__control prev" *ngIf="prevPost.length > 0">
      <button
        mat-icon-button
        aria-label="Gallery Navigation Right Button"
        [disabled]="prevPost.length === 0 || isComingsoon(prevPost[0])"
        (click)="navigateTo(prevPost[0])"
      >
        <!-- <mat-icon *ngIf="isSmallScreen">chevron_left</mat-icon> -->
        <mat-icon
          svgIcon="unh_round_tipped_left_arrow"
          inline="true"
        ></mat-icon>
      </button>
    </div>

    <div class="unh-dialog-nav__control next" *ngIf="nextPost.length > 0">
      <button
        mat-icon-button
        aria-label="Gallery Navigation Right Button"
        [disabled]="nextPost.length === 0 || isComingsoon(nextPost[0])"
        (click)="navigateTo(nextPost[0])"
      >
        <!-- <mat-icon style="font-size: 48px" *ngIf="isSmallScreen">chevron_right</mat-icon> -->
        <mat-icon
          svgIcon="unh_round_tipped_right_arrow"
          inline="true"
        ></mat-icon>
      </button>
    </div>
  </ng-container>
</div>
