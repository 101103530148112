export const meCustomQuery = `
  query MeSubscribed {
    me {
      subscribed
    }
  }
`;

export const meCoverImage = `
  query MeCover {
    me {
      user{
        coverpicture
      }
    }
  }
`;

export const meGallery = `
  query getMeGallery {
    me {
      user {
        availableForWork

        interests {
          interestid
          uid
          haveExperience
          interestIn
          studying
          uid
          interest {
              name
              interestid
              active

          }
        }
        galleries{
          gallery{
            galleryid
            name
            cover{
              galleryitemid
              mediaitem{
                content
                render
                imagesettings {
                  imagesettingid,
                  width,
                  height,
                  waspectratio,
                  haspectratio,
                  xcenter,
                  ycenter
                },
              }
            }
            items{
              galleryitemid
              mediaitem{
                imagesettings {
                  imagesettingid,
                  width,
                  height,
                  waspectratio,
                  haspectratio,
                  xcenter,
                  ycenter
                },
              }
            }
          }
        }
      }
    }
  }
`

