export const getUserArtworks = `
query getUserArtWorks($uid: Int!, $communityid: Int) {
  Posts(filters: [
    {creatoruid: {op: EQ, v: $uid}},
    {communityid: {op: EQ, v: $communityid}}
    {active: {op: EQ, v: true}}
  ]) {
    postid
    orderedpostparts{
      postpartid
    }
    badges{
      badge{
        name
        internalname

      }
    }
    postthumbnail
    title
    community {
      communityid
      communitycategid
    }
    relatedmedia{
      mediaitemid
      render
      imagesettings {
        width
        height
        waspectratio
        haspectratio
        xcenter
        ycenter
      }
      mediacontenttypeid
      mediacontenttype{
        name
        internalname
      }
    }
  }
}

`;
