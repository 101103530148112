import { interpolate, slugify } from '@frontend/unhideschool/app/helpers/utility-functions';
import { Component, Input, ChangeDetectionStrategy, HostBinding, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngxs/store';

import { SimpleGalleryItem } from '@frontend/unhideschool/shared/models/gallery/simple-gallery-item.model';
import { SelectGalleryView } from '@frontend/unhideschool/app/shared-nxgs/galleries/galleries.actions';
import { DomSanitizer } from '@angular/platform-browser';
const CATEGORIES = {
  1: 'contests',
  9: 'comunidade',
  10: 'desenvolvimento',
  2: 'mentoria',
  11: 'unhide-awards'
}
@Component({
  selector: 'unh-simple-gallery-item',
  templateUrl: './simple-gallery-item.component.html',
  styleUrls: ['./simple-gallery-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleGalleryItemComponent implements OnChanges {
  @Input() galleryRoutePath: string;
  @Input() galleryItem: SimpleGalleryItem;
  @Input() editMode: boolean;
  @Input() isProject?: boolean;
  @Input() galleryStatus?: boolean;
  @Input() topicRouteData: any;

  @HostBinding('class.hovered')
  @Input() hovered = false;
  
  value = 50;
  bufferValue = 75;

  itemRouteLink: string | string[];

  constructor(
    private store: Store,
    public sanitizer: DomSanitizer,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.galleryItem) {
      this.itemRouteLink = this.getItemRouteLink();
    }
  }

  getItemRouteLink(): string | string[] {
    if (!this.isProject) {
      return this.getTopicUrl();
    }

    return [
      this.galleryRoutePath, 
      this.galleryItem?.galleryid.toString(), 
      this.galleryItem?.galleryitemid.toString()
    ];
  }

  // selectGalleryView() {
  //   this.store.dispatch(new SelectGalleryView(
  //     this.galleryItem.galleryid,
  //     this.galleryItem.galleryitemid.toString(),
  //     this.isProject
  //   ));
  // }

  getTopicUrl() {
    const { communitycategid, subtitle } = this.topicRouteData;
    
    const data = {
      ...this.topicRouteData,
      category: CATEGORIES[communitycategid],
      slug: slugify(subtitle),
    };

    const urlModel = 
      `topico/{communitycategid}/{category}/{postid}/{postpartid}/{slug}`;

    return interpolate(urlModel, data);
  }
}

