import { Injectable } from '@angular/core';
import { State, Action, StateContext, Store } from '@ngxs/store';
import { catchError, map, tap, switchMap } from 'rxjs/operators';
import { GraphqlOrderByOption, GraphqlFilterItem } from '@frontend/unhideschool/shared/models/graphql/base-graphql';
import { Gallery } from '@frontend/unhideschool/shared/models/dtos/gallery.model';

import {
    UpdateGalleryImageSettings,
    AddPreview, UpdatePreview,
    UpdateGalleryItem,
    SelectGalleryView,
    FetchProjectGallery,
    UpdateGallery,
    FetchGalleries,
    LoadMoreGalleries
} from './galleries.actions';

import { updateImageSettingMutation } from '@frontend/unhideschool/shared/models/graphql/update-image-setting.mutation';
import { Observable, throwError } from 'rxjs';
import { updateGalleryItemMutation } from '@frontend/unhideschool/shared/models/graphql/updateGalleryItem.query';
import { asNumber } from '@frontend/unhideschool/app/helpers/utility-functions';
import { updateGalleryMutation } from '@frontend/unhideschool/shared/models/graphql/update-gallery.mutation';
import { GalleriesStateModel, defaultHomeGalleryFilters, generateFilters } from './galleries.model';
import { fetchGalleriesQuery } from '../../shared/models/graphql/fetch-galleries.query';
import { GraphqlApiService } from '../../api-gateway/services/graphql-api.service';

@State<GalleriesStateModel>({
    name: 'galleries',
    defaults: {
        Galleries: [],
        ProjectGalleries: {},
        TopicGalleries: {},
        GalleryView: {},
        galleriesLimit: {
            galleries: false,
            project: false,
            topic: false
        }
    }
})
@Injectable()
export class GalleriesState {

    private _currentHomeGalleryFilters: GraphqlFilterItem[] = defaultHomeGalleryFilters;

    get currentHomeGalleryFilters() {
        return this._currentHomeGalleryFilters;
    }
    constructor(
        private graph: GraphqlApiService,
        private store: Store
    ) { }

    @Action(FetchGalleries)
    fetchGalleries(
        ctx: StateContext<GalleriesStateModel>,
        action: FetchGalleries
    ): Observable<Gallery[]> {
        const filters: GraphqlFilterItem[] = generateFilters(action.payload.filters);
        const orderby: GraphqlOrderByOption<any> = { f: 'datecreated', o: 'DESC' };
        const query = fetchGalleriesQuery;

        return this.graph
            .graphqlRequest<'Galleries', any>(query, { page: 1, perpage: 10, filters, orderby })
            .pipe(
                map(res => res.data.Galleries),
                tap(galleries => {
                    switch (action.payload.galleryType) {
                        case 'ProjectGalleries': {
                            const state = ctx.getState().ProjectGalleries;
                                galleries[0].items = galleries[0].items.filter(galleryItem => galleryItem.active).reverse();
                                state[galleries[0].galleryid] = galleries[0];
                            ctx.patchState({ ProjectGalleries: state });

                            break;
                        }
                        case 'TopicGalleries': {
                            const state = ctx.getState()[action.payload.galleryType];
                            galleries[0].items = galleries[0].items.filter(galleryItem => galleryItem.active).reverse();
                            state[galleries[0].galleryid] = galleries[0];
                            ctx.patchState({ [action.payload.galleryType]: state });
                            break;
                        }
                        default: {
                            ctx.patchState({ Galleries: [...galleries] });
                        }
                    }
                })
            );
    }

    @Action(LoadMoreGalleries)
    loadMoreGalleries(
        ctx: StateContext<GalleriesStateModel>,
        action: LoadMoreGalleries
    ) {
        const query = fetchGalleriesQuery;
        const chekcNextQuery = fetchGalleriesQuery;
        const pagination = action.payload.pagination;
        const filters: GraphqlFilterItem[] = generateFilters(action.payload.filters)
        const orderby: GraphqlOrderByOption<any> = { f: 'datecreated', o: 'DESC' };

        return this.graph
            .graphqlRequest<'Galleries', any>(
                query, 
                { page: pagination.page, perpage: pagination.perpage, filters, orderby }
            ).pipe(
                map(res => res.data.Galleries),
                tap((gallery: any) => {
                    ctx.patchState({ Galleries: [...ctx.getState().Galleries, ...gallery] });
                }),
                switchMap(() => {
                    return this.graph.graphqlRequest<'Galleries', any>(
                        chekcNextQuery, 
                        { page: pagination.page+1, perpage: pagination.perpage, filters, orderby }
                    )
                })
            ).subscribe(res => {
                if (res.data.Galleries.length == 0) {
                    ctx.patchState({
                        galleriesLimit: {
                            galleries: true,
                            project: false,
                            topic: false
                        }
                    })
                }
            } );
    }

    @Action(UpdateGallery)
    updateGallery(
        ctx: StateContext<GalleriesStateModel>,
        action: UpdateGallery
    ) {
        const mutation = updateGalleryMutation;

        const variables: any = {
            galleryid: action.galleryid,
            published: action.published,
            active: action.active
        };

        if (action.coverid != null) {
            variables.coverid = action.coverid;
        }

        return this.graph.graphqlUpdate<'updateGallery', { Gallery: any }>(mutation, variables).pipe(
            map(res => res.data.updateGallery.Gallery),
            tap((gallery: any) => {
                const state = ctx.getState();
                const galleryIndex = state.Galleries.findIndex(gal => gal.galleryid === action.galleryid);
                const updatedGalleries = [...state.Galleries.slice(0, galleryIndex), gallery, ...state.Galleries.slice(galleryIndex + 1)];
                return ctx.patchState({ Galleries: updatedGalleries });
            })
        );
    }

    @Action(UpdateGalleryImageSettings)
    updateGalleryItemImageSettings(ctx: StateContext<GalleriesStateModel>, action: UpdateGalleryImageSettings) {
        let state;
        if (action.context == 'ProjectGalleries') {
            state = ctx.getState()[action.galleryType];
        } else {
            state = ctx.getState().Galleries;
        }
        if (action.context == 'ProjectGalleries') {
            const _state = { ...state };
            const cropinfo = action.imageSettings;
            const simplegalleryItem: any = action.gallery;
            const gallery = state[action.projectgalleryid];

            const index = gallery.items.findIndex(galItem => galItem.galleryitemid == simplegalleryItem.galleryitemid);
            const new_item = gallery.items[index];
            new_item.imagesettings = { ...cropinfo };

            const updated_gallery_items = [...gallery.items.slice(0, index), new_item, ...gallery.items.slice(index + 1)];
            gallery.items = updated_gallery_items;
            _state[action.projectgalleryid] = gallery;

            // Side effect
            const mutation = updateImageSettingMutation;
            const variables = action.imageSettings;
            const obs = this.graph.graphqlUpdate(mutation, variables).pipe(
                map(res => res),
                tap(res => {
                    this.store.dispatch(new FetchProjectGallery(gallery.galleryid, action.galleryType))
                }),
                catchError(err => {
                    ctx.patchState({
                        ProjectGalleries: state
                    })
                    return throwError(err)
                })

            )
            return obs;
        }
        const index = state.findIndex(gal => gal.galleryid === action.gallery.galleryid);
        const updated_galleries = [...state.slice(0, index), action.gallery, ...state.slice(index + 1)];

        // Side effect
        const mutation = updateImageSettingMutation;
        const variables = action.imageSettings;
        const obs = this.graph.graphqlUpdate(mutation, variables);

        obs.subscribe(
            res => {
                ctx.patchState({
                    Galleries: updated_galleries
                })
            },
            err => {
                ctx.patchState({
                    Galleries: state
                })
            },
        )
        return obs;
    }

    @Action(UpdateGalleryItem)
    updateGalleryItem(ctx: StateContext<GalleriesStateModel>, action: UpdateGalleryItem) {
        const { galleryType } = action;
        const mutation = updateGalleryItemMutation;
        const variables = { galleryitemid: asNumber(action.galleryitemid), active: false };
        const obs = this.graph.graphqlUpdate<'updateGalleryitem', any>(mutation, variables);

        obs.subscribe({
            next: res => {
                const state = ctx.getState()[galleryType];
                const gallery = state[res.data.updateGalleryitem.GalleryItem.galleryid];
                const items = gallery.items.slice();

                const _items = items.filter(item => item.galleryitemid != action.galleryitemid);
                gallery.items = _items;

                state[res.data.updateGalleryitem.GalleryItem.galleryid] = { ...gallery };
                ctx.patchState({ [galleryType]: state });
            },
            error: err => console.log('[UpdateGalleryItem] updateGalleryItem', err)
        });

        return obs;
    }

    @Action(AddPreview)
    addPreview(ctx: StateContext<GalleriesStateModel>, action: AddPreview) {
        const { projectid, galleryid , galleryType } = action;
        if (galleryid) {
            const state = ctx.getState()[galleryType];
            const gallery = state[galleryid]
            gallery.items = gallery.items.slice();

            gallery.items.unshift(action.item);
            state[galleryid] = { ...gallery };
            ctx.patchState({
                [galleryType]: state
            });
        } else {
            const state = ctx.getState().Galleries;
            state.unshift(action.item);
            ctx.patchState({
                Galleries: state
            });
        }

    }

    @Action(UpdatePreview)
    updatePreview(ctx: StateContext<GalleriesStateModel>, action: UpdatePreview) {
        const { galleryid, galleryType } = action;
        if (galleryid) {
            const { item } = action;
            const state = ctx.getState()[galleryType];
            const gallery = ctx.getState()[galleryType][galleryid]
            const _index = gallery.items.findIndex(({ previewdata }) => {
                return previewdata.fileid == action.item.previewdata.fileid;
            });

            gallery.items[_index].previewdata.progress = action.item.previewdata.progress;
            gallery.items[_index].previewdata.completed = action.item.previewdata.completed;

            state[galleryid] = { ...gallery };
            ctx.patchState({ [galleryType]: state });

        } else {
            const state = ctx.getState().Galleries.slice();
            const index = state.findIndex(({ previewdata }) => {
                return previewdata.fileid == action.item.previewdata.fileid;
            });

            state[index].previewdata.progress = action.item.previewdata.progress;
            state[index].previewdata.completed = action.item.previewdata.completed;
            ctx.patchState({ Galleries: state });
        }

    }


    @Action(SelectGalleryView)
    selectGalleryView(ctx: StateContext<GalleriesStateModel>, action: SelectGalleryView) {
        ctx.patchState({
            GalleryView: {
                galleryitemid: action.galleryitemid,
                galleryid: action.galleryid,
                isproject: action.isproject
            }
        });
    }

    @Action(FetchProjectGallery)
    fetchProjectGallery(ctx: StateContext<GalleriesStateModel>, action: FetchProjectGallery) {
        const { galleryType } = action;
        this.store.dispatch(new FetchGalleries({
            galleryType: galleryType,
            filters: [{
                name: 'project',
                value: action.galleryid
            }],
            pagination: {
                page: 1,
                perpage: 20
            }
        }));
    }
}
