import { ApplicationSelectors } from './../../../shared-nxgs/application/application.selectors';
import { Store } from '@ngxs/store';
import { getNextPrevPostsQuery } from './../../../shared/models/graphql/project-post-next-prev.query';
import { ProjectPost } from '@frontend/unhideschool/shared/models/project/project-post.model';
import { map, switchMap, tap } from 'rxjs/operators';
import { projectPostsByIdQuery } from './../../../shared/models/graphql/project-posts-by-id.query';
import { Injectable } from '@angular/core';
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {

  constructor(
    private graph: GraphqlApiService,
    private store: Store
  ) { }

  getProjectPostId(postid: number) {
    const getProjectById = projectPostsByIdQuery;
    const variables = { postid };
    let PostsVault;
    return this.graph.graphqlRequest<'Posts', any>(getProjectById, variables).pipe(
      map(res => res.data.Posts),
      tap(Posts => PostsVault = Posts),
      switchMap(() => this.store.select(ApplicationSelectors.getActivePromotion())),
      map(activePromotion => PostsVault.map(
        post => {
          const projectpost = new ProjectPost(post, activePromotion);
          projectpost.galleries = post.galleries;
          return projectpost;
        }
      ))
    );
  }

  getNextPrevPostsByPosition(position: number) {
    const getNextPrevPosts = getNextPrevPostsQuery;

    const variables = {
      nextPos: position + 1,
      prevPos: position - 1
    };

    return this.graph.graphqlRequest<'prevPost' | 'nextPost', any>(getNextPrevPosts, variables).pipe(
      map(res => res.data)
    );
  }

  populateOtherProjectPosts() {
    const getNextPrevPosts = getNextPrevPostsQuery;
    const variables = {
      page: 1,
      perpage: 10
    }
    return this.graph.graphqlRequest(getNextPrevPosts, variables);
  }
}
