import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from '@angular/cdk/layout';

import { AppRoutingModule } from '@frontend/unhideschool/app/app-routing.module';
import { AppComponent } from '@frontend/unhideschool/app/app.component';

import { CoreModule } from '@frontend/unhideschool/core/core.module';
import { HybridizerModule } from '@frontend/unhideschool/modules/hybridizer/hybridizer.module';

import { NgxsModule, NgxsModuleOptions } from '@ngxs/store';

import { SharedNGXSModule } from './shared-nxgs/shared-ngxs.module';
import { GenericComponentsModule } from '@frontend/unhideschool/shared/generic-components/generic-components.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { environment } from '@frontend/unhideschool/env';
import { ApplicationState } from './shared-nxgs/application/application.state';
import { ReactiveFormsModule } from '@angular/forms';


// declare const angular;

const ngxsConfig: NgxsModuleOptions = {
  selectorOptions: {
    injectContainerState: false,
    suppressErrors: false,
  },
};

// const facebookConfig = {
//   scope: 'public_profile',
//   fields: 'email',
//   return_scopes: true,
//   enable_profile_selector: true,
//   version: "11.0"
// }

@NgModule({
  declarations: [AppComponent],
  imports: [
    SocialLoginModule,
    BrowserModule,
    AppRoutingModule,
    CoreModule,
    HybridizerModule,
    HttpClientModule,
    BrowserAnimationsModule,
    LayoutModule,
    NgxsModule.forRoot([ApplicationState], ngxsConfig),
    SharedNGXSModule,
    GenericComponentsModule,
    DragDropModule,
    ReactiveFormsModule,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: 'Env', useValue: environment },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.clientIdGoogle),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.clientIdFacebook, ),
          },
        ],
        onError: (err) => {
          console.error(err);
        },
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
