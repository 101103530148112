export const CreateLessonMutation = `
mutation (
  $postid: Int!,
  $thumbnailid: Int!,
  $creatoruid: Int!,
  $postparttypeid: Int!,
  $mediaitemid: Int,
  $title: InputMVStringBag!,
  $public: Boolean!,
  $description: InputMVStringBag,
  $groupname: InputMVStringBag,
  $tags: [String]) {
  CreateVideo(
    videoData: {
      postid: $postid,
      thumbnailid: $thumbnailid,
      creatoruid: $creatoruid,
      description: $description,
      postparttypeid: $postparttypeid,
      public: $public,
      mediaitemid: $mediaitemid,
      title: $title,
      groupname: $groupname,
      tags: $tags
    }) {
    video {
      groupname
      description
      title
      postid
      postpartid
      thumbnail
      tags {
        name
      }
    }
  }
}
`
