import { Injectable } from '@angular/core';

import { Observable, forkJoin, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { 
  unreadNotificationsSchema, 
  userNotificationsSchema, 
  readNotificationSchema 
} from './uhql-schemas/notifications.schemas';

import {
  UHQLRequestFilter,
  UHQLRequestOptions,
  UHQLRequestSchema
} from '@frontend/unhideschool/core/models/base-uh-crude.model';

import { NotificationItem } from '../../models/notification-item.model';
import { UnreadUserNotification, NotificationDataModel } from '../../models/notifications.model';

import { BaseStore } from '@frontend/unhideschool/app/helpers/base-store.class';
import { isValidJson } from '@frontend/unhideschool/app/helpers/utility-functions';
import { UHQLApiService } from '../../../api-gateway/services/uhql-api.service';
import { LoggedUserStateService } from './logged-user.state';


export const storeInitialValue = {
  notifications: [],
  unreadNotifications: []
};

export interface UserNotificationsStoreModel {
  notifications: NotificationItem[];
  unreadNotifications: UnreadUserNotification[];
}

@Injectable({
  providedIn: 'root'
})
export class UserNotificationsStore extends BaseStore<UserNotificationsStoreModel> {
  constructor(
    private uhcapis: UHQLApiService,
    private luss: LoggedUserStateService
  ) {
    super(storeInitialValue);
  }

  fetchStoreStateData<T>(selector: keyof UserNotificationsStoreModel): Observable<T> {
    switch (selector) {
      case 'notifications': return this.getUserNotifications<T>();
      case 'unreadNotifications': return this.getUnreadNotifications<T>();
    }
  }

  getUnreadNotifications<T>() {
    if (!this.luss.isLogged) {
      return of([]);
    }
    
    const uid = this.luss.user.uid;
    const schema: UHQLRequestSchema = unreadNotificationsSchema;
    const filters: UHQLRequestFilter[] = [
      { field: 'uid', op: '==', value: uid },
      { field: 'read', op: '==', value: false }
    ];

    return this.uhcapis.uhqlRequest('LIST', 'NOTIFICATIONS', schema, filters).pipe(
      map((res: any) => res.dict.resource)
    );
  }

  getUserNotifications<T>() {
    if (!this.luss.isLogged) {
      return of([]);
    }

    const uid = this.luss.user.uid;
    const schema: UHQLRequestSchema = userNotificationsSchema;
    const filters: UHQLRequestFilter[] = [{ field: 'uid', op: '==', value: uid }];
    const opts: UHQLRequestOptions = { page: 1, perpage: 20, order_by: 'datecreated desc' };
    return this.uhcapis.uhqlRequest('LIST', 'NOTIFICATIONS', schema, filters, opts).pipe(
      // Ignore broken notifications (fallback for old broken notifications)
      map((res: any) => res.dict.resource
        .filter(n => isValidJson(n.data))
        .map(n => {
          const notif = new NotificationItem(JSON.parse(n.data),
            n.notificationid, n.notifcategid, n.notifcateg.internalname, n.read, n.title, n.datecreated);
          return notif
        })
      )
    );
  }

  markNotificationsAsRead(notificationids: number[]) {
    const storeState = this.readState('notifications');

    const updatedStore = this.reducer('markasread', notificationids);
    this.setStoreState('notifications', updatedStore.notifications);


    const obs = forkJoin(notificationids.map(nid => this.setNotificationAsRead(nid)));
    obs.subscribe({
      next: (e) => {
        // Update unreadNotifications state
        const store = this.reducer('removeunreads', notificationids);
        this.setStoreState('unreadNotifications', store.unreadNotifications);
      },
      // Restore the store
      error: () => this.setStoreState('notifications', storeState)
    });

    return obs;
  }

  updateUserNotification(notificationid: number, data: NotificationDataModel, datecreated: string) {
    const uid = this.luss.user.uid;
    const schema: UHQLRequestSchema = { data: JSON.stringify(data), datecreated };
    const filters: UHQLRequestFilter[] = [
      { field: 'uid', op: '==', value: uid },
      { field: 'notificationid', op: '==', value: notificationid },
    ];
    return this.uhcapis.uhqlRequest('UPDATE', 'NOTIFICATIONS', schema, filters);
  }

  updateNotificationItem(notifItem: NotificationItem) {
    const store = this.readState('notifications');
    const updatedstore = this.reducer('replace', notifItem);
    this.setStoreState('notifications', updatedstore.notifications);
    return store;
  }

  restoreState(
    selector: keyof UserNotificationsStoreModel,
    backupState: UserNotificationsStoreModel[keyof UserNotificationsStoreModel]
  ) {
    this.setStoreState(selector, backupState);
  }

  private reducer(action: 'replace' | 'markasread' | 'removeunreads', payload: any): UserNotificationsStoreModel {
    const store = { ...this.snapshot };

    switch (action) {
      case 'replace': {
        const index = store.notifications.findIndex(n => n.notificationid === payload.notificationid);
        const notifications = [...store.notifications.slice(0, index), payload, ...store.notifications.slice(index + 1)];
        return { ...store, notifications };
      }

      case 'markasread': {
        const storeNotifications = store.notifications;
        const notifications = storeNotifications.map(n => {
          const shouldUpdate = payload.some(nid => nid === n.notificationid);
          return (shouldUpdate ? { ...n, ...readNotificationSchema } : n) as NotificationItem;
        });

        return { ...store, notifications };
      }

      case 'removeunreads': {
        const storedUnreadNotifs = store.unreadNotifications;
        const unreadNotifications = storedUnreadNotifs.filter(un => payload.every(nid => nid !== un.notificationid));
        return { ...store, unreadNotifications };
      }
    }
  }

  private setNotificationAsRead(notificationid: number) {
    const uid = this.luss.user.uid;
    const schema: UHQLRequestSchema = readNotificationSchema;
    const filters: UHQLRequestFilter[] = [
      { field: 'uid', op: '==', value: uid },
      { field: 'notificationid', op: '==', value: notificationid }
    ];
    return this.uhcapis.uhqlRequest('UPDATE', 'NOTIFICATIONS', schema, filters);
  }
}
