export const meAuthQuery = `
  query MeAuthQuery {
    me {
      subscribed
    }
  }
`;

export const meProfileQuery = `
  query MeProfileQuery {
    me {
      user {
        uid, email, username, fullname, profilepicture,
        coverpicture, datejoined, activated, autoplay, shortbio,
        active, alias, occupationtitle, availableForWork,

        interests {
          uid
          interestid
          interestIn
          studying
          interest {
            interestid
            name
          }
          haveExperience
        }

        role {
          name,
          internalname
        },

        country {
          code2,
          countryid
        },

        relatedmedia{
          render
          mediaitemid
          mediacontenttype{
            internalname
          }

          imagesettings{
            width
            height
            xcenter
            ycenter
            haspectratio
            waspectratio
          }
        }
      }
    }
  }
`;
