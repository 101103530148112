export const searchTagsToAutocompleteAdminQuery = `
query searchTagsToAutocompleteAdmin($name: String!) {
  drTags(filters: [{name: {op: LIKE, v: $name}}]) {
    tagid
    name
    tagtype {
      tagtypeid
      name
      internalname
    }
  }
}
`
