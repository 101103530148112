import { AfterViewInit, Component, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';

import { UseTranslations } from '@frontend/unhideschool/app/utils/i18n/use-translation.decorator';
import { UNHChangeEmailModel } from '@frontend/unhideschool/app/utils/i18n/locale/unh-change-email/unh-change-email.i18n.model';

import { MatDialogRef } from '@angular/material/dialog';
import { RestApiService } from '@frontend/unhideschool/app/api-gateway/services/rest-api.service';
import { Subject, catchError, debounceTime, takeUntil, tap } from 'rxjs';
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';

interface Translations {
  "unh-change-email": UNHChangeEmailModel;
}

@Component({
  selector: 'unh-change-email',
  templateUrl: './change-email.component.html',
  styleUrls: ['./change-email.component.scss'],
})

@UseTranslations({
  translatableComponents: ["unh-change-email"]
})

export class ChangeEmailComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('changeEmailForm') changeEmailForm: NgForm;

  destroy$ = new Subject();

  pageTranslations: UNHChangeEmailModel;
  translations: Promise<Translations>;

  loading = false;

  state = {
    completed: false,
    checklist: false,
    initial: false,
    matchEmail: false,
    emailExists: null,
    startValidationEmail: false,
    email: '',
    code: '',
  };

  changeEmailFormGroup = new FormGroup({
    email: new FormControl(null, [Validators.required, Validators.email]),
    confirmEmail: new FormControl(null, [Validators.required, Validators.email])
  });

  get email() {
    return this.changeEmailFormGroup.get('email');
  }
  get confirmEmail() {
    return this.changeEmailFormGroup.get('confirmEmail');
  }
  get isValidSubmission() {
    return this.state.matchEmail && !this.state.emailExists && this.changeEmailFormGroup.valid;
  }

  constructor(
    private dialogRef: MatDialogRef<ChangeEmailComponent>,
    private rest: RestApiService,
    private graphql: GraphqlApiService,
    private renderer: Renderer2
  ) { }

  ngOnInit(): void {
    this.translations.then((translations) => {
      this.pageTranslations = translations['unh-change-email'];
    });

    this.observeFormChanges();
  }
  
  ngAfterViewInit() {
    const overlay = document.querySelector('.cdk-global-overlay-wrapper')
    this.renderer.setStyle(overlay, 'pointer-events', 'none')
  }

  ngOnDestroy() {
    this.destroy$.next({});
    this.destroy$.complete();
  }

  observeFormChanges() {
    this.changeEmailFormGroup.valueChanges.subscribe(value => {
      if (this.changeEmailForm.status !== 'INVALID') {
        this.state.completed = true;
      }

      this.state.startValidationEmail =
        value.email.length != 0 && (value.confirmEmail != null && value.confirmEmail.length != 0);

      this.state.matchEmail =
        value.email == value.confirmEmail && (value.confirmEmail != null && value.email !== '');
    });
      
    this.email.valueChanges
      .pipe(
        takeUntil(this.destroy$), 
        debounceTime(1000)
      )
      .subscribe(() => this.email.valid && this.checkEmailExists());
  }

  checkEmailExists() {
    this.graphql.graphqlRequestAll<'User', { totalCount: string }>(
      `
        query CheckEmailExists($email: String) {
          User {
            all(email: $email) {
              totalCount
            }
          }
        }
      `,
      { email: this.email.value }
    )
    .subscribe({
      next: res => (this.state.emailExists = res.data.User.all.totalCount > 0),
      error: err => (this.state.emailExists = !err.includes('User not found by email'))
    });
  }

  onSubmit() {
    if (this.isValidSubmission) {
      this.loading = true;
      this.state.initial = false;

      const email = this.email.value;

      this.rest
        .post('/user/changeemail', { email })
        .subscribe({
          next: () => this.dialogRef.close({
            message: this.pageTranslations.changeEmailSuccessText,
            success: true
          }),
          error: () => this.dialogRef.close({
            message: this.pageTranslations.changeEmailErrorText,
            success: false
          })
        });
    }
  }
}
