import { Component } from '@angular/core';


@Component({
  selector: 'unh-spinner.overlay.ts',
  templateUrl: './spinner-overlay.component.html',
  styleUrls: ['./spinner-overlay.component.scss']
})
export class SpinnerOverlayComponent {

}
