export const FetchLessonUpsetDataQuery =
`
query fetchLessonUpsetData($orderedpostpartid: Int){
	Video(orderedpostpartid: $orderedpostpartid){
    postpartid
    content
    titleLanguages
    descriptionLanguages
    creatoruid
    public
    tags{
      name
    }
    groupnameLanguages
    mediaadapter{
      internalname
    }
    thumbnail
  }
}
`
