import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'unh-image-cropper-container',
  templateUrl: './image-cropper-container.component.html',
  styleUrls: ['./image-cropper-container.component.scss']
})
export class ImageCropperContainerComponent {
  @Input() imageFile: File;

  loadingImage = true;
  croppedImage = '';

  @Input() aspectRatio = 1 / 1;

  @Output() cropInfo = new EventEmitter();
  @Output() cropImageLoaded = new EventEmitter();

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;

    this.cropInfo.emit({
      x: event.imagePosition.x1,
      y: event.imagePosition.y1,
      height: event.imagePosition.y2 - event.imagePosition.y1,
      width: event.imagePosition.x2 - event.imagePosition.x1
    });

  }

  imageLoaded() {
    // show cropper
    this.loadingImage = false;
    this.cropImageLoaded.emit(true);
  }

  cropperReady() {
    // Not implemented
  }

  loadImageFailed() {
    // Not implemented
  }
}
