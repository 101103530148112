import { Component, Input, ChangeDetectionStrategy, TemplateRef, ContentChild, AfterContentInit, HostBinding, Output, EventEmitter, ViewChild } from '@angular/core';
import { BannerHomeItem } from '../../models/mocks';

import { SwiperConfigInterface } from 'ngx-swiper-wrapper';

@Component({
  selector: 'unh-generic-swiper-slider',
  templateUrl: './generic-swiper-slider.component.html',
  styleUrls: ['./generic-swiper-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericSwiperSliderComponent implements AfterContentInit {
  _swiperConfigs;

  contentInit = false;
  configs = null;

  slideCount = 0;
  @Input() items: any[];
  @Input() isMobile: boolean;
  @Input() autoplay: number;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSlideChange = new EventEmitter<{ page: number }>();

  @Input() set swiperConfigs(value: SwiperConfigInterface) {
    this._swiperConfigs = value;
    this.configs = { ...this.configs, ...value }
  }

  get slidesPerView() {
    return this.isMobile ? 2 : 3.5;
  }

  @ContentChild('genericSwiperItemTemplate', { static: false })
  genericSwiperItemTemplateRef: TemplateRef<any>;

  trackByFn = (index) => index;

  ngAfterContentInit(): void {
    const configs: SwiperConfigInterface = {
      slidesPerView: this.slidesPerView,
      direction: 'horizontal',
      a11y: true,
      keyboard: false,
      navigation: true,
      mousewheel: false,
      scrollbar: false,
      pagination: false,
      fadeEffect: {
        crossFade: true
      }
    };

    if (this.autoplay && this.autoplay > 0) {
      configs[this.autoplay] = { delay: this.autoplay };
    }

    this.contentInit = true;
    this.configs = { ...configs, ...this._swiperConfigs };
  }

}
