import { StateContext } from '@ngxs/store';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ActivePromotion } from '@frontend/unhideschool/app/shared-nxgs/application/application.model';
import { HttpErrorResponse } from '@angular/common/http';
import { differenceInCalendarDays } from 'date-fns';

export function truncate(str: string, maxchar: number): string {
  return str.length > maxchar ? str.slice(0, maxchar) + '...' : str;
}

/**
 * Método conversor de string em slug
 * @args text
 * @description Esse método converte um texto com acentos e simbolos
 * em um slug (string normalizada* em caixa baixa separada por hífen).
 * @returns string
 * ___
 * *&nbsp;[FDN - Formato de Normalização Canônico de Decomposição
 * ](https://developer.mozilla.org/pt-BR/docs/Web/JavaScript/Reference/Global_Objects/String/normalize)</para>
 */
export function slugify(text: string): string {
  return text
    .toString()
    .normalize('NFD')                   // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, '')   // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, '')   // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, '-');
}

export function interpolate(str, obj, pattern = /{([^{}]*)}/g) {
  if (str == null) {
    return '';
  }
  return str.replace(pattern, (match, p1) => {
    const r = obj[p1.trim()];
    return typeof r === 'string' || typeof r === 'number' ? r : match;
  });
}

export function getHTMLStringAsText(htmlstring: string, maxchar?: number) {
  const divEl = document.createElement('div');
  divEl.innerHTML = htmlstring;
  return maxchar != null ? truncate(divEl.textContent, maxchar) : divEl.textContent;
}

export function safeJsonParse<T = any>(json: string): T {
  try {
    return JSON.parse(json);
  } catch {
    return null;
  }
}

export function asNumber(value: string | number) {
  return parseInt(value != null ? value.toString() : '0', 10);
}

export function compareDate(expirationDate: string): boolean {
  const currentDateTimestamps = new Date().getTime();
  const expirationDateTimestamps = new Date(expirationDate).getTime();

  const canRenew = (
    ((expirationDateTimestamps - currentDateTimestamps) / (60 * 60 * 24 * 1000)) < 15
  );

  return canRenew;
}

export function ngxsOptimisticErrorHandling(
  ctx: StateContext<any>,
  state: any,
) {
  return <T>(source: Observable<T>) => source.pipe(
    catchError(err => ctx.setState(state) && throwError(err))
  );
}



export function recursiveCollecionFlat(arr: any[], condition, key) {
  let result = [];

  for (const obj of arr) {
    if (condition(obj)) {
      result = [...result, { ...obj, replies: [] }];
      result = result.concat(recursiveCollecionFlat(obj[key], condition, key));
    } else {
      result = [...result, obj];
    }
  }

  return result;
}

/**
 * Capitalizes first letters of words in string.
 * @param  `string` String to be modified
 * @param  `boolean` lower Whether all other letters should be lowercased
 * @return `string`
 * @usage
 *   capitalize('fix this string');     // -> 'Fix This String'
 *   capitalize('javaSCrIPT');          // -> 'JavaSCrIPT'
 *   capitalize('javaSCrIPT', true);    // -> 'Javascript'
 */
export function capitalize(str, lower = false) {
  const tmpstring = lower ? str.toLowerCase() : str;
  return tmpstring.replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

export function getActivePromoPrice(
  activePromotion: ActivePromotion,
  product: { price: string | number, coupons: string[] }
) {
  const originalprice = parseFloat(product.price.toString());
  const hasCoupons = product.coupons.length > 0;
  const shouldApplyActivePromoCoupon = hasCoupons && product.coupons.some(cc => cc == activePromotion.coupon.couponcode);

  if (shouldApplyActivePromoCoupon) {
    const coupontype = activePromotion.coupon.discountcoupontype.internalname;
    const discountvalue = asNumber(activePromotion.coupon.value);

    const percentageRatio = (
      coupontype === 'percentage'
        ? discountvalue / 100
        : discountvalue / originalprice
    );

    const totaldiscount = originalprice * percentageRatio;
    return Math.ceil(originalprice - totaldiscount).toString();
  }

  return originalprice.toString();
}

export type CommonError = { message: string };

export function checkErrorsIncludesPatterns(
  httpError: HttpErrorResponse | CommonError[],
  patterns: string[]
): boolean {
  const isInstanceOfError = httpError instanceof HttpErrorResponse;

  const errs = (
    isInstanceOfError
      ? (httpError as HttpErrorResponse).error.errors
      : (httpError as CommonError[])
  );

  if (!errs) {
    return false;
  }

  return patterns.some(pat => {
    switch (true) {
      case (typeof errs === 'string'):
        return errs.includes(pat);

      case Array.isArray(errs):
        return errs.some(err => err?.message?.includes(pat));

      default:
        return false;
    }
  });
}

export function extractFromObjectListByKey(
  list: { [k: number]: any },
  key: string,
  transformer?: (val, id) => any
) {
  return Object.keys(list).reduce((acc, id) => {
    const value = list[id][key];
    acc[id] = transformer ? transformer(value, id) : value;
    return acc;
  }, {})
}


export function isValidJson(json: string) {
  try {
    const parsed = JSON.parse(json);
    return !!parsed;
  } catch (error) {
    return false;
  }
}

export function checkPostIsNew(datePublished: string, maxDays: number) {
  const today = new Date();
  const publishedDate = new Date(datePublished);
  const diffInDays = differenceInCalendarDays(today, publishedDate);
  return diffInDays <= maxDays;
}

export function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(item => deepClone(item)) as any;
  }

  const clonedObj: any = {};
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      clonedObj[key] = deepClone(obj[key]);
    }
  }
  return clonedObj;
}