export const updateMediaItemCreator = `
mutation(
  $mediaitemid:ID!
  $creatoruid:Int
){
  updateMediaitem(mediaitemData:{
      mediaitemid:$mediaitemid,
      creatoruid:$creatoruid
  }){
    MediaItem {
      name
      mimetype
      datecreated
      localfileid
      render
      contenttime
    }
  }
}
`;
