import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';
import { LoggedUserStateService } from '../services/legacy/logged-user.state';

@Injectable({
    providedIn: 'root'
})
export class AdminGuard implements CanActivate {

    constructor(
        private luss: LoggedUserStateService,
        private router: Router
    ) {}

    canActivate(route: ActivatedRouteSnapshot): boolean {
        if (!this.luss.isAdmin) {
            const defaultRedirect = '/home';
            const redirectTo = route.data != null && route.data.redirectTo != null ? route.data.redirectTo : defaultRedirect;
            this.router.navigate([ redirectTo ]);
            return false;
        }

        return true;
    }
}
