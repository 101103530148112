export interface SimpleSubscriptionPlan {
    subsplanid: number;
    subsplanpriceid: number;
}

export enum SimpleSubscriptionPlans {
    monthly,
    monthlybussiness
}




