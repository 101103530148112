export const GetProjectFilesQuery = `
query getProjectFiles($postid: Int) {
  VideoPlayer(postid: $postid) {
    course {
      files{
        name
        mediaitemid
        render
        mediaadapter {
          internalname
          mediaadapterid
        }
        mediacontenttype {
          internalname
          mediacontenttypeid
        }
        content
        size
      }
    }
  }
}
`;
