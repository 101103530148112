import { LoggedUserStateService } from '@frontend/unhideschool/core/services/legacy/logged-user.state';
import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { LoggedUser } from '@frontend/unhideschool/core/models/logged-user.model';
import { environment } from '@frontend/unhideschool/env';
import { UnreadUserNotification } from '@frontend/unhideschool/core/models';

@Component({
  selector: 'unh-user-actions',
  templateUrl: './user-actions.component.html',
  styleUrls: ['./user-actions.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserActionsComponent {

  @Output() notificationsMenuToggleClicked = new EventEmitter<void>();
  @Output() userMenuToggleClicked = new EventEmitter<void>();

  @Input() unreadNotifs: UnreadUserNotification[] = [];
  @Input() isSmallScreen = false;

  @Input() user: LoggedUser;

  defaultAvatarUrl = environment.defaultAvatarUrl;
  
  constructor(public luss: LoggedUserStateService) {}

  get isLogged() {
    return this.user && this.user.uid;
  }

  onToggleNotificationsMenu(): void {
    this.notificationsMenuToggleClicked.emit();
  }

  onToggleUserMenu(): void {
    this.userMenuToggleClicked.emit();
  }

}
