<div class="badges-container">
  <div 
    class="icon-container"
    *ngFor="let badge of badges"
    matTooltip="{{badge?.badge.name}}"
    matTooltipClass="tooltip-submit"
  >
    <img src="https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/badges/{{badge?.badge.internalname}}.svg" alt="Badge {{badge?.badge.name}}">
  </div>
  
</div>