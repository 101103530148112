import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@frontend/unhideschool/env';

@Injectable({
    providedIn: 'root'
})
export class AcceptLanguageInterceptor implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const lang = localStorage.getItem(environment.storagekeys.unhacceptlang);

        const authReq = req.clone({ 
            headers: req.headers.set('Accept-Language', lang || 'pt-BR') });
        
        return next.handle(req.clone(authReq));
    }
}
