import { Component, Input, ContentChild, TemplateRef, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { SimpleGalleryItem } from '@frontend/unhideschool/shared/models/gallery/simple-gallery-item.model';

@Component({
  selector: 'unh-generic-gallery',
  templateUrl: './generic-gallery.component.html',
  styleUrls: ['./generic-gallery.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericGalleryComponent implements OnInit {
  @Input() layout: string;
  @Input() galleryItems: SimpleGalleryItem[];
  @Input() editMode: boolean;
  @Input() trackByKey?: string
  @Input() isProject?: boolean 

  @ContentChild('genericGalleryItemTemplate', { static: false })
  genericGalleryItemTemplateRef: TemplateRef<any>;

  trackByFn = (index?, obj?: any) => this.trackByKey ? obj[this.trackByKey] : index;
  constructor() {
  }
  ngOnInit(){ }
}
