export const MembershipSignupFlowQuery = `
  mutation MembershipSignupFlowMutation(
    $fullname: String!
    $alias: String!
    $email: String!,
    $password: String!,
    $datebirth: Date!,
    $termaccept: Boolean!,
  ) {
    Signup(signupData: {
      email: $email,
      alias: $alias,
      password: $password,
      datebirth: $datebirth,
      termaccept: $termaccept,
      fullname: $fullname
    }) {
      authtoken {
        user {
          uid
          alias
          username
          fullname
          email
          country {
            countryid
            name
          }
          genderid
          datebirth
          interests {
            interestid
          }
          websites {
            uid
            url
          }
        }
        token
      }
      success
    }
  }
`;
