import { Component } from '@angular/core';

@Component({
  selector: 'unh-side-menu-footer',
  styleUrls: ['./side-menu-footer.component.scss'],
  template: `
    <div class="side-menu-footer">
      <ng-content></ng-content>
    </div>
  `
})
export class SideMenuFooterComponent {}
