export const GetCommunityViewallQuery = `
query getViewAllCourseCommunity($communityId: Int!){
  Community{
    item(communityId: $communityId){
      communitycategid
      path
      communityid
    }
  }
}
`
