import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'unh-user-menu-item-link',
  styleUrls: ['./user-menu-item-link.component.scss'],
  templateUrl: './user-menu-item-link.html'
})
export class UserMenuItemLinkComponent {
  iconIsActive = false;

  @Input() itemTitle: string;
  @Input() itemSubtitle: string;
  @Input() itemLink: string;
  @Input() itemIcon: string;
  @Input() itemIconType: string;

  @Output() itemClicked = new EventEmitter();

  clickItem(event){
    this.itemClicked.emit(event);
  }
}
