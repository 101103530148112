<div>
    <div >
        <p style="font-size:10px;">{{item.inputFile.name}}</p>
        <p *ngIf="!item.completed">{{item.progress}}%</p>
        <p *ngIf="item.completed">Completed</p>
        <img [src]="imgUrl"  width="50px" height="50px" alt="">
        <div style="width:50px">

        </div>
    </div>
</div>