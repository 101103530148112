import { AfterViewInit, Component, OnDestroy, OnInit, Optional, Renderer2, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import {
  containNumber,
  containSpecialCharacter,
  containUppercaseLetter,
  executeValidationText,
} from '@frontend/unhideschool/modules/signin/forms/validators';

import { UseTranslations } from '@frontend/unhideschool/app/utils/i18n/use-translation.decorator';
import { UNHChangePasswordModel } from '@frontend/unhideschool/app/utils/i18n/locale/unh-change-password/unh-change-password.i18n.model';
import { AuthService } from '@frontend/unhideschool/app/core/services/auth.service';
import { MatDialogRef } from '@angular/material/dialog';
import { RestApiService } from '@frontend/unhideschool/app/api-gateway/services/rest-api.service';
import { Subject, takeUntil } from 'rxjs';

interface Translations {
  "unh-change-password": UNHChangePasswordModel;
}

@Component({
  selector: 'unh-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})

@UseTranslations ({
  translatableComponents: ["unh-change-password"]
})

export class ChangePasswordComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('changePasswordForm') changePasswordForm: NgForm;

  destroy$ = new Subject();

  pageTranslations : UNHChangePasswordModel;
  translations: Promise<Translations>;

  loading = false;
  inDialog = false;

  state = {
    completed: false,
    checklist: false,
    initial: false,
    matchPassword: false,
    startValidationPassword: false,
    email: '',
    code: '',
  };

  changePasswordFormGroup = new FormGroup({
    password: new FormControl(
      null,
      [
        Validators.required,
        containSpecialCharacter(),
        containNumber(),
        Validators.maxLength(20),
        containUppercaseLetter()
      ]
    ),
    confirmPassword: new FormControl(
      null,
      [
        Validators.required,
        containSpecialCharacter(),
        containNumber(),
        Validators.maxLength(20),
        containUppercaseLetter()
      ]
    ),
  });

  get password() {
    return this.changePasswordFormGroup.get('password');
  }
  get confirmPassword() {
    return this.changePasswordFormGroup.get('confirmPassword');
  }

  get validationMinLengthPassword() {
    const passwordErros = this.password.errors;

    return executeValidationText(
      passwordErros?.required,
      passwordErros?.minlength
    );
  }

  get validationContainNumberPassword() {
    const passwordErros = this.password.errors;

    return executeValidationText(
      passwordErros?.required,
      passwordErros?.containNumber
    );
  }

  get validationContainUppercaseLetter() {
    const passwordErros = this.password.errors;

    return executeValidationText(
      passwordErros?.required,
      passwordErros?.containUppercaseLetter
    );
  }

  get validationSpecialCharacter() {
    const passwordErros = this.password.errors;

    return executeValidationText(
      passwordErros?.required,
      passwordErros?.containSpecialCharacter
    );
  }

  get validationMaxLength() {
    const passwordErros = this.password.errors;

    return executeValidationText(
      passwordErros?.required,
      passwordErros?.maxLength
    );
  }

  constructor(
    private auths: AuthService,
    private router: Router,
    @Optional()private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private rest: RestApiService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    const state = history.state;

    this.inDialog = this.dialogRef?.id !== undefined;

    if (!this.inDialog) {
      if (state) {
        this.state.email = state.email;
        this.state.code = state.code;
      } else {
        this.router.navigateByUrl('/recover-password');
      }
    }

    this.translations.then((translations) => {
      this.pageTranslations = translations['unh-change-password'];
    });

    this.observeFormChanges();
  }

  ngAfterViewInit() {
    if (this.inDialog) {
      const overlay = document.querySelector('.cdk-global-overlay-wrapper')
      this.renderer.setStyle(overlay, 'pointer-events', 'none')
    }
  }

  ngOnDestroy() {
    this.destroy$.next({});
    this.destroy$.complete();
  }

  observeFormChanges() {
    this.changePasswordFormGroup.valueChanges.pipe(
      takeUntil(this.destroy$)
    ).subscribe((value) => {
      if (this.changePasswordForm.status !== 'INVALID') {
        this.state.completed = true;
      }

      this.state.startValidationPassword =
        value.password.length != 0 && (value.confirmPassword != null && value.confirmPassword.length != 0);

      this.state.matchPassword =
        value.password == value.confirmPassword && (value.confirmPassword != null && value.password !== '');
    });
  }

  onSubmit() {
    if (this.changePasswordFormGroup.valid) {
      this.loading = true;
      this.state.initial = false;

      if (!this.inDialog) {
        this.auths
          .updatePassword(
            this.state.email,
            this.state.code,
            this.password.value
          )
          .subscribe({
            next: () => this.router.navigateByUrl('/signin-success', {
              state: { template: 'change-pass' }
            }),
            error: () =>  this.router.navigateByUrl('/signin-failure')
          });
      } else {
        const password = this.password.value;
        const prepareData = { password, passwordconfirm: password };

        this.rest
          .post('/user/changepassword', prepareData)
          .subscribe({
            next: () => this.dialogRef?.close({
              message: this.pageTranslations.changePasswordSuccessText,
              success: true
            }),
            error: () => this.dialogRef?.close({
              message: this.pageTranslations.changePasswordErrorText,
              success: false
            })
          });
      }
    }
  }
}
