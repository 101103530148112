import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { ProjectPost } from '@frontend/unhideschool/shared/models/project/project-post.model';
import { ApplicationSelectors } from "@frontend/unhideschool/app/shared-nxgs/application/application.selectors";
import { BreakpointObserver } from '@angular/cdk/layout';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { ActivePromotion } from '@frontend/unhideschool/app/shared-nxgs/application/application.model';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';


@Component({
  selector: 'unh-view-all-header',
  templateUrl: './view-all-header.component.html',
  styleUrls: ['./view-all-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewAllHeaderComponent {

  @Input() project: ProjectPost;
  @Input() totalViews: number;
  @Input() isSmallScreen: boolean;
  @Input() viewsLabel: string;

  @Select(ApplicationSelectors.hasAnualPromo())
  hasAnualPromo$: Observable<boolean>;

  @Select(ApplicationSelectors.getActivePromotion())
  activePromo$: Observable<ActivePromotion>;

  get features() {
    return this.getProjectFeatures(this.project);
  }

  get bgImageStyle() {
    if (this.project == null) {
      return '';
    }

    const isMobileScreen = this.breakpointObserver.isMatched('(max-width: 480px)');

    return isMobileScreen
      ? `url(${this.project.postmobilecover}?tr=w-480)`
      : `url(${this.project.postviewallhead}?tr=w-1320)`;
  }

  constructor(private breakpointObserver: BreakpointObserver, private luss: LoggedUserStateService) {

  }

  isSubscribed() {
    return this.luss.subscribed;
  }

  private getProjectFeatures(project: ProjectPost) {
    if (project != null) {
      return [
        // { iconUrl: 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/icons/view-all-banner/ico-level.png', text: project.difficultytags },
        { iconUrl: 'https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/svg-icons/eye-icon.svg', text:`${this.totalViews} +  ${this.viewsLabel}` },
        { iconUrl: 'assets/web-components/images/svg-icons/view-all-banner/ico-time.png', text: project.duration },
        { iconUrl: 'assets/web-components/images/svg-icons/view-all-banner/ico-language.png', text: project.audio.shortlangs.join(' / ') },
        { iconUrl: 'assets/web-components/images/svg-icons/view-all-banner/ico-legends.png', text: project.subtitle.shortlangs.join(' / ') },
      ];
    }

    return [];
  }
}
