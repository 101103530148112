/**
 * Contain all available resources for UHQL Requests to UNHIDE Api
 */

export enum UHQLRequestResources {
    // Users
    USERS = 'Users',

    // Notifications
    NOTIFICATIONS = 'Notifications',

    // Subscriptions
    SUBSCRIPTIONS = 'Subscriptions',
    SUBSCRIPTION_MEMBERS = 'SubscriptionMembers',
    SUBSCRIPTION_PAYMENTS = 'SubscriptionPayments',

    // Subscription -> States
    SUBSCRIPTION_STATES = 'drSubscriptionStates',
    SUBSCRIPTION_MEMBER_STATES = 'drSubscriptionMemberStates',
    SUBSCRIPTION_MEMBER_TYPES = 'drSubscriptionMemberTypes'
}
