import { Observable } from 'rxjs';

export abstract class AbstractNotificationActionHandlers {
    abstract acceptInvitation?(payload: AcceptInvitationNotificationAction): Observable<any>;
    abstract declineInvitation?(payload: DeclineInvitationNotificationAction): Observable<any>;
}

export type NotificationActionHandlerType = keyof AbstractNotificationActionHandlers;

export abstract class BaseNotificationActionHandler<T> {
    constructor(store: T) {}
}

export interface NotificationActionHandler<T> extends BaseNotificationActionHandler<T> {
    handleNotificationActions(action: NotificationActionHandlerType, payload: any): void;
}

export abstract class BaseNotificationActionPayload {}

export class AcceptInvitationNotificationAction extends BaseNotificationActionPayload {
    subscriptionid: number;
    subscriptionmemberid: number;
    subscriptionmemberstateinternalname: string;
}

export class DeclineInvitationNotificationAction extends BaseNotificationActionPayload {
    subscriptionid: number;
    subscriptionmemberid: number;
    subscriptionmemberstateinternalname: string;
}
