import { Directive, HostListener, ElementRef, Renderer2, NgZone, Input } from '@angular/core';

@Directive({
    selector: '[unhBounceOnClick]',
})
export class BounceOnClickDirective {
    @Input() unhBounceOnClick = false;

    constructor(private elref: ElementRef, private r2: Renderer2, private zone: NgZone) {}

    @HostListener('mousedown')
    onClick() {
        if (this.unhBounceOnClick) {
            const el = this.elref.nativeElement;
            this.r2.addClass(el, 'bounce-me');

            this.zone.run(() => {
                setTimeout(() => {
                    this.r2.removeClass(el, 'bounce-me');
                }, 300);
            });
        }
    }
}
