import { DrPostValidTraits } from "@frontend/unhideschool/graphql-schema";

export const formatFile = (file) => {
  return ({ id: file.mediaitemid, name: file.name, size: BytesConverterAndFormat(file.size), mediaitem: { mediaitemid: file.mediaitemid }, progress: 100, completed: true })
}


export const BytesConverterAndFormat = (bytes): string => {
  var marker = 1024; // Change to 1000 if required
  var decimal = 0; // Change as required
  var kiloBytes = marker; // One Kilobyte is 1024 bytes
  var megaBytes = marker * marker; // One MB is 1024 KB
  var gigaBytes = marker * marker * marker; // One GB is 1024 MB
  var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB

  // return bytes if less than a KB
  if (bytes < kiloBytes) return bytes + " Bytes";
  // return KB if less than a MB
  else if (bytes < megaBytes) return (bytes / kiloBytes).toFixed(decimal) + " KB";
  // return MB if less than a GB
  else if (bytes < gigaBytes) return (bytes / megaBytes).toFixed(decimal) + " MB";
  // return GB if less than a TB
  else return (bytes / gigaBytes).toFixed(decimal) + " GB";
}

export const toggleTrait = (traits: [], isToAdd: boolean, targetTrait: string) => {
  const traitsArr = isToAdd
    ? [
        ...traits.map((t: DrPostValidTraits) => t.internalname),
        targetTrait,
      ]
    : traits.reduce((acc: string[], cur: DrPostValidTraits) => {
        if (cur.internalname !== targetTrait) {
          acc.push(cur.internalname);
        }

        return acc;
      }, []);

  return traitsArr;
}
