export const fetchPlansProductQuery = `
query FetchSubscriptionPlansProduct {
  Product {
    all(onlyPlanProducts: true) {
      items {
        productid
        name
        internalname
        description
        additionalinfo
        quantity
        entitlementid
        entitlementDuration
        datecreated
        dateupdated
        active
        enabled
        prices {
          productpriceid
          price
        }
        discountcoupons{
          couponcode
        }
        entitlement {
          internalname
          entitlementid
          name
          internalname
          userdescription
          postdescription
          defaultduration
          datecreated
          active
        }
      }
    }
  }
}

`;
