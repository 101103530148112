import { Component, Input, ElementRef, Renderer2, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'unh-quill-content-renderer',
  styleUrls: ['./quill-content-renderer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: ``
})
export class QuillContentRendererComponent {
  private readonly contentClass = 'quill-content-container';

  @Input()
  set content(htmlstring: string) {
    this.clearContent();

    if (htmlstring != null) {
      let updatedHtmlString: string;

      // Valida se o valor de htmlstring é uma string em formato JSON
      // ex: '\"String de Teste que pode conter encode especial.\"'
      try {
        updatedHtmlString = JSON.parse(htmlstring);
      } catch {
        updatedHtmlString = htmlstring;
      }

      updatedHtmlString = this.removeEmptyHtmlTextNodes(updatedHtmlString);
      updatedHtmlString = this.wrapIframesWithAspectRatioBox(updatedHtmlString);

      this.appendContentToHost(updatedHtmlString);
    }
  }

  constructor(private elRef: ElementRef, private r2: Renderer2) { }

  private clearContent() {
    const hostEl = this.elRef.nativeElement;
    const contentContainerEl = hostEl.querySelector('.' + this.contentClass);

    if (contentContainerEl != null) {
      this.r2.removeChild(hostEl, contentContainerEl);
    }
  }

  private appendContentToHost(htmlstring: string) {
    const content: HTMLElement = this.r2.createElement('div');
    content.innerHTML = htmlstring;

    this.r2.appendChild(this.elRef.nativeElement, content);
    this.r2.addClass(content, this.contentClass);
  }

  private wrapIframesWithAspectRatioBox(htmlstring: string): string {
    const pattern = '(<\s*iframe[^>]*>.*?<\s*/\s*iframe>)';
    return htmlstring.replace(new RegExp(pattern, 'g'), '<div class="ratio-16-9"><div class="ratio-inner">$1</div></div>');
  }

  private removeEmptyHtmlTextNodes(htmlstring: string): string {
    const pattern = '(<[^>]*>\s*<br[^>]*>\s*<\/[^>]*>)';
    return htmlstring.replace(new RegExp(pattern, 'g'), '');
  }
}
