/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchUser {
  static readonly type = '[User API] Fetch';
  constructor(public page?: number, public perpage?: number) { }
}

