import { environment } from '@frontend/unhideschool/env';
import { UserMenuItem } from '../user-menu-item.model';

export const mockUserMenuItens: UserMenuItem[] = [
  {
    itemtype: 'link',
    title: 'Meus cursos',
    subtitle: 'Confira todos os seus cursos, seu progresso e onde você parou!',
    link: '/meus-cursos',
    icon: environment.cdnUrl + '/meuscursos.svg',
    iconType: 'url'
  },
  {
    itemtype: 'link',
    title: 'Editar perfil',
    subtitle: 'Edite suas informações, envie imagens para o seu sketchbook e mais',
    link: '/perfil/me',
    icon:  environment.cdnUrl + '/meuperfil.svg',
    iconType: 'url'
  },
  {
    itemtype: 'link',
    title: 'Minhas Indicações',
    subtitle: 'Veja suas vantagens e indique seus amigos',
    link: '/minhas-indicacoes',
    icon: environment.cdnUrl + '/images/svg-icons/referral-sidebar-icon-grey.svg',
    iconType: 'url'
  },
  {
    itemtype: 'link',
    title: 'Seu plano',
    subtitle: 'Veja os detalhes do seu plano atual, vencimento, histórico e mais',
    link: '/assinatura/plano',
    icon: environment.cdnUrl + '/sidebar-prices-icon.svg',
    iconType: 'url'
  },
  {
    itemtype: 'action',
    title: 'Alterar senha',
    subtitle: 'Altere sua senha de acesso cadastrada',
    link: 'change-pass',
    icon: environment.cdnUrl + '/password-sidebar-icon.svg',
    iconType: 'url'
  },
  {
    itemtype: 'action',
    title: 'Alterar e-mail',
    subtitle: 'Altere seu e-mail de acesso cadastrado',
    link: 'change-email',
    icon: environment.cdnUrl + '/edit-email-icon.svg',
    iconType: 'url'
  },
  {
    itemtype: 'action',
    title: 'Sair',
    subtitle: 'Já estamos sentindo a sua falta, volta logo!',
    link: 'logout',
    icon: environment.cdnUrl + '/exit-sidebar-icon.svg',
    iconType: 'url'
  }
];
