export class CourseAdminUpsetLesson {
  postid: number;
  thumbnailid: number;
  creatoruid: number;
  description: { ptBr: string, enUs: string };
  postparttypeid: number;
  mediaitemid: number;
  title: { ptBr: string, enUs: string };
  public: boolean;
  groupname: { ptBr: string, enUs: string };
  tags: string[];

  constructor(
    stateLesson: any,
    thumbnailid?: number,
    videoid?: number
  ) {
    this.postid = stateLesson.postid;
    this.creatoruid = Number(stateLesson.commonData.instructor.uid);
    this.description = { ptBr: stateLesson.translatableData.pt_BR.description, enUs: stateLesson.translatableData.en_US.description };
    this.postparttypeid = 1;
    if (videoid) {
      this.mediaitemid = Number(videoid);
    }

    if (thumbnailid) {
      this.thumbnailid = Number(thumbnailid);
    }
    this.title = { ptBr: stateLesson.translatableData.pt_BR.title, enUs: stateLesson.translatableData.en_US.title };
    this.public = stateLesson.commonData.visibility.value;
    this.groupname = { ptBr: stateLesson.translatableData.pt_BR.groupname.title, enUs: stateLesson.translatableData.en_US.groupname.title };
    this.tags = stateLesson.commonData.tags.map( tag => tag.content);
  }




}
