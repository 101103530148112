import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { GraphqlApiService } from '../../api-gateway/services/graphql-api.service';
import { RestApiService } from '../../api-gateway/services/rest-api.service';
import { StorageService } from '../../api-gateway/services/storage.service';

import {
  LoginMutationData,
  loginMutation,
  loginFacebookMutation,
  loginGoogleMutation,
  UserData,
  signupApplicationMutation,
  signupFacebookMutation,
  signupGoogleMutation,
  addSocialFacebookLoginMutation,
  resendCodeForgotPasswordMutation,
  forgotPasswordMutation,
  verifyCodeRecoverPasswordMutation,
  updatePasswordMutation,
  userGendersQuery,
  UserGendersQueryData
} from '../models/graphql';

import { SocialUser, } from 'angularx-social-login';
import { environment } from '@frontend/unhideschool/env';
import { MeInfoQueryData, meInfoQuery } from '../models/graphql/me-info.query';
import { Router } from '@angular/router';
import { CustomHttpOptions } from '../../api-gateway/models/base-api.model';
import { HttpHeaders } from '@angular/common/http';
import { AppLanguage, AuthData, MeInfo } from '../models';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private readonly TOKEN_KEY = environment.storagekeys.unhtoken;
  private readonly USER_KEY = environment.storagekeys.unhuser;
  private readonly LANGUAGE_KEY = environment.storagekeys.unhacceptlang;
  private readonly _authData$ = new BehaviorSubject<AuthData>(null);

  public authData$ = this._authData$.asObservable();

  private get authToken(): string {
    return this.storage.getItem(this.TOKEN_KEY);
  }

  private get meInfo(): MeInfo {
    return this.storage.getItem(this.USER_KEY);
  }

  public get authData() {
    return this._authData$.value;
  }
  private set authData(authData: AuthData) {
    this._authData$.next(authData);
  }

  public get acceptLanguage(): string {
    return this.storage.getItem(this.LANGUAGE_KEY, true) || 'pt-BR';
  }

  constructor(
    private storage: StorageService,
    private rest: RestApiService,
    private graph: GraphqlApiService,
    private router: Router,
  ) {
    try {
      this.storeAuthenticationData(this.authToken, this.meInfo);
      const currLanguage = this.storage.getItem(this.LANGUAGE_KEY, true);
      if(!currLanguage){
        this.setAcceptedLanguage(AppLanguage.PT_BR);
      }
    } catch (error) {
      this.clearAuthenticationData();
    }
  }

  private clearAuthenticationData() {
    this.storage.removeItem(this.TOKEN_KEY);
    this.storage.removeItem(this.USER_KEY);
    this.authData = null;
  }

  private storeAuthenticationData(token: string, me: MeInfo) {
    if (token && me) {
      this.storage.setItem(this.TOKEN_KEY, token);
      this.storage.setItem(this.USER_KEY, me);
      this.authData = { token, me };
    }
  }

  public getAuthenticatedUserData(token: string) {
    const options: CustomHttpOptions = {
      headers: new HttpHeaders({ 'Authorization': 'Unhide tok = ' + token })
    };
    const request = this.graph
      .graphqlRequest<'me', MeInfoQueryData>(meInfoQuery, null, options, true, false);
    return request.pipe(
      map(res => ({ me: new MeInfo(res.data.me), token } as AuthData)),
      tap(({ me }) => this.storeAuthenticationData(token, me))
    );
  }

  public tempUserLogin(): Observable<string> {
    const endpoint = '/login/temp_user_login';
    return this.rest.get<{ token: string }>(endpoint).pipe(
      map(res => res.dict.token),
      switchMap(token => this.getAuthenticatedUserData(token).pipe(map(() => token)))
    );
  }

  public logout(): void {
    this.clearAuthenticationData();
    this.router.navigate(['home']);
  }

  public loginFacebook(userData: SocialUser) {
    const payload = { authtoken: userData.authToken };

    const request = this.graph.graphqlRequest<'FacebookLogin', LoginMutationData>(
      loginFacebookMutation,
      payload
    );

    return request.pipe(
      map(res => res.data.FacebookLogin.authtoken.token),
      switchMap(token => this.getAuthenticatedUserData(token))
    );
  }

  public loginGoogle(userData: SocialUser) {
    const clientid = environment.clientIdGoogle;
    const payload = { clientid, idtoken: userData.response.id_token };

    const request = this.graph.graphqlRequest<'GoogleLogin', LoginMutationData>(
      loginGoogleMutation,
      payload
    );

    return request.pipe(
      map(res => res.data.GoogleLogin.authtoken.token),
      switchMap(token => this.getAuthenticatedUserData(token))
    );
  }

  public login(email: string, password: string) {
    const payload = { login: email, password: password };

    const request = this.graph.graphqlRequest<'Login', LoginMutationData>(
      loginMutation,
      payload,
      {},
      true,
      false
    );

    return request.pipe(
      map(res => res.data.Login.authtoken.token),
      switchMap(token => this.getAuthenticatedUserData(token))
    );
  }

  public signup(userData: UserData) {
    const request = this.graph.graphqlRequest<'Signup', LoginMutationData>(
      signupApplicationMutation,
      userData
    );

    return request.pipe(
      map(res => res.data.Signup.authtoken.token),
      switchMap(token => this.getAuthenticatedUserData(token))
    );
  }

  public socialSignup(
    gqlObjectName: 'GoogleSignup' | 'FacebookSignup',
    variables: any
  ) {
    const queryByGQLObjectName = {
      GoogleSignup: signupGoogleMutation,
      FacebookSignup: signupFacebookMutation,
    };

    const query = queryByGQLObjectName[gqlObjectName];

    const request = this.graph.graphqlRequest<typeof gqlObjectName, LoginMutationData>(
      query,
      variables
    );

    return request.pipe(
      map(res => res.data[gqlObjectName].authtoken.token),
      switchMap(token => this.getAuthenticatedUserData(token))
    );
  }

  public addSocialFacebookLogin(payload: { accesstoken: string; email: string; }) {
    return this.graph.graphqlRequest(addSocialFacebookLoginMutation, payload);
  }

  public recoverResetPassword(email: string, options?: { newRecover: boolean }) {
    const query = (
      options?.newRecover
        ? resendCodeForgotPasswordMutation
        : forgotPasswordMutation
    );

    return this.graph.graphqlRequest<'ForgotPasswordMutation', any>(query, { email }, {}, true);
  }

  public verifyCodeRecoverPassword(email: string, code: string) {
    const payload = { email, code };

    const request = (
      this.graph
        .graphqlRequest<'VerifyCodeEmailForgotPassword', boolean>(
          verifyCodeRecoverPasswordMutation,
          payload,
        )
    );

    return request;
  }

  public updatePassword(email: string, code: string, password: string) {
    const payload = { email, code, password };

    const request = this.graph.graphqlRequest<'UpdateUserPassword', boolean>(
      updatePasswordMutation,
      payload,
    );

    return request.pipe(map(res => res.data.UpdateUserPassword));
  }

  public getGenderData() {
    const request = this.graph
      .graphqlRequestAll<'UserGender', UserGendersQueryData[]>(userGendersQuery);

    return request.pipe(map(res => res.data.UserGender.all.items));
  }

  public setAcceptedLanguage(lang: string) {
    this.storage.setItem(this.LANGUAGE_KEY, lang, true);
  }

  private getDefaultLanguage() {
    const browserLanguage = navigator.language;
    if(browserLanguage.startsWith('pt') ) {
      return AppLanguage.PT_BR
    }

    return AppLanguage.EN_US
  }
}