import { Injectable } from '@angular/core';

import { UHQLApiService } from '@frontend/unhideschool/app/api-gateway/services/uhql-api.service';
import { UHQLRequestFilter, UHQLRequestSchema } from '@frontend/unhideschool/core/models/base-uh-crude.model';

// interface MembershipSubscriptonItem {
//     user: { uid: number };
//     subscriptionplan: {
//         rootplan: {
//             name: string;
//             internalname: string;
//             additionalinfo: string;
//         };
//     };
// }

// interface MembershipOrderItem {
//     user: { uid: number };
//     subscriptionplan: {
//         rootplan: {
//             name: string;
//             internalname: string;
//             additionalinfo: string;
//         };
//     };
// }

// interface MembershipSubscriptionPayment {
//     expectedamount: number;
//     subscription: MembershipSubscriptonItem;
// }

// interface MembershipOrderPayment {
//     expectedamount: number;
//     order: MembershipOrderItem;
// }

// type MembershipSubscriptionPaymentState = MembershipSubscriptionPayment[];
// type MembershipOrderPaymentState = MembershipOrderPayment[];

// interface MembershipSubscriptionStoreModel {
//     validuntil: string;
//     expired: boolean;
//     subscriptionpayment: MembershipSubscriptionPaymentState;
//     orderpayment: MembershipOrderPaymentState;
// }

// const storeInitialState: MembershipSubscriptionStoreModel = {
//     validuntil: null,
//     expired: null,
//     orderpayment: [],
//     subscriptionpayment: []
// };

@Injectable({
    providedIn: 'root'
})
export class MembershipSubscriptionStore {
    constructor(private uhql: UHQLApiService) {}

    updateSubscriptionMemberState(subscriptionmemberid: number, subscriptionmemberstateid: number) {
        const schema: UHQLRequestSchema = { subscriptionmemberstateid };
        const filters: UHQLRequestFilter[] = [{ field: 'subscriptionmemberid', op: '==', value: subscriptionmemberid }];
        return this.uhql.uhqlRequest('UPDATE', 'SUBSCRIPTION_MEMBERS', schema, filters);
    }
}
