<a
  [routerLink]="redirectToProfile ? ['/perfil/'+ uid +'/' + username] : []"
  class="user-avatar"
  [title]="username"
>
  <div *ngIf="hasUnreadNotifs" class="count-button-badge"></div>
  <img
    [src]="_avatarUrl"
    (imgLoaded)="avatarLoaded.emit(true)"
  >
  <div
    *ngIf="userSubscribed"
    class="pro-badge"
  >
    <mat-icon svgIcon="unh_pro_menu_pink"></mat-icon>
  </div>
</a>
