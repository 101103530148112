<div class="dialog-wrapper">
  <!-- <div class="logo"></div> -->

  <div class="dialog-container">
    <div class="background-svg"></div>
    <div class="text-container">
      <span *ngIf="data.progress" class="">Downloading...</span>
      <pzl-progress-bar *ngIf="data.progress" [value]="loadingProgress"></pzl-progress-bar>
      <unh-spinner-loader  *ngIf="!data.progress" >
      </unh-spinner-loader>
    </div>
  </div>

</div>
