import { Injectable } from '@angular/core';
import { HttpClient, HttpContext, HttpContextToken, HttpEvent, HttpEventType, HttpHeaders } from '@angular/common/http';

import { Observable, of, throwError } from 'rxjs';
import { catchError, map, publishLast, refCount, share, shareReplay, switchMap } from 'rxjs/operators';

import { environment } from '@frontend/unhideschool/env';

import { ApiResponse, CustomHttpOptions, HttpMethod, UploadEvent } from '../models/base-api.model';
import { handleApiErrors } from '../../core/models/base-api.model';

export const AUTH_REQUEST = new HttpContextToken<boolean>(() => true);
export const IS_PUBLIC_API = new HttpContextToken<boolean>(() => true);

function markAsAuthRequest() {
  return new HttpContext().set(AUTH_REQUEST, true);
}


@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  private readonly httpMethodTypes = {
    [HttpMethod.GET]: (url, options: object) => this.http.get(url, options),
    [HttpMethod.POST]: (url, options: object, data) => this.http.post(url, data, options),
    [HttpMethod.PUT]: (url, options: object, data) => this.http.put(url, data, options)
  };

  constructor(private http: HttpClient) { }

  private getFullApiPath(endpoint: string): string {
    return `${environment.apipath}${endpoint}`;
  }

  private handleError(err: any, handleErrors: boolean) {
    if (!handleErrors) {
      // Return an observable with a user-facing error message.
      return of('custom error handler', err);
    }

    if (err.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      return throwError(() => 'An error occurred:' + err.error.message);
    }

    if (Array.isArray(err)) {
      return throwError(() => (err as { message: string }[])[0].message);
    }

    // Return an observable with a user-facing error message.
    return throwError(() => ({
      message: 'Something bad happened; please try again later.',
      error: err
    }));
  }

  private handleSuccessRequest<T>(response: ApiResponse<T>, options: CustomHttpOptions) {
    if (options?.fullUrl) {
      // This means an external url is being used and we need to create
      // a custom "ApiResponse" object in order to be able to handle it.
      return {
        dict: response,
        reason: 'External API successfully request.',
        success: true
      } as ApiResponse<T>;
    }

    return response;
  }

  private httpRequest<T>(
    type: HttpMethod,
    endpoint: string,
    data?: any,
    options: CustomHttpOptions = {},
    handleErrors = true,
    auth = true
  ): Observable<ApiResponse<T>> {
    const { fullUrl, ...customOptions } = options;
    const url = fullUrl || this.getFullApiPath(endpoint);
    let reqOptions: CustomHttpOptions = customOptions ? {
      ...customOptions,
      headers: options.headers,
    } : {};
    if (auth) {
      reqOptions = {
        headers: { 'Authorization-Required': 'true' }  as any
      }
    }
    return this.httpMethodTypes[type](url, reqOptions, data).pipe(
      switchMap(res => handleErrors ? handleApiErrors(res) : of(res)),
      map(res => this.handleSuccessRequest<T>(res, options)),
      catchError(err => this.handleError(err, handleErrors)),
      publishLast(),
      refCount()
    );
  }

  private mapUploadEvent<T>(event: HttpEvent<T>, filekey): UploadEvent<T> {
    switch (event.type) {
      case HttpEventType.UploadProgress: {
        const progress = Math.round(100 * event.loaded / event.total);
        return { status: 'progress', message: progress, success: true, filekey };
      }
      case HttpEventType.Response:
        return { status: 'complete', message: event.body, success: true, filekey };

      default:
        return { status: 'unknown', message: event, filekey };
    }
  }

  public upload<T>(
    endpoint: string,
    data?: any,
    options: CustomHttpOptions = {},
    filekey = '',
    auth = true
  ): Observable<UploadEvent<T>> {
    const { fullUrl, ...customOptions } = options;
    const url = fullUrl || this.getFullApiPath(endpoint);
    let reqOptions: CustomHttpOptions = customOptions ? {
      ...customOptions,
      headers: customOptions?.headers ? customOptions?.headers : new HttpHeaders(),
      reportProgress: true,
      responseType: customOptions.responseType ??  'json',
      observe: 'events'
    } : {};
    if (auth) {
      reqOptions = {
        ...reqOptions,
        headers: {
          'Authorization-Required': 'true',
        } as any
      }
    }
    return this.http.post<T>(url, data, reqOptions as any).pipe(
      map(event => ({ ...this.mapUploadEvent(event, filekey) })),
      catchError(error => this.handleError(error, true))
    );
  }

  public get<T>(
    endpoint: string,
    options?: CustomHttpOptions,
    handleErrors?: boolean
  ): Observable<ApiResponse<T>> {
    return this.httpRequest<T>(HttpMethod.GET, endpoint, null, options, handleErrors);
  }

  public post<T>(
    endpoint: string,
    data: any,
    options?: CustomHttpOptions,
    handleErrors?: boolean,
    auth = true
  ): Observable<ApiResponse<T>> {
    return this.httpRequest<T>(HttpMethod.POST, endpoint, data, options, handleErrors, auth);
  }

  public put<T>(
    endpoint: string,
    data: any,
    options?: CustomHttpOptions,
    handleErrors?: boolean
  ): Observable<ApiResponse<T>> {
    return this.httpRequest<T>(HttpMethod.PUT, endpoint, data, options, handleErrors);
  }

}
