export interface LoginMutationData {
  authtoken: {
    token: string;
  }
}

export const loginMutation = `
  mutation LoginMutation($login: String!, $password: String!) {
    Login(login: $login, password: $password) {
      authtoken {
        token
      }
    }
  }
`;
