import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { RestApiService } from '@frontend/unhideschool/app/api-gateway/services/rest-api.service';

import { SimpleSubscriptionPlans } from '@frontend/unhideschool/core/models/subscription-plan.model';
import { OriginData } from '@frontend/unhideschool/shared/models/origin-data.model';

import { subscriptionPlans } from '@frontend/unhideschool/shared/models/mocks/subscription-plans.mock';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class CheckoutService {
  constructor(
    private rest: RestApiService,
    private auths: AuthService,
    private luss: LoggedUserStateService
  ) { }

  checkoutSubscriptionByType(subsplantype: keyof typeof SimpleSubscriptionPlans) {
    const { subsplanid, subsplanpriceid } = subscriptionPlans[subsplantype];
    return this.checkoutSubscription(subsplanid, subsplanpriceid);
  }

  checkoutSubscription(subsplanid: number, subsplanpriceid: number, qty = 1, couponcode?): Observable<OriginData> {
    const url = `/subscription/newsubscriptionfromrootplan/${subsplanid}/${subsplanpriceid}`;
    const isLogged = this.luss?.isLogged;
    const token = this.auths?.authData?.token;
    const loggedUser$ = isLogged ? of(token) : this.auths.tempUserLogin();
    const obs = loggedUser$.pipe(
      tap((authoken) => this.auths.getAuthenticatedUserData(authoken)),
      switchMap(() => this.rest.get(url)),
      switchMap((res: any) => {
        const subscriptionid = res.dict.subscription.subscriptionid;
        if (qty > 1) {
          return this.rest.get(`/subscription/updatesubscriptionquantity/${subscriptionid}/${qty}`)
        }
        return of(res)
      }),
      map((res: any) => this.createSubscriptionOriginData(res, couponcode)),
      tap(() => this.logoutTempUser())
    )
    return obs;
  }

  orderToOriginData(orderid: number, couponcode?: string) {
    const origin = 'unhideschool:subscriptionbundle';
    const token = this.auths.authData.token;
    const hasCouponcode = couponcode != undefined && couponcode != '';
    const originData = new OriginData({ origin, orderid, token, couponcode: hasCouponcode ? couponcode : null });
    return originData;
  }

  checkoutOrder(productid: number, productpriceid: number, couponcode?: string) {
    const isLogged = this.luss?.isLogged;
    const token = this.auths?.authData?.token;
    const loggedUser$ = isLogged ? of(token) : this.auths.tempUserLogin();

    return loggedUser$.pipe(
      tap(authtoken => this.auths.getAuthenticatedUserData(authtoken)),
      switchMap(() => this.createNewOrder()),
      switchMap((res: any) => {
        const order = res.dict.order;
        return this.addProductToOrder(order.orderid, productid, productpriceid).pipe(
          map(() => this.orderToOriginData(order.orderid, couponcode))
        );
      }),
      tap(() => this.logoutTempUser())
    );
  }

  getOrderById(orderid: number) {
    const endpoint = `/order/getorderbyid/${orderid}`;
    return this.rest.get(endpoint);
  }

  createNewOrder() {
    const endpoint = '/order/neworder';
    return this.rest.get(endpoint);
  }

  addProductToOrder(orderid: number, productid: number, productpriceid: number) {
    const endpoint = `/order/addproducttoorder/${orderid}/${productid}/${productpriceid}`;
    return this.rest.get(endpoint);
  }

  private createSubscriptionOriginData(res, couponcode) {
    const subscriptionid = res.dict.subscription.subscriptionid;
    const token = this.auths.authData.token;
    const originData = new OriginData({
      origin: 'unhideschool:membership',
      subscriptionid,
      token,
      couponcode
    });
    return originData;
  }

  private logoutTempUser() {
    const { alias } = this.luss.user;
    if (alias.includes('temp_user')) {
      this.auths.logout();
    }
  }
}
