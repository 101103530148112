<unh-step-card
  class="unh-change-password-container"
  [step]="0"
  [maxWidth]="512"
>
  <div slot="content">
    <form
      #changeEmailForm="ngForm"
      [formGroup]="changeEmailFormGroup"
      class="step"
    >
      <h1 class="title">{{pageTranslations?.h1ChangeEmailTitle}}</h1>

      <span>{{pageTranslations?.insertNewEmailspanText}}</span>

      <unh-input
        class="first"
        placeholder={{pageTranslations?.newEmailinputPlaceholder}}
        unhInpuAcessor
        formControlName="email"
      ></unh-input>

      <unh-input
        unhInpuAcessor
        formControlName="confirmEmail"
        placeholder={{pageTranslations?.confirmEmailInputPlaceholder}}
      ></unh-input>

      <div class="validations">
        <unh-validation-text
          [variant]="state.emailExists !== null && !state.emailExists ? 'completed' : 'wrong'"
        >
          {{
            state.emailExists !== null && !state.emailExists 
              ? pageTranslations?.validationsTexts?.validationEmailAvailableText
              : pageTranslations?.validationsTexts?.validationEmailUnavailableText
          }}
        </unh-validation-text>

        <unh-validation-text
          [variant]="state.matchEmail ? 'completed' : 'wrong'"
        >
          {{pageTranslations?.validationsTexts?.validationMatchEmailText}}
        </unh-validation-text>
      </div>

      <unh-button
        (click)="onSubmit()"
        
        [disabled]="!isValidSubmission"
        class="final"
        color="gradient"
        block
      >
        {{pageTranslations?.confirmButtonText}}
      </unh-button>
    </form>
  </div>
</unh-step-card>
