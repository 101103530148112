import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, } from '@angular/core';
import { FormControl } from '@angular/forms';
import { GenericGalleryAdminToolbar } from '@frontend/unhideschool/app/utils/i18n/locale/unh-gallery/unh-gallery.i18n.model';


@Component({
  selector: 'unh-generic-gallery-admin-toolbar',
  templateUrl: './generic-gallery-admin-toolbar.component.html',
  styleUrls: ['./generic-gallery-admin-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericGalleryAdminToolbarComponent implements OnInit {
  selectedDisplayOption = new FormControl();
  @Input() isProject?= false;
  @Input() editMode = false;
  dragStarted = false;

  _translations: any;

  @Input() set translations(value) {
    this._translations = value;
    this.displayOptions = [
      { value: 'all', viewValue: value?.listDisplayOptions?.displayOptions?.optionsItemAll ?? 'all'},
      { value: 'published', viewValue: value?.listDisplayOptions?.displayOptions?.optionsItemPublished ?? 'published'},
      { value: 'unpublished', viewValue: value?.listDisplayOptions?.displayOptions?.optionsItemUnpublished ?? 'unpublished'},
      { value: 'archived', viewValue: value?.listDisplayOptions?.displayOptions?.optionsItemArchived?? 'archived' },
    ];
  };

  get translations() {
    return this._translations;
  }

  displayOptions = [
    { value: 'all', viewValue: 'Todos' },
    { value: 'published', viewValue: 'Publicados' },
    { value: 'unpublished', viewValue: 'Não publicados' },
    { value: 'archived', viewValue: 'Arquivados' },
  ];

  @Output() editModeChanged = new EventEmitter<{ editModeEnabled: boolean }>();
  @Output() listFilterChanged = new EventEmitter<{ listFilter: string }>();

  @Input()
  set listFilter(listFilter: 'all') {
    const selectedFilter = this.displayOptions.find(dopt => dopt.value === listFilter);
    this.selectedDisplayOption.setValue(selectedFilter);
  }
  ngOnInit(): void {

  }

  compareObjectsByValueKey(o1: any, o2: any): boolean {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  }

  onEditModeChanged(evt: Event) {
    const { detail: isopen } = (evt as CustomEvent);
    this.editMode = isopen;
    this.editModeChanged.emit({ editModeEnabled: isopen });    
    this.dragStarted = isopen;
  }

  onListFilterChange() {
    const selectedDisplayOption = this.selectedDisplayOption.value;
    this.listFilterChanged.emit({ listFilter: selectedDisplayOption.value });
  }
}
