export const postsQuery = `
    query FilteredPosts(
        $page: Int!,
        $perpage: Int!,
        $filters: [PostQueryParams]!,
        $orderby: PostOrderByParams
    ) {
        Posts(
            page: $page,
            perpage: $perpage,
            filters: $filters,
            orderBy: $orderby
        ){
            creatoruid
            title
            headline
            postid
            datecreated
            description
            fulldescription
            postviewallhead
            postthumbnail
            postmobilecover
            tags {
                name
                tagtype {
                    internalname
                }
            }
            traits {
                internalname
            },
            contenttime,
            creator {
                fullname,
                shortbio,
                username,
                profilepicture,
                occupationtitle,
                uid,
                alias

      relatedmedia{
        render

        imagesettings {
          imagesettingid
          width
          height
          waspectratio
          haspectratio
          xcenter
          ycenter
        }

        mediacontenttype {
          internalname
        }
      }
            },
            datepublished
        }
    }
`;
