export const getCampaignQuery = `
query FetchCampaign {
  Campaign {
    all(active: true) {
      items {
        id
        campaignName
        discountCouponId
        discountCoupon {
          active
          name
          couponcode
          value
          validuntil
          cycles
          datecreated
          discountcoupontype {
            internalname
          }
        }
      }
    }
  }
}
`;
