import { UHQLRequestSchema } from '@frontend/unhideschool/core/models/base-uh-crude.model';

export const unreadNotificationsSchema: UHQLRequestSchema = {
    notificationid: '',
    read: '',
    notifcateg: {
        internalname: ''
    }
 

};

export const userNotificationsSchema: UHQLRequestSchema = {
    notificationid: '',
    notifcategid: '',
    data: '',
    read: '',
    title: '',
    datecreated: '',
    notifcateg: {
        internalname: ''
    }
};

export const readNotificationSchema = { read: true, seen: true };
