export const updateImageSettingMutation = `
    mutation(
        $imagesettingid: ID!,
        $width: Int,
        $height: Int,
        $waspectratio: Int,
        $haspectratio: Int,
        $xcenter: Int,
        $ycenter: Int
    ) {
        updateImagesetting(imagesettingData: {
            imagesettingid: $imagesettingid,
            width: $width,
            height: $height,
            waspectratio: $waspectratio,
            haspectratio: $haspectratio,
            xcenter: $xcenter,
            ycenter: $ycenter,
        }) {
            ImageSetting {
                imagesettingid
            }
        }
    }
`;
