import { ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';

export const containSpecialCharacter = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const expression = new RegExp(/[!#$%&()*+,\-\.\/:;<=>?\\@\[\]\^_{\|}~]+/, 'g');
    const forbidden = expression.test(control.value);
    return forbidden ?  { containSpecialCharacter: true } : null ;
  };
};

export const containNumber = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const expression = new RegExp(/[\d]+/, 'g');

    const forbidden = expression.test(control.value);

    return !forbidden ? { containNumber: true } : null;
  };
};

export const containUppercaseLetter = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    const expression = new RegExp(/(?=.*[A-Z])/, 'g');

    const forbidden = expression.test(control.value);

    return !forbidden ? { containUppercaseLetter: true } : null;
  };
};

export const isChecked = (): ValidatorFn => {
  return (control: AbstractControl): ValidationErrors | null => {
    return !control.value ? { isChecked: true } : null;
  };
};


export const executeValidationText = (requiredError: boolean, validationError: boolean) => {
  return !requiredError
    &&
    !validationError
    ? 'completed'
    : 'wrong'
}

export const validateEmailAlreadyExists = (emailAlreadyExists: string) => {
  return (control: AbstractControl) => {
    if (emailAlreadyExists && control.value === emailAlreadyExists) {
      return { exists: true };
    }

    return null;
  }
}
