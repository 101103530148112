import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'unh-menu-toggle',
  styleUrls: ['./menu-toggle.component.scss'],
  template: `
    <button class="menu-toggle"
      [ngClass]="'menu-' + layout"
      (click)="toggleMobile($event)"
    >
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </button>
  `
})
export class MenuToggleComponent implements OnInit {

  @Input() layout: string;
  @Output() clickHandler = new EventEmitter();

  constructor() { }

  ngOnInit() {}

  toggleMobile(evt) {
    this.clickHandler.emit(evt);
  }

}
