import {
  Component,
  Input,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';
import { getProfilePicture } from '@frontend/unhideschool/app/helpers/relatedmedia-and-crop.helper';
import { CreatorType } from '@frontend/unhideschool/graphql-schema';
import { ProjectPost } from '@frontend/unhideschool/shared/models/project/project-post.model';

@Component({
  selector: 'unh-project-item',
  templateUrl: './project-item.component.html',
  styleUrls: ['./project-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectItemComponent {
  @Output() itemClicked = new EventEmitter();
  @Input() project: ProjectPost;

  creatorAvatar;

  ngOnInit(): void {
    this.creatorAvatar = getProfilePicture(
      this.project.creator.profilepicture,
      this.project?.creator.relatedmedia
    );
  }
}
