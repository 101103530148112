import { UHQLRequestSchemaObject } from '@frontend/unhideschool/core/models/base-uh-crude.model';

export const userSearchSchema: UHQLRequestSchemaObject = {
    fullname: '',
    email: '',
    uid: '',
    profilepicture: '',
    relatedmedia: {
        profilepicture: ''
    }
};
