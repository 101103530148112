export const orderedPostPartsQuery = `
    query FilteredOrderedPostParts(
        $filters: [OrderedPostPartQueryParams]!,
        $orderby: OrderedPostPartOrderByParams
    ) {
        OrderedPostParts(
            filters: $filters,
            orderBy: $orderby,
            perpage: 100
        ){
            postid,
            orderedpostpartid,
            groupname,
            position,
            postpartid,
            postpart {
                public,
                postparttypeid,
                postpartthumbnail,
                commentsquantity,
                content {
                    contenttime
                    render
                },
                subtitle,
                description,
                metadataobjs {
                    data,
                    type {
                        internalname
                    }
                },
                orderedpostparts {
                    postid
                    post {
                        community {
                            path
                            communityid
                            category {
                                communitycategid,
                                name
                            }
                        }
                    }
                }
            }
        }
    }
`;
