export default (name, email) => {
  const tempUserKey = 'temp_user'
  // Intercom Setup
  const app_id = 'p5vn00iy';
  const intercomSettingsDefault = {
    app_id,
    custom_launcher_selector: '#intercom-custom-launcher',
    hide_default_launcher: true
  }
  window.intercomSettings = intercomSettingsDefault;

  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/p5vn00iy'
  (function () {
    var w = window;
    var ic = w.Intercom;

    // Check if User is logged and send user data and if isn't temporary user account
    if (name && email && !name.includes(tempUserKey) && !email.includes(tempUserKey)) {
      window.intercomSettings = {
        ...window.intercomSettings,
        name,
        email,
        created_at: Math.round(new Date().getTime() / 1000) // Current Unix Timestamp
      };
    }

    if (typeof ic === 'function') {
      ic('reattach_activator');
      ic('update', w.intercomSettings);
    } else {
      var d = document;
      var i = function () {
        i.c(arguments);
      };

      i.q = [];
      i.c = function (args) {
        i.q.push(args);
      };
      w.Intercom = i;
      w.Intercom('shutdown')
      // set intercomSettings initial state
      window.intercomSettings = intercomSettingsDefault;
      // reset cookies
      document.cookie = `${'intercom-session-p5vn00iy='}`;
      document.cookie = `${'intercom-device-id-p5vn00iy='}`;
      document.cookie = `${'intercom-id-p5vn00iy='}`;

      w.Intercom('boot', window.intercomSettings);
      var l = function () {
        var s = d.createElement('script');
        s.type = 'text/javascript';
        s.async = true;
        s.src = 'https://widget.intercom.io/widget/p5vn00iy%27';
        var x = d.getElementsByTagName('script')[0];
        x.parentNode.insertBefore(s, x);
      };

      if (w.attachEvent) {
        w.attachEvent('onload', l);
      } else {
        w.addEventListener('load', l, false);
      }
    }
  })();
};
