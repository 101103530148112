import { safeJsonParse, slugify } from '@frontend/unhideschool/app/helpers/utility-functions';
import { OrderedPostPart } from '@frontend/unhideschool/shared/models/dtos/ordered-post-part.model';

export interface HomePost {
    postid: number;
    position: number;
    description: string;
    datepublished: string;
    dateupdated: string;
    postthumbnail: string;

    creator: {
        fullname: string;
        profilepicture: string;
    };

    tags: {
        tagid: number;
        name: string;
    };

    traits: {
        name: string;
        internalname: string;
    };

    product: {
        productid: number;

        prices: {
            productpriceid: number;
            price: number;
        }
    };
}

export interface RecommendedLesson {
    orderedpostpartid: number;
    postid: number;
    title: string;
    renderedcontent: string;
    creator: string;
    description: string;
    postpartid: number;
    thumbnail: string;
    subtitle: string;
}

export class LessonSuggestion {
    title: string;
    thumbnail: string;
    instructor: string;
    link: string;
    totaltime: string;

    constructor(rcl: RecommendedLesson) {
      const postid = rcl.postid;
      const orderedpostpartid = rcl.orderedpostpartid;
      const posttitleslug = slugify(rcl.title);
      const postparttitleslug = slugify(rcl.subtitle);
      const [_, postparttotaltime] = safeJsonParse(rcl.renderedcontent);

      this.title = rcl.subtitle;
      this.thumbnail = rcl.thumbnail;
      this.instructor = rcl.creator;
      this.link = `/post/${postid}/${orderedpostpartid}/${posttitleslug}/${postparttitleslug}`;
      this.totaltime = postparttotaltime;
    }
  }

export interface LessonPostpartsStateModel {
    suggestions: RecommendedLesson[];
    per_page: number;
    page: number;
}

export const defaultLessonPostpartsState: LessonPostpartsStateModel = {
    suggestions: [],
    per_page: 10,
    page: 1
};
