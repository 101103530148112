export const  GetVdoCipherCredentialsMutation = `
mutation ($title: String!,$contenttime: Int!) {
  UploadCredentialsVdocipher(title: $title,contenttime:$contenttime) {
      credentials {
          videoId
          clientPayload {
              key
              policy
              xAmzSignature
              xAmzAlgorithm
              xAmzDate
              xAmzCredential
              uploadLink
          }
      }
      mediaitem{
        mediaitemid
        content
        mediaadapter{
          internalname
        }
      }
  }
}`
