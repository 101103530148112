import { Directive, ElementRef, Renderer2, Output, Input, EventEmitter, HostListener, OnInit, OnDestroy } from '@angular/core';
import { fromEvent, Observable, Observer, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { debounce } from './clickedout.directive';

@Directive({
  selector: '[unhObserveOffsetBottom]'
})
export class ObserveOffsetBottomDirective implements OnInit, OnDestroy {
  private destroyScrolListener: () => void;
  private destroyResizeListener: () => void;

  @Input() takeOne = false;
  @Input() enabled = false;
  @Input() extraOffset = 0;
  @Output() willEnterViewport = new EventEmitter<Event>();
  observable: Subject<Event>;
  constructor(private elRef: ElementRef, private r2: Renderer2) {
    this.observable = new Subject<Event>();
    this.observable.pipe(
      debounceTime(30)
    ).subscribe(evt => {
      this.willEnterViewport.emit(evt);
    });
  }

  ngOnInit() {
    const wrapperContent: Element = document.querySelector('.wrapper-content');
    this.destroyScrolListener = this.r2.listen(wrapperContent, 'scroll', evt => this.onScroll(evt));
    this.destroyResizeListener = this.r2.listen(wrapperContent, 'resize', evt => this.onScroll(evt));

  }

  onScroll(evt: Event) {
    if (this.enabled) {
      const wHeight = window.innerHeight;
      const hostEl: HTMLElement = this.elRef.nativeElement;
      const distance = (hostEl.getBoundingClientRect().bottom - wHeight) - this.extraOffset;

      if (distance <= 0) {
        this.observable.next(evt);

        if (this.takeOne) {
          this.ngOnDestroy();
        }
      }
    }
  }

  ngOnDestroy() {
    if (this.destroyScrolListener != null) {
      this.destroyScrolListener();
    }

    if (this.destroyResizeListener != null) {
      this.destroyResizeListener();
    }
  }
}
