<div 
  #sideMenu
  class="side-menu-wrapper"
  [class.opened]="isOpen"
  [class.side-menu-right]="position === 'right'"
  [class.with-warning]="withWarning"
>
  <ng-content select="unh-side-menu-header"></ng-content>
  <ng-content></ng-content>
  <ng-content select="unh-side-menu-footer"></ng-content>
</div>
