import { GalleryItemId, GalleryItem, ImageSettings, Gallery, PreviewData } from '@frontend/unhideschool/shared/models/dtos/gallery.model';
import { SimpleGallery } from './simple-gallery.model';

export interface SimpleGalleryItemModel {
    active?:boolean
    galleryActive:boolean;
    galleryPublished:boolean;
    galleryid: number;
    galleryitemid: GalleryItemId;
    image: string;
    thumbnail: string;
    imagesettings: ImageSettings;
    creator: SimpleGalleryItemCreatorModel;
    isCover: boolean;
       preview?:boolean;
    previewdata?:PreviewData
}

export interface SimpleGalleryItemCreatorModel {
    fullname: string;
    occupation: string;
    avatar: string;
    username: string;
    alias: string
    uid?: string
}

export class SimpleGalleryItem implements SimpleGalleryItemModel {
    galleryActive:boolean;
    galleryPublished:boolean;
    galleryid: number;
    galleryitemid: GalleryItemId;
    image: string;
    thumbnail: string;
    imagesettings: ImageSettings;
    creator: SimpleGalleryItemCreatorModel;
    isCover: boolean;
    preview?:boolean;
    previewdata?:PreviewData
    active?: boolean
    galleryTopicData?: any;
    badges?: any


    constructor(gallery: SimpleGallery, {active, galleryitemid, imagesettings, mediaitem }: GalleryItem) {
        this.galleryActive = gallery.active;
        this.galleryPublished = gallery.published;
        this.galleryid = gallery.galleryid;
        this.galleryitemid = galleryitemid;
        this.imagesettings = imagesettings;
        this.image = mediaitem.render;
        this.creator = {
            avatar: mediaitem.creator.profilepicture,
            fullname: mediaitem.creator.fullname,
            username: mediaitem.creator.username,
            uid: mediaitem.creator.uid,
            alias: mediaitem.creator.alias,
            occupation: (
                mediaitem.creator?.occupationtitle != null && mediaitem.creator?.occupationtitle !== ''
                    ? mediaitem.creator?.occupationtitle
                    : mediaitem.creator?.occupation != null
                        ? mediaitem.creator.occupation.name
                        : 'Artista'
            )
        };
        this.preview = gallery.preview
        this.previewdata = gallery.previewdata
        this.galleryTopicData = gallery.gallerytopicdata;
        if (this.galleryTopicData) {
        this.badges = gallery.gallerytopicdata.badges;

        }
        if(this.preview){
            this.thumbnail = mediaitem.render
        }else{
            this.thumbnail = this.getImageRenderWithOptions(mediaitem, imagesettings);
        }
        this.active = active
    }

    private getImageRenderWithOptions(mediaitem, imagesettings: ImageSettings) {
        const ch = imagesettings.height;
        const cw = imagesettings.width;
        const cx = imagesettings.xcenter;
        const cy = imagesettings.ycenter;
        const options = `?tr=w-860:w-${cw},h-${ch},cm-extract,x-${cx},y-${cy},f-jpg,pr-true,q-80`;
        return mediaitem.render + options;
    }
}
