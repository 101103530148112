import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private auth: AuthService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.auth.authData?.token;
    const isAuthReq = req.headers.has('Authorization-Required');
    const shouldAddAuthorizationHeader = isAuthReq && token && !req.headers.get('Authorization');
    if (shouldAddAuthorizationHeader) {
      const _headers = req.headers.delete("authorization-required");
      const authReq = req.clone({
        headers: _headers.set('Authorization', `Unhide tok = ${token}`)
      });
      return next.handle(authReq).pipe(
        catchError(error => {
          // todo: identify if the error was because of an invalid token
          if (error.status === 401) {
            this.auth.logout();
          }
          throw error;
        })
      );
    }

    const _headers = req.headers.delete("authorization-required");
    return next.handle(req.clone({headers: _headers}));
  }
}
