import { Component, OnInit, Input, ChangeDetectorRef, Inject, Output, EventEmitter } from '@angular/core';
// import { SimpleGalleryItem } from '@frontend/unhideschool/modules/home/models/simple-gallery-item.model';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface CropDialogData {
  header: {
    title: string,
    subtitle: string,
    dimensions: string
  };
}
@Component({
  selector: 'unh-gallery-item-crop',
  templateUrl: './gallery-item-crop.dialog.html',
  styleUrls: ['./gallery-item-crop.dialog.scss']
})
// tslint:disable-next-line: component-class-suffix
// eslint-disable-next-line @angular-eslint/component-class-suffix
export class GalleryItemCropDialog implements OnInit {
  imageLoaded: boolean;
  imageFile: Observable<File>;
  croppedUrl: string;

  cropInfo: { x: number, y: number, height: number, width: number };
  aspectRatio: { x: number, y: number };
  @Input() aspectRatios: { x: number, y: number }[] = [
    { x: 1, y: 1  },
    { x: 1, y: 2  },
    { x: 2, y: 1  },
  ];
  @Input() header?: {
    title: string;
    subtitle: string,
    dimensions: string
  };
  @Input() imageUrl: string;

  @Output() cropInfoChanged = new EventEmitter();

  constructor(
    private http: HttpClient,
    private cdr: ChangeDetectorRef,
    private dialogRef: MatDialogRef<GalleryItemCropDialog>,
    @Inject(MAT_DIALOG_DATA) public data: CropDialogData
  ) {}

  ngOnInit() {
    // TODO: Criar uma configuracao global para esse valor
    this.aspectRatio = this.aspectRatios[0];
    const url = this.imageUrl + '?tr=w-860';
    this.croppedUrl = url;
    this.imageFile = this.http.get(url, { responseType: 'blob' }).pipe(
      map(blob => blob as File),
      take(1)
    );
    this.dialogRef.keydownEvents().subscribe(keyEvent => {
      if (keyEvent.code == 'Escape') {
        this.dialogRef.close(false);
      }
    })
  }

  setImageUrlWithCropOptions(cropInfo: { x, y, width, height }) {
    const url = this.imageUrl != null ? this.imageUrl : null;
    this.cropInfo = cropInfo;
    this.cropInfoChanged.emit({cropInfo, aspectRatio: this.aspectRatio});
    if (cropInfo != null) {
      const options = `?tr=w-${cropInfo.width},h-${cropInfo.height},cm-extract,x-${cropInfo.x},y-${cropInfo.y},f-jpg,pr-true,q-80`;
      this.croppedUrl = url + options;
    } else {
      this.croppedUrl = url;
    }

    this.cdr.detectChanges();
  }
  onCropImageLoaded(val) {
    this.imageLoaded = val;
  }
  closeDialog(data?: { cropInfo, aspectRatio, url?}) {
    if (data) {
      this.dialogRef.close(data);
    } else {
      this.dialogRef.close(false);

    }
  }
}
