import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { tap, map } from 'rxjs/operators';

import { FetchUser } from './user.actions';

import { UserStateModel, defaultUserStateModel } from './user.model';
import { UserQueryResponse, userQuery } from '@frontend/unhideschool/shared/models/graphql/user.query';
import { GraphqlApiService } from '../../api-gateway/services/graphql-api.service';

@State<UserStateModel>({
  name: 'user',
  defaults: defaultUserStateModel
})
@Injectable()
export class UserState {
  constructor(private graph: GraphqlApiService) { }

  @Action(FetchUser)
  fetchUser(
    ctx: StateContext<UserStateModel>
  ) {
    const query = userQuery;

    return this.graph.graphqlRequest<'me', UserQueryResponse>(query).pipe(
      map(res => res.data.me),
      tap(me => me && ctx.patchState({ 
        id: me.user.uid, 
        courses: me.courses.map(course => Number(course.postid)), 
        subscribed: me.subscribed
      }))
    );
  }
}
