import { environment } from '@frontend/unhideschool/env';

export class LocaleServiceController {
  locale: string;

  constructor() {
    const storageKey = environment.storagekeys.unhacceptlang;

    try {
      const locale = localStorage.getItem(storageKey);
      this.locale = locale;
    } catch (ex) {
      throw new Error(
        `${LocaleServiceController.name} trying to get ${storageKey} from localstorage, but found none.`
      );
    }
  }

  getComponentClosestLanguage(): string {
    return this.locale ? this.locale : 'pt-BR';
  }

  fetchLocaleStringsForComponent(componentName: string, locale: string): Promise<any> {
    return new Promise((resolve, reject): void => {
      fetch(`/i18n/locale/${componentName}/${componentName}.i18n.${locale.substring(0, 2)}.json`)
        .then((result) => {
          if (result.ok) resolve(result.json());
          else reject();
        }, () => reject());
    });
  }

  getLocaleComponentStrings(componentName) {
    return this.getAsyncTranslations(componentName);
  }

  getAsyncTranslations(componentName) {
    const componentLanguage = this.getComponentClosestLanguage();
    let strings;

    try {
      strings = this.fetchLocaleStringsForComponent(componentName, componentLanguage);
    } catch (e) {
      console.warn(`no locale for ${componentName} (${componentLanguage}) loading default locale en.`);
      strings = this.fetchLocaleStringsForComponent(componentName, 'en');
    }

    return new Promise((resolve) => resolve(strings));
  }
}

export const LocaleService = new LocaleServiceController();
