import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'unh-user-menu-item-button',
  styleUrls: ['./user-menu-item-button.component.scss'],
  template: `
    <a
      class="user-menu-item"
      (click)="onClickAction(itemAction)"
      (mouseenter)="iconIsActive = true"
      (mouseleave)="iconIsActive = false"
    >
      <unh-icon-container class="icon-container" [iconType]="itemIconType" [icon]="itemIcon" [active]="iconIsActive">
      </unh-icon-container>

      <div class="text-container">
        <span>{{ itemTitle }}</span>
        <small class="muted">{{ itemSubtitle }}</small>
      </div>
    </a>
  `
})
export class UserMenuItemButtonComponent {
  iconIsActive = false;

  @Input() itemTitle: string;
  @Input() itemSubtitle: string;
  @Input() itemAction: string;
  @Input() itemIcon: string;
  @Input() itemIconType: string;

  @Output() clickAction = new EventEmitter<string>();

  onClickAction(name: string) {
    this.clickAction.emit(name);
  }
}
