import { Component, Input, ContentChild, TemplateRef } from '@angular/core';

@Component({
  selector: 'unh-generic-list-container',
  styleUrls: ['./generic-list-container.component.scss'],
  template: `
    <ul>
      <ng-container *ngFor="let item of itensList">
        <li>
          <ng-container *ngTemplateOutlet="listItemTemplate; context: { $implicit: item }">
          </ng-container>
        </li>
      </ng-container>
    </ul>
  `
})
export class GenericListContainerComponent {
  @ContentChild(TemplateRef, { static: true }) listItemTemplate;
  @Input() itensList: any[];
}
