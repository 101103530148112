import { asNumber } from "./utility-functions";

export function getCroppedImageUrl(imageUrl: string, cropInfo = null, finalSize: number, imageSizeRef = 860) {
    if (cropInfo == null) {
        return [
            `${imageUrl}?tr=`,
            `w-${imageSizeRef}:`,
            `w-${finalSize},`,
            `h-${finalSize},`,
            `x-0,`,
            `y-0,`,
            `cm-auto`,
        ].join('');
    }

    // const antes = {
    //     imageUrl,
    //     finalSize,
    //     imageSizeRef,
    //     cropInfo
    // };

    const ratio = finalSize / asNumber(cropInfo.width);
    const imageFullSize = imageSizeRef * ratio;
    const _cropInfo = {
        width: asNumber(cropInfo.width) * ratio,
        height: asNumber(cropInfo.height) * ratio,
        xcenter: asNumber(cropInfo.xcenter) * ratio,
        ycenter: asNumber(cropInfo.ycenter) * ratio
    };

    // const depois = {
    //     ratio,
    //     imageFullSize,
    //     _cropInfo
    // };

    // const all = {
    //     antes, depois
    // };

    return [
        `${imageUrl}?tr=`,
        `w-${imageFullSize}:`,
        `w-${_cropInfo.width},`,
        `h-${_cropInfo.height},`,
        `x-${_cropInfo.xcenter},`,
        `y-${_cropInfo.ycenter},`,
        `cm-extract`,
    ].join('');
}

export function cropProfilePicture(this: any, user) {
    try {
        const mediaprofile = user.relatedmedia.find(rm => rm && rm.mediacontenttype && rm.imagesettings ? rm.mediacontenttype.internalname == 'profilepicture' : null);
        if (mediaprofile) {
            const imagesettings = mediaprofile.imagesettings;
            return getCroppedImageUrl(user.profilepicture, imagesettings, 150, 860);
        } else {
            return user.profilepicture;
        }
    } catch {
        this.args.userMenu.user.avatar = user.profilepicture;
    }
}

export function getPostThumbnailUrl(post: any, size: number) {
    const relatedMedia = post.relatedmedia
        .find(rm => rm?.mediacontenttype?.internalname === 'postthumbnail');

    if (relatedMedia) {
        const imagesettings = relatedMedia !== undefined
            ? relatedMedia.imagesettings
            : null;
        return getCroppedImageUrl(post.postthumbnail, imagesettings, size);
    }

    return false;
}

export const getProfilepictureImageCropSettings = (relatedmedia) => {
    if (Array.isArray(relatedmedia)) {
        return relatedmedia ? relatedmedia.find(rm => rm && rm.mediacontenttype && rm.imagesettings ? rm.mediacontenttype.internalname == 'profilepicture' : null) : null;
    } else {
        return relatedmedia['profilepicture'] ? relatedmedia['profilepicture'] : null;
    }
}

export function getProfilePicture(url = '', relatedmedia = null, finalSize = 90, sizeRef = 860) {
    if (relatedmedia) {
        const related = getProfilepictureImageCropSettings(relatedmedia);

        if (related) {
            const imagesettings = related.imagesettings;

            if (imagesettings) {
                return url.includes('cm-extract') ? url : getCroppedImageUrl(url, imagesettings, finalSize, sizeRef);
            }
        }
    }

    return url;
}
