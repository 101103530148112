import { Gallery } from '@frontend/unhideschool/shared/models/dtos/gallery.model';
import { GraphqlFilterItem } from '@frontend/unhideschool/shared/models/graphql/base-graphql';

export const defaultGalleryFilters: { name: string, value: any }[] = [
    { name: 'home', value: 1 },
    { name: 'published', value: true },
    { name: 'active', value: true }
];

export function generateFilters(state_filters = []): any[] {
    return state_filters.map(filter => {
        switch (filter.name) {
            case 'home': return { rootgalleryid: { op: 'EQ', v: 1 } };
            case 'project': return { galleryid: { op: 'EQ', v: filter.value } };
            case 'active': return { active: { op: 'EQ', v: filter.value } };
            case 'published': return { published: { op: 'EQ', v: filter.value } };
            default: throw new Error(`Filter not implemented. (reading ${filter.name})`);
        }
    });
}

export interface ProjectGalleriesModel {
    name: any;
}

export interface GalleriesStateModel {
    Galleries: Gallery[];
    ProjectGalleries: any;
    TopicGalleries: any;
    GalleryView: {
        galleryid?: number;
        galleryitemid?: string;
        isproject?: boolean;
    };
    galleriesLimit: {
        galleries: boolean,
        project: boolean,
        topic: boolean
    }
}

export const defaultHomeGalleryFilters: GraphqlFilterItem[] = [
    { rootgalleryid: { op: 'EQ', v: 1 } },
    { active: { op: 'EQ', v: true } },
    { published: { op: 'EQ', v: true } }
];
