import { createSelector } from '@ngxs/store';
import { GalleriesStateModel } from './galleries.model';
import { GalleriesState } from './galleries.state';

export class GalleriesSelectors {
    static getRootGalleries() {
        return createSelector(
            [GalleriesState],
            (state: GalleriesStateModel) => {
                const Galleries = state.Galleries.sort((a, b) => {
                    const dateA:any = new Date(a.datecreated);
                    const dateB:any = new Date(b.datecreated);
                    return (dateB - dateA);
                })
                return Galleries;
            }
        );
    }
    
    static getTopicGalleries(galleryTypeName, galleryid?) {
        return createSelector(
            [GalleriesState],
            (state: GalleriesStateModel) => {
                return state[galleryTypeName][galleryid];
            }
        );
    }

    static getProjectGalleries(galleryid?) {
        return createSelector(
            [GalleriesState],
            (state: GalleriesStateModel) => {
                return state.ProjectGalleries[galleryid];
            }
        );
    }

    static getGalleryView() {
        return createSelector(
            [GalleriesState],
            (state: GalleriesStateModel) => {
                return state.GalleryView;
            }
        );
    }

    static getGalleryLimit() {
        return createSelector(
            [GalleriesState],
            (state: GalleriesStateModel) => {
                return state.galleriesLimit;
            }
        );
    }
}