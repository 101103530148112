import { VideoPlayerCourse, VideoPlayerCoursePlaylistVideo } from '../dtos/video-player-course.model';

export class LessonItem {
    oppid?: number;
    title?: string;
    description?: string;
    postpartthumbnail?: string;
    groupname?: string;
    vimeoid?: string;
    duration?: number;
    views?: number;
    likes?: number;
    comments?: number;

    public = false;

    constructor(opp: VideoPlayerCoursePlaylistVideo) {
        this.oppid = opp.orderedpostpartid;
        this.postpartthumbnail = opp.thumbnail;

        this.comments = opp.comments.totalCount;

        // MVStringBag fields
        this.groupname = opp.groupname
        this.title = opp.title;
        this.description = opp.description;

        // Content
        this.public = opp.public;

        let vimeoid = null;

        try {
            vimeoid = JSON.parse(opp.content)[0].toString();
        } catch(err) {
          console.log(err)
        }

        if(opp.content){
          this.vimeoid = vimeoid;
          const duration = JSON.parse(opp.content)[1];
          if(opp.content && duration){
            this.duration = parseInt(duration, 10);
          }
        }

        // // Metadata
        this.views = opp.views;
        this.likes = opp.likes;

    }
}
