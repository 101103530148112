import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    truncate(str: string, maxchar: number): string {
        return str.length > maxchar ? str.slice(0, maxchar) + '...' : str;
    }


    slugify(text: string): string {
        if (text == null) {
            return '';
        }

        return text.normalize('NFD').toString().toLowerCase()
            .replace(/\s+/g, '-')           // Replace spaces with -
            .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
            .replace(/\-\-+/g, '-')         // Replace multiple - with single -
            .replace(/^-+/, '')             // Trim - from start of text
            .replace(/-+$/, '');            // Trim - from end of text
    }

    interpolate(str, obj, pattern = /{([^{}]*)}/g) {
        return str.replace(pattern, (match, p1) => {
            const r = obj[p1];
            return typeof r === 'string' || typeof r === 'number' ? r : match;
        });
    }

    getHTMLStringAsText(htmlstring: string, maxchar?: number) {
        const divEl = document.createElement('div');
        divEl.innerHTML = htmlstring;
        return maxchar != null ? this.truncate(divEl.textContent, maxchar) : divEl.textContent;
    }

    isValidJson(json: string) {
        try {
            const parsed = JSON.parse(json);
            return !!parsed;
        } catch (error) {
            return false;
        }
    }
}
