import { AfterViewInit, Component, ComponentFactory, ComponentFactoryResolver, ComponentRef, ElementRef, Injector, OnDestroy, OnInit, Renderer2, Type, ViewChild, ViewContainerRef } from '@angular/core';
import { UnhDialogRefDirective } from '@frontend/unhideschool/shared/directives/dialog/dialog-conatiner-ref.directive';
import { Console } from 'console';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'unh-modalx-container',
  templateUrl: './modalx.component.html',
  styleUrls: ['./modalx.component.scss']
})
export class ModalxContainerComponent implements OnInit, AfterViewInit, OnDestroy {
  customComponentFactoryRef: ComponentFactory<any>;
  dialogRef: ComponentRef<any>;

  created = false;

  afterClosed = new Subject<any>();
  beforeClosed = new Subject<any>();
  afterOpened = new Subject<any>();


  width = '100%'
  height = '100%';
  maxWidth = '498px';
  maxHeight = '460px';
  isMobile = false;

  @ViewChild(UnhDialogRefDirective) host: UnhDialogRefDirective;

  constructor(
    private render: Renderer2
  ) { }

  ngOnInit(): void {
    return;
  }

  ngAfterViewInit() {
    this.dialogRef = this.host.viewContainerRef.createComponent(this.customComponentFactoryRef);
    this.beforeClosed = this.dialogRef.instance.beforeClosed as Subject<any>;
    this.afterOpened.next(true);
    this.beforeClosed.asObservable().pipe(
      tap(() => {
        this.render.removeStyle(window.document.body, 'overflow')
        window.document.body.style.overflow = 'auto';
      })
    )
    this.beforeClosed.asObservable().pipe(
      tap(() => {
        this.render.removeStyle(window.document.body, 'overflow')
        window.document.body.style.overflow = 'auto';
      })
    )
  }

  close() {
    this.ngOnDestroy();
    window.document.body.style.overflow = 'initial';
  }

  ngOnDestroy() {
    window.document.body.style.overflow = 'initial';
    this.render.removeStyle(window.document.body, 'overflow')
    this.beforeClosed.next(null);
  }



}
