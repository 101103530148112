
export enum GTMVisitorsTypes {
    visitor = "Visitor",
    notSubscribed = "Free User",
    subscribed = "Pro User",
    notSubscribedWithCourses = "Courses User"
}

export interface GTMEventPayloadModel<T> {
    event: string;
    data: T
}

export interface GTMUserPropertiesPayloadModel {
    user: { userType: GTMVisitorsTypes; }
}
