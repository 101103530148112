import { Gallery, GalleryItem, GalleryItemId, ImageSettings } from '@frontend/unhideschool/shared/models/dtos/gallery.model';
import { SimpleGalleryItem } from '@frontend/unhideschool/shared/models/gallery/simple-gallery-item.model';
import { GalleriesStateModel } from './galleries.model';

export interface FetchGalleriesPayload {
    galleryType: '[ROOTGALLERY]' | 'ProjectGalleries' | 'TopicGalleries';
    filters: any;
    pagination: {
        page: number,
        perpage: number
    };
}

export class FetchGalleries {
    static readonly type = '[Galleries] fetchGalleries';
    constructor(
        public payload: FetchGalleriesPayload,
        ) { }
}

export class FetchGalleryByGalleryId {
    static readonly type = '[Galleries] fetchGalleryByGalleryId';
    constructor(
        public galleryid: number,
    ) { }
}

export class UpdateGallery {
    static readonly type = '[GeneralGalleries] updateGallery';
    constructor(
        public galleryid: number,
        public published?: boolean,
        public active?: boolean,
        public coverid?: number) { }
}

export class UpdateGalleryImageSettings {
    static readonly type = '[GeneralGalleries] updateGalleryImageSettings';
    constructor(
        public gallery: Gallery | SimpleGalleryItem,
        public imageSettings: ImageSettings,
        public context: keyof GalleriesStateModel,
        public projectgalleryid?: number,
        public galleryType?: any

    ) { }
}

export class LoadMoreGalleries {
    static readonly type = '[Galleries] loadMoreGalleries';
    constructor(public payload: FetchGalleriesPayload) { }
}

export class AddPreview {
    static readonly type = '[GeneralGalleries] AddGalleryPreview';
    constructor(
        public item: Gallery | GalleryItem,
        public projectid?: number,
        public galleryid?: number,
        public galleryType?: string) { }
}

export class UpdatePreview {
    static readonly type = '[GeneralGalleries] UpdateGalleryPreview';
    constructor(
        public item: Gallery | GalleryItem,
        public galleryid?: number,
        public galleryType?: string) { }
}

export class UpdateGalleryItem {
    static readonly type = '[GeneralGalleries] UpdateGalleryItem';
    constructor(
        public galleryitemid: GalleryItemId,
        public active: boolean,
        public published: boolean,
        public galleryType?: any
    ) { }
}

export class SelectGalleryView {
    static readonly type = '[GeneralGalleries] SelectGalleryView';
    constructor(
        public galleryid: GalleryItemId,
        public galleryitemid: string,
        public isproject: boolean) { }
}

export class FetchProjectGallery {
    static readonly type = '[GeneralGalleries] fetchProjectGallery';
    constructor(
        public galleryid: GalleryItemId,
        public galleryType?: any) { }
}
