import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, ActivatedRoute } from '@angular/router';
import { OriginData } from '@frontend/unhideschool/shared/models/origin-data.model';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';
import { safeJsonParse, slugify } from '@frontend/unhideschool/app/helpers/utility-functions';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class CheckoutOriginGuard implements CanActivate {
  constructor(
    private router: Router,
    private ar: ActivatedRoute,
    private auths: AuthService,
    private luss: LoggedUserStateService
  ) { }

  canActivate(route: ActivatedRouteSnapshot): boolean {
    const queryparams = route.queryParams;
    const originhash = queryparams._c;
    const goToProfile = queryparams._p;
    if (originhash != null) {
      this.router.navigate(['.'], { relativeTo: this.ar, queryParams: {}, replaceUrl: true });
      this.loginUser(originhash);
      if (goToProfile) {
        const user = this.luss.user;
        const aliasSlug = slugify(user.alias);
        this.router.navigate([`/perfil/${user.uid}/${aliasSlug}`]);
      }
      return false;
    }

    return true;
  }

  private loginUser(originhash: string) {
    try {
      const jsonOriginData = window.atob(originhash);
      const { token }: OriginData = safeJsonParse(jsonOriginData);

      if (token !== '') {
        this.auths.getAuthenticatedUserData(token).subscribe(({ me }) => {
          if (me != null && me.user.alias.includes('temp_user')) {
            this.auths.logout();
          }
        });
      }
    } catch {
      this.auths.logout();
    }
  }
}
