export const updateCourseAdminCourseMutation = `
mutation update(
  $postid: Int!,
  $productactive: Boolean
  $creatoruid: Int,
  $traits: [String],
  $title: String,
  $active: Boolean,
  $tags: [String],
  $softwareTagIds: [Int],
  $careerTagIds: [Int],
  $postthumbnailid: Int,
  $postviewallheadid: Int,
  $postmobilecoverid: Int,
  $postthumbnailcoverid: Int,
  $fulldescription: InputMVStringBag,
  $published: Boolean,
  $position: Int,
  $belongsToTheProCatalog: Boolean,
  $price: Float

) {
  UpdateCourse(courseData: {
 	creatoruid: $creatoruid,
   productactive:  $productactive,
    postid:$postid,
    title: $title,
    active: $active,
    tags: $tags,
    softwareTagIds: $softwareTagIds,
    careerTagIds: $careerTagIds,
    traits: $traits,
    postthumbnailid: $postthumbnailid,
    postviewallid: $postviewallheadid,
    postmobilecoverid: $postmobilecoverid,
    postthumbnailcoverid: $postthumbnailcoverid,
    fulldescription: $fulldescription,
    published: $published,
    position: $position,
    belongsToTheProCatalog: $belongsToTheProCatalog
    price: $price

  }) {
    course {
      postid
      title
      postthumbnailcover
      active
      published
      fulldescription
      belongsToTheProCatalog
      traits {
        internalname
      }
    }
  }
}
`;
