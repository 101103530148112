import { Observable, map } from "rxjs";

type FilterOperation = 'EQ' | 'NEQ' | 'IS' | 'ISNOT' | 'LT' | 'GT' | 'LIKE' | 'NOTLIKE' | 'ILIKE' | 'NOTILIKE' | 'NOTIN' | 'IN';
type OrderByOperation = 'ASC' | 'DESC';

export interface GraphqlOrderByOption<T> {
    f: keyof T;
    o: OrderByOperation;
}

export interface GraphqlFilterItemOption {
    op: FilterOperation;
    v?: string | number | boolean;
    vl?: string[] | number[];
}

export interface GraphqlFilterItem {
    [key: string]: GraphqlFilterItemOption;
}

export type GraphqlFilters = GraphqlFilterItem[];

export interface GraphqlQueryArguments<T> {
    page?: number;
    perpage?: number;
    filters?: GraphqlFilterItem[];
    orderby?: GraphqlOrderByOption<T>;
    [k: string]: any;
}
export type GraphqlQueryArgumentsType = GraphqlQueryArguments<any> | any;

export interface GraphqlResponseError {
    locations: { line: number, column: number };
    message: string;
    path: string[];
}

export type GraphqlResponse<K extends string, T> =
    Observable<GraphqlResponseData<K, T>>;

export type GraphqlResponseAll<K extends string, T> =
    Observable<GraphqlResponseDataAll<K, T>>;

export interface GraphqlResponseData<K extends string, T> {
    data: Record<K, T>;
    errors: GraphqlResponseError[];
}

export interface GraphqlResponseInterface<T> { all: GraphqlResponseDataAllProps<T> }
export interface GraphqlResponseDataAll<K extends string, T> {
    data: Record<K, GraphqlResponseInterface<T>>;
    errors: GraphqlResponseError[];
}

type GraphqlResponseDataAllProps<T> = {
    items?: T;
    totalCount?: number;
    page?: number;
    perpage?: number;
    pages?: number;
}

export class BaseGraphqlQuery<T> {
    constructor(public query: string, public variables: T) { }
}

export class BaseGraphqlQueryBuilder<T> extends BaseGraphqlQuery<GraphqlQueryArguments<T>> {
    constructor(query: string, args: GraphqlQueryArguments<T>) {
        const variables: GraphqlQueryArguments<T> = {};

        Object.keys(args || {}).forEach((argsKey: string) => {
            switch (argsKey) {
                case 'filters':
                    variables.filters = args.filters || [];
                    break;

                case 'orderby':
                    variables.orderby = args.orderby;
                    break;

                default:
                    variables[argsKey] = args[argsKey];
                    break;
            }
        });

        super(query, args);
    }
}
