import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UploadImageMutation } from '../graphql/mutation/upload-file-course-admin.mutation';
import { searchTagsToAutocompleteAdminQuery } from '../graphql/query/index';

import { CourseAdminTagDTOModel } from '../models/course';
import { map } from 'rxjs/operators';
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';

@Injectable({
  providedIn: 'root'
})
export class CourseAdminService {

  constructor(
    private graph: GraphqlApiService
  ) { }

  uploadimage(file, mediacontenttype, fileid?: string) {
    const mutation = UploadImageMutation;
    const variables = {
      file: null,
      mediatype: mediacontenttype
    }
    return this.graph.graphqlUpload(mutation, variables, file, fileid);
  }

  searchForTags(tagname): Observable<CourseAdminTagDTOModel[]> {
    const query = searchTagsToAutocompleteAdminQuery;
    const variables = {
      name: tagname,
    };

    return this.graph
      .graphqlRequest<'drTags', any>(query, variables)
      .pipe(map((res) => (res.data.drTags as CourseAdminTagDTOModel[])));
  }
}
