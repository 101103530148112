
export type LinkTarget = '_self'|'_blank';

export interface SocialNavItem {
  title: string;
  linkpath?: string;
  iconclass: string;
  target: LinkTarget;
  icon: string;
}

export const mainNavSocialListModel: SocialNavItem[] = [
  {
    title: 'Email',
    iconclass: 'ico-mail',
    linkpath: 'mailto:contato@unhideschool.com',
    target: '_self',
    icon: 'unh_footer-mail'
  },
  {
    title: 'Twitch',
    iconclass: 'ico-twitch',
    linkpath: 'https://www.twitch.tv/unhideschool/',
    target: '_blank',
    icon: 'unh_footer-twitch'
  },
  {
    title: 'Discord',
    iconclass: 'ico-discord',
    linkpath: 'https://discord.com/invite/8kRnGJn/',
    target: '_blank',
    icon: 'unh_footer-discord'
  },
  {
    title: 'Instagram',
    iconclass: 'ico-insta',
    linkpath: 'https://www.instagram.com/unhideschool/',
    target: '_blank',
    icon: 'unh_footer-insta'
  },
  {
    title: 'Twitter',
    iconclass: 'ico-twitter',
    linkpath: 'https://twitter.com/unhideschool',
    target: '_blank',
    icon: 'unh_footer-twitter'
  },
  {
    title: 'Facebook',
    iconclass: 'ico-face',
    linkpath: 'https://www.facebook.com/unhideschool/',
    target: '_blank',
    icon: 'unh_footer-face'
  },
  {
    title: 'Youtube',
    iconclass: 'ico-tube',
    linkpath: 'https://www.youtube.com/channel/UCNRIxZQFjMAtmB5SHSKsL4A',
    target: '_blank',
    icon: 'unh_footer-tube'
  },
  {
    title: 'LinkedIn',
    iconclass: 'ico-linkedin',
    linkpath: 'https://www.linkedin.com/company/unhide-school/',
    target: '_blank',
    icon: 'unh_footer-linkedin'
  },

];
