import { ApplicationState } from './application/application.state';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ProjectPostsState } from './project-posts/project-posts.state';
import { LessonPostpartsState } from './lesson-postparts/lesson-postparts.state';

import { UserState } from './user/user.state';
import { GalleriesState } from './galleries/galleries.state';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

@NgModule({
    imports: [
        NgxsModule.forFeature([
            ProjectPostsState,
            GalleriesState,
            LessonPostpartsState,
            UserState,
            ApplicationState
        ]),
        NgxsReduxDevtoolsPluginModule.forRoot()
    ]
})
export class SharedNGXSModule { }
