import { Component, Input, HostBinding, ChangeDetectionStrategy } from '@angular/core';

// export interface QuickActionsItem {
//   type: 'button' | 'link' | 'separator';
//   label?: string;
//   iconName?: string;
//   href?: string;
//   active?: boolean;
//   onclick?: (action?: QuickActionsItem) => void;
// }

@Component({
  selector: 'unh-quick-actions-container',
  styleUrls: ['./quick-actions-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: `
    <div class="quick-actions-container">
      <ng-content select="unh-quick-actions-item"></ng-content>
    </div>
  `
})
export class QuickActionsContainerComponent {
  @HostBinding('class.visible')
  @Input() showQuickActions = false;
}
