
/**
 * Graphql Query | ProjectPosts
 * ***
 * @filters posttypeid, active, published
 * ***
 * Essa query aplica 3 filtros padrões para trazer filtrar os posts
 * de projeto e não aceita argumentos.
 */
export const projectPostsByIdQuery = `
query ProjectPosts($postid: Int) {
    Posts(
        filters: [
            {postid: {op:EQ, v: $postid}}
            {posttypeid:{op:EQ,v:1}},
        ],
        orderBy: {f:position,o:ASC}
    ) {
        creatoruid
        postid
        position
        description
        fulldescription
        datepublished
        dateupdated
        postthumbnail
        
        active
        published

        title
        headline
        contenttime

        postviewallhead
        postthumbnail
        postmobilecover
        postthumbnailcover
        galleries {
            galleryid
            name
            internalname
            rootgalleryid
            orderedpostpartid
            coverid
            datecreated
            dateupdated
            active
          }
        orderedpostparts {
          orderedpostpartid
          postpartid
          postpart {
            subtitle
            postparttype{
                internalname
              }
          }
        }

        creator {
            fullname,
            profilepicture,
            occupationtitle,
            shortbio,
            uid,
            alias

      relatedmedia{
        render

        imagesettings {
          imagesettingid
          width
          height
          waspectratio
          haspectratio
          xcenter
          ycenter
        }

        mediacontenttype {
          internalname
        }
      }
        }

        tags {
            tagid
            name
            tagtype {
                internalname
            }
        }

        traits {
            name
            internalname
        }

        product {
            productid
            active

            prices {
                productpriceid
                price
            }

            discountcoupons {
                couponcode
            }
        }
    }
}
`;
