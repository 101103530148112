import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { BaseStore } from '@frontend/unhideschool/app/helpers/base-store.class';

import { GraphqlQueryArguments, GraphqlFilterItem, GraphqlOrderByOption } from '@frontend/unhideschool/shared/models/graphql/base-graphql';
import { orderedPostPartsQuery } from '@frontend/unhideschool/shared/models/graphql/ordered-post-parts.query';
import { OrderedPostPart } from '@frontend/unhideschool/shared/models/dtos/ordered-post-part.model';
import { postsQuery } from '@frontend/unhideschool/shared/models/graphql/posts.query';
import { Post } from '@frontend/unhideschool/shared/models/dtos/posts.model';
import { postRelatedUrlFilesQuery } from '@frontend/unhideschool/shared/models/graphql/post-related-url-files.query';
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';


// tslint:disable-next-line: no-empty-interface
interface PostRelatedUrlFileModel {
    url?: string;
    content?: string;
    name?: string;
}

interface OrderedPostPartState {
    [postid: number]: OrderedPostPart[];
}

export interface PostRelatedUrlFilesState {
    [postid: number]: PostRelatedUrlFileModel[];
}

interface ProjectStoreModel {
    posts: Post[];
    orderedPostParts: OrderedPostPartState;
    postRelatedUrlFiles: PostRelatedUrlFilesState;
}

const projectStoreInitialValue = {
    posts: [],
    orderedPostParts: {},
    postRelatedUrlFiles: {}
};

// Posts
export const defaultProjectFilters: GraphqlFilterItem[] = [{ active: { op: 'EQ', v: true }}, {posttypeid: { op: 'EQ', v: 1 }}];
export const defaultProjectOrderBy: GraphqlOrderByOption<Post> = { f: 'datecreated', o: 'DESC' };

// OrderedPostParts
export const defaultOppFilters: GraphqlFilterItem[] = [];
export const defaultOppOrderBy: GraphqlOrderByOption<Post> = { f: 'position', o: 'ASC' };

@Injectable({
    providedIn: 'root'
})
export class ProjectStore extends BaseStore<ProjectStoreModel> {
    constructor(private graph: GraphqlApiService) {
        super(projectStoreInitialValue);
    }

    getPostRelatedUrlFiles(postid: number): Observable<PostRelatedUrlFileModel[]> {
        const currentState = this.readState<PostRelatedUrlFilesState>('postRelatedUrlFiles');

        if (currentState[postid] != null) {
            return of(currentState[postid]);
        }

        const query: string = postRelatedUrlFilesQuery;
        // const filters: GraphqlFilterItem[] = [{ postid: { op: 'EQ', v: postid }}];
        const variables = {
          postid
        }
        const obs = this.graph
            .graphqlRequest<'VideoPlayer', any>(query, variables)
            .pipe(map(res => res.data.VideoPlayer.course.files));

        obs.subscribe(urlfiles => {
            const updatedState = ({ ...currentState, [postid]: urlfiles });
            this.setStoreState('postRelatedUrlFiles', updatedState);
        });

        return obs;
    }

    getOrderedPostParts(
        postid: number,
        filters = defaultOppFilters,
        orderby = defaultOppOrderBy
    ): Observable<OrderedPostPart[]> {
        const currentState = this.readState<OrderedPostPartState>('orderedPostParts');

        if (currentState[postid] != null) {
            return of(currentState[postid]);
        }

        filters = [...filters, { postid: { op: 'EQ', v: postid }}];

        const query = orderedPostPartsQuery;
        const variables: GraphqlQueryArguments<Post> = { filters, orderby };
        
        const obs = this.graph
            .graphqlRequest<'OrderedPostParts', any>(query, variables)
            .pipe(map(res => res.data.OrderedPostParts));

        obs.subscribe(opps => {
            const updatedState = ({ ...currentState, [postid]: opps });
            this.setStoreState('orderedPostParts', updatedState);
        });

        return obs;
    }

    private getPosts<T>(page = 1, perpage = 40, filters = defaultProjectFilters, orderby = defaultProjectOrderBy) {
        const query = postsQuery;
        const variables: GraphqlQueryArguments<Post> = { page, perpage, filters, orderby };

        return this.graph
            .graphqlRequest<'Posts', any>(query, variables)
            .pipe(map(res => res.data.Posts));
    }

    protected fetchStoreStateData<T>(selector: keyof ProjectStoreModel): Observable<T> {
        switch (selector) {
            case 'posts': return this.getPosts<T>();
            default: return undefined;
        }
    }
}

