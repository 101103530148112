import { CareersTagModel } from "../course/course-admin.model";

/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class UpdateTranslatedFormData {
  static readonly type = '[Update Translated Form Data] Update';
  constructor(public payload: any, public lang: string) { }
}

export class UpdateCommonFormData {
  static readonly type = '[Update Common Form Data] Update';
  constructor(public payload: any, public lang: string) { }
}


export class UpdateProjectUpsertLanguage {
  static readonly type = '[Update Project Upsert Language] Update';
  constructor(public lang: string) { }
}


export class CreateProjectCourseAdminUpset {
  static readonly type = '[Create Project Upset] Create';
}

export class EditProjectCourseAdminUpset {
  static readonly type = '[Edit Project Upset] Edit';
}

export class FetchEditDataCourseAdminProjectUpset {
  static readonly type = '[Fetch Edit Data Project Upset] Get';
  constructor(public postid: any) { }
}


export class FetchProjectFiles {
  static readonly type = '[Course Admin Project] FetchProjetFiles';
  constructor(public postid: any) { }
}


export class AddProjectFiles {
  static readonly type = '[Course Admin Project] AddProjectFiles';
  constructor(public mediaitemid: number, public postid: number) { }
}

export class RemoveProjectFileFromState {
  static readonly type = '[ Course Admin Project] RemoveLessonFileFromState';
  constructor(public fileid: any) { }
}
export class UpdateFiles {
  static readonly type = '[ Course Admin Project] updatedFiles';
  constructor(public files: any) { }
}

export class ResetState {
  static readonly type = '[ CourseAdmin Project Upsert ] ResetState';
}
