import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { slugify } from '@frontend/unhideschool/app/helpers/utility-functions';

@Pipe({
    name: 'safeImage'
})

export class SafeImage implements PipeTransform {
  constructor( private sanitizer: DomSanitizer){}
    transform(str: string): any {
        return this.sanitizer.bypassSecurityTrustUrl(str);
    }
}