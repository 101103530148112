export const GetAllLessonQuery = `
query GetAllLessonsQuery($postid: Int!, $page: Int, $perpage: Int){
  VideoPlayer(postid: $postid){
    course{
      playlist(page: $page, perpage: $perpage){
        totalCount
        page
        perpage
        items {
          active
          postpartid
          orderedpostpartid
          thumbnail
          title
          groupname
          position
          groupnameLanguages
          public
          course{
            postid
            title
          }
        }
      }
    }
  }
}
`
