/**
 * Elements inside of web components sometimes need to inherit global attributes
 * set on the host. For example, the inner input in `ion-input` should inherit
 * the `title` attribute that developers set directly on `ion-input`. This
 * helper function should be called in componentWillLoad and assigned to a variable
 * that is later used in the render function.
 *
 * This does not need to be reactive as changing attributes on the host element
 * does not trigger a re-render.
 */
export const inheritAttributes = (el: HTMLElement, attributes: string[] = []) => {
  const attributeObject: { [k: string]: any } = {};

  attributes.forEach(attr => {
    if (el.hasAttribute(attr)) {
      const value = el.getAttribute(attr);
      if (value !== null) {
        attributeObject[attr] = el.getAttribute(attr);
      }
      el.removeAttribute(attr);
    }
  });

  return attributeObject;
}

export function getAppAssetPath(localPath: string) {
  if (!/^(\.\/|\.\.\/|\/)*assets\//.test(localPath)) {
    return localPath;
  }

  const [_, filepath] = localPath.split('assets/');

  // Better if imported from an environment package.json file
  const libname = 'web-components';

  return ['assets', libname, filepath].join('/');
}

export const testValueWithPattern = (text: string, pattern: RegExp) => {
  return pattern.test(text);
};

export function interpolate(str, obj, pattern = /{([^{}]*)}/g) {
  if (str == null) {
    return '';
  }

  return str.replace(pattern, (match, p1) => {
    const r = obj[p1.trim()];
    return typeof r === 'string' || typeof r === 'number' ? r : match;
  });
}

export const extractElementFromSlots = (selector, elementList: Element[]): any => {
  return elementList.find(element => element.localName === selector);
}

export function convertUrlToPNG(url: string) {
  // Use a regular expression to replace ".webp" with ".png" in the URL
  const newUrl = url.replace(/\.webp$/, '.png');
  return newUrl;
}

export function converURLToWebp(url: string) {
  // Use a regular expression to replace ".png" with ".webp" in the URL
  const newUrl = url.replace(/\.png$/, '.webp');
  return newUrl;
}


export const ellipsis = (target: string, maxLength: number = 90) => {
  if (target == undefined || target == null) {
    return '';
  }
  
  const htmlStripped = target.replace(/(<([^>]+)>)/gi, '');

  if (htmlStripped.length >= maxLength) {
    return `${htmlStripped.slice(0, maxLength)}...`;
  }
  return htmlStripped;
}

export const isBetweenNumbers = (value: number, a: number, b: number) => {
  return value >= a && value <= b;
}