import { map, switchMap, tap } from 'rxjs/operators';
import { othersProjectPostQuery } from './../../../../../shared/models/graphql/project-posts.query';
import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';

import { ViewAllStateModel, defaultState, ViewallMentoringDataModel, FetchLastWatchedLessonPayload } from './view-all.models';
import { FetchLastWatchedLesson, FetchMentoringData, FetchOthersPosts, FetchViewallLessons, LoadmoreOthersPosts, UpdateOthersPostsPagination } from './view-all.actions';

import { getViewAllLessons } from '../graphql/queries/get-lessons.query'
import { getViewAllMentoringDataQuery } from '../graphql/queries/get-lesson-mentoring-data.query'
import { GetCommunityViewallQuery } from '../graphql/queries/get-community-id-viewall.query'
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';
import { lastWatchedLessonQuery } from '../graphql/queries/get-last-watched-lesson.query';

@State<ViewAllStateModel>({
  name: 'viewall',
  defaults: defaultState
})
@Injectable()
export class ViewAllState {

  constructor(
    private graph: GraphqlApiService
  ) { }

  @Action(FetchOthersPosts)
  FetchOderPosts(
    ctx: StateContext<ViewAllStateModel>,
    action: FetchOthersPosts
  ) {
    const query = othersProjectPostQuery;
    const variables = {
      page: action.page,
      perpage: action.perpage
    }

    const obs = this.graph.graphqlRequest<'homeposts', any>(query, variables).pipe(
      map(res => ({ posts: res.data.homeposts.posts })),
      tap(({ posts }) => ctx.patchState({
        othersPosts: [...posts],
        othersPostsLimit: false
      }))
    );

    return obs;
  }

  @Action(LoadmoreOthersPosts)
  teste(
    ctx: StateContext<ViewAllStateModel>,
    action: LoadmoreOthersPosts
  ) {
    const query = othersProjectPostQuery;
    
    const variables = {
      page: action.page,
      perpage: action.perpage
    };

    ctx.patchState({ othersPostsLimit: true });

    return this.graph.graphqlRequest<'homeposts', any>(query, variables).pipe(
      map(res => ({ 
        posts: res.data.homeposts.posts, 
        totalCount: res.data.homeposts.totalCount 
      })),
      tap(({ posts, totalCount }) => {

        const calc = (action.page * action.perpage) + posts.length
        if (calc > totalCount) {
          ctx.patchState({
            othersPostsLimit: true,
            othersPosts: [...ctx.getState().othersPosts, ...posts],

          })
        } else {
          ctx.patchState({
            othersPostsLimit: false,
            othersPosts: [...ctx.getState().othersPosts, ...posts],
          })
        }
      })
    );
  }


  @Action(UpdateOthersPostsPagination)
  updatePagination(
    ctx: StateContext<ViewAllStateModel>,
    action: UpdateOthersPostsPagination
  ) {
    const { page, perpage } = action;
    ctx.patchState(
      {
        othersPostPagination: { page, perpage }
      }
    )
  }


  @Action(FetchViewallLessons)
  fetchViewallLessons(
    ctx: StateContext<ViewAllStateModel>,
    action: FetchViewallLessons
  ) {
    const { postid } = action;
    const query = getViewAllLessons;
    const variables = {
      postid
    }
    return this.graph.graphqlRequest<'VideoPlayer', any>(query, variables).pipe(
      map(res => res.data.VideoPlayer.course.playlist.items),
      tap(lessons => ctx.patchState({ lessons }))
    )
  }


  @Action(FetchMentoringData)
  fetchMentoringData(
    ctx: StateContext<ViewAllStateModel>,
    action: FetchMentoringData
  ) {
    const { postid } = action;
    const getViewAllMentoringData = getViewAllMentoringDataQuery;
    const GetCommunityViewall = GetCommunityViewallQuery;
    const variables = {
      postid
    }
    return this.graph.graphqlRequest<'VideoPlayer', any>(getViewAllMentoringData, variables).pipe(
      map(res => res.data.VideoPlayer.course),
      switchMap(course => this.graph.graphqlRequest<'Community', any>(
        GetCommunityViewall, 
        { communityId: course.mentoringTopic.communityid })
      ),
      map(res => res.data.Community.item),
      map(community => {
        return community ? ({
          communityid: community.communityid,
          path: community.path,
          communitycategid: community.communitycategid
        }) : null
      }),
      tap((mentoringData: ViewallMentoringDataModel) => {
        mentoringData ? ctx.patchState({ mentoringData }) : null
      })
    )
  }

  @Action(FetchLastWatchedLesson)
  handleFetchLastWatchedLesson(
    ctx: StateContext<ViewAllStateModel>,
    action: FetchLastWatchedLesson
  ) {
    const variables = {
      postId: action.postId
    }
    return this.graph.graphqlRequest<'MyCourses', FetchLastWatchedLessonPayload>(lastWatchedLessonQuery, variables).pipe(
      tap((res) => {
        const lastWatchedLesson = res.data.MyCourses.courses[0].lastwatched;
        ctx.patchState({lastWatchedLesson})
      })
    )
  }
}
