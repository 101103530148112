import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

import { environment } from '@frontend/unhideschool/env';
import { SideMenuList, SideMenuListItem } from '../../models';

const initialState = new SideMenuList(environment.enabledMenus);

@Injectable({
    providedIn: 'root'
})
export class SideMenuStateService {
    private readonly _sideMenuListOptions = new BehaviorSubject<SideMenuList>(initialState);
    readonly sideMenuListOptions$ = this._sideMenuListOptions.asObservable();

    getMenuState(menu: string) {
        return this.sideMenuListOptions$.pipe(
            map(menus => menus[menu])
        );
    }

    getMenuOptionState(menu: string, option: string) {
        return this.getMenuState(menu).pipe(
            map(menuOptions => menuOptions && menuOptions != undefined ? menuOptions[option] :
              {
                isopen: false,
                position: 'left'
              })
        );
    }

    setMenuOptions(menu: string, options: SideMenuListItem) {
        // Before opening a menu, we need to close any open menu
        if (options.isopen != null && options.isopen) {
            this.closeAll();
        }

        const oldState = this._sideMenuListOptions.value;

        const newState = {};
        newState[menu] = options;

        this._sideMenuListOptions.next({ ...oldState, ...newState });
    }

    closeAll() {
        const oldState = this._sideMenuListOptions.value;
        const newState: SideMenuList = {};

        // Iterate over state and close all menus
        for (const prop in oldState) {
            if (prop in oldState) {
                newState[prop] = { ...oldState[prop], isopen: false };
            }
        }

        this._sideMenuListOptions.next(initialState);
    }
}
