export const addSocialFacebookLoginMutation = `
  mutation AddSocialFacebookLoginMutation(
    $accesstoken: String!,
    $email: String!
  ) {
    AddSocialFacebookLogin(
      accesstoken: $accesstoken,
      email: $email
    ) {
      authtoken {
        token
      }
      success
    }
  }
`;