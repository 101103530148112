<swiper
  #swiper
  (slideChange)="slideCount = slideCount + 1 ; onSlideChange.emit({page:slideCount})"
  [config]="configs">
  <ng-content></ng-content>

  <div
    *ngFor="let item of items"
    [style.position]="'relative'">
    <div class="swiper-slide-container">
      <ng-container
        [ngTemplateOutlet]="genericSwiperItemTemplateRef"
        [ngTemplateOutletContext]="{ $implicit: item }"
      ></ng-container>
    </div>
  </div>
</swiper>

