import { SimpleSubscriptionPlans, SimpleSubscriptionPlan } from '@frontend/unhideschool/core/models/subscription-plan.model';

export const subscriptionPlans: { [K in keyof typeof SimpleSubscriptionPlans]: SimpleSubscriptionPlan } = {
    monthly: {
        subsplanid: 1672,
        subsplanpriceid: 1670
    },
    monthlybussiness: {
        subsplanid: 6186,
        subsplanpriceid: 6184
    }
};
