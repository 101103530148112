export interface UserStateModel {
  id: string;
  courses: number[];
  subscribed: boolean;
}

export const defaultUserStateModel: UserStateModel = {
  id: null,
  courses: [],
  subscribed: false,
};
