import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WarningBarComponent } from './warning-bar/warning-bar.component';

@NgModule({
  declarations: [WarningBarComponent],
  imports: [CommonModule, ],
  exports: [WarningBarComponent]
})
export class GenericComponentsModule { }
