/**
 * Graphql Query | User
 */

import { PostType, UserType } from "@frontend/unhideschool/graphql-schema";

export interface UserQueryResponse {
  user: UserType;
  courses: PostType[]; 
  subscribed: boolean;
}

export const userQuery = `
  query MeUserCoursesIds {
    me {
      user {
        uid
      },
      courses {
        postid
      },
      subscribed
    }
  }
`;
