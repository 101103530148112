
interface FilterOptionModel {
    tagid: number;
    slug: string;
    name?: string;
}

const defaultFilterOptionValue: FilterOptionModel = {
    tagid: 0,
    slug: '',
    name: 'todos'
};

export class FilterOption implements FilterOptionModel {
    constructor(
        public tagid = defaultFilterOptionValue.tagid,
        public slug = defaultFilterOptionValue.slug,
        public name = defaultFilterOptionValue.name,
    ) { }
}

export const defaultEmptyFilterOption = new FilterOption(-1, 'semfiltro', 'SemFiltro');
