import { Injectable } from '@angular/core';

import { RestApiService } from './rest-api.service';

import { BaseGraphqlQuery, BaseGraphqlQueryBuilder, GraphqlQueryArguments, GraphqlQueryArgumentsType, GraphqlResponse, GraphqlResponseAll } from '../models/base-graphql';
import { CustomHttpOptions, UploadEvent } from '../models/base-api.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GraphqlApiService {
  constructor(private rest: RestApiService) { }

  private getOperationName(query: string) {
    const regexResult =
      new RegExp('((query)|(mutation))\\s*([^\\s()){]+)', 'gm').exec(query);

    return regexResult?.pop() || null;
  }

  private getGraphqlRequestData(query: string, variables: { [k: string]: any }) {
    const gqldata = new BaseGraphqlQueryBuilder(query, variables);
    const operationName = this.getOperationName(query);
    const endpoint = operationName != null ? `/graphql?e=${operationName}` : `/graphql`;
    return { endpoint, variables: gqldata.variables, operationName };
  }

  public graphqlUpload<T>(
    query: string,
    data: GraphqlQueryArguments<any> = {},
    file: File,
    filekey: string,
    options?: CustomHttpOptions
  ) {
    const { endpoint, variables } = this.getGraphqlRequestData(query, data);
    const mapper = { file: ["variables.file"] };
    const operations = {query, variables};
    const formData = new FormData();
    formData.append('operations', JSON.stringify(operations));
    formData.append('map', JSON.stringify(mapper));
    formData.append('file',file, file.name);
    return this.rest.upload(endpoint, formData, options, filekey, true) as Observable<UploadEvent<T>>;
  }

  public graphqlRequest<K extends string, T>(
    query: string,
    data: GraphqlQueryArgumentsType= {},
    options?: CustomHttpOptions,
    handleErrors?: boolean,
    auth = true
  ) {
    const { endpoint, variables } = this.getGraphqlRequestData(query, data);
    const gqldata = { query, variables };
    return this.rest.post<T>(endpoint, gqldata, options, handleErrors, auth) as any as GraphqlResponse<K, T>;
  }

  public graphqlRequestAll<K extends string, T>(
    query: string,
    data: GraphqlQueryArguments<any> = {},
    options?: CustomHttpOptions,
    handleErrors?: boolean,
  ) {
    const { endpoint, variables } = this.getGraphqlRequestData(query, data);
    const gqldata = { query, variables };
    return this.rest.post<T>(endpoint, gqldata, options, handleErrors) as any as GraphqlResponseAll<K, T>;
  }

  public graphqlUpdate<K extends string, T>(
    mutation: string,
    variables: any = {},
    handleErrors?: boolean,
  ) {
    const gqldata = new BaseGraphqlQuery<any>(mutation, variables);
    return this.rest.post<T>('/graphql', gqldata, undefined, handleErrors) as any as GraphqlResponse<K, T>;
  }
}
