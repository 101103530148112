/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchRecommendedLessons {
  static readonly type = '[RecommendedLessons API] Fetch';
  constructor(public islogged: boolean) { }
}


export class SetSubscriptionWarning {
  static readonly type = '[Subscription Warning] setHasWarning';
  constructor(public payload: boolean) { }
}


export class FetchPricingData {
  static readonly type = '[Application State] Fetch Pricing Data';
  constructor(public inviteCouponcode: string){}
}


/**
 * This class update sidebar toggle state in application state to be used in entire application.
 * @param toggleState Define the pagination current page.
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
 export class UpdateSidebarToggle   {
  static readonly type = '[Sidebar State] UpdateSidebarToggle ';
  constructor(public toggleState: boolean) { }
}

export class FetchUserCoursesIds {
  static readonly type = '[Application State] Fetch User Courses Ids';
  constructor() { }
}

export class FetchInvitationCoupon {
  static readonly type = '[Application State] Fetch Invitation Coupon';
  constructor(public couponcode: string) { }
}
