export const FetchUserByEmail = `
  query FetchUserByEmail($email: String){
    Users(
      filters:[
        {email: {op: EQ, v: $email}}
      ]
    ){
      uid
      alias,
      sendEmailTypes{
        active
        emailType{
          internalName
          active
        }
      }
    }
  }
`;
