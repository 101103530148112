<a class="notifications-menu-item" [class.read]="notificationItem.read"
    (click)="navigateTo(notificationItem)">
    <div class="flex-content">
        <div class="left-container">
            <div class="thumb-container">
                <unh-spinner-loader *ngIf="!imageLoaded && notificationItem.imagetype !== 'icony'" size="small">
                </unh-spinner-loader>

                <ng-container [ngSwitch]="notificationItem.imagetype">
                    <div class="notif-icon" *ngSwitchCase="'icony'">
                        <unh-svg-icon 
                            width="33px" 
                            height="40px"
                            [url]="notificationItem.imageurl"
                        ></unh-svg-icon>
                    </div>

                    <!-- TODO: pegar userSubscribed real -->
                    <!-- TODO: pegar username real e trocar redirectToProfile para TRUE -->

                    <div class="project-image-container" *ngSwitchDefault>
                        <img class="project-image" 
                            [src]="notificationItem.imageurl" 
                            [alt]="notificationItem.title"
                            (imgLoaded)="imageLoaded = true"
                        >
                    </div>
                    <div class="title-container">
                      <div class="avatar-container" *ngSwitchCase="'avatar'">
                        <unh-user-avatar
                          [avatarUrl]="notificationItem.imageurl"
                          [username]="notificationItem.fullname"
                          [uid]="notificationItem.uid"
                          [userSubscribed]="false"
                          [hasUnreadNotifs]="false"
                          [redirectToProfile]="false"
                          [defaultAvatarUrl]="defaultAvatarUrl"
                          (avatarLoaded)="imageLoaded = true"
                        ></unh-user-avatar>
                    </div>
                    <span class="title-container-text" [innerHTML]="notificationItem.title"></span>

                    </div>

                </ng-container>

                <!--div class="icon-badge">
              <unh-icon-container></unh-icon-container>
            </div-->
            </div>
        </div>

        <div class="right-container">
            <div class="content">
                <p [innerHTML]="notificationItem.text" class="muted"></p>
            </div>

            <span class="time-ago-label">{{ notificationItem.datecreated }}</span>
        </div>
    </div>

    <div class="actions-container">
        <unh-button *ngFor="let action of notificationItem.actions"
            color="default"
            variation="outline"
            (click)="onItemClick($event, action)"
            >
            {{ action.label }}
        </unh-button>
    </div>
</a>
