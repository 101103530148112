import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnChanges } from '@angular/core';

@Component({
  selector: 'unh-user-avatar',
  styleUrls: ['./user-avatar.component.scss'],
  templateUrl: './user-avatar.component.html',
})
export class UserAvatarComponent implements OnChanges {
  _avatarUrl = '';

  @Output() avatarLoaded = new EventEmitter();

  @Input() defaultAvatarUrl: string;
  @Input() username: string;
  @Input() uid: string | number;
  @Input() userSubscribed = false;
  @Input() hasUnreadNotifs = false;
  @Input() redirectToProfile = false;
  @Input() avatarUrl: string;

  ngOnChanges(changes) {
    if (changes.avatarUrl) {
      this._avatarUrl = this.avatarUrl?.length > 0 ? this.avatarUrl : this.defaultAvatarUrl;
    }
  }
}
