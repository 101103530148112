import { Component, Input, OnInit } from '@angular/core';
import { ActivePromotion } from '@frontend/unhideschool/app/shared-nxgs/application/application.model';

@Component({
  selector: 'unh-custom-promo-tag',
  templateUrl: './custom-promo-tag.component.html',
  styleUrls: ['./custom-promo-tag.component.scss']
})
export class CustomPromoTagComponent implements OnInit {
  @Input() activePromo: ActivePromotion;

  @Input() type: 'tag' | 'card-flag' | 'flag';

  @Input() plus:boolean;

  @Input() width = 113;

  @Input() fontSize = '52px';

  isCircle: boolean;
  isFlag: boolean;
  isCardFlag: boolean;

  ngOnInit(): void {

    this.isCircle = this.type == 'tag';
    this.isFlag = this.type == 'flag';
    this.isCardFlag = this.type == 'card-flag'
  }

}
