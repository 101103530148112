export const getActivePromotionCouponQuery = `
  query GetPromoCouponByCode($couponcode: String) {
    DiscountCoupon {
      item(inviteCode: $couponcode) {
          active
          name
          couponcode
          cycles
          value
          validuntil
          datecreated
          discountcoupontype {
            internalname
        }
      }
    }
  }
`;
