import { Component, Input } from '@angular/core';
import { environment } from '@frontend/unhideschool/env';

@Component({
  selector: 'unh-menu-logo',
  styleUrls: ['./menu-logo.component.scss'],
  template: `
    <a  [href]="linkurl" [title]="title">
      <img [src]="logourl" [title]="title">
    </a>
  `
})
export class MenuLogoComponent {
  @Input() title = 'UNHIDE School';
  @Input() logourl = environment.cdnUrl + '/logo-unhide.svg';
  @Input() linkurl = '/';
}
