<div class="view-all-lesson">
    <div class="resume">
        <a class="thumb-wrapper"
            (click)="goToLesson()"
            [class.free]="lessonItem?.public"
            [class.pro]="!lessonItem?.public"
            [class.logged]="luss.subscribed">

            <img class="thumb-list" [src]="lessonItem?.postpartthumbnail" alt="">
        </a>

        <div class="field-title">
            <a 
            (click)="goToLesson()"
            >{{ lessonItem?.title }}</a>
            <span class="field-subtitle desktop">{{ lessonItem?.views }} {{viewsLabel}}</span>
        </div>
    </div>

    <div class="social">
        <span class="field-subtitle mobile">{{ lessonItem?.views }} {{viewsLabel}}</span>

        <div flex></div>

        <div class="likes">
            <img class="ico ico-time" src="assets/web-components/images/svg-icons/view-all/ico-heart-lessons.png" alt="">
            {{ lessonItem?.likes }}
            <span>{{likesLabel}}</span>
        </div>

        <div class="comments">
            <img class="ico ico-time" src="assets/web-components/images/svg-icons/view-all/ico-comment-lessons.png" alt="">
            {{ lessonItem?.comments }}
            <span>{{commentsLabel}}</span>
        </div>
    </div>
</div>
