import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { getActiveCoupons } from '../../shared/models/graphql/get-active-coupons.query';
import { GraphqlApiService } from '../../api-gateway/services/graphql-api.service';

interface getCouponsResponse{
  couponcode: string;
}
@Injectable({
  providedIn: 'root'
})
export class CouponcodeGuardService {

  constructor(
    private graph: GraphqlApiService,
  ) {}

  checkHasCouponcode(couponcode: string): Observable<boolean> {
    const query  = getActiveCoupons;
      return this.graph.graphqlRequest<'DiscountCoupon', any>(query).pipe(
        map(res => res.data.DiscountCoupon.all.items),
        map( (discountCoupons: Array<getCouponsResponse>) => discountCoupons.includes({couponcode}))
      )
  }
}
