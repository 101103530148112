<div [ngClass]="generalClass">
  <div class="logo"></div>

  <div class="dialog-container">
    <div class="background-svg"></div>
    <div class="text-container" *ngIf="!data && !spinnerControl">
      <h2 class="title">Tem certeza disso?</h2>
      <p class="text">
        Você está prestes a deletar algo da sua postagem. <br />Essa ação é
        irreversível.
      </p>
    </div>
    <div class="text-container" *ngIf="data">
      <h2 class="title">{{ data.text.title }}</h2>
      <p class="text" [innerHtml]="data.text.message"></p>
    </div>
    <div class="loading" *ngIf="spinnerControl">
      <unh-spinner-loader> </unh-spinner-loader>
    </div>

    <div class="actions" *ngIf="!data.buttons">
      <a
        mat-button
        theme="pink-pink-outline"
        id="cancel-button"
        class="unh-button-old mat-button mat-button-base"
        (click)="response(false)"
        >Cancelar</a
      >
      <a
        mat-button
        theme="pink-white"
        class="unh-button-old mat-button mat-button-base"
        (click)="response(true)"
        >Confirmar</a
      >
    </div>

    <div class="actions" *ngIf="data.buttons">
      <ng-container *ngFor="let btn of data.buttons">
        <a
          *ngIf="btn.type === 'default'"
          mat-button
          theme="pink-white"
          id="cancel-button"
          class="unh-button-old mat-button mat-button-base"
          (click)="response(btn.response !== undefined ? btn.response : true)"
          >{{ btn.text }}</a
        >

        <a
          *ngIf="btn.type === 'outline'"
          mat-button
          theme="pink-pink-outline"
          id="cancel-button"
          class="unh-button-old mat-button mat-button-base"
          (click)="response(btn.response !== undefined ? btn.response : true)"
          >{{ btn.text }}</a
        >
      </ng-container>
    </div>
  </div>
</div>
