export const FetchCareerByIdQuery = `
query getCurrentTrail($careerId: Int!, $userId: Int!) {
    CourseTrail{
      item(careerId: $careerId) {
        id
        name
        progress(uid: $userId)
        canRequireCertificate(uid: $userId)
        finishedCareer(uid: $userId)
        lastWatchedCourse(uid: $userId){
            id
            position
            progress(uid: $userId)
        }
        nextCourseToWatch(uid: $userId){
          id
          position
        }
        courses {
          items {
            id
            progress(uid: $userId)
            position
            careerId
            lastWatchedLesson{
                title
                orderedpostpartid
              }
            post {
              creatoruid
              postid
              position
              description
              fulldescription
              postthumbnail
              published
              title
              contenttime
              postviewallhead
              postthumbnail
              postmobilecover
              postthumbnailcover
              #orderedpostparts {
                #orderedpostpartid
                #postpartid
                #postpart {
                 # subtitle
                #  postparttype {
                #    internalname
                #  }
                #}
              #}
              creator {
                occupationtitle
                uid
                alias
                profilepicture
                relatedmedia {
                  render
                  imagesettings {
                    imagesettingid
                    width
                    height
                    waspectratio
                    haspectratio
                    xcenter
                    ycenter
                  }
                  mediacontenttype {
                    internalname
                  }
                }
              }
              tags {
                tagid
                name
                tagtype {
                  internalname
                }
              }
              traits {
                name
                internalname
              }
            }
          }
        }
      }
    }
  }
  `;