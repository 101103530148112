import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { BehaviorSubject, from } from 'rxjs';
import { MediaUploaderService } from '../../media-uploader.service';
import { mergeAll, map } from 'rxjs/operators';
import { Input } from '@angular/core';
interface MediaFileData{
  fileid: number
  urlfile: string
  urlimage: string
}
interface FileCustomObject {
  inputFile: File
  progress?:number
  completed:boolean;
  uploading:boolean;
  mediaFileData?: MediaFileData;
  position:number
}


@Component({
  selector: 'pzl-media-uploader',
  template: `
    
  
      <pzl-dropzone (fileEmitter)="getFiles($event)" [multiple]="multiple" [theme]="theme"></pzl-dropzone>
      <pzl-media-list *ngIf="false" [listItem]="listItem | async"></pzl-media-list>
   
  `,
  styles: [':host{ width:100%;}']
})
export class MediaUploaderComponent {
  listItem: BehaviorSubject<FileCustomObject[]> = new BehaviorSubject([] as any)
  
  @Input() theme = false;
  @Input() multiple = true;


    
  @Output() itensEmmiter: EventEmitter<any> = new EventEmitter();
  @Output() uploadResult: EventEmitter<any> = new EventEmitter();

  constructor(private uploader: MediaUploaderService) { }

  getFiles(files: any){ 
    let distinctFiles = []
    const listItemPreviousLength = this.listItem.getValue().length;
    
    if(listItemPreviousLength == 0 ){
        distinctFiles = files
    }

    if(listItemPreviousLength > 0){
      distinctFiles = files.slice(this.listItem.getValue().length)
    }


    let index = listItemPreviousLength;
    const filesArray = distinctFiles.reduce((acc: any[], file: any) => {
      // filter 'uploading' files
      if (!('uploading' in file)) {
        file = {
          inputFile: file,
          completed: false,
          uploading: true,
          position: index++,
          progress:0
        };
  
        if(!file.completed && file.uploading) {
          const data = new FormData();
          data.append('file', file.inputFile , file.inputFile.name);
          data.append('mediacontenttype', 'communitymediathumb');
  
          const request = this.uploader
            .upload('/media/uploadfile', true, data, 'auto')
            .pipe(map(res => ({ file, res })));
  
          return [...acc, request];
        } 
      }

      return acc;
    }, []);
    
    from(filesArray).pipe(mergeAll() as any).subscribe(({ file, res }: any) => {
      if (res.status == 'progress') {
        file.progress = res.progress;
      } else if(res.success) {
        file.completed = true;
        file.uploading = false;
        file.mediaFileData = res.dict;
      }

      // Atualiza lista
      const lista = this.listItem.getValue();

      const fileWrapperIndex = lista.findIndex(fco => 
        (fco.inputFile.name === file.inputFile.name) && (fco.position == file.position));

      // -1 = não achou
      if (fileWrapperIndex > -1 ) {
        const updatedList = lista.slice();
        updatedList[fileWrapperIndex] = file;
        this.listItem.next(updatedList);
      } else {
        this.listItem.next([...lista, file]);
      }

      this.itensEmmiter.emit(this.listItem.getValue());
    })
  }
}