import { ViewAllStateModel } from './view-all.models';
import { createSelector } from '@ngxs/store';
import { ViewAllState } from './view-all.state';


export class ViewAllSelectors {

  static getOthersPosts() {
    return createSelector(
      [ViewAllState],
      (state: ViewAllStateModel) => state.othersPosts
    );
  }

  static getOthersPostsLimit() {
    return createSelector(
      [ViewAllState],
      (state: ViewAllStateModel) => state.othersPostsLimit
    );
  }


  static getOthersPostsPagination() {
    return createSelector(
      [ViewAllState],
      (state: ViewAllStateModel) => state.othersPostPagination
    );
  }

  static getPostLessons() {
    return createSelector(
      [ViewAllState],
      (state: ViewAllStateModel) => state.lessons
    );
  }

  static getMentoringData() {
    return createSelector(
      [ViewAllState],
      (state: ViewAllStateModel) => state.mentoringData
    );
  }

  static getLastWatchedLesson() {
    return createSelector(
      [ViewAllState],
      (state: ViewAllStateModel) => state.lastWatchedLesson
    );
  }
}
