export const mentorUsersQuery = `
    query MentorUsers(
        $filters: [UserQueryParams]!,
        $orderby: UserOrderByParams
    ) {
        Users(filters: $filters, orderBy: $orderby) {
            username,
            fullname,
            email,
            occupationtitle,
            shortbio,
            profilepicture,
            alias
          }
    }
`;
