import { Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { environment } from '@frontend/unhideschool/env';
import * as data from '../../utils/seo/metatags.json';
import * as blog_metatags from '../../utils/seo/metatags-blog.json';
import { BlogMetaTagsModel, MetaTagsSeoModel } from '../../utils/seo/metatags.model';
import { ProjectPost } from '../../shared/models/project/project-post.model';
import { getHTMLStringAsText } from '../../helpers/utility-functions';

@Injectable({ providedIn: 'root' })
export class SeoTagsManagerService {
  metatagsdata: MetaTagsSeoModel = (data as any).default.data;
  blogMetatags: BlogMetaTagsModel = (blog_metatags as any).default;

  isBlogMetaTagsPopulated = false;
  constructor(private title: Title, private meta: Meta) { }

  setMetas(metas: MetaDefinition[]) {
    this.meta.addTags(metas);
  }

  updatedMetas(metas: MetaDefinition[]) {
    for (const meta of metas) {
      this.meta.updateTag(meta);
    }
  }

  getTitleAndMetas() {
    const title = this.title.getTitle();
    const metas = this.meta.getTags('content');
    return { title, metas };
  }

  updateCanonicalTags(url: string, params?: any) {
    const document = window.document;
    const head = document.querySelector('head');
    const link = document.createElement('link');
    const persistedLink = head.querySelector('#canonical-tag') as HTMLLinkElement
    if (persistedLink) {
      persistedLink.href = environment.apppath + url;
    } else {
      link.id = 'canonical-tag'
      link.rel = 'canonical';
      link.href = environment.apppath + url;
      head.appendChild(link);
    }
  }

  setNoIndex() {
    const document = window.document;
    const head = document.querySelector('head');
    const meta = document.createElement('meta');
    const noIndexMeta = head.querySelector('#no-index') as HTMLLinkElement
    if (noIndexMeta) {
      return;
    } else {
      meta.id = 'no-index'
      meta.name = "robots"
      meta.content = 'noindex';
      head.appendChild(meta);
    }
  }

  removeNoIndex() {
    const head = document.querySelector('head');
    const noIndexMeta = head.querySelector('#no-index') as HTMLLinkElement
    head.removeChild(noIndexMeta);
  }

  updateTitle(title) {
    document.title = title;
    this.updatedMetas([{ name: 'og:title', content: title }])
  }

  injectCustomMetaTagDescriptionFromList(identifier) {
    if (this.metatagsdata[identifier] === undefined) return null;
    this.updatedMetas([{ name: 'description', content: this.metatagsdata[identifier] ? this.metatagsdata[identifier].DESCRIPTION : "UNHIDE School" }])
    this.updatedMetas([{ property: 'og:description', content: this.metatagsdata[identifier] ? this.metatagsdata[identifier].DESCRIPTION : "UNHIDE School" }])
    return true;
  }

  initializeCustomMetaTagsFromList(identifier) {
    this.metatagsdata[identifier] ? this.updateTitle(this.metatagsdata[identifier].TITLE) : this.initializeCourseMetaTags(identifier);
    this.injectCustomMetaTagDescriptionFromList(identifier);
  }

  injectBlogMetaTags(id) {
    const metadata = this.blogMetatags[id];
    if (metadata) {
      this.isBlogMetaTagsPopulated = true;
      this.updateTitle(metadata.TITLE);
      this.updatedMetas([
        { name: 'description', content: metadata.DESCRIPTION },
        { property: 'og:description', content: metadata.DESCRIPTION }
      ]);
      return;
    }
    this.isBlogMetaTagsPopulated = false;
  }

  initializeCourseMetaTags(courseName) {
    if (!this.injectCustomMetaTagDescriptionFromList(courseName)) {
      const courseNameTemplate = `Curso ${courseName} | UNHIDE School `
      this.updateTitle(courseNameTemplate)
    }
  }

  setStructuredData(post: ProjectPost) {
    var scriptElement = document.createElement('script');
    scriptElement.type = 'application/ld+json';
    var structuredData = {
      "@context": "https://schema.org/",
      "@type": "Product",
      name: post.title,
      image: post.postthumbnail,
      logo: post.postthumbnail,
      description: getHTMLStringAsText(post.fulldescription),
      productID: post.product.productid,
      brand: {
        "@type": "Brand",
        name: 'UNHIDE SCHOOL',
        logo: "https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/logo-unhide.svg"
      },
      offers: {
        "@type": "Offer",
        priceCurrency: 'BRL',
        price: post.product.price,
        "availability": "https://schema.org/InStock",
        seller: {
          "@type": "Organization",
          name: 'UNHIDE SCHOOL',
        logo: "https://unhide-static-prod.nyc3.cdn.digitaloceanspaces.com/logo-unhide.svg"

        }
      }
    };

    // Convert the structured data object to a JSON string
    var jsonData = JSON.stringify(structuredData, null, 2);

    // Set the script content to the JSON string
    scriptElement.innerHTML = jsonData;

    // Append the script element to the document's head
    document.head.appendChild(scriptElement);
  }
}
