import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SimpleGallery } from '@frontend/unhideschool/shared/models/gallery/simple-gallery.model';
import { SimpleGalleryItem } from '@frontend/unhideschool/shared/models/gallery/simple-gallery-item.model';
import { GalleriesSelectors } from '@frontend/unhideschool/app/shared-nxgs/galleries/galleries.selectors';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { Gallery, GalleryItem } from '@frontend/unhideschool/shared/models/dtos/gallery.model';
import { GalleryItemCropDialog } from '@frontend/unhideschool/shared/dialogs/gallery-item-crop/gallery-item-crop.dialog';
import { AddPreview, FetchProjectGallery, UpdateGallery, UpdateGalleryImageSettings, UpdateGalleryItem, UpdatePreview } from '@frontend/unhideschool/app/shared-nxgs/galleries/galleries.actions';
import { createImageProject } from '@frontend/unhideschool/shared/models/graphql/createImageProject.query';
import { createImageSettings } from '@frontend/unhideschool/shared/models/graphql/createImageSettings.query';
import { UnhDialog } from '@frontend/unhideschool/shared/services/unh-dialog.service';
import { updateMediaItemCreator } from '@frontend/unhideschool/shared/models/graphql/update-media-item-creator.query';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { UNHGalleryTranslationModel } from '@frontend/unhideschool/app/utils/i18n/locale/unh-gallery/unh-gallery.i18n.model';
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';
import { GalleryRouterQueryParamsModel } from '../../models/gallery-query-params.model';

@Component({
  selector: 'unh-basic-gallery',
  templateUrl: './basic-gallery.component.html',
  styleUrls: ['./basic-gallery.component.scss']
})
export class BasicGalleryComponent implements OnInit {
  private _galleries: Gallery[] = [];
  previewWidth: number;
  previewHeight: number;
  galleryLoadingSkeleton: boolean;

  @Input() translations: UNHGalleryTranslationModel;


  @Output() loadMore?: EventEmitter<boolean> = new EventEmitter();
  @Output() filterEmmiter: EventEmitter<string> = new EventEmitter();
  @Output() updateGalleryImageSettings: EventEmitter<string> = new EventEmitter();
  @Input() galleryViewPath: string;
  @Input() galleryRouterQueryParams: GalleryRouterQueryParamsModel;
  @Input() projectId?: any;
  @Input() creatorId?: any;
  @Input() galleryType?: string;
  @Input() hasAdminToolbar = true;
  @Input() galleryStatus?: boolean;
  @Input() scrollEnabled?: boolean;

  @Input() hideEditmodeSwitcher = false;

  @Input() set editModeStart(val) {
    this.galleryEditModeEnabled = val;
  }

  @Input() set customEditMode(val) {
    this.galleryEditModeEnabled = val;
  }

  @Input()
  set galleries(val: Gallery[]) {
    if (val != null && val.length > 0) {
      this._galleries = val;
      if (this.isProject) {
        this.initProjectGalleries();
      } else {
        this.initHomeGalleries();
      }
    }
  }
  get galleries() {
    return this._galleries;
  }

  @Input() isProject = false;

  // homeGalleryItems$: Observable<>;
  hoverObs;
  viewdItems = [];
  isAdmin = false;
  showHomeGallery = false;
  homeGalleryPage = 1;
  galleryScrollTrackerEnabled = true;
  gallerySelectedListFilter = 'all' as const;
  galleryEditModeEnabled = false;
  homeGalleryItems: any;
  gallery = null;
  uploadedImages: any[] = [];
  previewImg;
  inViewportChange: any;
  itemsCount = 3;
  debouncer: Subject<any> = new Subject<any>();

  constructor(
    public luss: LoggedUserStateService,
    private dialog: MatDialog,
    private store: Store,
    private graph: GraphqlApiService,
    private unhd: UnhDialog,
    private sanitizer: DomSanitizer
  ) {
  }

  ngOnInit() {
    this.isAdmin = this.luss.isAdmin;

    this.debouncer.subscribe((value) => {
      this.loadMore.emit(true)
      this.itemsCount++;
    });
  }

  initHomeGalleries() {
    const galleriesArray = this.galleries.filter(gal => gal.items.length > 0).map((gal) => new SimpleGallery(gal));
    this.homeGalleryItems = galleriesArray.map((gal) => gal.cover);

  }

  initProjectGalleries() {
    const array: SimpleGallery[] = [];
    // let galleriesArray= new BehaviorSubject([]);


    const galleriesArray = this.galleries[0].items.map(galleryItem => {
      // criar outro tipo de request para pegar somente os itens ativos
      const item: Gallery = {
        active: galleryItem.active ? true : false,
        coverid: galleryItem.galleryitemid,
        galleryid: this.galleries[0].galleryid,
        items: [galleryItem],
        name: 'projectGallery',
        published: galleryItem.active ? true : false,
        preview: galleryItem.preview,
        previewdata: galleryItem.previewdata,
        orderedpostpart: null
      };
      return new SimpleGallery(item);
    });
    this.homeGalleryItems = galleriesArray.map((gal) => gal.cover);
  }


  loadMoreGalleryItems(ev?, item?) {
    this.debouncer.next(true);


  }
  updateHomeGalleryImageSettings(
    simpleGalleryItem: SimpleGalleryItem,
    cropInfo: { x; y; height; width },
    aspectRatio: { x; y }
  ) {

    if (this.isProject) {

      this.store.dispatch(new UpdateGalleryImageSettings(
        simpleGalleryItem,
        {
          imagesettingid: simpleGalleryItem.imagesettings.imagesettingid,
          haspectratio: aspectRatio.y,
          waspectratio: aspectRatio.x,
          height: cropInfo.height,
          width: cropInfo.width,
          xcenter: cropInfo.x,
          ycenter: cropInfo.y,
        },
        'ProjectGalleries', this.galleries[0].galleryid, this.galleryType)).subscribe();
    } else {
      const gallery = this.galleries.find((gal) => gal.galleryid === simpleGalleryItem.galleryid);

      const updatedItems = gallery.items.map(
        gi => {
          const _galleryItem: GalleryItem = { ...gi };

          if (_galleryItem.galleryitemid === simpleGalleryItem.galleryitemid) {
            _galleryItem.imagesettings.xcenter = cropInfo.x;
            _galleryItem.imagesettings.ycenter = cropInfo.y;
            _galleryItem.imagesettings.height = cropInfo.height;
            _galleryItem.imagesettings.width = cropInfo.width;
            _galleryItem.imagesettings.haspectratio = aspectRatio.y;
            _galleryItem.imagesettings.waspectratio = aspectRatio.x;
          }

          return _galleryItem;
        }
      );


      this.updateGalleryImageSettings.emit();
      this.store.dispatch(new UpdateGalleryImageSettings({
        ...gallery,
        items: updatedItems
      },
        {
          imagesettingid: simpleGalleryItem.imagesettings.imagesettingid,
          haspectratio: aspectRatio.y,
          waspectratio: aspectRatio.x,
          height: cropInfo.height,
          width: cropInfo.width,
          xcenter: cropInfo.x,
          ycenter: cropInfo.y,
        },
        'ProjectGalleries')).subscribe();
    }

  }
  openCropDialog(simpleGalleryItem: SimpleGalleryItem) {
    const dialogRef = this.dialog.open(GalleryItemCropDialog, {
      width: '100%',
      maxWidth: '1072px',
      maxHeight: '768px',
      backdropClass: 'unh-dark-overlay',
      panelClass: ['unh-dialog', 'scrollable', 'dialog-style-reset'],
    });

    dialogRef.componentInstance.imageUrl = simpleGalleryItem.image;


    dialogRef
      .afterClosed()
      .subscribe(
        ({ cropInfo, aspectRatio }) =>
          cropInfo != null &&
          this.updateHomeGalleryImageSettings(
            simpleGalleryItem,
            cropInfo,
            aspectRatio
          )
      );
  }

  updateGallery(galleryItem?, published?, active?) {
    this.store.dispatch(new UpdateGallery(galleryItem.galleryid, published, active));
  }

  removeGallery(galleryItem) {
    this.unhd.confirm({
      text: {
        title: 'Você tem certeza?',
        message: `Você está prestes a deletar uma imagem.<br> Essa ação é irreversivel.`
      }
    }).subscribe(res => {
      res
        ? this.store.dispatch(new UpdateGalleryItem(galleryItem.galleryitemid, false, false, this.galleryType))
        // tslint:disable-next-line: no-unused-expression
        : null;
    });
  }
  onHomeGalleryEditModeChanged(editMode?) {
    this.galleryEditModeEnabled = editMode;
    this.onHomeGalleryListFilterChanged(editMode ? 'all' : 'default');
  }
  onHomeGalleryListFilterChanged(listfilter?) {
    this.filterEmmiter.emit(listfilter);
  }
  SyncRead(incoming) {
    const reader = new FileReader();
    reader.readAsDataURL(incoming.inputFile);
    reader.onload = () => (reader.result);
  }
  sanitize(url: string) {
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  async getPreviews(e) {
    /*TO-DO
      1- VER SE EXISTE OU NÃO (SE NÃO EXISTE ADICIONA UM NOVO PREVIEW)
      2- VER SE ESTA COMPLETO OU NAO (TALVEZ)
      3- DEPOIS ATUALIZAR O PROGRESSO SE FOR PROGRESS
      ENVIANADO PARA LÁ O ITEM ESPECIFICO E ATUALIZANDO SOMENTE O PROGRESS DO ITEM LÁ
      **DISPATCH NO ITEM, SE O ID BATER ENTÃO ELE ATUALIZA, SE NÃO, ADICIONA UM NOVO
    */

    const Gallery: Gallery = {
      active: true,
      coverid: 9619,
      galleryid: 972,
      items: [
        {
          galleryitemid: 9619,
          imagesettings: {
            imagesettingid: '9619',
            width: null,
            height: null,
            waspectratio: '1',
            haspectratio: '1',
            xcenter: '1',
            ycenter: '1',
          },
          mediaitem: {
            render: 'https://ik.imagekit.io/puzzl/mediaitems/public/20200813_230411_r53.mi',
            creator: {
              fullname: 'José Wilder',
              occupation: { name: 'Artist' },
              occupationtitle: '',
              profilepicture: 'https://ik.imagekit.io/puzzl/mediaitems/public/20200817_190554_r77.mi',
              username: 'joaopaulopreto',
              alias: ' Alias'
            }
          }
        },],
      name: 'IMAGEM DE PREVIEW',
      published: false,
      preview: true,
      previewdata: {
        fileid: 123,
        progress: 0,
      },
      orderedpostpart: null
    };
    const _galleryitempreview: GalleryItem = {
      galleryitemid: 10749,
      imagesettings: {
        haspectratio: '1',
        height: null,
        imagesettingid: '10781',
        waspectratio: '1',
        width: null,
        xcenter: '0',
        ycenter: '0',
      },
      mediaitem: {
        creator: {
          username: 'wilholt',
          profilepicture: '',
          fullname: 'José Wilder de Morais Venancio',
          occupationtitle: null,
          occupation: {
            name: 'Artist'
          },
          alias: 'Alias'
        },
        render: 'https://ik.imagekit.io/puzzl/mediaitems/public/20200915_191254_r09.mi'
      },
      preview: true,
      previewdata: {
        fileid: 0,
        progress: 0
      }
    };



    e.map(incoming => {
      // ************ GALLERY HANDLER GENÉRICO ************* //
      const currentState = this.store.selectSnapshot(GalleriesSelectors.getTopicGalleries(this.galleryType, this.galleries[0].galleryid))
        .items.filter(galleryItem => galleryItem.preview);

      _galleryitempreview.galleryitemid = incoming.position;
      _galleryitempreview.previewdata.fileid = incoming.position;

      const index = currentState.findIndex(galleryItem => galleryItem.previewdata.fileid == incoming.position);
      if (index == -1) {
        if (!incoming.completed && incoming.uploading && incoming.progress == 0) {

          _galleryitempreview.galleryitemid = incoming.position;
          _galleryitempreview.previewdata.fileid = incoming.position;
          _galleryitempreview.preview = true;
          _galleryitempreview.previewdata.progress = incoming.progress;
          _galleryitempreview.previewdata.completed = incoming.completed;
          // Side effect
          // create Blob-URL for File (=Blob) object
          const url = (URL || webkitURL).createObjectURL(incoming.inputFile);

          // set Blob-URL as image source:
          // _galleryitempreview.mediaitem.render = this.sanitize(url);
          _galleryitempreview.mediaitem.render = url;


          const reader = new FileReader();
          reader.readAsDataURL(incoming.inputFile);
          reader.onload = (ev: any) => {
            const previewimg: any = reader.result;
            // _galleryitempreview.mediaitem.render = previewimg
            const imagem = new Image();
            imagem.src = ev.target.result;
            imagem.onload = (() => {
              this.previewWidth = imagem.width;
              this.previewHeight = imagem.height;
            });
          };


          this.store.dispatch(new AddPreview(_galleryitempreview, this.projectId ? this.projectId : null, Gallery.galleryid ?
            this.galleries[0].galleryid : null, this.galleryType ? this.galleryType : null));

        }
      } else {
        if (incoming.completed && !incoming.uploading) {
          if (!this.uploadedImages.includes(incoming.position)) {
            const mutation = createImageSettings;

            const minSize = this.getImageMinSize({
              a: this.previewWidth,
              b: this.previewHeight
            });

            const ratio = 860 / this.previewWidth;

            const variables = {
              width: minSize * ratio,
              height: minSize * ratio,
              waspectratio: '1',
              haspectratio: '1',
              xcenter: '0',
              ycenter: '0',
            };

            this.graph.graphqlUpdate<'createImagesetting', { ImageSetting: any }>(
              mutation, 
              variables
            ).pipe(
                switchMap(res => {
                  const ImageSetting = res.data.createImagesetting.ImageSetting;
                  const mutation2 = createImageProject;
                  const isi = {
                    imagesettingid: Number(ImageSetting.imagesettingid),
                    mediaitemid: incoming.mediaFileData.fileid, galleryid: Number(this.galleries[0].galleryid)
                  };
                  return this.graph.graphqlUpdate(mutation2, isi);
                })
              ).subscribe(() => {
                const updateMediaItemCreatorMutation = updateMediaItemCreator;
                const variables = {
                  mediaitemid: Number(incoming.mediaFileData.fileid),
                  creatoruid: Number(this.creatorId)
                };
                this.graph.graphqlUpdate(updateMediaItemCreatorMutation, variables).subscribe(
                  () => {
                    if (this.uploadedImages.length == e.length) {
                      this.store.dispatch(new FetchProjectGallery(this.galleries[0].galleryid, this.galleryType));
                    }
                  }
                );

              });
            this.uploadedImages.push(incoming.position);
          }
        }

        _galleryitempreview.previewdata.progress = incoming.progress;
        _galleryitempreview.previewdata.completed = incoming.completed;
        
        this.store.dispatch(new UpdatePreview(
          _galleryitempreview, this.galleries[0].galleryid, this.galleryType));
      }
    });
  }

  private getImageMinSize(comparator: { a: number, b: number }) {
    const func = (obj, obj2) => {
      const max = Math.min(obj.val, obj2.val);
      return max === obj.val ? obj.name : obj2.name;
    };

    const minSizeKey = func(
      {
        name: 'a',
        val: comparator.a
      },
      {
        name: 'b',
        val: comparator.b
      }
    );

    return comparator[minSizeKey];
  }
}
