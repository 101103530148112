import { BehaviorSubject, Observable } from 'rxjs';
import { map, filter, tap, take, distinctUntilChanged } from 'rxjs/operators';

export abstract class BaseStore<T> {
    private readonly _store$: BehaviorSubject<T>;

    private _alreadyFetching: { [K in keyof T]?: boolean } = {};

    get snapshot(): T {
        return this._store$.value;
    }

    private get store$(): Observable<T> {
        return this._store$.asObservable();
    }

    constructor(initialValue: T = null) {
        this._store$ = new BehaviorSubject<T>(initialValue);
    }

    selectState<S = T[keyof T]>(selector: keyof T, refetch = false, payload?: any): Observable<S> {
        return this.store$.pipe(
            map(s => s[selector] as any),
            distinctUntilChanged(),
            tap(() => {
                if (!this._alreadyFetching[selector] || refetch) {
                    this._alreadyFetching = { ...this._alreadyFetching, [selector]: true };
                    this.fetchStoreStateData(selector, payload).subscribe(
                        updatedState => updatedState != null ? this.setStoreState(selector, updatedState) : void 0
                    );
                }
            })
        );
    }

    readState<S = T[keyof T]>(selector: keyof T): S {
        const storeStateSnapshot: any = this.snapshot[selector];
        return storeStateSnapshot;
    }

    findInCollection<S = StoreState<T>, C = StoreStateCollectionType<T>>(
        selector: StoreStateSelector<T>, prop: keyof C, value: C[keyof C]
    ): Observable<Unarray<S>> {
        return this.selectState<S>(selector).pipe(
            filter(collection => Array.isArray(collection) && collection.length > 0),
            map(collection => {
                if (!Array.isArray(collection)) {
                    throw new Error('[BaseStore findInCollection] Value is not a collection!');
                }

                return collection.find(obj => obj[prop] === value);
            }),
            take(1)
        );
    }

    protected abstract fetchStoreStateData<S = T[keyof T]>(selector: StoreStateSelector<T>, payload?: any): Observable<S>;

    protected setStoreState(selector: keyof T, state: StoreState<T>) {
        this._store$.next({ ...this.snapshot, [selector]: state });
    }
}

export type StoreStateCollectionType<T> = Unarray<StoreState<T>>;
export type StoreStateSelector<T> = keyof T;
export type StoreState<T> = T[keyof T];
export type Unarray<T> = T extends Array<infer U> ? U : T;
