export const getViewAllMentoringDataQuery = `
  query getViewAllMentoringData($postid: Int!){
    VideoPlayer(postid: $postid) {
      course {
        mentoringTopic{
          communityid
        }
      }
    }
  }
`;
