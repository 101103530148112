import { environment } from "@frontend/unhideschool/env";
import { ISideMenuMobileArgs } from "../interfaces/unh-sidebar-mobile.interface";

export const sideMenu: ISideMenuMobileArgs = {
	menuIsOpen: true,
	userIsLogged: false,
	handleLinkApp: 'https://www.unhideschool.com/',
	menuItemSidebar: [
		{
			icon: {
				url: environment.cdnUrl + '/home-sidebar-icon.svg',
			},
			content: {
				title: {
					open: 'Home'
				},
				path: '/home',
			},
			type: '',
		},
		{
			icon: {
				url: environment.cdnUrl + '/catalog-sidebar.svg',
			},
			content: {
				title: {
					open: 'Cursos'
				},
				path: '/cursos',
			},
			type: 'badge',
			count: 60,
		},
		{
			icon: {
				url: environment.cdnUrl + '/sidebar-prices-icon.svg',
			},
			content: {
				title: {
					open: 'Preços'
				},
				path: '/assinatura/planos-e-precos',
			},
		},
		{
			icon: {
				url: environment.cdnUrl + '/gallery.svg',
			},
			content: {
				title: {
					open: 'Unhide Gallery'
				},
				path: '/gallery',
			},
		},
		{
			icon: {
				url: environment.cdnUrl + '/contest-sidebar-icon.svg',
			},
			content: {
				title: {
					open: 'UNHIDE Contests'
				},
				path: '/forum/categoria/1/unhide-contests',
			},
		},
		// {
		// 	icon: {
		// 		url: environment.cdnUrl + '/menu-vagas.svg',
		// 	},
		// 	content: {
		// 		title: {
		// 			open: 'Trabalhe conosco'
		// 		},
		// 		path: '/trabalhe-conosco'
		// 	},
		// 	type: '',
		// }
	],

	subMenuSidebar: [
		{
			open: false,
			header: {
				icon: {
					url: environment.cdnUrl + '/community-sidebar-icon.svg',
				},
				title: {
					open: 'Comunidade'
				}
			},
			menus: [
				{
					icon: {
						url: environment.cdnUrl + '/forum-sidebar-icon.svg',
					},
					type: 'sub',
					content: {
						title: {
							open: 'Fórum'
						},
						path: '/forum'
					},
				},
				{
					icon: {
						url: environment.cdnUrl + '/voice-sidebar-icon.svg',
					},
					type: 'sub',
					content: {
						title: {
							open: 'UNHIDE Cast'
						},
						path: '/forum/topicos/9/comunidade/15/unhide-cast'
					},
				},
				{
					icon: {
						url: environment.cdnUrl + '/live-sidebar-icon.svg',
					},
					type: 'sub',
					content: {
						title: {
							open: 'UNHIDE Lives'
						},
						path: '/forum/topicos/9/comunidade/20/unhide-lives'
					},
				},
				{
					icon: {
						url: environment.cdnUrl + '/user-group-sidebar-icon.svg',
					},
					type: 'link',
					content: {
						title: {
							open: 'Facebook User Group'
						},
						path: 'https://www.facebook.com/groups/UNHIDE/'
					},
				},
				{
					icon: {
						url: environment.cdnUrl + '/svg-icons/main-menu/ico-twitch.svg',
					},
					type: 'link',
					content: {
						title: {
							open: 'Twitch Unhiders'
						},
						path: 'landing/twitch'
					},
				},
				{
					icon: {
						url: environment.cdnUrl + '/home-sidebar-icon.svg',
					},
					type: 'link',
					content: {
						title: {
							open: 'Discord User Group'
						},
						path: 'https://discord.gg/J7KhF8GcTH'
					},
				},
			],
		}
	],

	languages: [
		{
			languageLabel: 'Português Brasil',
			languageCode: 'pt-BR',
			icon: environment.cdnUrl + '/pt-lang-sidebar-icon.svg',
			label: 'Idioma',
		},
		{
			languageLabel: 'Inglês',
			languageCode: 'en-US',
			icon: environment.cdnUrl + '/en-us-flag.svg',
			label: 'Language'
		}
	],

	currentLang: {
		languageLabel: 'Português Brasil',
		languageCode: 'pt-BR',
		icon: environment.cdnUrl + '/pt-lang-sidebar-icon.svg',
		label: 'Idioma',
	},
}
