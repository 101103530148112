import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserAvatarComponent } from './user-avatar.component';
import {  MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [UserAvatarComponent],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule
  ],
  exports:[UserAvatarComponent],
})
export class UserAvatarModule { }
