import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';

@Component({
  selector: 'pzl-media-item',
  templateUrl: './media-item.component.html',
  styleUrls: ['./media-item.component.scss'],
})
export class MediaItemComponent implements OnInit {
  @Input() item: any;

  @ViewChild('#progressBar', { static: false }) pb!: ElementRef;
  
  color = 'primary';
  mode: any = 'determinate';
  bufferValue = 75;
  imgUrl!: ArrayBuffer | string | null;
  value: any;

  ngOnInit(): void {
    const reader = new FileReader();

    reader.readAsDataURL(this.item.inputFile);

    reader.onload = () => {
      this.imgUrl = reader.result;
    };

    this.value = this.item.progress;
  }
}
