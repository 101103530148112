interface BaseFormData {
    name?: string;
    internalname?: string;
    description?: string;
    datecreated?: string;
    enabled?: boolean;
}

export interface SubscriptionFormDataStoreModel {
    subscriptionstates: SubscriptionState[];
    memberstates: SubscriptionMemberState[];
    membertypes: SubscriptionMemberType[];
}

export interface SubscriptionState extends BaseFormData {
    subsstateid?: number;
}

export interface SubscriptionMemberState extends BaseFormData {
    subscriptionmemberstateid?: number;
}

export interface SubscriptionMemberType extends BaseFormData {
    subscriptionmembertypeid?: number;
}

export const defaultSubscriptionMemberFormData = {
    subscriptionstates: [],
    memberstates: [],
    membertypes: []
};

export type SubscriptionFormDataStoreState = SubscriptionState[] | SubscriptionMemberState[] | SubscriptionMemberType[];
