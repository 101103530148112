import { ActivePromotion } from "../application/application.model";
import { defaultEmptyFilterOption } from "@frontend/unhideschool/shared/models/constants/default-empty-option-filter";

/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchProjectPosts {
  static readonly type = "[ProjectPosts API] Fetch Posts";
  constructor(
    public page: number,
    public perpage: number,
    public reset = false,
    public activePromotion?: ActivePromotion,
    public filter?: any,
    public initItemsQuantity?: any
  ) {
    this.filter = filter ?? defaultEmptyFilterOption;
  }
}

/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchProjectPostOrderedPostParts {
  static readonly type = "[OrderedPostParts API] Fetch";
  constructor(
    public postid: number,
    public page?: number,
    public perpage?: number
  ) {}
}

/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchProjectPostRelatedUrlFiles {
  static readonly type = "[PostRelatedUrlFiles API] Fetch";
  constructor(public page?: number, public perpage?: number) {}
}

/**
 * Clear Project Post State
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class ClearProjectpostState {
  static readonly type = "[PostRelatedUrlFiles API] Fetch";
  constructor(public page?: number, public perpage?: number) {}
}

/**
 * change project url to lazyload image
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class updateProjectImageUrl {
  static readonly type = "[ProjectPosts API] Update Image Url";
  constructor(public postid: any) {}
}
