export const UploadImageMutation = `
mutation($file: Upload!, $mediatype: String!) {
  UploadMedia(file: $file, mediatype: $mediatype) {
      success
      mediaitem{
        mediaitemid
        render
        mediacontenttype{
          internalname
        }
      }
  }
}
`
