import { Component, Input, Renderer2, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { BreakpointObserver, MediaMatcher } from '@angular/cdk/layout';

@Component({
  selector: 'unh-side-menu',
  styleUrls: ['./side-menu.component.scss'],
  templateUrl: './side-menu.component.html'
})
export class SideMenuComponent {
  @Input() isOpen = false;
  @Input() position: 'right' | 'left' = 'left';
  @Input() withWarning:boolean
  @ViewChild('sideMenu', {static:false}) sideMenu:ElementRef

constructor(){}
}
