// Utils
import { environment } from '@frontend/unhideschool/env';
// Utils
import { CardScrollerOptionArgs } from '@frontend/web-components/src/components/interfaces/compositions/card-scroller-option/card-scroller-option.model';

export const mockCardScroller: CardScrollerOptionArgs = {
  modes: [
    {
      type: 'Administrador',
      operations: [
        {
          icon: {
            url: environment.cdnUrl + '/course-admin-icons/manage-course-icon.svg',
          },
          content: {
            title: {
              open: 'Edição de Cursos'

            },
            path: '/admin/curso'
          },
        },
        {
          icon: {
            url: environment.cdnUrl + '/banner-sidebar-icon.svg',
          },
          content: {
            title: {
              open: 'Banners',
            },
            path: 'admin/banners'
          },
        },
      ],
    },
  ],
  mode: 0,
};

export const menuSectionOne = {
  header: {
    icon: {
      url: environment.cdnUrl + '/arrow-tabmenu-icon.svg',
    },
    title: {
      open: 'UNHIDE School'
    },
  },
  open: true,
  permission: false,
  submenus: [
    {
      header: {
        icon: {
          url: environment.cdnUrl + '/community-sidebar-icon.svg',
        },
        title: {
          open: 'Comunidade',
          collapsed: 'Comunidade'
        },

      },
      open: false,
      menus: [
        {
          icon: {
            url: environment.cdnUrl + '/forum-sidebar-icon.svg',
          },
          type: 'sub',
          content: {
            title:{
              open: 'Fórum',
              collapsed: 'Fórum'
            },
          path: '/forum'

          },
        },
        {
          icon: {
            url: environment.cdnUrl + '/voice-sidebar-icon.svg',
          },
          type: 'sub',
          content: {
            title:{
              open: 'UNHIDE Cast',
              collapsed: 'Cast'
            },
            path: '/forum/topicos/9/comunidade/15/unhide-cast'
          },
        },
        {
          icon: {
            url: environment.cdnUrl + '/live-sidebar-icon.svg',
          },
          type: 'sub',
          content: {
            title:{
              open: 'UNHIDE Lives',
              collapsed: 'Lives'
            },
            path: '/forum/topicos/9/comunidade/20/unhide-lives'
          },
        },
        {
          icon: {
            url: environment.cdnUrl + '/user-group-sidebar-icon.svg',
          },
          type: 'link',
          content: {
            title:{
              open: 'Facebook User Group',
              collapsed: 'Facebook'
            },
            path:'https://www.facebook.com/groups/UNHIDE/'
          },
        },
        {
          type: 'link',
          icon: {
            url: environment.cdnUrl + '/svg-icons/main-menu/ico-twitch.svg',
          },
          content: {
            title:{
              open: 'Twitch Unhiders',
              collapsed: 'Twitch'
            },
            path: 'landing/twitch'

          },
        },
        {
          icon: {
            url: environment.cdnUrl + '/discord-sidebar-icon.svg',
          },
          type: 'link',
          content: {
            title:{
              open: 'Discord User Group',
              collapsed: 'Discord'
            },
            path: 'https://discord.gg/J7KhF8GcTH'
          },
        },
      ],
    },
  ],
  menus: [
    {
      icon: {
        url: environment.cdnUrl + '/home-sidebar-icon.svg',
      },
      content: {
        title:{
          open: 'Home',
          collapsed: 'Home'
        },
        path: '/home'
      },
    },
    {
      icon: {
        url: environment.cdnUrl + '/sidebar-prices-icon.svg',
      },
      content: {
        title:{
          open: 'Planos e Preços',
          collapsed: 'Preços'
        },
        path: '/assinatura/planos-e-precos'
      },
    },
    {
      icon: {
        url: environment.cdnUrl + '/catalog-sidebar.svg',
      },
      content: {
        title:{
          open: 'Todos os cursos',
          collapsed: 'Cursos'
        },
        path: '/cursos'
      },
    },
    {
      icon: {
        url: environment.cdnUrl + '/gallery.svg',
      },
      content: {
        title:{
          open: 'UNHIDE Gallery',
          collapsed: 'Gallery'
        },
        path: '/gallery'
      },
    },
    {
      icon: {
        url: environment.cdnUrl + '/contest-sidebar-icon.svg',
      },
      content: {
        title:{
          open: 'UNHIDE Contests',
          collapsed: 'Contests'
        },
        path: '/forum/categoria/1/unhide-contests'
      },
    },
  ],
};

export const menuSectionTwo = {
  header: {
    icon: {
      url: environment.cdnUrl + '/arrow-tabmenu-icon.svg',
    },
    title: {
      open: 'Gerenciar',
      collapsed: 'Gerenciar',
    },
  },
  open: false,
  permission: true,
  mode: mockCardScroller.mode,
  modes: mockCardScroller.modes,
  menus: [],
};
