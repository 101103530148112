import { SetSubscriptionWarning } from './../../../shared-nxgs/application/application.actions';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from '@frontend/unhideschool/env';
import { takeUntil, tap, map, } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { NotificationItem } from '@frontend/unhideschool/app/core/models/notification-item.model';
import { Select, Store } from '@ngxs/store';
import { UserSelectors } from '@frontend/unhideschool/app/shared-nxgs/user/user.selectors';
import { UserStateModel } from '@frontend/unhideschool/app/shared-nxgs/user/user.model';
import { FetchUser } from '@frontend/unhideschool/app/shared-nxgs/user/user.actions';
import { ApplicationSelectors } from '@frontend/unhideschool/app/shared-nxgs/application/application.selectors';
import { MainNavMenu, MainNavMenuModel } from '@frontend/unhideschool/app/utils/i18n/locale/main-nav/main-nav.i18n.model';
import { UseTranslations } from '@frontend/unhideschool/app/utils/i18n/use-translation.decorator';
import { UnreadUserNotification } from '../../models';
import { LoggedUserStateService } from '../../services/legacy/logged-user.state';
import { MenuOptionsStateService } from '../../services/legacy/menu-options.state';
import { SideMenuStateService } from '../../services/legacy/side-menu.state';
import { UserNotificationsStore } from '../../services/legacy/user-notifications.store';
import { getCroppedImageUrl } from '@frontend/unhideschool/app/helpers/relatedmedia-and-crop.helper';

interface Translations {
  "main-nav": MainNavMenuModel;
}

@Component({
  selector: 'unh-menu-container',
  templateUrl: './menu-container.component.html',
  styleUrls: ['./menu-container.component.scss']
})

@UseTranslations({
  translatableComponents: ["main-nav"]
})

export class MenuContainerComponent implements OnInit, OnDestroy {
  private destroyMenuOptionsObservable$ = new Subject();
  private currentCommunity: string;

  unreadNotifs$: Observable<UnreadUserNotification[]>;
  unreadNotifsCopy$: Observable<UnreadUserNotification[]>;

  defaultAvatarUrl = environment.defaultAvatarUrl;
  hasCourse = false;
  mainNavMenuIsOpen = false;
  allNotifications$: Observable<NotificationItem[]>;

  // TODO: remove unused one
  userAvatar = '';
  userAvatarUrl = '';

  navItemList: MainNavMenu[] = [];
  translations: Promise<Translations>;

  // Email activation top bar
  activationIncomplete = false;
  activationEmailLoading = false;
  activationEmailSent = false;

  get isScrolled() {
    return this.moss.isscrolled;
  }

  get isTransparent() {
    return this.moss.isTransparent;
  }


  @Select(UserSelectors.getUser())
  user$: Observable<UserStateModel>;

  @Select(ApplicationSelectors.hasSubscriptionWarning())
  hasWarning$: Observable<boolean>;

  constructor(
    public luss: LoggedUserStateService,
    private moss: MenuOptionsStateService,
    private smss: SideMenuStateService,
    private uns: UserNotificationsStore,
    private store: Store,
  ) {
    this.unreadNotifs$ = this.uns.selectState<any[]>('unreadNotifications');
    this.allNotifications$ = this.observeNotficationsState();
    //this.navItemList = mainNavListModel;

    this.store.dispatch(new FetchUser());

    // Verifica se usuário está logado mas não ativou a conta
    if (this.luss.isLogged && !this.luss.userProfileInfo.activated) {
      this.activationIncomplete = true;
      this.store.dispatch(new SetSubscriptionWarning(true));
    }
  }

  ngOnInit() {
    this.userAvatarUrl = this.luss.getProfilePicture();

    this.luss.user$.subscribe(user => {
      const { relatedmedia } = this.luss.userProfileInfo || {};

      if (relatedmedia) {
        const profilepicture = relatedmedia.find(rm => {
          const isProfilePicture = rm.mediacontenttype.internalname == 'profilepicture';
          return rm.mediacontenttype && rm.imagesettings && isProfilePicture;
        });

        if (profilepicture) {
          const imagesettings = profilepicture.imagesettings;
          const croppedCoverPicture = getCroppedImageUrl(user.profilepicture, imagesettings, 34, 860);
          this.userAvatar = croppedCoverPicture;
        }
      }
    });

    this.smss
      .getMenuOptionState('main_nav_menu', 'isopen')
      .pipe(takeUntil(this.destroyMenuOptionsObservable$))
      .subscribe(isopen => this.mainNavMenuIsOpen = isopen);

    this.user$.subscribe(user => {
      if (user.courses?.length !== 0) {
        this.hasCourse = true;
      }
    });

    this.translations.then((translations) => {
      this.navItemList = translations['main-nav'].mainNavMenu;
    });
  }

  observeNotficationsState() {
    const type = ['subscriptionwarning', 'subscriptioexpirednwarning', 'subscriptionbackwarning'];

    return this.uns.selectState<NotificationItem[]>('notifications').pipe(
      tap(value => {
        const [current] = value.filter(notif =>
          type.includes(notif.notifcateginternalname) && !notif.read);

        if (current && !current.read) {
          this.store.dispatch(new SetSubscriptionWarning(true));
        }
      })
    );
  }

  // todo: create new email activation system
  resendActivationEmail() {
    // this.activationEmailSent = true;
    // const userService = this.lbs.getService('UserService');
    // userService.resendActivationEmail();
  }

  checkHasWarning() {
    const type = [
      'subscriptionwarning', 
      'subscriptioexpirednwarning', 
      'subscriptionbackwarning'
    ];
    
    return this.allNotifications$.pipe(
      map(value => {
        const [current] = value.filter(({notifcateginternalname, read}) => {
          return type.includes(notifcateginternalname) && !read;
        });

        const shouldSetSubsWarn = current && !current.read

        if (shouldSetSubsWarn) {
          this.store.dispatch(new SetSubscriptionWarning(true));
        }

        return shouldSetSubsWarn;
      })
    );
  }

  ngOnDestroy() {
    this.destroyMenuOptionsObservable$.next(true);
    this.destroyMenuOptionsObservable$.complete();
  }

  toggleMobileMenu() {
    this.smss.setMenuOptions('main_nav_menu', {
      isopen: !this.mainNavMenuIsOpen,
      position: 'left'
    });
  }

  openNotificationsMenu() {
    this.smss.setMenuOptions('notifications_menu', { isopen: true });
  }

  openUserMenu() {
    this.smss.setMenuOptions('user_menu', { isopen: true });
  }

  openMobileMenu() {
    this.smss.setMenuOptions('mobile_user_menu_notifications', { isopen: true });
  }

  getProfilePicture() {
    return this.luss.getProfilePicture();
  }

}
