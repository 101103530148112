import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';
import { environment } from '@frontend/unhideschool/env';
import { LessonItem } from '@frontend/unhideschool/shared/models/project/lesson-item.model';
import { ProjectPost } from '@frontend/unhideschool/shared/models/project/project-post.model';
import { UtilityService } from '@frontend/unhideschool/shared/services/utility.service';

@Component({
  selector: 'unh-view-all-lesson-item',
  templateUrl: './view-all-lesson-item.component.html',
  styleUrls: ['./view-all-lesson-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewAllLessonItemComponent {
  @Input() project: ProjectPost;
  @Input() lessonItem: LessonItem;
  @Input() viewsLabel: string;
  @Input() likesLabel: string;
  @Input() commentsLabel: string;

  @Output() navigatedToLesson = new EventEmitter<boolean>();
  
  cdnUrl = environment.cdnUrl;

  get postUrl() {
    const titleslug = this.utils.slugify(this.project.title);
    const postparttitleslug = this.utils.slugify(this.lessonItem.title);
    return `/post/${this.project.postid}/${this.lessonItem.oppid}/${titleslug}/${postparttitleslug}`;
  }

  constructor(
    private utils: UtilityService,
    public luss: LoggedUserStateService,
    private router: Router
    ) { }

    goToLesson(){
      this.navigatedToLesson.emit(false);
      this.router.navigateByUrl(this.postUrl, {state: {isAdmin: this.luss.isAdmin}})
    }
}
