import { Component, ContentChild, ChangeDetectionStrategy } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';

@Component({
  selector: 'unh-quick-actions-item',
  styleUrls: ['./quick-actions-item.component.scss'],
  template: `
    <ng-content select="[mat-icon-button]"></ng-content>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickActionsItemComponent {
  @ContentChild(MatButton, { static: false })
  matButton: MatButton;

  @ContentChild(MatIcon, { static: false })
  matIcon: MatIcon;
}
