import { FocusMonitor } from '@angular/cdk/a11y';
import { HttpEventType } from '@angular/common/http';
import { Inject } from '@angular/core';
import { AfterViewInit, Component, INJECTOR, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CourseAdminLessonUpsetSelectors } from '@frontend/unhideschool/modules/course-admin/ngxs/lesson/course-admin-lesson.selector';
import { Store } from '@ngxs/store';
import { Observable, of, throwError } from 'rxjs';
import { catchError, filter, tap } from 'rxjs/operators';
import { Class } from 'utility-types';

interface DialogData {
  text?: {
    title?: string,
    message?: string
  },
  observer: Observable<any>,
  action?: Class<any>,
  progress: boolean,
  filesize: boolean,
  actionArgs:any
}
@Component({
  selector: 'unh-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, AfterViewInit {
  loadingProgress: number;
  constructor(
    private dialogRef: MatDialogRef<LoadingComponent>,
    private fm: FocusMonitor,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data?: DialogData
  ) { }

  ngOnInit(): void {
    const Action = this.data.action;

    if (this.data.observer != undefined) {
      this.data.observer.pipe(

      ).subscribe(res => {
        this.dialogRef.close(res)
      })
    }


    if (this.data.action != undefined) {
      if(this.data.actionArgs){
        this.store.dispatch(new Action(...this.data.actionArgs)).pipe(catchError(err => {
          this.dialogRef.close(false)
          return err
        })).subscribe(res => {
          this.dialogRef.close(true)
        })
        return;
      }
      this.store.dispatch(new Action).pipe(catchError(err => {
        this.dialogRef.close(false)
        return err
      })).subscribe(res => {
        this.dialogRef.close(true)
      })
    }

    if(this.data.action && this.data.progress){
      this.store.select(CourseAdminLessonUpsetSelectors.getVideoProgress()).subscribe( res => {
        this.loadingProgress = res;
      })
    }
  }
  ngAfterViewInit() {
    this.fm.stopMonitoring(document.getElementById('cancel-button'));

  }
  response(response: boolean) {
    this.dialogRef.close(response)

  }
}
