import { Injectable, OnDestroy } from '@angular/core';

import { fromEvent, Subject } from 'rxjs';
import { map, takeWhile, takeUntil } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class MenuOptionsStateService implements OnDestroy {
    private readonly _shouldListenScroll$ = new Subject();

    private _isscrolled = false;
    private _scrolledDistance = 0;
    private _istransparent = false;

    public _activeMenu = 'home'

    get isscrolled() {
        return this._isscrolled;
    }

    get scrolledDistance() {
        return this._scrolledDistance;
    }

    get isTransparent() {
        return this._istransparent;
    }

    

    constructor() {
        this.listenToWindowScrollEvent();
    }

    ngOnDestroy() {
        this._shouldListenScroll$.next({});
        this._shouldListenScroll$.complete();
    }

    setTransparentMenuOption(istransparent: boolean) {
        this._istransparent = istransparent;
    }

    listenToWindowScrollEvent() {
        fromEvent(window, 'scroll').pipe(
            map(() => Math.max(
                document.documentElement.scrollTop,
                document.body.scrollTop,
                document.scrollingElement.scrollTop
            )),
            takeUntil(this._shouldListenScroll$)
        )
        .subscribe({
            next: distance => {
                this._isscrolled = distance > 1;
                this._scrolledDistance = distance;
            }
        });
    }
}
