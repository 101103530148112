// Utils

import { environment } from "@frontend/unhideschool/env";
import { UserSideBar } from "@frontend/web-components/types/components/interfaces/compositions/sidebar/sidebar.model";

export const userSideBar: UserSideBar = {
  avatar: environment.cdnUrl + '/avatar-sidebar-icon.png',
  isPro: false,
  isBadge: false,
  work: 'Designer at UNHIDE SCHOOL',
  name: 'Unhide User',
  plan: {
    dataExpires: '14/07/21',
  },
  languages: [
    {
      label: 'Português Brasil',
      languageCode: 'pt-BR',
      icon: environment.cdnUrl + '/pt-lang-sidebar-icon.svg',
    },
    {
      label: 'Inglês',
      languageCode: 'en-US',
      icon: environment.cdnUrl + '/en-us-flag.svg',
    },
  ],
  currentLang: {
    label: 'Português Brasil',
    languageCode: 'pt-BR',
    icon: environment.cdnUrl + '/pt-lang-sidebar-icon.svg',
  },
  menus: [
    {
      icon: {
        url: environment.cdnUrl + '/meuscursos.svg',
      },
      content: {
        title: {
          open: 'Meus Cursos',
        },
        path: '/meus-cursos',
      },
      type: 'light',
    },
    {
      icon: {
        url: environment.cdnUrl + '/meuperfil.svg',
      },
      content: {
        title: {
          open: 'Meu perfil',
        },
        path: '/perfil',
      },
      type: 'light',
    },

    {
      icon: {
        url: environment.cdnUrl + '/notification-sidebar-icon.svg',
      },
      content: {
        title: {
          open: 'Notificações',
        },
        action: {
          type: 'action',
          name: 'show-notifications',
        },
      },
      count: 99,
      type: 'badge',
    },
    {
      icon: {
        url: environment.cdnUrl + '/sidebar-prices-icon.svg',
      },
      content: {
        title: {
          open: 'Meu Plano',
        },
        path: '/assinatura/plano',
      },
      type: 'light',
    },
    {
      icon: {
        url: environment.cdnUrl + '/password-sidebar-icon.svg',
      },
      content: {
        title: {
          open: 'Alterar minha senha',
        },
        action: {
          type: 'action',
          name: 'change-pass',
        },
      },
      type: 'light',
    },
    {
      title: 'Alterar meu e-mail',
      icon: {
        url: environment.cdnUrl + '/edit-email-icon.svg',
      },
      content: {
        title: {
          open: 'Alterar meu e-mail',
        },
        action: {
          type: 'action',
          name: 'change-email',
        },
      },
      type: 'light',
    },
    // {
    //   icon: {
    //     url: environment.cdnUrl + '/config-sidebar-icon.svg'
    //   },
    //   type: 'light',
    //   content: {
    //     open: 'Configurações'
    //   },
    // },
    {
      icon: {
        url: environment.cdnUrl + '/exit-sidebar-icon.svg',
      },
      type: 'light',
      content: {
        title: {
          open: 'Sair',
          collapsed: 'Sair',
        },
        path: '/meus-cursos',
        action: {
          type: 'action',
          name: 'logout',
        },
      },
    },
  ],
};
