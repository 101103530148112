export const signupFacebookMutation = `
  mutation SignupFacebookMutation(
    $alias: String!
    $accesstoken: String!
    $termaccept: Boolean!
    $datebirth: Date!
    $genderid: Int!
    $email: String!
  ) {
    FacebookSignup(signupData: {
      alias: $alias, 
      accesstoken: $accesstoken, 
      termaccept: $termaccept, 
      datebirth: $datebirth, 
      genderid: $genderid,
      email: $email
    }) {
      success
      authtoken {
        token
      }
    }
  }
`;
