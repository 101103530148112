import { AfterViewInit, Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterStateSnapshot } from '@angular/router';
import { CustomIconsRegistryService } from '@frontend/unhideschool/core/services/custom-icons-registry.service';
import { Select, Store } from '@ngxs/store';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';
import { Observable } from 'rxjs';
import { filter, map, mergeMap, take, tap } from 'rxjs/operators';
import { FetchPricingData, FetchInvitationCoupon, FetchUserCoursesIds } from './shared-nxgs/application/application.actions';
import { UserStateModel } from './shared-nxgs/user/user.model';
import { UserSelectors } from './shared-nxgs/user/user.selectors';

import { SeoTagsManagerService } from '@frontend/unhideschool/core/services/seo-tags-manager.service';
import { UnhDialog } from '@frontend/unhideschool/shared/services/unh-dialog.service';
import initIntercomCustomLauncher from './utils/intercom-custom-launcher';

import { environment } from '@frontend/unhideschool/env';
import { GoogleTagManagerService } from './core/services/google-tag-manager.service';
import { GTMVisitorsTypes } from './core/models/google-tag-manager-service.model';
import { MeInfoUser } from './core/models';
import { FetchPlans } from './modules/membership/ngxs/membership.action';
import { ta } from 'date-fns/locale';

@Component({
  selector: 'unh-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'unhideschool';

  @Select(UserSelectors.getUser())
  user$: Observable<UserStateModel>;

  constructor(
    private cirs: CustomIconsRegistryService,
    private luss: LoggedUserStateService,
    private router: Router,
    private ar: ActivatedRoute,
    private store: Store,
    private r2: Renderer2,
    private unhdialog: UnhDialog,
    private stmg: SeoTagsManagerService,
    private gtms: GoogleTagManagerService,
  ) {
    this.cirs.registerCustomIcons();
  }

  ngOnInit(): void {
    this.ar.queryParams.pipe(
      take(2),
      tap(() => this.store.dispatch(new FetchPricingData(this.getInviteCouponCode())))
    ).subscribe()
    this.setGTMVisitor();
    this.store.dispatch(new FetchPlans());
    this.luss.userProfileInfo$.subscribe(profile => {
      if (profile) {
        const email = profile.email;
        initIntercomCustomLauncher(profile.alias, email);

      } else {
        initIntercomCustomLauncher();

      }
    });

    if (this.luss.isLogged) {
      this.store.dispatch(new FetchUserCoursesIds())
    }

  }

  ngAfterViewInit() {
    this.checkAcceptance();
    this.router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      tap((e: NavigationEnd) => {
        this.checkCanonicalTags(e);
        this.handleBlogNavigationMetatags(e.url);

      })
    ).subscribe();

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.ar),
      map(route => {
        // tslint:disable-next-line: curly
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
      filter(route => route.outlet === 'primary'),
      mergeMap(route => route.data)
    ).subscribe(data => {
      if (data && data.menuOnTop) {
        this.r2.addClass(document.body, 'topic-overlay-override');
      } else {
        this.r2.removeClass(document.body, 'topic-overlay-override');
      }
    });
  }

  sendLoggedUserVisitorState(user: MeInfoUser) {
    const isSubscribed = this.luss.subscribed;
    const isNotSubscribedWithCourses = !this.luss.subscribed && this.luss.courseListIds.length > 0;
    if (isSubscribed) {
      return this.gtms.setVisitor(GTMVisitorsTypes.subscribed);
    }

    if (!isSubscribed) {
      return this.gtms.setVisitor(GTMVisitorsTypes.notSubscribed);
    }

    if (isNotSubscribedWithCourses) {
      return this.gtms.setVisitor(GTMVisitorsTypes.notSubscribedWithCourses);
    }

  }

  checkCanonicalTags = (to: RouterStateSnapshot | NavigationEnd) => {
    const indexOfViewallSubstring = to.url.indexOf('/view-all');
    const isGalleryUrl = to.url.indexOf('/gallery');

    const removeQueryParamsFromUrl = (url) => {
      return url.substring(0, url.indexOf('?'))
    }
    const handleWithQueryParams = () => {
      this.stmg.updateCanonicalTags(removeQueryParamsFromUrl((this.ar.snapshot as any)._routerState.url))

    }
    const handleWithoutQueryParams = () => {
      if (isGalleryUrl > 0) {
        this.stmg.setNoIndex();
      }

      if (indexOfViewallSubstring > 0) {
        return this.setViewallCanonicalTag(to.url.slice(indexOfViewallSubstring));
      }

      return this.stmg.updateCanonicalTags((this.ar.snapshot as any)._routerState.url, this.ar.snapshot)

    }

    if (Object.keys(this.ar.snapshot.queryParams).length > 0) {
      return handleWithQueryParams.call(this);

    }

    return handleWithoutQueryParams.call(this);
  }

  setViewallCanonicalTag(params) {
    this.stmg.updateCanonicalTags('/cursos' + params);
  }

  checkAcceptance() {
    const acceptancekey = 'accepted'
    const cookieacceptance = localStorage.getItem(environment.storagekeys.unhcookieaccept);
    if (!cookieacceptance || cookieacceptance != acceptancekey) {
      this.unhdialog.cookieConfirm();
    }
  }

  handleBlogNavigationMetatags(path: string) {
    const isBlog = path.includes('unhide-blog/topico/');
    const isBlogList = path.includes('237/unhide-blog') && !path.includes('topico/');
    if (isBlog) {
      const params = path.split('/');
      const postid = params[8];
      this.stmg.injectBlogMetaTags(postid);
    } else if (isBlogList) {
      this.stmg.updateTitle('UNHIDE Community');
      this.stmg.updatedMetas([
        { property: 'og:title', content: 'UNHIDE Community' },
        { property: 'og:description', content: 'Crie ou participe de discussões aqui.' },
        {
          property: 'og:image',
          content:
            'https://ik.imagekit.io/puzzl/mediaitems/public/20201112_181212_r60.mi?tr=w-1200,c-at_max',
        },
        { property: 'og:url', content: window.location.href },
      ]);
    }
  }

  getInviteCouponCode() {
    const { convite: invite = null } = this.ar.snapshot.queryParams;
    return invite;
  }

  setGTMVisitor() {
    this.luss.isLogged$
      .pipe(
        filter(isLogged => isLogged),
        tap(() => this.sendLoggedUserVisitorState(this.luss.user))
      ).subscribe();

    if (!this.luss.isLogged) {
      this.gtms.setVisitor(GTMVisitorsTypes.visitor);
    }
  }
}

