export const getUserMedias = `

query getUserMedias {
  me{
    user{
      coverpicture,
      profilepicture
      relatedmedia{
        render
        mediacontenttype{
          internalname
        }

        imagesettings{
          width
          height
          xcenter
          ycenter
          haspectratio
          waspectratio
        }
      }
    }
  }
}

`
