export const getUserDataQuery = `
query getUserData(
	$uid: Int!
){
 	Users(
    filters: [ { uid: { op: EQ, v: $uid } } ]
  ) {
    uid, email, username, fullname, profilepicture, 
    coverpicture, datejoined, activated, autoplay, shortbio, 
    active, alias, occupationtitle, availableForWork,

    interests {
      uid
      interestid
      interestIn
      studying
      interest {
        interestid
        name
      }
      haveExperience
    }

    role { 
      name, 
      internalname 
    },

    country { 
      code2, 
      countryid 
    }, 
    
    relatedmedia{
      render

      mediacontenttype{
        internalname
      }

      imagesettings{
        width
        height
        xcenter
        ycenter
        haspectratio
        waspectratio
      }
    }
  }
}
`;
