import { Component, Input, Output, EventEmitter } from '@angular/core';
import { environment } from '@frontend/unhideschool/env';

import { UserMenuItem, NotificationItem } from '../../models';

@Component({
  selector: 'unh-side-menu-container-mobile',
  templateUrl: './side-menu-container-mobile.component.html',
  styleUrls: ['../side-menu-container-mobile/side-menu-container-mobile.component.scss']
})
export class SideMenuContainerMobileComponent {
  selectedTab = 'userMenuTab';
  defaultAvatarUrl = environment.defaultAvatarUrl;

  @Input() isSubscribed = false;
  
  @Input() username!: string;
  @Input() uid: any;
  @Input() useravatarurl!: string;

  @Input() isOpen!: boolean;
  @Input() menuTitle!: string;
  @Input() userMenuItens!: UserMenuItem[];
  @Input() notificationsMenuItens!: NotificationItem[];
  @Input() unreadNotifs!: boolean;

  @Output() closeClicked = new EventEmitter();
  @Output() userMenuItemClicked = new EventEmitter();
  @Output() notificationsMenuItemClicked = new EventEmitter();

  close() {
    this.closeClicked.emit(true);
  }

  closeAtButton(event) {
    this.userMenuItemClicked.emit(event);
    this.closeClicked.emit(true);
  }
}
