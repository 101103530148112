export const getViewAllLessons = `
query getViewAllLessons($postid: Int!){
  VideoPlayer(postid: $postid) {
    course {
      playlist(page: 1, perpage: 200){
        items {
          postid
          orderedpostpartid
          groupname
          position
          postpartid
          public
          thumbnail
          active
          description
          title
          content
          comments{
            totalCount
          }
          views
          likes
        }
      }
    }
  }
}

`;
