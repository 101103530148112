export const GetVideoFilesQuery = `
query getVideoFiles($orderedpostpartid: Int){
  Video(orderedpostpartid:$orderedpostpartid){
    files{
      mediaitemid
      name
      render
      mediaadapter {
        internalname
        mediaadapterid
      }
      mediacontenttype {
        internalname
        mediacontenttypeid
      }
      content
      size
    }
  }
}
`;
