import { MeInfo } from '.';


export interface AuthData {
    token: string;
    me: MeInfo;
  }
  
export  enum AppLanguage {
    EN_US= 'en-US',
    PT_BR= 'pt-BR'
  }