export interface SideMenuListItem {
    isopen?: boolean;
    position?: 'left' | 'right';
}

export const initialSideMenuListState: SideMenuListItem = {
    isopen: false,
    position: 'left'
};

export class SideMenuList {
    [key: string]: SideMenuListItem;

    constructor(menus: string[]) {
        menus.forEach(menu => {
            this[menu] = initialSideMenuListState;
        });
    }
}
