import { HttpContext, HttpHeaders, HttpParams, HttpRequest } from '@angular/common/http';
import { throwError, of } from 'rxjs';

export interface ApiResponse<T> {
  dict: T;
  reason: string;
  success: boolean;
  errordata?: string;
}

export interface GenericDict<T> {
  [propname: string]: T;
}

export type ExternalResponse<T> = GenericDict<T>;
export type Response<T> = ApiResponse<Dict<T>>;
export type Dict<T> = GenericDict<T>;

export enum HttpMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put'
}

export interface UploadEvent<T> {
  status: 'progress' | 'complete' | 'error' | 'unknown';
  message: number | T | any;
  filekey?: string;
  success?: boolean;
}

export type BaseHttpOptionsResponseType = 'json' | 'text';
export type BaseHttpOptionsObserveType = 'body' | 'events';

interface BaseHttpRequest {
  headers?: HttpHeaders | {
    [header: string]: string | string[];
  };
  observe?: 'events';
  params?: HttpParams | {
    [param: string]: string | string[];
  };
  reportProgress?: boolean;
  responseType: 'text' | 'json';
  withCredentials?: boolean;
}

export interface CustomHttpOptions extends Partial<BaseHttpRequest> {
  fullUrl?: string;
}

export function handleApiErrors(res) {
  // Unhide API handling
  if ('success' in res) {
    return !res.success ? throwError(() => res.reason) : of(res);
  }

  // Graphql handling
  return res.errors ? throwError(() => res.errors) : of(res);
}
