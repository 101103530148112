import { Component, Input } from '@angular/core';

@Component({
  selector: 'pzl-media-list',
  templateUrl: './media-list.component.html',
  styleUrls: ['./media-list.component.scss']
})
export class MediaListComponent {
  listItem2: any;

  @Input() set listItem(list: any){
    this.listItem2 = list;
  }
}
