import { OrderedPostPart } from '@frontend/unhideschool/shared/models/dtos/ordered-post-part.model';
import { ProjectPost } from '@frontend/unhideschool/shared/models/project/project-post.model';

export interface PostRelatedUrlFiles {
  id: number;
  url: string;
  name: string;
}

export interface ProjectPostsStateModel {
  projectposts: ProjectPost[] | any[];
  orderedPostParts: { [k: number]: OrderedPostPart[] };
  postRelatedUrlFiles: { [k: number]: PostRelatedUrlFiles[] };
  postsLimit: boolean;
  actualFilterState: string | null;
}

export const defaultHomePostsState: ProjectPostsStateModel = {
  projectposts: [],
  orderedPostParts: {},
  postRelatedUrlFiles: {},
  actualFilterState: null,
  postsLimit: false
};

export const defaultEmptyCourse = {
  postid: 9999,
  title: 'loading',
  creator: {
    fullname: 'fullname',
    alias: 'alias'
  },
  galleries: [{
    galleryid: 0
  }],
  basictags: [
    {
      name: "3d",
      tagid: "155",
      tagtype: { internalname: "basic" }
    },
    {
      name: "ilustracao",
      slug: "ilustracao",
      tagid: 437
    },
    {
      name: "animacao",
      slug: "animacao",
      tagid: 483
    },
    {
      name: "fotografia",
      slug: "fotografia",
      tagid: 667
    },
    {
      name: "retoque",
      slug: "retoque",
      tagid: 54
    }
  ]
};

