import { NotificationItem } from "./notification-item.model";

abstract class BaseUserNotification {
    notificationid: number;
}

export class DBNotifCateg {
    notifcategid: number;
    name: string;
    internalname: string;
    datecreated: string;
    active: boolean;
}

export interface DBNotification extends BaseUserNotification {
    notifcategid: number;
    rootnotificationid?: any;
    uid: number;
    title: string;
    data: string;
    datecreated: string;
    seen: boolean;
    dateseen: string;
    read: boolean;
    dateread: string;
    notifcateg: DBNotifCateg;
    accepted?: boolean;
}

export interface NotificationDataModel {
    creator?: string;
    title?: string;
    postpartid?: number | string;
    postparttitle?: string;
    postpartcreatoralias?: string;
    postid?: number | string;
    posttitle?: string;
    posttype?: string;
    orderedpostpartid?: number | string;
    postpartcommentid?: number | string;
    text?: string;
    commenteralias?: string;
    commenterusername?: string;
    commenterpicture?: string;
    useralias?: string;
    roottext?: string;
    postpartthumbnail?: string;
    relatedmedia?: { postthumbnail: string };
    profilepicture?: string;
    fullname?: string;
    subscriptionid?: number;
    subscriptionmemberid?: number;
    accepted?: boolean;
    days?: string
}

export type NotificationItemImageType = 'avatar' | 'landscape' | 'portrait' | 'icony';

export interface UnreadUserNotification extends BaseUserNotification {
    read: boolean;
    notifcateg:{
        internalname:string
    }
}

export function isUnreadNotification(pet: NotificationItem | UnreadUserNotification): pet is UnreadUserNotification {
    return (pet as UnreadUserNotification).notifcateg !== undefined;
}