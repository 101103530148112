<section id="home-gallery">
  <ng-content select="unh-section-title"></ng-content>

  <div class="home-gallery__container">

    <ng-container>
      <unh-generic-gallery-admin-toolbar
      *ngIf="(!hideEditmodeSwitcher) && isAdmin"
        [listFilter]="gallerySelectedListFilter"
        [ngClass]="isProject ? 'isProject' : ''"
        [editMode]="galleryEditModeEnabled"
        (editModeChanged)="onHomeGalleryEditModeChanged($event.editModeEnabled)"
        (listFilterChanged)="onHomeGalleryListFilterChanged($event.listFilter)"
        [isProject]="isProject"
        [translations]="translations?.genericGalleryAdminToolbar"
        >
      </unh-generic-gallery-admin-toolbar>

      <div *ngIf="isProject && galleryEditModeEnabled" class="uploader-container">
        <pzl-media-uploader (itensEmmiter)="getPreviews($event)"></pzl-media-uploader>
      </div>
      <unh-generic-gallery layout="grid" trackByKey="galleryid"
        unhObserveOffsetBottom
        [enabled]="true"
        [extraOffset]="800"
        (willEnterViewport)="loadMoreGalleryItems()"
        [editMode]="galleryEditModeEnabled"
        [galleryItems]="homeGalleryItems"
        [isProject]="isProject? true : false"
        >


        <ng-template #genericGalleryItemTemplate let-galleryItem let-editMode="false">
          <div unhHoverObserver #hoverObs="hoverObs" style="height: 100%; width: 100%;">

            <unh-simple-gallery-item [galleryItem]="galleryItem" [hovered]="hoverObs.hovered" [editMode]="editMode"
              [galleryRoutePath]="galleryViewPath" 
              [galleryRouterQueryParams]="galleryRouterQueryParams"
              [isProject]="isProject" [galleryStatus]="galleryStatus"
              [topicRouteData]="galleryItem?.galleryTopicData?.urldata"
              [topicData]="galleryItem.galleryTopicData?.info"></unh-simple-gallery-item>
            <div style="position: absolute;bottom: 30px;left: 50%;transform: translateX(-50%)">
              <unh-quick-actions-container *ngIf="galleryEditModeEnabled" [showQuickActions]="hoverObs.hovered">
                <unh-quick-actions-item>
                  <button mat-icon-button title={{translations?.basicGalleryQuickActions?.coverCrop}} (click)="openCropDialog(galleryItem)">
                    <mat-icon svgIcon="unh_block_3"></mat-icon>
                  </button>
                </unh-quick-actions-item>

                <span qac-separator></span>

                <unh-quick-actions-item *ngIf="isProject">
                  <button mat-icon-button title={{translations?.basicGalleryQuickActions?.removeItem}} (click)="removeGallery(galleryItem)">
                    <mat-icon [svgIcon]="
                              'unh_box_in_trash'
                            "></mat-icon>
                  </button>
                </unh-quick-actions-item>

                <unh-quick-actions-item *ngIf="!isProject">
                  <button mat-icon-button title={{translations?.basicGalleryQuickActions?.archiveItem}} (click)="updateGallery(galleryItem, false, false)">
                    <mat-icon [svgIcon]="
                              'unh_box_in' +
                              (!galleryItem?.galleryActive ? '_active' : '')
                            "></mat-icon>
                  </button>
                </unh-quick-actions-item>

                <unh-quick-actions-item *ngIf="!isProject">
                  <button mat-icon-button title={{translations?.basicGalleryQuickActions?.publishItem}} (click)="updateGallery(galleryItem, true, true)">
                    <mat-icon [svgIcon]="
                              'unh_check_circle' +
                              (galleryItem?.galleryPublished ? '_active' : '')
                            "></mat-icon>
                  </button>
                </unh-quick-actions-item>
              </unh-quick-actions-container>
            </div>
            <!-- <pzl-skeleton  width="100%" height="100%" rounded="0"></pzl-skeleton> -->
          </div>

        </ng-template>
      </unh-generic-gallery>
    </ng-container>


  </div>
</section>
