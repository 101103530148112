export const fetchSubscriptionPlansProductQuery = `
query FetchSubscriptionPlans {
  SubscriptionPlan {
    all(onlyRootPlans: true, active: true) {
      items {
        subsplanid
        name
        internalname
        description
        planfrequencyid
        frequencyInterval
        entitlementid
        entitlementDuration
        rootplanid
        datecreated
        dateupdated
        active
        enabled
        discountcoupons{
          couponcode
        }
        prices {
          price
          subsplanpriceid
        }
        planfrequency {
          planfrequencyid
          internalname
          seconds
        }
        additionalinfo
      }
    }
  }
}

`;
