// Dependencies
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UseTranslations } from '@frontend/unhideschool/app/utils/i18n/use-translation.decorator';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';

// Utils
import { ISideMenuMobileArgs } from './interfaces/unh-sidebar-mobile.interface';
import { sideMenu } from './mocks/sidebar-mobile.mock';

// Services
import { RestApiService } from '@frontend/unhideschool/app/api-gateway/services/rest-api.service';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';

// imports user
import { environment } from '@frontend/unhideschool/env';
import { GetTotalCountCourses } from '../menu-content-mobile.query';
import { GraphqlApiService } from '@frontend/unhideschool/app/api-gateway/services/graphql-api.service';
import { AuthService } from '../../services/auth.service';
import { UnreadUserNotification } from '../../models';
import { SideMenuStateService } from '../../services/legacy/side-menu.state';

interface Translations {
  'unh-menu-mobile': ISideMenuMobileArgs;
}

@Component({
  selector: 'unh-menu-content-mobile',
  templateUrl: './menu-content-mobile.layout.html',
  styleUrls: ['./menu-content-mobile.layout.scss'],
})
@UseTranslations({
  translatableComponents: ['unh-menu-mobile'],
})
export class MenuContentMobileComponent implements OnInit {
  private destroyMenuOptionsObservable$ = new Subject();
  translations: Promise<Translations>;
  mainNavMenuIsOpen = true;
  currLanguage: any;
  notificationsOpen = false;
  sidebarTranslation;
  currentMenu = '';

  // props user
  hasCourse = false;
  unreadNotifs$: Observable<UnreadUserNotification[]>;
  defaultAvatarUrl = environment.defaultAvatarUrl;
  totalCount;
  mobileSidebarData: any = sideMenu;

  userAvatarUrl = '';

  constructor(
    public luss: LoggedUserStateService,
    private smss: SideMenuStateService,
    private rest: RestApiService,
    private router: Router,
    private graph: GraphqlApiService,
    private auths: AuthService
  ) {
    this.translations.then((translations) => {
      this.luss.isLogged$.subscribe((logged) => {
        this.mobileSidebarData.userIsLogged = logged;
      });
      this.resolveTranslations(translations);
    });
  }

  @Output() inputValueChanged = new EventEmitter();

  ngOnInit() {
    this.userAvatarUrl = this.luss.getProfilePicture();

    this.getAllCourses();

    this.smss
      .getMenuOptionState('main_nav_menu', 'isopen')
      .pipe(takeUntil(this.destroyMenuOptionsObservable$))
      .subscribe((isopen) => (this.mainNavMenuIsOpen = isopen));
  }

  getAllCourses() {
    this.graph
      .graphqlRequest<'Courses', any>(GetTotalCountCourses)
      .pipe(map(res => res.data.Courses)).subscribe(courses => {
        this.totalCount = courses.totalCount;
      });
  }

  openUserMenu() {
    this.smss.setMenuOptions('user_menu', { isopen: true });
  }

  toggleMobileMenu() {
    this.smss.setMenuOptions('main_nav_menu', {
      isopen: !this.mainNavMenuIsOpen,
      position: 'left',
    });
  }

  handleUserMenuItemClick(name: string | Event) {
    // this.closeMenu();

    if (name instanceof Event) {
      return;
    }

    // todo: create new dialogs: 'change-pass', 'change-email'

    const dialogManager = {} as any; // this.lbs.getService('DialogManager');

    switch (name) {
      case 'change-pass':
        dialogManager.changePassword();
        break;

      case 'change-email':
        dialogManager.changeEmail();
        break;

      case 'show-notifications':
        this.notificationsOpen = !this.notificationsOpen;
        break;

      case 'logout':
        this.logout();
        break;
    }
  }

  closeMenu(condition = true) {
    if (condition) {
      this.smss.closeAll();
    }
    this.notificationsOpen = !this.notificationsOpen;
  }


  // todo: review this after changes on user service
  private logout() {
    this.closeMenu();
    this.auths.logout();
  }

  emitInputValueChanged(evt: Event) {
    const { detail: menuItemTitle } = (evt as CustomEvent);
    this.router.navigate([menuItemTitle.path]).then(() =>
      this.smss.setMenuOptions('main_nav_menu', { isopen: false }));
  }

  menuChanged(evt: Event) {
    const { detail: menuItem } = (evt as CustomEvent);
    if (menuItem.action != undefined) {
      if (
        this.notificationsOpen &&
        menuItem.action.name == 'show-notifications'
      ) {
        this.observeInitialMenu(this.sidebarTranslation);
      }
      this.handleUserMenuItemClick(menuItem.action.name);
    } else {
      this.router.navigate([menuItem.secondLevel.path]) &&
        this.smss.setMenuOptions('main_nav_menu', {
          isopen: false,
        });
    }
  }

  subMenuChanged(evt: Event) {
    const { detail: subMenuItem } = (evt as CustomEvent);
    if (subMenuItem) {
      this.handleSubMenuChanged(subMenuItem);
    }

  }
  handleSubMenuChanged(subMenuItem) {
    if (subMenuItem?.action != undefined) {
      if (
        this.notificationsOpen &&
        subMenuItem?.action?.name == 'show-notifications'
      ) {
        this.observeInitialMenu(this.sidebarTranslation);
      }
      this.handleUserMenuItemClick(subMenuItem?.action?.name);
    } else {
      subMenuItem?.thirdLevel?.path &&  this.router.navigate([subMenuItem?.thirdLevel?.path]) &&
        this.smss.setMenuOptions('main_nav_menu', {
          isopen: false,
        });
    }
  }
  findMenu(r) {
    let path;
    let menu;
    try {
      path = window.location.pathname.includes('perfil')
        ? this.mobileSidebarData?.subMenuSidebar[0].menus[1].content.path
        : window.location.pathname;
      if (this.luss.subscribed && this.luss.isAdmin) {
        menu = this.mobileSidebarData?.subMenuSidebar[0].menus[1].content.path;

        if (menu !== undefined) {
          return menu;
        }
      }
      menu = this.mobileSidebarData?.menuItemSidebar.find(
        (item) => path == item.content.path
      );
    } catch (e) {
      console.log(e);
    }

    return menu;
  }

  languageChanged(evt: Event) {
    const { languageCode } = (evt as CustomEvent).detail;
    this.auths.setAcceptedLanguage(languageCode);
    setTimeout(() => document.location.reload(), 300);
  }

  loginChange() {
    this.router.navigate(['/login']);
  }

  signupChange() {
    this.router.navigate(['/signup']);
  }

  observeInitialMenu(r) {
    try {
      this.currLanguage = r.sidebar.languages.find(
        (lang) =>
          lang.languageCode == localStorage.getItem('unh-accept-language')
      );
      this.mobileSidebarData.currentLang = this.currLanguage;

      const path = window.location.pathname.includes('perfil')
        ? this.mobileSidebarData?.userMenu?.user?.menus[1].content.path
        : window.location.pathname;

      const menu = this.findMenu(r);

      const submenus = this.mobileSidebarData.subMenuSidebar[0].menus;

      const usermenu = this.mobileSidebarData.subMenuSidebar[0].menus.find(
        (m) => path == m.content.path
      );
      if (menu != undefined) {
        this.currentMenu = menu;
      } else if (usermenu != undefined) {
        this.currentMenu = usermenu.content.title.open;
      } else {
        let submenufound;

        for (const submenu of submenus) {
          submenufound = submenu.path;

          if (submenufound != undefined) {
            this.currentMenu = submenufound.content?.title?.open;
            break;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
  }

  resolveTranslations(translations: Translations) {
    const sidebar = translations['unh-menu-mobile'];
    this.sidebarTranslation = sidebar;
    sidebar.menuItemSidebar = [sidebar.menuItemSidebar];
    this.addManageMenuSection();
  }

  addManageMenuSection() {
    if (this.luss.isAdmin) {
      this.mobileSidebarData = {
        ...sideMenu,
        ...this.sidebarTranslation.sidebar,
      };
    } else {
      this.mobileSidebarData = { ...this.sidebarTranslation.sidebar };
    }
    this.observeInitialMenu(this.sidebarTranslation);
  }
}
