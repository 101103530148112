<div *ngIf="galleryItem.badges" class="badges-wrapper">
  <unh-post-badges-list [badges]="galleryItem.badges"> </unh-post-badges-list>
</div>

<div
  *ngIf="galleryItem; else skeleton"
  class="simple-gallery-item__container"
  [class.published]="editMode && galleryItem?.galleryPublished && !isProject"
  [class.archived]="editMode && !galleryItem?.galleryActive && !isProject"
>
  <div
    *ngIf="galleryItem.preview && !galleryItem.previewdata?.completed"
    class="preview-layer"
  >
    <mat-progress-bar
      mode="determinate"
      [value]="galleryItem.previewdata?.progress"
    ></mat-progress-bar>
  </div>

  <a
    [class.preview]="galleryItem.preview && !galleryItem.previewdata?.completed"
    rel="nofollow"
    class="simple-gallery-item"
    [routerLink]="itemRouteLink"
    [queryParamsHandling]="'preserve'"
    [state]="{
      galleryStatus: galleryStatus,
      asdialog: true,
      fromGallery: true
    }"
  >
    <img [src]="galleryItem?.thumbnail | safeImage" alt="" />

    <div class="container" *ngIf="!isProject">
      <h3 class="topic-title">
        {{ galleryItem?.galleryTopicData?.info?.title | getHTMLStringAsText }}
      </h3>

      <h4 class="creator">{{ galleryItem?.creator.alias }}</h4>
    </div>

    <div
      [class.isproject]="isProject"
      class="container"
      *ngIf="isProject && !editMode"
    >
      <div [class.admin]="editMode" class="lupa-icon"></div>
    </div>
  </a>
</div>

<ng-template #skeleton>
    <pzl-skeleton width="100%" height="100%" rounded="0">
    </pzl-skeleton>
</ng-template>