export const postRelatedUrlFilesQuery = `
query getProjectFiles($postid: Int) {
  VideoPlayer(postid: $postid) {
    course {
      files{
        name
        content
      }
    }
  }
}
`;
