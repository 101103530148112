/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchRecommendedLessons {
  static readonly type = '[RecommendedLessons API] Fetch';
  constructor(public islogged: boolean) { }
}

/**
 * Class that represents a NGXS Action
 * @param page Define the pagination current page.
 * @param perpage Limit the amount of items to paginate.
 * ***
 * {@link https://www.ngxs.io/concepts/actions NGXS Action Documentation}
 */
export class FetchLessonPostparts {
  static readonly type = '[LessonPostparts API] Fetch';
  constructor(public oppidlist: number[]) { }
}
