import { VideoPlayerCoursePlaylistVideo } from '@frontend/unhideschool/shared/models/dtos/video-player-course.model';
import { Post } from './../../../../../shared/models/dtos/posts.model';

interface LastWatchedLessonModel {
  orderedpostpartid: number;
  title: string;
}
export interface ViewallMentoringDataModel {
  communityid: number;
  path: string;
  communitycategid: number;
}
export interface ViewAllStateModel {
  lessons: VideoPlayerCoursePlaylistVideo[];
  othersPosts: Post[];
  othersPostsLimit: boolean;
  othersPostPagination: {
    page: number,
    perpage:number
  },
  mentoringData: ViewallMentoringDataModel;
  lastWatchedLesson: LastWatchedLessonModel;
}

export const defaultState: ViewAllStateModel = {
  othersPosts: [],
  othersPostsLimit: false,
  othersPostPagination: {
    page: 1,
    perpage:10
  },
  lessons: [],
  mentoringData: null,
  lastWatchedLesson: null
};

export interface FetchLastWatchedLessonPayload {
  courses: {
    lastwatched: LastWatchedLessonModel
  }[]
}