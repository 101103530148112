<div class="sidebar-mobile-wrapper">
  <div class="menu-container" [class.open]="mainNavMenuIsOpen">
    <unh-menu-toggle
      [layout]="mainNavMenuIsOpen ? 'times' : 'bars'"
      (clickHandler)="toggleMobileMenu()"
    ></unh-menu-toggle>

    <div class="logo-container">
      <unh-menu-logo></unh-menu-logo>
    </div>

    <div class="flex-separator"></div>

    <nav class="user-actions">
      <ng-container *ngIf="luss?.isLogged">
        <div class="btn-meus_cursos" *ngIf="hasCourse">
          <unh-nav-item-link
            title="Meus Cursos"
            target="_self"
            linkpath="/meus-cursos"
            icon="unh_meus_cursos"
            [scrollable]="false"
            iconSize="24px">
          </unh-nav-item-link>
        </div>

        <div class="avatar-container" (click)="openUserMenu()">
          <unh-user-avatar
            [avatarUrl]="userAvatarUrl"
            [username]="luss?.user?.alias"
            [uid]="luss?.user?.uid+''"
            [userSubscribed]="luss?.subscribed"
            [hasUnreadNotifs]="(unreadNotifs$ | async)?.length > 0"
            [redirectToProfile]="false"
            [defaultAvatarUrl]="defaultAvatarUrl"
          ></unh-user-avatar>
        </div>
      </ng-container>
    </nav>
  </div>
  <unh-side-menu-mobile
    [menuItemSidebar]="mobileSidebarData.menuItemSidebar"
    [subMenuSidebar]="mobileSidebarData.subMenuSidebar"
    [menuIsOpen]="mainNavMenuIsOpen"
    [userIsLogged]="mobileSidebarData.userIsLogged"
    [handleLinkApp]="mobileSidebarData.handleLinkApp"
    [languages]="mobileSidebarData.languages"
    [currLanguage]="currLanguage"
    [count]="totalCount"
    (emitLanguageSelector)="languageChanged($event)"
    (emitClickMenuItem)="menuChanged($event)"
    (emitClickSubMenu)="subMenuChanged($event)"
    (emitClickButtonLogin)="loginChange()"
    (emitClickButtonSignup)="signupChange()"
    (emitWriteInput)="emitInputValueChanged($event)"
    (sidebarTitle)="emitInputValueChanged($event)"
  ></unh-side-menu-mobile>
</div>
