<a
  class="user-menu-item"
  [routerLink]="[itemLink]"
  (click)="clickItem($event)"
  (mouseenter)="iconIsActive = true"
  (mouseleave)="iconIsActive = false"
>
  <unh-icon-container class="icon-container"
    [iconType]="itemIconType"
    [icon]="itemIcon"
    [active]="iconIsActive"
  >
  </unh-icon-container>

  <div class="text-container">
    <span>{{ itemTitle }}</span>
    <small class="muted">{{ itemSubtitle }}</small>
  </div>
</a>
