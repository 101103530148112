import { Component } from '@angular/core';

@Component({
  selector: 'unh-side-menu-header',
  styleUrls: ['./side-menu-header.component.scss'],
  template: `
    <div class="side-menu-header">
      <ng-content></ng-content>
    </div>
  `
})
export class SideMenuHeaderComponent {}
