import { Component, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { NotificationItem, NotificationAction } from '@frontend/unhideschool/app/core/models/notification-item.model';
import { environment } from '@frontend/unhideschool/env';
import { Router } from '@angular/router';

@Component({
  selector: 'unh-notifications-menu-item',
  styleUrls: ['./notifications-menu-item.component.scss'],
  templateUrl: './notifications-menu-item.component.html'
})
export class NotificationsMenuItemComponent {
  defaultAvatarUrl = environment.defaultAvatarUrl;

  @Input() notificationItem: NotificationItem;
  @Output() itemClicked = new EventEmitter();

  imageLoaded = false;

  @HostListener('click', ['$event'])
  onItemClick(evt: MouseEvent, action: NotificationAction = null) {
    if (this.notificationItem.disableNavigation) {
      evt.preventDefault();
      evt.stopPropagation();
    }

    // if (!this.notificationItem.read || action != null) {
    return this.itemClicked.emit({
      action: action != null ? action.type : null,
      item: this.notificationItem
    });
    // }
  }

  constructor(
    private router: Router
  ){}

  navigateTo(notificationItem) {
    this.router.navigate(!notificationItem.disableNavigation ? [notificationItem.originPath] : [], {
      skipLocationChange: false,
      queryParams: notificationItem.postpartcommentid && { _c: notificationItem.postpartcommentid },
      preserveFragment: false
    });

  }
}
