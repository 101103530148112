import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HybridizerComponent } from '@frontend/unhideschool/modules/hybridizer/hybridizer.component';
import { PreCheckoutGuard } from '@frontend/unhideschool/core/guards/pre-checkout.guard';
import { ViewallComponent } from '@frontend/unhideschool/modules/project/pages/view-all/viewall.component';
import { CheckoutOriginGuard } from '@frontend/unhideschool/core/guards/checkout-origin.guard';
import { AdminGuard } from '@frontend/unhideschool/core/guards/admin.guard';
import { CouponCodeGuard } from '@frontend/unhideschool/core/guards/couponcode.guard';
import { MenuContentFooterComponent } from '@frontend/unhideschool/core/layouts/menu-content-footer/menu-content-footer.layout';
import { EmptyContentComponent } from '@frontend/unhideschool/core/layouts/empty-content/empty-content.component';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/home',
  },

  // Layout vazio (sem menu, footer e sidebars)
  {
    path: '',
    component: EmptyContentComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('@frontend/unhideschool/modules/signin/signin.module').then((m) => m.SigninModule),
      },
      {
        path: 'landing',
        loadChildren: () =>
          import('@frontend/unhideschool/modules/landing/landing.module').then(
            (m) => m.LandingModule
          ),
      },
    ],
  },

  // Layout padrão (com menu, footer e sidebars)
  {
    path: '',
    component: MenuContentFooterComponent,
    children: [
      {
        path: 'aqualua',
        canActivate: [CouponCodeGuard],
        // Por padrao o router pede que seja declarado um component, redirectTo ou children
        children: [],
      },
      {
        path: 'randall',
        canActivate: [CouponCodeGuard],
        // Por padrao o router pede que seja declarado um component, redirectTo ou children
        children: [],
      },
      {
        path: 'pixel',
        canActivate: [CouponCodeGuard],
        // Por padrao o router pede que seja declarado um component, redirectTo ou children
        children: [],
      },
      {
        path: 'monjinho',
        canActivate: [CouponCodeGuard],
        // Por padrao o router pede que seja declarado um component, redirectTo ou children
        children: [],
      },
      {
        path: 'rafatuma',
        canActivate: [CouponCodeGuard],
        // Por padrao o router pede que seja declarado um component, redirectTo ou children
        children: [],
      },
      {
        path: 'polerito',
        canActivate: [CouponCodeGuard],
        // Por padrao o router pede que seja declarado um component, redirectTo ou children
        children: [],
      },
      {
        path: 'home',
        canActivate: [CheckoutOriginGuard],
        loadChildren: () =>
          import('@frontend/unhideschool/modules/new-home/new-home.module').then((m) => m.NewHomeModule),
      },
      {
        path: 'cursos',
        canActivate: [CheckoutOriginGuard],
        loadChildren: () => import('@frontend/unhideschool/modules/course-list/course-list.module').then(m => m.CourseListModule),

      },
      {
        path: 'gallery',
        loadChildren: () =>
          import('@frontend/unhideschool/modules/gallery/gallery.module').then(
            (m) => m.GalleryModule
          ),
      },

      {
        path: 'view-all/:postid/:posttitleslug',
        component: ViewallComponent,
      },

      //player
      {
        path: 'post',
        loadChildren: () =>
          import('@frontend/unhideschool/modules/player/player.module').then((m) => m.PlayerModule),
      },

      // Membership
      {
        path: 'assinatura',
        loadChildren: () =>
          import('@frontend/unhideschool/modules/membership/membership.module').then(
            (m) => m.MembershipModule
          ),
      },
      // Route para fazer checkout de nova assinatura
      {
        path: 'checkout/:subsplan',
        canActivate: [PreCheckoutGuard],
        // Por padrao o router pede que seja declarado um component, redirectTo ou children
        children: [],
      },
      {
        path: 'forum',
        loadChildren: () =>
          import('@frontend/unhideschool/modules/forum/forum.module').then((m) => m.ForumModule),
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('@frontend/unhideschool/modules/course-admin/course-admin.module').then(
            (m) => m.CourseAdminModule
          ),
          
      },
      {
        path: '',
        loadChildren: () =>
          import('@frontend/unhideschool/modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'trabalhe-conosco',
        canActivate: [],
        loadChildren: () =>
          import('@frontend/unhideschool/modules/work-with-us/work-with-us.module').then(
            (m) => m.WorkWithUsModule
          ),
      },
      {
        path: 'admin',
        canActivate: [AdminGuard],
        loadChildren: () =>
          import('@frontend/unhideschool/modules/banner-admin/banner-admin.module').then(
            (m) => m.BannerAdminModule
          ),
      },
      {
        path: '**',
        component: HybridizerComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'disabled'
    }),
  ],
  exports: [RouterModule],
  providers: [PreCheckoutGuard],
})
export class AppRoutingModule { }
