import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { CheckoutService } from '@frontend/unhideschool/core/services/checkout.service';
import { LoggedUserStateService } from '@frontend/unhideschool/app/core/services/legacy/logged-user.state';

@Injectable()
export class PreCheckoutGuard implements CanActivate {

    constructor(private ckts: CheckoutService, private luss: LoggedUserStateService, private router: Router) { }

    canActivate(
        route: ActivatedRouteSnapshot): Observable<boolean> | boolean {

        if (!this.luss.isLogged) {
            const stateName = 'root.user.subscription.plans';
            const r_hash = btoa(stateName);
            this.router.navigate(['/login'], { queryParams: { r: r_hash }});
            return false;
        }

        const subsplan = route.params.subsplan;
        const obs = this.ckts.checkoutSubscriptionByType(subsplan);

        obs.subscribe({
            error: err => {
                console.log(err);
                throw new Error('Não foi possível iniciar o checkout da assinatura.');
            }
        });

        return obs.pipe(map(() => false));
    }
}
