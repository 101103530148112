export const createCredentialsFormData = (credentials, file) => {
  const credentialsData = new FormData();
  credentialsData.append('policy', credentials.clientPayload.policy);
  credentialsData.append('key', credentials.clientPayload.key);
  credentialsData.append('x-amz-signature', credentials.clientPayload.xAmzSignature);
  credentialsData.append('x-amz-algorithm', credentials.clientPayload.xAmzAlgorithm);
  credentialsData.append('x-amz-date', credentials.clientPayload.xAmzDate);
  credentialsData.append('x-amz-credential', credentials.clientPayload.xAmzCredential);
  credentialsData.append('success_action_status', '201');
  credentialsData.append('success_action_redirect', '');
  credentialsData.append('file', file);
  return credentialsData;
}


export const filterThumbnailUploadCompleted = (res) => {
  return res != undefined && res.status == 'complete';
}
