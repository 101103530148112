<unh-modalx
[open]="true"
class="full-avatar-modal"
full
[radius]="'12px'"
[width]="width"
[height]="height"
[maxWidth]="maxWidth"
[maxHeight]="maxHeight"
[isMobile]="isMobile"
(unhClose)="close()"
>
  <ng-template unhDialogRef></ng-template>
</unh-modalx>
