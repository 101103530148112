
export const GetAllCourseCreatorsQuery = `
query fetchAllCourseCreators {
  Users(
    page:1,
    perpage: 200,
  filters: [{roleid: {op: EQ, v: 3}}], orderBy: {f: alias, o: ASC}) {
    uid
    alias
    profilepicture
    relatedmedia {
      render
      imagesettings {
        imagesettingid
        width
        height
        waspectratio
        haspectratio
        xcenter
        ycenter
      }
      mediacontenttype {
        internalname
      }
    }
    role {
      datecreated
      internalname
    }
  }
}
`
