export enum SubscriptionMemberStates {
    INVITED = 'invitationsent',
    ACCEPTED = 'invitationaccepted',
    DECLINED = 'invitationdeclined',
    REMOVED = 'removedmember',
    CANCELED = 'canceledmember',
}

export enum SubscriptionMemberTypes {
    ADMIN = 'admin',
    MEMBER = 'member'
}

export enum SubscriptionStates {
    ACTIVE = 'active',
    CANCELLED = 'cancelled'
}
