export const fetchGalleriesQuery = `
    query FilteredGalleries(
        $page: Int!,
        $perpage: Int!,
        $filters: [GalleryQueryParams]!,
        $orderby: GalleryOrderByParams
    ) {
        Galleries(
            page: $page,
            perpage: $perpage,
            filters: $filters,
            orderBy: $orderby
        ){
            name,
            galleryid,
            coverid,
            published,
            active,
            datecreated,
            items {
                active,
                galleryitemid,
                imagesettings {
                    imagesettingid,
                    width,
                    height,
                    waspectratio,
                    haspectratio,
                    xcenter,
                    ycenter
                },
                mediaitem {
                    render,
                    creator
                    {
                        username,
                        profilepicture,
                        fullname,
                        occupationtitle,
                        occupation {
                            name
                        },
                        alias

                    }
                }
            }
            orderedpostpart{
                postid
                postpartid
                post{
                  title
                  community{
                    communitycategid
                  }
                  badges{
                    postbadgeid
                    badge{
                        name
                        internalname
                      }
                  }
                },
                postpart{
                  subtitle
                }
              }
        }
    }
`;