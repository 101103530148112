import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@frontend/unhideschool/env';

@Injectable({
    providedIn: 'root'
})
export class SvgIconInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const regex = new RegExp('^' + environment.cdnUrl + '[^.]+.svg$');
        const isCDNSvgRequest = regex.test(req.url);

        if (isCDNSvgRequest) {
            const svgReqHeaders = req.headers;
            svgReqHeaders.set('Content-Type', 'image/svg+xml');
            const svgReq = req.clone({ headers: svgReqHeaders });
            return next.handle(svgReq);
        }

        return next.handle(req);
    }
}
