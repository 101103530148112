import { Injectable } from '@angular/core';

import { RestApiService } from './rest-api.service';

import {
    UHQLRequestOptions,
    UHQLRequestData,
    UHQLRequestFilter,
    UHQLRequestMethods,
    UHQLRequestSchema
} from '@frontend/unhideschool/core/models/base-uh-crude.model';

import { UHQLRequestResources } from '@frontend/unhideschool/core/models/uhql-database-resources.model';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UHQLApiService {
    constructor(private rest: RestApiService) { }

    uhqlRequest(
        methodKey: keyof typeof UHQLRequestMethods,
        resourceKey: keyof typeof UHQLRequestResources,
        schema: UHQLRequestSchema = {},
        filters: UHQLRequestFilter[] = [],
        options: UHQLRequestOptions = {}
    ) {
        const endpoint = `/crud/${UHQLRequestMethods[methodKey]}`;
        let rdata: UHQLRequestData = { resource: UHQLRequestResources[resourceKey], ...options };

        if (filters.length > 0) {
            rdata = { ...rdata, filters };
        }

        if (Object.keys(schema).length > 0) {
            rdata = { ...rdata, schema };
        }

        return this.rest.post(endpoint, rdata);
    }
}
