import { Component, Output, EventEmitter, ElementRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'pzl-dropzone',
  templateUrl: './dropzone.component.html',
  styleUrls: ['./dropzone.component.scss']
})
export class DropzoneComponent {
  @Input() theme!: boolean;
  @Input() multiple!: boolean;

  @Output() fileEmitter = new EventEmitter<any[]>()
  @ViewChild('inputFile') inputFile!: ElementRef;


  fileState: any[] = [];
  hasLargeFile = false;

  // FILES = [1,2,3 ]
  onFileDropped(event: Event) {
    const target = event.target as HTMLInputElement;
    const filesArray = Array.from(target.files as FileList);

    filesArray.forEach(file => {
      const filesize = file.size / 1024 / 1024;

      if (filesize > 10) {
        alert(`O tamanho do arquivo ${file.name} é : ` + file.size / 1024 / 1024 + "MB, por favor, envie em tamanho menor que 10MB");
        this.hasLargeFile = true
        this.inputFile.nativeElement.value = "";
      } else {
        this.hasLargeFile = false
      }
    });

    if (!this.hasLargeFile) {
      this.fileState = [...this.fileState, ...filesArray]
      this.fileEmitter.emit(this.fileState);
      this.inputFile.nativeElement.value = "";
    }
  }
}
