<!-- <input type="file" (change)="fileChangeEvent($event)" /> -->
<div class="spinner-wrapper">
    <unh-spinner-loader *ngIf="loadingImage" size="small"></unh-spinner-loader>
</div>
<image-cropper
    [hidden]="loadingImage"
    format="png"
    [imageFile]="imageFile"
    [maintainAspectRatio]="true"
    [aspectRatio]="aspectRatio"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>
