export const createImageProject = `
mutation (
    $imagesettingid: Int!, 
    $galleryid: Int!, 
    $mediaitemid: Int!,
  ) {
      createGalleryitem(
     galleryitemData:{
              imagesettingid: $imagesettingid,
              galleryid: $galleryid,
              mediaitemid: $mediaitemid
      })
        {
        GalleryItem{
          galleryitemid
        }
      }
  }
  
`;